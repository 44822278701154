import axios from 'axios';
import useAuth from '../hooks/useAuth';


const BASE_URL = 'http://localhost:3010/api';

const baseURL = process.env.NODE_ENV === 'production' ? "/api" : "http://localhost:3010/api";
// const baseURL = process.env.NODE_ENV === 'production' ? "/api" : "http://frayudaanggara.my.id/api";


export const axiosBasic = axios.create({
    baseURL : baseURL
});

export const axiosForm = axios.create({
  baseURL : baseURL
});

const axiosApi = axios.create({
    baseURL : baseURL
});

axiosForm.interceptors.request.use(
  async config => {
    const response = await axios.get(`${baseURL}/auth/refreshToken`);
    const accessToken = response.data.accessToken;
    config.headers = { 
      'Authorization': `Bearer ${accessToken}`,
      'Accept': 'application/json',
      'Content-Type': 'multipart/form-data'
    }
    return config;
  },
  error => {
    Promise.reject(error)
});

axiosApi.interceptors.request.use(
    async config => {
      const response = await axios.get(`${baseURL}/auth/refreshToken`);
      const accessToken = response.data.accessToken;
      config.headers = { 
        'Authorization': `Bearer ${accessToken}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
      return config;
    },
    error => {
      Promise.reject(error)
  });
  
  // Response interceptor for API calls
  // axiosApi.interceptors.response.use((response) => {
  //   return response
  // }, async function (error) {
  //   const originalRequest = error.config;
  //   if (error.response.status === 403 && !originalRequest._retry) {
  //     originalRequest._retry = true;
  //     const access_token = await refreshAccessToken();            
  //     axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
  //     return axiosApi(originalRequest);
  //   }
  //   return Promise.reject(error);
  // });



// export default axios.create({
//     baseURL : BASE_URL
// })

export default axiosApi;