import React, { useEffect, useState } from "react";
import Cards, { CardsSmall } from "../component/Cards";
import ContainerCards from "../component/ContainerCards";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import Button from "react-bootstrap/esm/Button";
import { Modals } from "../component/ModalPopup";
import axiosApi, { axiosForm } from "../api/axios";
import { TablePaginationSelect } from "../component/TableData";
import {
  FormImpaksiKelas,
  FormImpaksiKlasifikasi,
  FormImpaksiPosisi,
  FormPenilaian,
  FormSingle,
  FormSingleAnak,
  FormSingleBleed,
  FormSinglePoket,
} from "../component/Forms";
import useAuth from "../hooks/useAuth";
import "./Modal.css";
import ListGroup from "react-bootstrap/ListGroup";
import {
  AssesmentKeteranganImpaksiGigi,
  KeteranganStatusGeligi,
} from "../component/AssesmentKeterangan";
import AsyncSelect from "react-select/async";
import axios from "axios";
import { ModalConfirmation } from "../component/ModalPopup";
import { ToastPopup } from "../component/Toastbar";
import { useNavigate } from "react-router-dom";
import Tooltip from "../component/Tooltip";
import HeaderAssesment from "../component/HeaderAssesment";
import Select from "react-select";

const AssesmentAdd = () => {
  const [pasienName, setPasienName] = useState("");
  const { auth } = useAuth();
  const navigate = useNavigate();
  const [pasien, setPasien] = useState([]);
  const [page, setPage] = useState(0);
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [query, setQuery] = useState("");
  const [dataPasien, setDataPasien] = useState({});
  const [showPopup, setShowPopup] = useState(false);
  const [riwayatSistematik, setRiwayatSistematik] = useState("");
  const [age, setAge] = useState("");
  const [blok, setBlok] = useState([]);
  const [tahunAjaran, setTahunAjaran] = useState([]);
  const [isUpload, setIsUpload] = useState(true);

  const [assesmentData, setAssesmentData] = useState({
    tanggalKunjungan: "",
    blokId: "",
    tahunAjaranId: "",
    namaPemeriksaId: auth.id,
    pasienId: "",
    image_filename: [],
  });
  const [type, setType] = useState("");

  const [renderResponden, setRenderResponden] = useState(false);
  const [renderPemeriksa, setRenderPemeriksa] = useState(false);
  const [renderAssesment, setRenderAssesment] = useState(false);
  const [renderImpaksi, setRenderImpaksi] = useState(false);
  const [renderPeriodontal, setRenderPeriodontal] = useState(false);
  const [renderAssesmentDataLain, setRenderAssesmentDataLain] = useState(false);

  const [data, setData] = useState("");
  const [option, setOption] = useState([]);
  const [optionList, setOptionList] = useState([]);

  const [additional, setAdditional] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [showToastUpload, setShowToastUpload] = useState(false);

  const [response, setResponse] = useState({});
  const [kelas18, setKelas18] = useState(true);
  const [kelas28, setKelas28] = useState(true);
  const [kelas38, setKelas38] = useState(true);
  const [kelas48, setKelas48] = useState(true);

  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [imageFileNameList, setImageFileNameList] = useState([]);
  const [tindakanPerawatan, setTindakanPerawatan] = useState([]);

  const [diagnosis,setDiagnosis] = useState([]);
  const [tindakan, setTindakan] = useState([]);


  const handleUpload = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    handleUploadFiles(chosenFiles);
  };

  useEffect(() => {
    const getTindakan = async () => {
      const response2 = await axiosApi.get("/setting/tindakan/findList");
      const response3 = await axiosApi.get("/setting/diagnosis/findList");

      setTindakan(response2?.data?.result);
      setDiagnosis(response3?.data?.result);

    };
    getTindakan();
  }, []);

  const [message, setMessage] = useState("");

  const loadTindakan = async (inputValue, callBack) => {
    const result = await axiosApi.get(
      `setting/tindakan/findList?search_query=${inputValue}`
    );
    callBack(result.data.result);
  };

 

  const upload = async () => {
    try {
      const formData = new FormData();
      uploadedFiles.forEach((item) => {
        formData.append("filename", item);
      });
      formData.append("destination", "images");
      formData.append("create_thumbnail", true);

      const result = await axiosForm.post("/upload/create", formData);
      setAssesmentData({
        ...assesmentData,
        image_filename: result.data.path,
      });
      setIsUpload(false);
      setMessage("Upload berhasil");
      setResponse(result);
      setShowToastUpload(true);
    } catch (error) {
      setMessage("Upload gagal");

      setResponse(error.response);
      setShowToastUpload(true);
    }
  };

  const handleUploadFiles = (files) => {
    const uploaded = [...uploadedFiles];
    files.some((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
      }
    });
    setUploadedFiles(uploaded);
  };

  const loadOption = async (inputValue, callBack) => {
    const result = await axios.get(
      `https://clinicaltables.nlm.nih.gov/api/icd10cm/v3/search?sf=code,name&terms=${inputValue}`,
      {
        withCredentials: false,
      }
    );
    let temp = [];
    result.data[3].forEach((element) => {
      const item = {
        value: element[0],
        label: element[0] + " | " + element[1],
      };
      temp.push(item);
    });
    callBack(temp);
  };

  const initialStateASGGAkar = {
    a11: "",
    a12: "",
    a13: "",
    a14: "",
    a15: "",
    a16: "",
    a17: "",
    a18: "",
    a21: "",
    a22: "",
    a23: "",
    a24: "",
    a25: "",
    a26: "",
    a27: "",
    a28: "",
    a31: "",
    a32: "",
    a33: "",
    a34: "",
    a35: "",
    a36: "",
    a37: "",
    a38: "",
    a41: "",
    a42: "",
    a43: "",
    a44: "",
    a45: "",
    a46: "",
    a47: "",
    a48: "",
  };
  const initialStateASGGMahkota = {
    m11: "",
    m12: "",
    m13: "",
    m14: "",
    m15: "",
    m16: "",
    m17: "",
    m18: "",
    m21: "",
    m22: "",
    m23: "",
    m24: "",
    m25: "",
    m26: "",
    m27: "",
    m28: "",
    m31: "",
    m32: "",
    m33: "",
    m34: "",
    m35: "",
    m36: "",
    m37: "",
    m38: "",
    m41: "",
    m42: "",
    m43: "",
    m44: "",
    m45: "",
    m46: "",
    m47: "",
    m48: "",
    m51: "",
    m52: "",
    m53: "",
    m54: "",
    m55: "",
    m61: "",
    m62: "",
    m63: "",
    m64: "",
    m65: "",
    m71: "",
    m72: "",
    m73: "",
    m74: "",
    m75: "",
    m81: "",
    m82: "",
    m83: "",
    m84: "",
    m85: "",
  };
  const initialStateASPPoket = {
    p11: "",
    p12: "",
    p13: "",
    p14: "",
    p15: "",
    p16: "",
    p17: "",
    p18: "",
    p21: "",
    p22: "",
    p23: "",
    p24: "",
    p25: "",
    p26: "",
    p27: "",
    p28: "",
    p31: "",
    p32: "",
    p33: "",
    p34: "",
    p35: "",
    p36: "",
    p37: "",
    p38: "",
    p41: "",
    p42: "",
    p43: "",
    p44: "",
    p45: "",
    p46: "",
    p47: "",
    p48: "",
    p51: "",
    p52: "",
    p53: "",
    p54: "",
    p55: "",
    p61: "",
    p62: "",
    p63: "",
    p64: "",
    p65: "",
    p71: "",
    p72: "",
    p73: "",
    p74: "",
    p75: "",
    p81: "",
    p82: "",
    p83: "",
    p84: "",
    p85: "",
  };
  const initialStateASPBleed = {
    b11: "",
    b12: "",
    b13: "",
    b14: "",
    b15: "",
    b16: "",
    b17: "",
    b18: "",
    b21: "",
    b22: "",
    b23: "",
    b24: "",
    b25: "",
    b26: "",
    b27: "",
    b28: "",
    b31: "",
    b32: "",
    b33: "",
    b34: "",
    b35: "",
    b36: "",
    b37: "",
    b38: "",
    b41: "",
    b42: "",
    b43: "",
    b44: "",
    b45: "",
    b46: "",
    b47: "",
    b48: "",
    b51: "",
    b52: "",
    b53: "",
    b54: "",
    b55: "",
    b61: "",
    b62: "",
    b63: "",
    b64: "",
    b65: "",
    b71: "",
    b72: "",
    b73: "",
    b74: "",
    b75: "",
    b81: "",
    b82: "",
    b83: "",
    b84: "",
    b85: "",
  };
  const initialStateASIG = {
    kelas18: "",
    kelas28: "",
    kelas38: "",
    kelas48: "",
    posisi18: "",
    posisi28: "",
    posisi38: "",
    posisi48: "",
    klasifikasi18: "",
    klasifikasi28: "",
    klasifikasi38: "",
    klasifikasi48: "",
  };
  const initialStateDataLain = {
    maloklusiDental: "",
    kehilanganPerlekatan1716: "",
    kehilanganPerlekatan4746: "",
    kehilanganPerlekatan11: "",
    kehilanganPerlekatan31: "",
    kehilanganPerlekatan2627: "",
    kehilanganPerlekatan3637: "",
    fluorosisEmail: "",
    keparahanErosiGigi: "",
    keparahanJumlah: "",
    lesiMukosaOral: "",
    kebutuhanPerawatanSegera: "",
    gigiTiruanAtasTigaTahunRA: "",
    gigiTiruanAtasTigaTahunRB: "",
    traumaDentalStatus: "",
    traumaDentalJumlah: "",
    tablePenilaianDebris: "",
    tablePenilaianKalkulus: "",
    indeksOhis: "",
    tindakanPerawatan: "",
    deskripsi: "",
    diagnosisKlinis: "",
  };

  const [assesmentSGGAkar, setAssesmentSGGAkar] =
    useState(initialStateASGGAkar);

  const [assesmentSGGMahkota, setAssesmentSGGMahkota] = useState(
    initialStateASGGMahkota
  );

  const [assesmentSPPoket, setAssesmentSPPoket] =
    useState(initialStateASPPoket);

  const [assesmentSPBleed, setAssesmentSPBleed] =
    useState(initialStateASPBleed);

  const [assesmentSIG, setAssesmentSIG] = useState(initialStateASIG);

  const [assesmentDataLain, setAssesmentDataLain] =
    useState(initialStateDataLain);

  const [penilaianDebris, setPenilaianDebris] = useState({
    bukal16: "",
    labial11: "",
    bukal26: "",
    lingual46: "",
    labial31: "",
    lingual36: "",
  });

  const [penilaianKalkulus, setPenilaianKalkulus] = useState({
    bukal16: "",
    labial11: "",
    bukal26: "",
    lingual46: "",
    labial31: "",
    lingual36: "",
  });

  const updatePenilaianDebris = (e) => {
    setPenilaianDebris({
      ...penilaianDebris,
      [e.target.name]: e.target.value,
    });
  };

  const updatePenilaianKalkulus = (e) => {
    setPenilaianKalkulus({
      ...penilaianKalkulus,
      [e.target.name]: e.target.value,
    });
  };

  const updateAssesmentData = (e) => {
    setAssesmentData({
      ...assesmentData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    const response = await axiosApi.get(
      `/pasien/view?search_query=${pasienName}&page=${page}&limit=${10}`
    );
    setPasien(response.data.result);
    setShowPopup(true);
  };

  const changePage = ({ selected }) => {
    setPage(selected);
  };

  const onSelect = async (e) => {
    const response = await axiosApi.get(`/pasien/riwayat?id=${e.id}`);
    setDataPasien(e);
    setShowPopup(false);
    setRiwayatSistematik(response.data.toString());
    calculateAge(e.tanggal_lahir);
    setRenderResponden(true);
  };

  const select = {
    name: "Pilih",
    handle: onSelect,
  };


  const onNextResponden = async () => {
    const response = await axiosApi.get("/setting/blok/findList");
    const response1 = await axiosApi.get("/setting/tahunAjaran/findList");

    setAssesmentData({ ...assesmentData, pasienId: dataPasien.id });
    setBlok(response?.data?.result);
    setTahunAjaran(response1?.data?.result);
    setRenderPemeriksa(true);
  };

  const calculateAge = (param) => {
    let dob = new Date(param);
    let month_diff = Date.now() - dob.getTime();
    let age_dt = new Date(month_diff);

    let year = age_dt.getUTCFullYear();

    let age = Math.abs(year - 1970);
    setAge(age.toString());
  };

  const updateAssesmentSGGAkar = (e) => {
    setAssesmentSGGAkar({
      ...assesmentSGGAkar,
      [e.target.name]: e.target.value,
    });
  };

  const updateAssesmentSGGMahkota = (e) => {
    setAssesmentSGGMahkota({
      ...assesmentSGGMahkota,
      [e.target.name]: e.target.value.toUpperCase(),
    });
  };

  const updateAssesmentSIG = (e) => {
    setAssesmentSIG({
      ...assesmentSIG,
      [e.target.name]: e.target.value.toUpperCase(),
    });
  };

  const updateAssesmentBleed = (e) => {
    setAssesmentSPBleed({
      ...assesmentSPBleed,
      [e.target.name]: e.target.value.toUpperCase(),
    });
  };

  const updateAssesmentPoket = (e) => {
    setAssesmentSPPoket({
      ...assesmentSPPoket,
      [e.target.name]: e.target.value.toUpperCase(),
    });
  };

  const updateAssesmentDataLain = (e) => {
    setAssesmentDataLain({
      ...assesmentDataLain,
      [e.target.name]: e.target.value.toUpperCase(),
    });
  };

  const nextPemeriksaanGigiDanMulut = (e) => {
    e.preventDefault();
    setRenderAssesment(true);
  };

  const changeType = (e) => {
    setType(e.target.value);
    setAssesmentSGGAkar(initialStateASGGAkar);
    setAssesmentSGGMahkota(initialStateASGGMahkota);
    setAssesmentSPBleed(initialStateASPBleed);
    setAssesmentSPPoket(initialStateASPPoket);
    setAssesmentSIG(initialStateASIG);
    setAdditional(false);
  };

  const [showConfirmation, setShowConfirmation] = useState(false);

  const confirmSubmit = async (e) => {
    e.preventDefault();
    setShowConfirmation(true);
  };

  const submitAssesment = async () => {
    try {
      const data = {
        type,
        assesmentData,
        assesmentDataLain,
        assesmentSGGAkar,
        assesmentSGGMahkota,
        assesmentSIG,
        assesmentSPBleed,
        assesmentSPPoket,
        penilaianDebris,
        penilaianKalkulus,
      };
      const response = await axiosApi.post("/assesment/create", { data: data });
      setShowConfirmation(false);
      setResponse(response);
      setShowToast(true);

      const sleep = (ms) => new Promise((r) => setTimeout(r, ms));
      await sleep(1000);

      navigate("/assesment", { replace: true });
    } catch (error) {
      console.log(error.response);
      setResponse(error.response);
      setShowConfirmation(false);
      setShowToast(true);
    }
  };

  const toImpaksiGigi = (e) => {
    e.preventDefault();
    // disableImpaksi();
    setRenderImpaksi(true);
  };
  const toPeriodontal = (e) => {
    e.preventDefault();
    setRenderPeriodontal(true);
  };
  const toAssesmentDataLain = (e) => {
    e.preventDefault();
    setRenderAssesmentDataLain(true);
  };

  const handleTindakan = (e) => {
    let temp = [];
    e.forEach((e) => {
      temp.push(e.label);
    });
    setAssesmentDataLain({
      ...assesmentDataLain,
      "tindakanPerawatan": temp.toString(),
    });
  };

  const handleChangeTindakanPerawatan = (e) => {
    let temp = [];
    e.forEach((e) => {
      temp.push(e.label);
    });
    setAssesmentDataLain({
      ...assesmentDataLain,
      "diagnosisKlinis": temp.toString(),
    });
  };

  const handleBlok = (e) => {
    setAssesmentData({
      ...assesmentData,
      "blokId": e.value,
    });
  };


  const handleTahunAjaran = (e) => {
    setAssesmentData({
      ...assesmentData,
      "tahunAjaranId": e.value,
    });
  };

  console.log(assesmentDataLain);

  const disableImpaksi = () => {
    if (assesmentSGGAkar.a18 === "8" || assesmentSGGMahkota.m18 === "8") {
      setKelas18(false);
    } else {
      setKelas18(true);
    }
    if (assesmentSGGAkar.a28 === "8" || assesmentSGGMahkota.m28 === "8") {
      setKelas28(false);
    } else {
      setKelas28(true);
    }
    if (assesmentSGGAkar.a38 === "8" || assesmentSGGMahkota.m38 === "8") {
      setKelas38(false);
    } else {
      setKelas38(true);
    }
    if (assesmentSGGAkar.a48 === "8" || assesmentSGGMahkota.m48 === "8") {
      setKelas48(false);
    } else {
      setKelas48(true);
    }
  };

  const onChange18Mahkota = (e) => {
    setAssesmentSGGMahkota({
      ...assesmentSGGMahkota,
      [e.target.name]: e.target.value.toUpperCase(),
    });
    if (e.target.value === "8") {
      setKelas18(false);
    } else {
      setKelas18(true);
    }
  };

  const onChange18Akar = (e) => {
    setAssesmentSGGAkar({
      ...assesmentSGGAkar,
      [e.target.name]: e.target.value.toUpperCase(),
    });
    if (e.target.value === "8") {
      setKelas18(false);
    } else {
      setKelas18(true);
    }
  };

  const onChange28Mahkota = (e) => {
    setAssesmentSGGMahkota({
      ...assesmentSGGMahkota,
      [e.target.name]: e.target.value.toUpperCase(),
    });
    if (e.target.value === "8") {
      setKelas28(false);
    } else {
      setKelas28(true);
    }
  };

  const onChange28Akar = (e) => {
    setAssesmentSGGAkar({
      ...assesmentSGGAkar,
      [e.target.name]: e.target.value.toUpperCase(),
    });
    if (e.target.value === "8") {
      setKelas28(false);
    } else {
      setKelas28(true);
    }
  };

  const onChange38Mahkota = (e) => {
    setAssesmentSGGMahkota({
      ...assesmentSGGMahkota,
      [e.target.name]: e.target.value.toUpperCase(),
    });
    if (e.target.value === "8") {
      setKelas38(false);
    } else {
      setKelas38(true);
    }
  };

  const onChange38Akar = (e) => {
    setAssesmentSGGAkar({
      ...assesmentSGGAkar,
      [e.target.name]: e.target.value.toUpperCase(),
    });
    if (e.target.value === "8") {
      setKelas38(false);
    } else {
      setKelas38(true);
    }
  };

  const onChange48Mahkota = (e) => {
    setAssesmentSGGMahkota({
      ...assesmentSGGMahkota,
      [e.target.name]: e.target.value.toUpperCase(),
    });
    if (e.target.value === "8") {
      setKelas48(false);
    } else {
      setKelas48(true);
    }
  };

  const onChange48Akar = (e) => {
    setAssesmentSGGAkar({
      ...assesmentSGGAkar,
      [e.target.name]: e.target.value.toUpperCase(),
    });
    if (e.target.value === "8") {
      setKelas48(false);
    } else {
      setKelas48(true);
    }
  };
  return (
    <>
      <ContainerCards>
        <Cards title="PASIEN">
          <Form onSubmit={handleSearch}>
            <Row>
              <Col xl={6}>
                <Form.Group as={Row} className="mb-3" controlId="username">
                  <Form.Label column xl="4">
                    Nama Pasien
                  </Form.Label>
                  <Col xl="8">
                    <Form.Control
                      type="text"
                      required
                      autoComplete="off"
                      onChange={(e) => {
                        setPasienName(e.target.value);
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col xl={2}>
                <Button className="primary" type="submit">
                  Search
                </Button>
              </Col>
            </Row>
          </Form>
        </Cards>

        {renderResponden && (
          <Cards title="PEMERIKSAAN GIGI RESPONDEN">
            <Row>
              <Col xl={6}>
                <Form.Group as={Row} className="mb-3" controlId="namaResponden">
                  <Form.Label column xl="4">
                    Nama Responden
                  </Form.Label>
                  <Col xl="8">
                    <Form.Control
                      type="text"
                      autoComplete="off"
                      disabled
                      defaultValue={dataPasien.name}
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col xl={6}>
                <Form.Group as={Row} className="mb-3" controlId="tanggalLahir">
                  <Form.Label column xl="4">
                    Tanggal Lahir
                  </Form.Label>
                  <Col xl="8">
                    <Form.Control
                      type="date"
                      autoComplete="off"
                      disabled
                      defaultValue={dataPasien.tanggal_lahir}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xl={6}>
                <Form.Group as={Row} className="mb-3" controlId="jenisKelamin">
                  <Form.Label column xl="4">
                    Jenis Kelamin
                  </Form.Label>
                  <Col xl="8">
                    <Form.Control
                      type="text"
                      autoComplete="off"
                      disabled
                      defaultValue={dataPasien.jenis_kelamin}
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col xl={6}>
                <Form.Group as={Row} className="mb-3" controlId="umur">
                  <Form.Label column xl="4">
                    Umur
                  </Form.Label>
                  <Col xl="8">
                    <Form.Control
                      type="text"
                      autoComplete="off"
                      disabled
                      defaultValue={age}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xl={12}>
                <Form.Group as={Row} className="mb-3" controlId="riwayat">
                  <Form.Label column xl="2">
                    Riwayat Sistematik
                  </Form.Label>
                  <Col xl="10">
                    <Form.Control
                      type="text"
                      autoComplete="off"
                      disabled
                      defaultValue={riwayatSistematik}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xl={12}>
                <Button className="primary float-end" onClick={onNextResponden}>
                  Next
                </Button>
              </Col>
            </Row>
          </Cards>
        )}

        {renderPemeriksa && (
          <Cards title="KETERANGAN PEMERIKSA GIGI">
            <Form onSubmit={nextPemeriksaanGigiDanMulut}>
              <Row>
                <Col xl={6}>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="tanggalLahir"
                  >
                    <Form.Label column xl="5">
                      Tanggal Kunjungan
                    </Form.Label>
                    <Col xl="7">
                      <Form.Control
                        name="tanggalKunjungan"
                        type="date"
                        autoComplete="off"
                        onChange={updateAssesmentData}
                        required
                        value={assesmentData.tanggalKunjungan}
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col xl={6}>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="namaResponden"
                  >
                    <Form.Label column xl="5">
                      Nama Pemeriksa Gigi
                    </Form.Label>
                    <Col xl="7">
                      <Form.Control
                        name="namaPemeriksaId"
                        type="text"
                        autoComplete="off"
                        disabled
                        defaultValue={auth.name}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xl={6}>
                  <Form.Group as={Row} className="mb-3" controlId="blok">
                    <Form.Label column xl="5">
                      Blok
                    </Form.Label>
                    <Col xl="7">
                      <Select
                        options={blok}
                        onChange={handleBlok}
                        placeholder="Pilih salah satu"
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col xl={6}>
                  <Form.Group as={Row} className="mb-3" controlId="tahunAjaran">
                    <Form.Label column xl="5">
                      Tahun Ajaran
                    </Form.Label>
                    <Col xl="7">
                      <Select
                        options={tahunAjaran}
                        onChange={handleTahunAjaran}
                        placeholder="Pilih salah satu"
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xl={12}>
                  <Button className="primary float-end" type="submit">
                    Next
                  </Button>
                </Col>
              </Row>
            </Form>
          </Cards>
        )}
        {renderAssesment && (
          <Cards title="STATUS GIGI GELIGI">
            <Row className="mb-5">
              <Col xl={12}>
                <Tooltip>
                  <KeteranganStatusGeligi />
                </Tooltip>
                <div className="col-xl-3 float-end">
                  <Form.Select autoFocus value={type} onChange={changeType}>
                    <option value="">-Pilih salah satu-</option>
                    <option value="dewasa">Dewasa</option>
                    <option value="anak-anak">Anak-anak</option>
                  </Form.Select>
                </div>
              </Col>
            </Row>

            {type === "dewasa" && (
              <Form onSubmit={toImpaksiGigi}>
                <div className="d-flex flex-row flex-wrap overflow-auto">
                  <div className="d-flex ">
                    <div className="width-box align-middle">
                      <p className="pt-2">M</p>
                    </div>

                    <div className="d-flex me-4">
                      <FormSingle
                        autoFocus={true}
                        placeholderInput="18"
                        name="m18"
                        onChangeInput={onChange18Mahkota}
                      />
                      <FormSingle
                        placeholderInput="17"
                        name="m17"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                      <FormSingle
                        placeholderInput="16"
                        name="m16"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                      <FormSingle
                        placeholderInput="15"
                        name="m15"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                      <FormSingle
                        placeholderInput="14"
                        name="m14"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                      <FormSingle
                        placeholderInput="13"
                        name="m13"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                      <FormSingle
                        placeholderInput="12"
                        name="m12"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                      <FormSingle
                        placeholderInput="11"
                        name="m11"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                    </div>
                    <div className="d-flex">
                      <FormSingle
                        placeholderInput="21"
                        name="m21"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                      <FormSingle
                        placeholderInput="22"
                        name="m22"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                      <FormSingle
                        placeholderInput="23"
                        name="m23"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                      <FormSingle
                        placeholderInput="24"
                        name="m24"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                      <FormSingle
                        placeholderInput="25"
                        name="m25"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                      <FormSingle
                        placeholderInput="26"
                        name="m26"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                      <FormSingle
                        placeholderInput="27"
                        name="m27"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                      <FormSingle
                        placeholderInput="28"
                        name="m28"
                        onChangeInput={onChange28Mahkota}
                      />
                    </div>

                    <div className="width-box align-middle">
                      <p className="pt-2">M</p>
                    </div>
                  </div>

                  <div className="d-flex ">
                    <div className="width-box align-middle">
                      <p className="pt-2">A</p>
                    </div>

                    <div className="d-flex me-4">
                      <FormSingle
                        placeholderInput="18"
                        name="a18"
                        onChangeInput={onChange18Akar}
                      />
                      <FormSingle
                        placeholderInput="17"
                        name="a17"
                        onChangeInput={updateAssesmentSGGAkar}
                      />
                      <FormSingle
                        placeholderInput="16"
                        name="a16"
                        onChangeInput={updateAssesmentSGGAkar}
                      />
                      <FormSingle
                        placeholderInput="15"
                        name="a15"
                        onChangeInput={updateAssesmentSGGAkar}
                      />
                      <FormSingle
                        placeholderInput="14"
                        name="a14"
                        onChangeInput={updateAssesmentSGGAkar}
                      />
                      <FormSingle
                        placeholderInput="13"
                        name="a13"
                        onChangeInput={updateAssesmentSGGAkar}
                      />
                      <FormSingle
                        placeholderInput="12"
                        name="a12"
                        onChangeInput={updateAssesmentSGGAkar}
                      />
                      <FormSingle
                        placeholderInput="11"
                        name="a11"
                        onChangeInput={updateAssesmentSGGAkar}
                      />
                    </div>
                    <div className="d-flex">
                      <FormSingle
                        placeholderInput="21"
                        name="a21"
                        onChangeInput={updateAssesmentSGGAkar}
                      />
                      <FormSingle
                        placeholderInput="22"
                        name="a22"
                        onChangeInput={updateAssesmentSGGAkar}
                      />
                      <FormSingle
                        placeholderInput="23"
                        name="a23"
                        onChangeInput={updateAssesmentSGGAkar}
                      />
                      <FormSingle
                        placeholderInput="24"
                        name="a24"
                        onChangeInput={updateAssesmentSGGAkar}
                      />
                      <FormSingle
                        placeholderInput="25"
                        name="a25"
                        onChangeInput={updateAssesmentSGGAkar}
                      />
                      <FormSingle
                        placeholderInput="26"
                        name="a26"
                        onChangeInput={updateAssesmentSGGAkar}
                      />
                      <FormSingle
                        placeholderInput="27"
                        name="a27"
                        onChangeInput={updateAssesmentSGGAkar}
                      />
                      <FormSingle
                        placeholderInput="28"
                        name="a28"
                        onChangeInput={onChange28Akar}
                      />
                    </div>

                    <div className="width-box align-middle">
                      <p className="pt-2">A</p>
                    </div>
                  </div>
                  <div className="d-flex ">
                    <div className="width-box align-middle">
                      <p className="pt-2">M</p>
                    </div>

                    <div className="d-flex me-4">
                      <FormSingle
                        placeholderInput="48"
                        name="m48"
                        onChangeInput={onChange48Mahkota}
                      />
                      <FormSingle
                        placeholderInput="47"
                        name="m47"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                      <FormSingle
                        placeholderInput="46"
                        name="m46"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                      <FormSingle
                        placeholderInput="45"
                        name="m45"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                      <FormSingle
                        placeholderInput="44"
                        name="m44"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                      <FormSingle
                        placeholderInput="43"
                        name="m43"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                      <FormSingle
                        placeholderInput="42"
                        name="m42"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                      <FormSingle
                        placeholderInput="41"
                        name="m41"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                    </div>
                    <div className="d-flex">
                      <FormSingle
                        placeholderInput="31"
                        name="m31"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                      <FormSingle
                        placeholderInput="32"
                        name="m32"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                      <FormSingle
                        placeholderInput="33"
                        name="m33"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                      <FormSingle
                        placeholderInput="34"
                        name="m34"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                      <FormSingle
                        placeholderInput="35"
                        name="m35"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                      <FormSingle
                        placeholderInput="36"
                        name="m36"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                      <FormSingle
                        placeholderInput="37"
                        name="m37"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                      <FormSingle
                        placeholderInput="38"
                        name="m38"
                        onChangeInput={onChange38Mahkota}
                      />
                    </div>

                    <div className="width-box align-middle">
                      <p className="pt-2">M</p>
                    </div>
                  </div>
                  <div className="d-flex ">
                    <div className="width-box align-middle">
                      <p className="pt-2">A</p>
                    </div>

                    <div className="d-flex me-4">
                      <FormSingle
                        placeholderInput="48"
                        name="a48"
                        onChangeInput={onChange48Akar}
                      />
                      <FormSingle
                        placeholderInput="47"
                        name="a47"
                        onChangeInput={updateAssesmentSGGAkar}
                      />
                      <FormSingle
                        placeholderInput="46"
                        name="a46"
                        onChangeInput={updateAssesmentSGGAkar}
                      />
                      <FormSingle
                        placeholderInput="45"
                        name="a45"
                        onChangeInput={updateAssesmentSGGAkar}
                      />
                      <FormSingle
                        placeholderInput="44"
                        name="a44"
                        onChangeInput={updateAssesmentSGGAkar}
                      />
                      <FormSingle
                        placeholderInput="43"
                        name="a43"
                        onChangeInput={updateAssesmentSGGAkar}
                      />
                      <FormSingle
                        placeholderInput="42"
                        name="a42"
                        onChangeInput={updateAssesmentSGGAkar}
                      />
                      <FormSingle
                        placeholderInput="41"
                        name="a41"
                        onChangeInput={updateAssesmentSGGAkar}
                      />
                    </div>
                    <div className="d-flex">
                      <FormSingle
                        placeholderInput="31"
                        name="a31"
                        onChangeInput={updateAssesmentSGGAkar}
                      />
                      <FormSingle
                        placeholderInput="32"
                        name="a32"
                        onChangeInput={updateAssesmentSGGAkar}
                      />
                      <FormSingle
                        placeholderInput="33"
                        name="a33"
                        onChangeInput={updateAssesmentSGGAkar}
                      />
                      <FormSingle
                        placeholderInput="34"
                        name="a34"
                        onChangeInput={updateAssesmentSGGAkar}
                      />
                      <FormSingle
                        placeholderInput="35"
                        name="a35"
                        onChangeInput={updateAssesmentSGGAkar}
                      />
                      <FormSingle
                        placeholderInput="36"
                        name="a36"
                        onChangeInput={updateAssesmentSGGAkar}
                      />
                      <FormSingle
                        placeholderInput="37"
                        name="a37"
                        onChangeInput={updateAssesmentSGGAkar}
                      />
                      <FormSingle
                        placeholderInput="38"
                        name="a38"
                        onChangeInput={onChange38Akar}
                      />
                    </div>

                    <div className="width-box align-middle">
                      <p className="pt-2">A</p>
                    </div>
                  </div>
                </div>
                <Row>
                  <Col xl={12}>
                    <Button
                      className="red float-start mt-3 mb-3"
                      onClick={(e) => {
                        setAdditional((prevData) => !prevData);
                      }}
                    >
                      Data tambahan
                    </Button>
                  </Col>
                </Row>
                <Row>
                  {additional && type === "dewasa" && (
                    <Form>
                      <div className="d-flex flex-column flex-wrap overflow-auto">
                        <div className="d-flex ">
                          <div className="width-box align-middle pe-4">
                            <p className="pt-2">M</p>
                          </div>

                          <div className="d-flex me-5">
                            <FormSingleAnak
                              placeholderInput="55"
                              name="m55"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                            <FormSingleAnak
                              placeholderInput="54"
                              name="m54"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                            <FormSingleAnak
                              placeholderInput="53"
                              name="m53"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                            <FormSingleAnak
                              placeholderInput="52"
                              name="m52"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                            <FormSingleAnak
                              placeholderInput="51"
                              name="m51"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                          </div>
                          <div className="d-flex">
                            <FormSingleAnak
                              placeholderInput="61"
                              name="m61"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                            <FormSingleAnak
                              placeholderInput="62"
                              name="m62"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                            <FormSingleAnak
                              placeholderInput="63"
                              name="m63"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                            <FormSingleAnak
                              placeholderInput="64"
                              name="m64"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                            <FormSingleAnak
                              placeholderInput="65"
                              name="m65"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                          </div>

                          <div className="width-box align-middle ms-4">
                            <p className="pt-2">M</p>
                          </div>
                        </div>

                        <div className="d-flex ">
                          <div className="width-box align-middle pe-4">
                            <p className="pt-2">M</p>
                          </div>

                          <div className="d-flex me-5">
                            <FormSingleAnak
                              placeholderInput="85"
                              name="m85"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                            <FormSingleAnak
                              placeholderInput="84"
                              name="m84"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                            <FormSingleAnak
                              placeholderInput="83"
                              name="m83"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                            <FormSingleAnak
                              placeholderInput="82"
                              name="m82"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                            <FormSingleAnak
                              placeholderInput="81"
                              name="m81"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                          </div>
                          <div className="d-flex">
                            <FormSingleAnak
                              placeholderInput="71"
                              name="m71"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                            <FormSingleAnak
                              placeholderInput="72"
                              name="m72"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                            <FormSingleAnak
                              placeholderInput="73"
                              name="m73"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                            <FormSingleAnak
                              placeholderInput="74"
                              name="m74"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                            <FormSingleAnak
                              placeholderInput="75"
                              name="m75"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                          </div>

                          <div className="width-box align-middle ms-4">
                            <p className="pt-2">M</p>
                          </div>
                        </div>
                      </div>
                    </Form>
                  )}
                </Row>

                <Row>
                  <Col xl={12}>
                    <Button className="primary float-end" type="submit">
                      Next
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}

            {type === "anak-anak" && (
              <Form onSubmit={toPeriodontal}>
                <div className="d-flex flex-column flex-wrap overflow-auto">
                  <div className="d-flex ">
                    <div className="width-box align-middle pe-4">
                      <p className="pt-2">M</p>
                    </div>

                    <div className="d-flex me-5">
                      <FormSingleAnak
                        placeholderInput="55"
                        name="m55"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                      <FormSingleAnak
                        placeholderInput="54"
                        name="m54"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                      <FormSingleAnak
                        placeholderInput="53"
                        name="m53"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                      <FormSingleAnak
                        placeholderInput="52"
                        name="m52"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                      <FormSingleAnak
                        placeholderInput="51"
                        name="m51"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                    </div>
                    <div className="d-flex">
                      <FormSingleAnak
                        placeholderInput="61"
                        name="m61"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                      <FormSingleAnak
                        placeholderInput="62"
                        name="m62"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                      <FormSingleAnak
                        placeholderInput="63"
                        name="m63"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                      <FormSingleAnak
                        placeholderInput="64"
                        name="m64"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                      <FormSingleAnak
                        placeholderInput="65"
                        name="m65"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                    </div>

                    <div className="width-box align-middle ms-4">
                      <p className="pt-2">M</p>
                    </div>
                  </div>

                  <div className="d-flex ">
                    <div className="width-box align-middle pe-4">
                      <p className="pt-2">M</p>
                    </div>

                    <div className="d-flex me-5">
                      <FormSingleAnak
                        placeholderInput="85"
                        name="m85"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                      <FormSingleAnak
                        placeholderInput="84"
                        name="m84"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                      <FormSingleAnak
                        placeholderInput="83"
                        name="m83"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                      <FormSingleAnak
                        placeholderInput="82"
                        name="m82"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                      <FormSingleAnak
                        placeholderInput="81"
                        name="m81"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                    </div>
                    <div className="d-flex">
                      <FormSingleAnak
                        placeholderInput="71"
                        name="m71"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                      <FormSingleAnak
                        placeholderInput="72"
                        name="m72"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                      <FormSingleAnak
                        placeholderInput="73"
                        name="m73"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                      <FormSingleAnak
                        placeholderInput="74"
                        name="m74"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                      <FormSingleAnak
                        placeholderInput="75"
                        name="m75"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                    </div>

                    <div className="width-box align-middle ms-4">
                      <p className="pt-2">M</p>
                    </div>
                  </div>
                </div>
                <Row>
                  <Col xl={12}>
                    <Button
                      className="red float-start mt-3 mb-3"
                      onClick={(e) => {
                        setAdditional((prevData) => !prevData);
                      }}
                    >
                      Data tambahan
                    </Button>
                  </Col>
                </Row>
                <Row>
                  {additional && type === "anak-anak" && (
                    <Form>
                      <div className="d-flex flex-column flex-wrap overflow-auto">
                        <div className="d-flex ">
                          <div className="width-box align-middle">
                            <p className="pt-2">M</p>
                          </div>

                          <div className="d-flex me-4">
                            <FormSingle
                              placeholderInput="18"
                              name="m18"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                            <FormSingle
                              placeholderInput="17"
                              name="m17"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                            <FormSingle
                              placeholderInput="16"
                              name="m16"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                            <FormSingle
                              placeholderInput="15"
                              name="m15"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                            <FormSingle
                              placeholderInput="14"
                              name="m14"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                            <FormSingle
                              placeholderInput="13"
                              name="m13"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                            <FormSingle
                              placeholderInput="12"
                              name="m12"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                            <FormSingle
                              placeholderInput="11"
                              name="m11"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                          </div>
                          <div className="d-flex">
                            <FormSingle
                              placeholderInput="21"
                              name="m21"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                            <FormSingle
                              placeholderInput="22"
                              name="m22"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                            <FormSingle
                              placeholderInput="23"
                              name="m23"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                            <FormSingle
                              placeholderInput="24"
                              name="m24"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                            <FormSingle
                              placeholderInput="25"
                              name="m25"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                            <FormSingle
                              placeholderInput="26"
                              name="m26"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                            <FormSingle
                              placeholderInput="27"
                              name="m27"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                            <FormSingle
                              placeholderInput="28"
                              name="m28"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                          </div>

                          <div className="width-box align-middle">
                            <p className="pt-2">M</p>
                          </div>
                        </div>

                        <div className="d-flex ">
                          <div className="width-box align-middle">
                            <p className="pt-2">A</p>
                          </div>

                          <div className="d-flex me-4">
                            <FormSingle
                              placeholderInput="18"
                              name="a18"
                              onChangeInput={updateAssesmentSGGAkar}
                            />
                            <FormSingle
                              placeholderInput="17"
                              name="a17"
                              onChangeInput={updateAssesmentSGGAkar}
                            />
                            <FormSingle
                              placeholderInput="16"
                              name="a16"
                              onChangeInput={updateAssesmentSGGAkar}
                            />
                            <FormSingle
                              placeholderInput="15"
                              name="a15"
                              onChangeInput={updateAssesmentSGGAkar}
                            />
                            <FormSingle
                              placeholderInput="14"
                              name="a14"
                              onChangeInput={updateAssesmentSGGAkar}
                            />
                            <FormSingle
                              placeholderInput="13"
                              name="a13"
                              onChangeInput={updateAssesmentSGGAkar}
                            />
                            <FormSingle
                              placeholderInput="12"
                              name="a12"
                              onChangeInput={updateAssesmentSGGAkar}
                            />
                            <FormSingle
                              placeholderInput="11"
                              name="a11"
                              onChangeInput={updateAssesmentSGGAkar}
                            />
                          </div>
                          <div className="d-flex">
                            <FormSingle
                              placeholderInput="21"
                              name="a21"
                              onChangeInput={updateAssesmentSGGAkar}
                            />
                            <FormSingle
                              placeholderInput="22"
                              name="a22"
                              onChangeInput={updateAssesmentSGGAkar}
                            />
                            <FormSingle
                              placeholderInput="23"
                              name="a23"
                              onChangeInput={updateAssesmentSGGAkar}
                            />
                            <FormSingle
                              placeholderInput="24"
                              name="a24"
                              onChangeInput={updateAssesmentSGGAkar}
                            />
                            <FormSingle
                              placeholderInput="25"
                              name="a25"
                              onChangeInput={updateAssesmentSGGAkar}
                            />
                            <FormSingle
                              placeholderInput="26"
                              name="a26"
                              onChangeInput={updateAssesmentSGGAkar}
                            />
                            <FormSingle
                              placeholderInput="27"
                              name="a27"
                              onChangeInput={updateAssesmentSGGAkar}
                            />
                            <FormSingle
                              placeholderInput="28"
                              name="a28"
                              onChangeInput={updateAssesmentSGGAkar}
                            />
                          </div>

                          <div className="width-box align-middle">
                            <p className="pt-2">A</p>
                          </div>
                        </div>
                        <div className="d-flex ">
                          <div className="width-box align-middle">
                            <p className="pt-2">M</p>
                          </div>

                          <div className="d-flex me-4">
                            <FormSingle
                              placeholderInput="48"
                              name="m48"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                            <FormSingle
                              placeholderInput="47"
                              name="m47"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                            <FormSingle
                              placeholderInput="46"
                              name="m46"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                            <FormSingle
                              placeholderInput="45"
                              name="m45"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                            <FormSingle
                              placeholderInput="44"
                              name="m44"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                            <FormSingle
                              placeholderInput="43"
                              name="m43"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                            <FormSingle
                              placeholderInput="42"
                              name="m42"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                            <FormSingle
                              placeholderInput="41"
                              name="m41"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                          </div>
                          <div className="d-flex">
                            <FormSingle
                              placeholderInput="31"
                              name="m31"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                            <FormSingle
                              placeholderInput="32"
                              name="m32"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                            <FormSingle
                              placeholderInput="33"
                              name="m33"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                            <FormSingle
                              placeholderInput="34"
                              name="m34"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                            <FormSingle
                              placeholderInput="35"
                              name="m35"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                            <FormSingle
                              placeholderInput="36"
                              name="m36"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                            <FormSingle
                              placeholderInput="37"
                              name="m37"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                            <FormSingle
                              placeholderInput="38"
                              name="m38"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                          </div>

                          <div className="width-box align-middle">
                            <p className="pt-2">M</p>
                          </div>
                        </div>
                        <div className="d-flex ">
                          <div className="width-box align-middle">
                            <p className="pt-2">A</p>
                          </div>

                          <div className="d-flex me-4">
                            <FormSingle
                              placeholderInput="48"
                              name="a48"
                              onChangeInput={updateAssesmentSGGAkar}
                            />
                            <FormSingle
                              placeholderInput="47"
                              name="a47"
                              onChangeInput={updateAssesmentSGGAkar}
                            />
                            <FormSingle
                              placeholderInput="46"
                              name="a46"
                              onChangeInput={updateAssesmentSGGAkar}
                            />
                            <FormSingle
                              placeholderInput="45"
                              name="a45"
                              onChangeInput={updateAssesmentSGGAkar}
                            />
                            <FormSingle
                              placeholderInput="44"
                              name="a44"
                              onChangeInput={updateAssesmentSGGAkar}
                            />
                            <FormSingle
                              placeholderInput="43"
                              name="a43"
                              onChangeInput={updateAssesmentSGGAkar}
                            />
                            <FormSingle
                              placeholderInput="42"
                              name="a42"
                              onChangeInput={updateAssesmentSGGAkar}
                            />
                            <FormSingle
                              placeholderInput="41"
                              name="a41"
                              onChangeInput={updateAssesmentSGGAkar}
                            />
                          </div>
                          <div className="d-flex">
                            <FormSingle
                              placeholderInput="31"
                              name="a31"
                              onChangeInput={updateAssesmentSGGAkar}
                            />
                            <FormSingle
                              placeholderInput="32"
                              name="a32"
                              onChangeInput={updateAssesmentSGGAkar}
                            />
                            <FormSingle
                              placeholderInput="33"
                              name="a33"
                              onChangeInput={updateAssesmentSGGAkar}
                            />
                            <FormSingle
                              placeholderInput="34"
                              name="a34"
                              onChangeInput={updateAssesmentSGGAkar}
                            />
                            <FormSingle
                              placeholderInput="35"
                              name="a35"
                              onChangeInput={updateAssesmentSGGAkar}
                            />
                            <FormSingle
                              placeholderInput="36"
                              name="a36"
                              onChangeInput={updateAssesmentSGGAkar}
                            />
                            <FormSingle
                              placeholderInput="37"
                              name="a37"
                              onChangeInput={updateAssesmentSGGAkar}
                            />
                            <FormSingle
                              placeholderInput="38"
                              name="a38"
                              onChangeInput={updateAssesmentSGGAkar}
                            />
                          </div>

                          <div className="width-box align-middle">
                            <p className="pt-2">A</p>
                          </div>
                        </div>
                      </div>
                    </Form>
                  )}
                </Row>

                <Row>
                  <Col xl={12}>
                    <Button className="primary float-end" type="submit">
                      Next
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Cards>
        )}

        {renderImpaksi && (
          <Cards title="STATUS IMPAKSI GIGI">
            <Form onSubmit={toPeriodontal}>
              <Row className="mb-3">
                <Col xl={12}>
                  <Tooltip>
                    <AssesmentKeteranganImpaksiGigi />
                  </Tooltip>
                </Col>
              </Row>
              <div className="d-flex flex-row flex-wrap overflow-auto">
                <div className="d-flex ">
                  <div className="width-box align-middle">
                    <p className="pt-2">18</p>
                  </div>

                  <div className="d-flex me-2">
                    <FormImpaksiKelas
                      autoFocus={true}
                      placeholderInput="kelas 18"
                      name="kelas18"
                      onChangeInput={updateAssesmentSIG}
                      disabled={kelas18}
                    />
                    <FormImpaksiPosisi
                      placeholderInput="posisi 18"
                      name="posisi18"
                      onChangeInput={updateAssesmentSIG}
                      disabled={kelas18}
                    />
                    <FormImpaksiKlasifikasi
                      placeholderInput="klasifikasi 18"
                      name="klasifikasi18"
                      onChangeInput={updateAssesmentSIG}
                      disabled={kelas18}
                    />
                  </div>
                  <div className="d-flex">
                    <FormImpaksiKelas
                      placeholderInput="kelas 28"
                      name="kelas28"
                      onChangeInput={updateAssesmentSIG}
                      disabled={kelas28}
                    />
                    <FormImpaksiPosisi
                      placeholderInput="posisi 28"
                      name="posisi28"
                      onChangeInput={updateAssesmentSIG}
                      disabled={kelas28}
                    />
                    <FormImpaksiKlasifikasi
                      placeholderInput="klasifikasi 28"
                      name="klasifikasi28"
                      onChangeInput={updateAssesmentSIG}
                      disabled={kelas28}
                    />
                  </div>

                  <div className="width-box align-middle ps-4">
                    <p className="pt-2">28</p>
                  </div>
                </div>

                <div className="d-flex ">
                  <div className="width-box align-middle">
                    <p className="pt-2">48</p>
                  </div>

                  <div className="d-flex me-2">
                    <FormImpaksiKelas
                      placeholderInput="kelas 48"
                      name="kelas48"
                      onChangeInput={updateAssesmentSIG}
                      disabled={kelas48}
                    />
                    <FormImpaksiPosisi
                      placeholderInput="posisi 48"
                      name="posisi48"
                      onChangeInput={updateAssesmentSIG}
                      disabled={kelas48}
                    />
                    <FormImpaksiKlasifikasi
                      placeholderInput="klasifikasi 48"
                      name="klasifikasi48"
                      onChangeInput={updateAssesmentSIG}
                      disabled={kelas48}
                    />
                  </div>
                  <div className="d-flex">
                    <FormImpaksiKelas
                      placeholderInput="kelas 38"
                      name="kelas38"
                      onChangeInput={updateAssesmentSIG}
                      disabled={kelas38}
                    />
                    <FormImpaksiPosisi
                      placeholderInput="posisi 38"
                      name="posisi38"
                      onChangeInput={updateAssesmentSIG}
                      disabled={kelas38}
                    />
                    <FormImpaksiKlasifikasi
                      placeholderInput="klasifikasi 38"
                      name="klasifikasi38"
                      onChangeInput={updateAssesmentSIG}
                      disabled={kelas38}
                    />
                  </div>

                  <div className="width-box align-middle ps-4">
                    <p className="pt-2">38</p>
                  </div>
                </div>
              </div>

              <Row>
                <Col xl={12}>
                  <Button className="primary float-end" type="submit">
                    Next
                  </Button>
                </Col>
              </Row>
            </Form>
          </Cards>
        )}

        {renderPeriodontal && (
          <Cards title="STATUS PERIODONTAL">
            {type === "dewasa" && (
              <Form onSubmit={toAssesmentDataLain}>
                <Row className="mb-3">
                  <Col xl={12}>
                    <Tooltip>
                      <Row>
                        <ListGroup key="xl" horizontal="xl">
                          <ListGroup.Item>
                            <h5>Perdarahan Gingiva</h5>
                            <ListGroup variant="flush">
                              <ListGroup.Item className="d-flex">
                                <div className="col-xl-1 me-3">0</div>{" "}
                                <div className="col-xl-11 light">
                                  {" "}
                                  Keadaan gusi sehat
                                </div>
                              </ListGroup.Item>
                              <ListGroup.Item className="d-flex">
                                <div className="col-xl-1 me-3">1</div>{" "}
                                <div className="col-xl-11 light">
                                  {" "}
                                  Ada perdarahan
                                </div>
                              </ListGroup.Item>
                              <ListGroup.Item className="d-flex">
                                <div className="col-xl-1 me-3">9</div>{" "}
                                <div className="col-xl-11 light">
                                  {" "}
                                  Gigi eksklusi
                                </div>
                              </ListGroup.Item>
                              <ListGroup.Item className="d-flex">
                                <div className="col-xl-1 me-3">X</div>{" "}
                                <div className="col-xl-11 light">
                                  {" "}
                                  Gigi tidak ada
                                </div>
                              </ListGroup.Item>
                            </ListGroup>
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <h5>Poket Skor</h5>
                            <ListGroup variant="flush">
                              <ListGroup.Item className="d-flex">
                                <div className="col-xl-1 me-3">0</div>{" "}
                                <div className="col-xl-11 light">
                                  {" "}
                                  Tidak ada poket
                                </div>
                              </ListGroup.Item>
                              <ListGroup.Item className="d-flex">
                                <div className="col-xl-1 me-3">1</div>{" "}
                                <div className="col-xl-11 light">
                                  {" "}
                                  Poket 4 - 5 mm
                                </div>
                              </ListGroup.Item>
                              <ListGroup.Item className="d-flex">
                                <div className="col-xl-1 me-3">2</div>{" "}
                                <div className="col-xl-11 light">
                                  {" "}
                                  Poket 6 mm atau lebih
                                </div>
                              </ListGroup.Item>
                              <ListGroup.Item className="d-flex">
                                <div className="col-xl-1 me-3">9</div>{" "}
                                <div className="col-xl-11 light">
                                  {" "}
                                  Gigi tidak dapat dicatat
                                </div>
                              </ListGroup.Item>
                              <ListGroup.Item className="d-flex">
                                <div className="col-xl-1 me-3">X</div>{" "}
                                <div className="col-xl-11 light">
                                  {" "}
                                  Gigi tidak erupsi
                                </div>
                              </ListGroup.Item>
                            </ListGroup>
                          </ListGroup.Item>
                        </ListGroup>
                      </Row>
                    </Tooltip>
                  </Col>
                </Row>

                <div className="d-flex flex-row flex-wrap overflow-auto">
                  <div className="d-flex ">
                    <div className="width-periodontal align-middle">
                      <p className="pt-2">Bleed</p>
                    </div>

                    <div className="d-flex me-4">
                      <FormSingleBleed
                        autoFocus={true}
                        placeholderInput="18"
                        name="b18"
                        onChangeInput={updateAssesmentBleed}
                      />
                      <FormSingleBleed
                        placeholderInput="17"
                        name="b17"
                        onChangeInput={updateAssesmentBleed}
                      />
                      <FormSingleBleed
                        placeholderInput="16"
                        name="b16"
                        onChangeInput={updateAssesmentBleed}
                      />
                      <FormSingleBleed
                        placeholderInput="15"
                        name="b15"
                        onChangeInput={updateAssesmentBleed}
                      />
                      <FormSingleBleed
                        placeholderInput="14"
                        name="b14"
                        onChangeInput={updateAssesmentBleed}
                      />
                      <FormSingleBleed
                        placeholderInput="13"
                        name="b13"
                        onChangeInput={updateAssesmentBleed}
                      />
                      <FormSingleBleed
                        placeholderInput="12"
                        name="b12"
                        onChangeInput={updateAssesmentBleed}
                      />
                      <FormSingleBleed
                        placeholderInput="11"
                        name="b11"
                        onChangeInput={updateAssesmentBleed}
                      />
                    </div>
                    <div className="d-flex">
                      <FormSingleBleed
                        placeholderInput="21"
                        name="b21"
                        onChangeInput={updateAssesmentBleed}
                      />
                      <FormSingleBleed
                        placeholderInput="22"
                        name="b22"
                        onChangeInput={updateAssesmentBleed}
                      />
                      <FormSingleBleed
                        placeholderInput="23"
                        name="b23"
                        onChangeInput={updateAssesmentBleed}
                      />
                      <FormSingleBleed
                        placeholderInput="24"
                        name="b24"
                        onChangeInput={updateAssesmentBleed}
                      />
                      <FormSingleBleed
                        placeholderInput="25"
                        name="b25"
                        onChangeInput={updateAssesmentBleed}
                      />
                      <FormSingleBleed
                        placeholderInput="26"
                        name="b26"
                        onChangeInput={updateAssesmentBleed}
                      />
                      <FormSingleBleed
                        placeholderInput="27"
                        name="b27"
                        onChangeInput={updateAssesmentBleed}
                      />
                      <FormSingleBleed
                        placeholderInput="28"
                        name="b28"
                        onChangeInput={updateAssesmentBleed}
                      />
                    </div>

                    <div className="width-periodontal align-middle">
                      <p className="pt-2">Bleed</p>
                    </div>
                  </div>

                  <div className="d-flex ">
                    <div className="width-periodontal align-middle">
                      <p className="pt-2">Poket</p>
                    </div>

                    <div className="d-flex me-4">
                      <FormSinglePoket
                        placeholderInput="18"
                        name="p18"
                        onChangeInput={updateAssesmentPoket}
                      />
                      <FormSinglePoket
                        placeholderInput="17"
                        name="p17"
                        onChangeInput={updateAssesmentPoket}
                      />
                      <FormSinglePoket
                        placeholderInput="16"
                        name="p16"
                        onChangeInput={updateAssesmentPoket}
                      />
                      <FormSinglePoket
                        placeholderInput="15"
                        name="p15"
                        onChangeInput={updateAssesmentPoket}
                      />
                      <FormSinglePoket
                        placeholderInput="14"
                        name="p14"
                        onChangeInput={updateAssesmentPoket}
                      />
                      <FormSinglePoket
                        placeholderInput="13"
                        name="p13"
                        onChangeInput={updateAssesmentPoket}
                      />
                      <FormSinglePoket
                        placeholderInput="12"
                        name="p12"
                        onChangeInput={updateAssesmentPoket}
                      />
                      <FormSinglePoket
                        placeholderInput="11"
                        name="p11"
                        onChangeInput={updateAssesmentPoket}
                      />
                    </div>
                    <div className="d-flex">
                      <FormSinglePoket
                        placeholderInput="21"
                        name="p21"
                        onChangeInput={updateAssesmentPoket}
                      />
                      <FormSinglePoket
                        placeholderInput="22"
                        name="p22"
                        onChangeInput={updateAssesmentPoket}
                      />
                      <FormSinglePoket
                        placeholderInput="23"
                        name="p23"
                        onChangeInput={updateAssesmentPoket}
                      />
                      <FormSinglePoket
                        placeholderInput="24"
                        name="p24"
                        onChangeInput={updateAssesmentPoket}
                      />
                      <FormSinglePoket
                        placeholderInput="25"
                        name="p25"
                        onChangeInput={updateAssesmentPoket}
                      />
                      <FormSinglePoket
                        placeholderInput="26"
                        name="p26"
                        onChangeInput={updateAssesmentPoket}
                      />
                      <FormSinglePoket
                        placeholderInput="27"
                        name="p27"
                        onChangeInput={updateAssesmentPoket}
                      />
                      <FormSinglePoket
                        placeholderInput="28"
                        name="p28"
                        onChangeInput={updateAssesmentPoket}
                      />
                    </div>

                    <div className="width-periodontal align-middle">
                      <p className="pt-2">Poket</p>
                    </div>
                  </div>
                  <div className="d-flex ">
                    <div className="width-periodontal align-middle">
                      <p className="pt-2">Bleed</p>
                    </div>

                    <div className="d-flex me-4">
                      <FormSingleBleed
                        placeholderInput="48"
                        name="b48"
                        onChangeInput={updateAssesmentBleed}
                      />
                      <FormSingleBleed
                        placeholderInput="47"
                        name="b47"
                        onChangeInput={updateAssesmentBleed}
                      />
                      <FormSingleBleed
                        placeholderInput="46"
                        name="b46"
                        onChangeInput={updateAssesmentBleed}
                      />
                      <FormSingleBleed
                        placeholderInput="45"
                        name="b45"
                        onChangeInput={updateAssesmentBleed}
                      />
                      <FormSingleBleed
                        placeholderInput="44"
                        name="b44"
                        onChangeInput={updateAssesmentBleed}
                      />
                      <FormSingleBleed
                        placeholderInput="43"
                        name="b43"
                        onChangeInput={updateAssesmentBleed}
                      />
                      <FormSingleBleed
                        placeholderInput="42"
                        name="b42"
                        onChangeInput={updateAssesmentBleed}
                      />
                      <FormSingleBleed
                        placeholderInput="41"
                        name="b41"
                        onChangeInput={updateAssesmentBleed}
                      />
                    </div>
                    <div className="d-flex">
                      <FormSingleBleed
                        placeholderInput="31"
                        name="b31"
                        onChangeInput={updateAssesmentBleed}
                      />
                      <FormSingleBleed
                        placeholderInput="32"
                        name="b32"
                        onChangeInput={updateAssesmentBleed}
                      />
                      <FormSingleBleed
                        placeholderInput="33"
                        name="b33"
                        onChangeInput={updateAssesmentBleed}
                      />
                      <FormSingleBleed
                        placeholderInput="34"
                        name="b34"
                        onChangeInput={updateAssesmentBleed}
                      />
                      <FormSingleBleed
                        placeholderInput="35"
                        name="b35"
                        onChangeInput={updateAssesmentBleed}
                      />
                      <FormSingleBleed
                        placeholderInput="36"
                        name="b36"
                        onChangeInput={updateAssesmentBleed}
                      />
                      <FormSingleBleed
                        placeholderInput="37"
                        name="b37"
                        onChangeInput={updateAssesmentBleed}
                      />
                      <FormSingleBleed
                        placeholderInput="38"
                        name="b38"
                        onChangeInput={updateAssesmentBleed}
                      />
                    </div>

                    <div className="width-periodontal align-middle">
                      <p className="pt-2">Bleed</p>
                    </div>
                  </div>
                  <div className="d-flex ">
                    <div className="width-periodontal align-middle">
                      <p className="pt-2">Poket</p>
                    </div>

                    <div className="d-flex me-4">
                      <FormSinglePoket
                        placeholderInput="48"
                        name="p48"
                        onChangeInput={updateAssesmentPoket}
                      />
                      <FormSinglePoket
                        placeholderInput="47"
                        name="p47"
                        onChangeInput={updateAssesmentPoket}
                      />
                      <FormSinglePoket
                        placeholderInput="46"
                        name="p46"
                        onChangeInput={updateAssesmentPoket}
                      />
                      <FormSinglePoket
                        placeholderInput="45"
                        name="p45"
                        onChangeInput={updateAssesmentPoket}
                      />
                      <FormSinglePoket
                        placeholderInput="44"
                        name="p44"
                        onChangeInput={updateAssesmentPoket}
                      />
                      <FormSinglePoket
                        placeholderInput="43"
                        name="p43"
                        onChangeInput={updateAssesmentPoket}
                      />
                      <FormSinglePoket
                        placeholderInput="42"
                        name="p42"
                        onChangeInput={updateAssesmentPoket}
                      />
                      <FormSinglePoket
                        placeholderInput="41"
                        name="p41"
                        onChangeInput={updateAssesmentPoket}
                      />
                    </div>
                    <div className="d-flex">
                      <FormSinglePoket
                        placeholderInput="31"
                        name="p31"
                        onChangeInput={updateAssesmentPoket}
                      />
                      <FormSinglePoket
                        placeholderInput="32"
                        name="p32"
                        onChangeInput={updateAssesmentPoket}
                      />
                      <FormSinglePoket
                        placeholderInput="33"
                        name="p33"
                        onChangeInput={updateAssesmentPoket}
                      />
                      <FormSinglePoket
                        placeholderInput="34"
                        name="p34"
                        onChangeInput={updateAssesmentPoket}
                      />
                      <FormSinglePoket
                        placeholderInput="35"
                        name="p35"
                        onChangeInput={updateAssesmentPoket}
                      />
                      <FormSinglePoket
                        placeholderInput="36"
                        name="p36"
                        onChangeInput={updateAssesmentPoket}
                      />
                      <FormSinglePoket
                        placeholderInput="37"
                        name="p37"
                        onChangeInput={updateAssesmentPoket}
                      />
                      <FormSinglePoket
                        placeholderInput="38"
                        name="p38"
                        onChangeInput={updateAssesmentPoket}
                      />
                    </div>

                    <div className="width-periodontal align-middle">
                      <p className="pt-2">Poket</p>
                    </div>
                  </div>
                </div>

                <Row>
                  <Col xl={12}>
                    <Button className="primary float-end" type="submit">
                      Next
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}

            {type === "anak-anak" && (
              <Form onSubmit={toAssesmentDataLain}>
                <Row className="mb-3">
                  <Col xl={12}>
                    <Tooltip>
                      <Row>
                        <ListGroup key="xl" horizontal="xl">
                          <ListGroup.Item>
                            <h5>Perdarahan Gingiva</h5>
                            <ListGroup variant="flush">
                              <ListGroup.Item className="d-flex">
                                <div className="col-xl-1 me-3">0</div>{" "}
                                <div className="col-xl-11 light">
                                  {" "}
                                  Keadaan gusi sehat
                                </div>
                              </ListGroup.Item>
                              <ListGroup.Item className="d-flex">
                                <div className="col-xl-1 me-3">1</div>{" "}
                                <div className="col-xl-11 light">
                                  {" "}
                                  Ada perdarahan
                                </div>
                              </ListGroup.Item>
                              <ListGroup.Item className="d-flex">
                                <div className="col-xl-1 me-3">9</div>{" "}
                                <div className="col-xl-11 light">
                                  {" "}
                                  Gigi eksklusi
                                </div>
                              </ListGroup.Item>
                              <ListGroup.Item className="d-flex">
                                <div className="col-xl-1 me-3">X</div>{" "}
                                <div className="col-xl-11 light">
                                  {" "}
                                  Gigi tidak ada
                                </div>
                              </ListGroup.Item>
                            </ListGroup>
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <h5>Poket Skor</h5>
                            <ListGroup variant="flush">
                              <ListGroup.Item className="d-flex">
                                <div className="col-xl-1 me-3">0</div>{" "}
                                <div className="col-xl-11 light">
                                  {" "}
                                  Tidak ada poket
                                </div>
                              </ListGroup.Item>
                              <ListGroup.Item className="d-flex">
                                <div className="col-xl-1 me-3">1</div>{" "}
                                <div className="col-xl-11 light">
                                  {" "}
                                  Poket 4 - 5 mm
                                </div>
                              </ListGroup.Item>
                              <ListGroup.Item className="d-flex">
                                <div className="col-xl-1 me-3">2</div>{" "}
                                <div className="col-xl-11 light">
                                  {" "}
                                  Poket 6 mm atau lebih
                                </div>
                              </ListGroup.Item>
                              <ListGroup.Item className="d-flex">
                                <div className="col-xl-1 me-3">9</div>{" "}
                                <div className="col-xl-11 light">
                                  {" "}
                                  Gigi tidak dapat dicatat
                                </div>
                              </ListGroup.Item>
                              <ListGroup.Item className="d-flex">
                                <div className="col-xl-1 me-3">X</div>{" "}
                                <div className="col-xl-11 light">
                                  {" "}
                                  Gigi tidak erupsi
                                </div>
                              </ListGroup.Item>
                            </ListGroup>
                          </ListGroup.Item>
                        </ListGroup>
                      </Row>
                    </Tooltip>
                  </Col>
                </Row>
                <div className="d-flex flex-column flex-wrap overflow-auto ps-5">
                  <div className="d-flex ">
                    <div className="width-periodontal align-middle pe-4">
                      <p className="pt-2">Bleed</p>
                    </div>

                    <div className="d-flex me-5">
                      <FormSingleBleed
                        placeholderInput="55"
                        name="b55"
                        onChangeInput={updateAssesmentBleed}
                      />
                      <FormSingleBleed
                        placeholderInput="54"
                        name="b54"
                        onChangeInput={updateAssesmentBleed}
                      />
                      <FormSingleBleed
                        placeholderInput="53"
                        name="b53"
                        onChangeInput={updateAssesmentBleed}
                      />
                      <FormSingleBleed
                        placeholderInput="52"
                        name="b52"
                        onChangeInput={updateAssesmentBleed}
                      />
                      <FormSingleBleed
                        placeholderInput="51"
                        name="b51"
                        onChangeInput={updateAssesmentBleed}
                      />
                    </div>
                    <div className="d-flex">
                      <FormSingleBleed
                        placeholderInput="61"
                        name="b61"
                        onChangeInput={updateAssesmentBleed}
                      />
                      <FormSingleBleed
                        placeholderInput="62"
                        name="b62"
                        onChangeInput={updateAssesmentBleed}
                      />
                      <FormSingleBleed
                        placeholderInput="63"
                        name="b63"
                        onChangeInput={updateAssesmentBleed}
                      />
                      <FormSingleBleed
                        placeholderInput="64"
                        name="b64"
                        onChangeInput={updateAssesmentBleed}
                      />
                      <FormSingleBleed
                        placeholderInput="65"
                        name="b65"
                        onChangeInput={updateAssesmentBleed}
                      />
                    </div>

                    <div className="width-periodontal align-middle ms-4">
                      <p className="pt-2">Bleed</p>
                    </div>
                  </div>

                  <div className="d-flex ">
                    <div className="width-periodontal align-middle pe-4">
                      <p className="pt-2">Poket</p>
                    </div>

                    <div className="d-flex me-5">
                      <FormSinglePoket
                        placeholderInput="55"
                        name="p55"
                        onChangeInput={updateAssesmentPoket}
                      />
                      <FormSinglePoket
                        placeholderInput="54"
                        name="p54"
                        onChangeInput={updateAssesmentPoket}
                      />
                      <FormSinglePoket
                        placeholderInput="53"
                        name="p53"
                        onChangeInput={updateAssesmentPoket}
                      />
                      <FormSinglePoket
                        placeholderInput="52"
                        name="p52"
                        onChangeInput={updateAssesmentPoket}
                      />
                      <FormSinglePoket
                        placeholderInput="51"
                        name="p51"
                        onChangeInput={updateAssesmentPoket}
                      />
                    </div>
                    <div className="d-flex">
                      <FormSinglePoket
                        placeholderInput="61"
                        name="p61"
                        onChangeInput={updateAssesmentPoket}
                      />
                      <FormSinglePoket
                        placeholderInput="62"
                        name="p62"
                        onChangeInput={updateAssesmentPoket}
                      />
                      <FormSinglePoket
                        placeholderInput="63"
                        name="p63"
                        onChangeInput={updateAssesmentPoket}
                      />
                      <FormSinglePoket
                        placeholderInput="64"
                        name="p64"
                        onChangeInput={updateAssesmentPoket}
                      />
                      <FormSinglePoket
                        placeholderInput="65"
                        name="p65"
                        onChangeInput={updateAssesmentPoket}
                      />
                    </div>

                    <div className="width-periodontal align-middle ms-4">
                      <p className="pt-2">Poket</p>
                    </div>
                  </div>

                  <div className="d-flex ">
                    <div className="width-periodontal align-middle pe-4">
                      <p className="pt-2">Bleed</p>
                    </div>

                    <div className="d-flex me-5">
                      <FormSingleBleed
                        placeholderInput="85"
                        name="b85"
                        onChangeInput={updateAssesmentBleed}
                      />
                      <FormSingleBleed
                        placeholderInput="84"
                        name="b84"
                        onChangeInput={updateAssesmentBleed}
                      />
                      <FormSingleBleed
                        placeholderInput="83"
                        name="b83"
                        onChangeInput={updateAssesmentBleed}
                      />
                      <FormSingleBleed
                        placeholderInput="82"
                        name="b82"
                        onChangeInput={updateAssesmentBleed}
                      />
                      <FormSingleBleed
                        placeholderInput="81"
                        name="b81"
                        onChangeInput={updateAssesmentBleed}
                      />
                    </div>
                    <div className="d-flex">
                      <FormSingleBleed
                        placeholderInput="71"
                        name="b71"
                        onChangeInput={updateAssesmentBleed}
                      />
                      <FormSingleBleed
                        placeholderInput="72"
                        name="b72"
                        onChangeInput={updateAssesmentBleed}
                      />
                      <FormSingleBleed
                        placeholderInput="73"
                        name="b73"
                        onChangeInput={updateAssesmentBleed}
                      />
                      <FormSingleBleed
                        placeholderInput="74"
                        name="b74"
                        onChangeInput={updateAssesmentBleed}
                      />
                      <FormSingleBleed
                        placeholderInput="75"
                        name="b75"
                        onChangeInput={updateAssesmentBleed}
                      />
                    </div>

                    <div className="width-periodontal align-middle ms-4">
                      <p className="pt-2">Bleed</p>
                    </div>
                  </div>
                  <div className="d-flex ">
                    <div className="width-periodontal align-middle pe-4">
                      <p className="pt-2">Poket</p>
                    </div>

                    <div className="d-flex me-5">
                      <FormSinglePoket
                        placeholderInput="85"
                        name="p85"
                        onChangeInput={updateAssesmentPoket}
                      />
                      <FormSinglePoket
                        placeholderInput="84"
                        name="p84"
                        onChangeInput={updateAssesmentPoket}
                      />
                      <FormSinglePoket
                        placeholderInput="83"
                        name="p83"
                        onChangeInput={updateAssesmentPoket}
                      />
                      <FormSinglePoket
                        placeholderInput="82"
                        name="p82"
                        onChangeInput={updateAssesmentPoket}
                      />
                      <FormSinglePoket
                        placeholderInput="81"
                        name="p81"
                        onChangeInput={updateAssesmentPoket}
                      />
                    </div>
                    <div className="d-flex">
                      <FormSinglePoket
                        placeholderInput="71"
                        name="p71"
                        onChangeInput={updateAssesmentPoket}
                      />
                      <FormSinglePoket
                        placeholderInput="72"
                        name="p72"
                        onChangeInput={updateAssesmentPoket}
                      />
                      <FormSinglePoket
                        placeholderInput="73"
                        name="p73"
                        onChangeInput={updateAssesmentPoket}
                      />
                      <FormSinglePoket
                        placeholderInput="74"
                        name="p74"
                        onChangeInput={updateAssesmentPoket}
                      />
                      <FormSinglePoket
                        placeholderInput="75"
                        name="p75"
                        onChangeInput={updateAssesmentPoket}
                      />
                    </div>

                    <div className="width-periodontal align-middle ms-4">
                      <p className="pt-2">Poket</p>
                    </div>
                  </div>
                </div>

                <Row>
                  <Col xl={12}>
                    <Button className="primary float-end" type="submit">
                      Next
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Cards>
        )}

        {renderAssesmentDataLain && (
          <Form onSubmit={confirmSubmit}>
            <Row>
              <CardsSmall title="Maloklusi Dental">
                <Row>
                  <Col xl={12}>
                    <Tooltip>
                      <Row>
                        <ListGroup>
                          <ListGroup.Item className="d-flex">
                            <div className="col-xl-1 me-3">0</div>{" "}
                            <div className="col-xl-11 light">
                              Relasi molar kelas I, tanpa kelainan anterior.
                            </div>
                          </ListGroup.Item>
                          <ListGroup.Item className="d-flex">
                            <div className="col-xl-1 me-3">1</div>{" "}
                            <div className="col-xl-11 light">
                              Relasi molar kelas I, dengan crowding
                              anterior/kelainan overjet.
                            </div>
                          </ListGroup.Item>
                          <ListGroup.Item className="d-flex">
                            <div className="col-xl-1 me-3">2</div>{" "}
                            <div className="col-xl-11 light">
                              Relasi molar kelas II.
                            </div>
                          </ListGroup.Item>
                          <ListGroup.Item className="d-flex">
                            <div className="col-xl-1 me-3">3</div>{" "}
                            <div className="col-xl-11 light">
                              Relasi molar kelas III.
                            </div>
                          </ListGroup.Item>
                        </ListGroup>
                      </Row>
                    </Tooltip>
                  </Col>
                </Row>
                <Row>
                  <Col
                    xl="5"
                    md="5"
                    sm="5"
                    xs="5"
                    className="d-flex flex-column justify-content-center"
                  >
                    Relasi Molar
                  </Col>
                  <Col
                    xl="3"
                    md="3"
                    sm="3"
                    xs="3"
                    className="d-flex flex-column justify-content-center"
                  >
                    STATUS
                  </Col>
                  <Col
                    xl="4"
                    md="4"
                    sm="4"
                    xs="4"
                    className="d-flex flex-column justify-content-center"
                  >
                    <div className="width-box">
                      <Form.Control
                        onKeyPress={(event) => {
                          if (!/[0-3]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        autoFocus
                        name="maloklusiDental"
                        type="text"
                        autoComplete="off"
                        required
                        maxLength={1}
                        className="text-align input"
                        onChange={updateAssesmentDataLain}
                      />
                    </div>
                  </Col>
                </Row>
              </CardsSmall>
              <CardsSmall title="Kehilangan Perlekatan*">
                <Row>
                  <Col xl={12}>
                    <Tooltip>
                      <Row>
                        <ListGroup>
                          <ListGroup.Item className="d-flex">
                            <div className="col-xl-1 me-3">0</div>{" "}
                            <div className="col-xl-11 light"> 0 - 3 mm</div>
                          </ListGroup.Item>
                          <ListGroup.Item className="d-flex">
                            <div className="col-xl-1 me-3">1</div>{" "}
                            <div className="col-xl-11 light">
                              {" "}
                              4 - 5 mm CEJ belum melewati Band Hitam
                            </div>
                          </ListGroup.Item>
                          <ListGroup.Item className="d-flex">
                            <div className="col-xl-1 me-3">2</div>{" "}
                            <div className="col-xl-11 light">
                              {" "}
                              6 - 8 mm CEJ antara Band Hitam II & III 3 9 - 11
                              mm CEJ antara Band Hitam III & I
                            </div>
                          </ListGroup.Item>
                          <ListGroup.Item className="d-flex">
                            <div className="col-xl-1 me-3">4</div>{" "}
                            <div className="col-xl-11 light">
                              {" "}
                              12 mm kurang dari CEJ, lebih dari Band Hitam IV
                            </div>
                          </ListGroup.Item>
                          <ListGroup.Item className="d-flex">
                            <div className="col-xl-1 me-3">X</div>{" "}
                            <div className="col-xl-11 light">
                              {" "}
                              Sextan tidak diperiksa
                            </div>
                          </ListGroup.Item>
                          <ListGroup.Item className="d-flex">
                            <div className="col-xl-1 me-3">9</div>{" "}
                            <div className="col-xl-11 light">
                              {" "}
                              Gigi tidak dicatat
                            </div>
                          </ListGroup.Item>
                        </ListGroup>
                      </Row>
                    </Tooltip>
                  </Col>
                </Row>
                <Row>
                  <p className="light">
                    {" "}
                    <i className="light">
                      *Diukur pada responden usia lebih dari 15 Tahun
                    </i>{" "}
                  </p>
                </Row>
                <Row className="perekatan-font">
                  <Col
                    xl="2"
                    md="2"
                    sm="2"
                    xs="2"
                    className="d-flex flex-column justify-content-center"
                  >
                    17/16
                  </Col>
                  <Col
                    xl="2"
                    md="2"
                    sm="2"
                    xs="2"
                    className="d-flex flex-column justify-content-center"
                  >
                    <div className="width-box">
                      <Form.Control
                        onKeyPress={(event) => {
                          if (!/[0-4,9,x,X]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        name="kehilanganPerlekatan1716"
                        type="text"
                        autoComplete="off"
                        required
                        maxLength={1}
                        className="text-align input"
                        onChange={updateAssesmentDataLain}
                      />
                    </div>
                  </Col>
                  <Col
                    xl="2"
                    md="2"
                    sm="2"
                    xs="2"
                    className="d-flex flex-column justify-content-center"
                  >
                    11
                  </Col>
                  <Col
                    xl="2"
                    md="2"
                    sm="2"
                    xs="2"
                    className="d-flex flex-column justify-content-center"
                  >
                    <div className="width-box">
                      <Form.Control
                        onKeyPress={(event) => {
                          if (!/[0-4,9,x,X]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        name="kehilanganPerlekatan11"
                        type="text"
                        autoComplete="off"
                        required
                        maxLength={1}
                        className="text-align input"
                        onChange={updateAssesmentDataLain}
                      />
                    </div>
                  </Col>
                  <Col
                    xl="2"
                    md="2"
                    sm="2"
                    xs="2"
                    className="d-flex flex-column justify-content-center"
                  >
                    26/27
                  </Col>
                  <Col
                    xl="2"
                    md="2"
                    sm="2"
                    xs="2"
                    className="d-flex flex-column justify-content-center"
                  >
                    <div className="width-box">
                      <Form.Control
                        onKeyPress={(event) => {
                          if (!/[0-4,9,x,X]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        name="kehilanganPerlekatan2627"
                        type="text"
                        autoComplete="off"
                        required
                        maxLength={1}
                        className="text-align input"
                        onChange={updateAssesmentDataLain}
                      />
                    </div>
                  </Col>
                  <Col
                    xl="2"
                    md="2"
                    sm="2"
                    xs="2"
                    className="d-flex flex-column justify-content-center"
                  >
                    47/46
                  </Col>
                  <Col
                    xl="2"
                    md="2"
                    sm="2"
                    xs="2"
                    className="d-flex flex-column justify-content-center"
                  >
                    <div className="width-box">
                      <Form.Control
                        onKeyPress={(event) => {
                          if (!/[0-4,9,x,X]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        name="kehilanganPerlekatan4746"
                        type="text"
                        autoComplete="off"
                        required
                        maxLength={1}
                        className="text-align input"
                        onChange={updateAssesmentDataLain}
                      />
                    </div>
                  </Col>
                  <Col
                    xl="2"
                    md="2"
                    sm="2"
                    xs="2"
                    className="d-flex flex-column justify-content-center"
                  >
                    31
                  </Col>
                  <Col
                    xl="2"
                    md="2"
                    sm="2"
                    xs="2"
                    className="d-flex flex-column justify-content-center"
                  >
                    <div className="width-box">
                      <Form.Control
                        onKeyPress={(event) => {
                          if (!/[0-4,9,x,X]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        name="kehilanganPerlekatan31"
                        type="text"
                        autoComplete="off"
                        required
                        maxLength={1}
                        className="text-align input"
                        onChange={updateAssesmentDataLain}
                      />
                    </div>
                  </Col>
                  <Col
                    xl="2"
                    md="2"
                    sm="2"
                    xs="2"
                    className="d-flex flex-column justify-content-center"
                  >
                    36/37
                  </Col>
                  <Col
                    xl="2"
                    md="2"
                    sm="2"
                    xs="2"
                    className="d-flex flex-column justify-content-center"
                  >
                    <div className="width-box">
                      <Form.Control
                        onKeyPress={(event) => {
                          if (!/[0-4,9,x,X]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        name="kehilanganPerlekatan3637"
                        type="text"
                        autoComplete="off"
                        required
                        maxLength={1}
                        className="text-align input"
                        onChange={updateAssesmentDataLain}
                      />
                    </div>
                  </Col>
                </Row>
              </CardsSmall>
              <CardsSmall title="Fluorosis Email*">
                <Row>
                  <Col xl={12}>
                    <Tooltip>
                      <Row>
                        <ListGroup key="xl" horizontal="xl">
                          <ListGroup.Item>
                            <ListGroup variant="flush">
                              <ListGroup.Item className="d-flex">
                                <div className="col-xl-1 me-3">0</div>{" "}
                                <div className="col-xl-11 light"> Normal</div>
                              </ListGroup.Item>
                              <ListGroup.Item className="d-flex">
                                <div className="col-xl-1 me-3">1</div>{" "}
                                <div className="col-xl-11 light">
                                  {" "}
                                  Meragukan
                                </div>
                              </ListGroup.Item>
                              <ListGroup.Item className="d-flex">
                                <div className="col-xl-1 me-3">2</div>{" "}
                                <div className="col-xl-11 light">
                                  {" "}
                                  Sangat ringan
                                </div>
                              </ListGroup.Item>
                              <ListGroup.Item className="d-flex">
                                <div className="col-xl-1 me-3">3</div>{" "}
                                <div className="col-xl-11 light"> Ringan</div>
                              </ListGroup.Item>
                            </ListGroup>
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <ListGroup variant="flush">
                              <ListGroup.Item className="d-flex">
                                <div className="col-xl-1 me-3">4</div>{" "}
                                <div className="col-xl-11 light"> Sedang</div>
                              </ListGroup.Item>
                              <ListGroup.Item className="d-flex">
                                <div className="col-xl-1 me-3">5</div>{" "}
                                <div className="col-xl-11 light"> Berat</div>
                              </ListGroup.Item>
                              <ListGroup.Item className="d-flex">
                                <div className="col-xl-1 me-3">8</div>{" "}
                                <div className="col-xl-11 light">
                                  {" "}
                                  Gigi di-ekslusi (mahkota, tambahan, “bracket”)
                                </div>
                              </ListGroup.Item>
                              <ListGroup.Item className="d-flex">
                                <div className="col-xl-1 me-3">9</div>{" "}
                                <div className="col-xl-11 light">
                                  {" "}
                                  Tidak dapat dicatat (gigi belum erupsi)
                                </div>
                              </ListGroup.Item>
                            </ListGroup>
                          </ListGroup.Item>
                        </ListGroup>
                      </Row>
                    </Tooltip>
                  </Col>
                </Row>
                <Row>
                  <p className="light">
                    {" "}
                    <i className="light">
                      *Diukur pada responden usia lebih dari 3 Tahun
                    </i>{" "}
                  </p>
                </Row>
                <Row>
                  <Col
                    xl="5"
                    md="5"
                    sm="5"
                    xs="5"
                    className="d-flex flex-column justify-content-center"
                  >
                    Fluorosis Email
                  </Col>
                  <Col
                    xl="3"
                    md="3"
                    sm="3"
                    xs="3"
                    className="d-flex flex-column justify-content-center"
                  >
                    STATUS
                  </Col>
                  <Col
                    xl="4"
                    md="4"
                    sm="4"
                    xs="4"
                    className="d-flex flex-column justify-content-center"
                  >
                    <div className="width-box">
                      <Form.Control
                        onKeyPress={(event) => {
                          if (!/[0-5,8-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        name="fluorosisEmail"
                        type="text"
                        autoComplete="off"
                        required
                        maxLength={1}
                        className="text-align input"
                        onChange={updateAssesmentDataLain}
                      />
                    </div>
                  </Col>
                </Row>
              </CardsSmall>
              <CardsSmall title="Keparahan">
                <Row>
                  <Col xl={12}>
                    <Tooltip>
                      <Row>
                        <ListGroup>
                          <ListGroup.Item className="d-flex">
                            <div className="col-xl-1 me-3">0</div>{" "}
                            <div className="col-xl-11 light">
                              {" "}
                              Tidak ada tanda erosi
                            </div>
                          </ListGroup.Item>
                          <ListGroup.Item className="d-flex">
                            <div className="col-xl-1 me-3">1</div>{" "}
                            <div className="col-xl-11 light">
                              {" "}
                              Erosi pada email
                            </div>
                          </ListGroup.Item>
                          <ListGroup.Item className="d-flex">
                            <div className="col-xl-1 me-3">2</div>{" "}
                            <div className="col-xl-11 light">
                              {" "}
                              Erosi pada dentin
                            </div>
                          </ListGroup.Item>
                          <ListGroup.Item className="d-flex">
                            <div className="col-xl-1 me-3">3</div>{" "}
                            <div className="col-xl-11 light">
                              {" "}
                              Keterlibatan pulpa
                            </div>
                          </ListGroup.Item>
                        </ListGroup>
                      </Row>
                    </Tooltip>
                  </Col>
                </Row>
                <Row>
                  <Col
                    xl="3"
                    md="3"
                    sm="3"
                    xs="3"
                    className="d-flex flex-column justify-content-center"
                  >
                    Erosi Gigi
                  </Col>
                  <Col
                    xl="2"
                    md="2"
                    sm="2"
                    xs="2"
                    className="d-flex flex-column justify-content-center"
                  >
                    <div className="width-box">
                      <Form.Control
                        onKeyPress={(event) => {
                          if (!/[0-3]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        name="keparahanErosiGigi"
                        type="text"
                        autoComplete="off"
                        required
                        maxLength={1}
                        className="text-align input"
                        onChange={updateAssesmentDataLain}
                      />
                    </div>
                  </Col>
                  <Col
                    xl="3"
                    md="3"
                    sm="3"
                    xs="3"
                    className="d-flex flex-column justify-content-center"
                  >
                    Jumlah
                  </Col>
                  <Col
                    xl="2"
                    md="2"
                    sm="2"
                    xs="2"
                    className="d-flex flex-column justify-content-center"
                  >
                    <div className="width-box">
                      <Form.Control
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        name="keparahanJumlah"
                        type="text"
                        autoComplete="off"
                        required
                        maxLength={2}
                        className="text-align input"
                        onChange={updateAssesmentDataLain}
                      />
                    </div>
                  </Col>
                </Row>
              </CardsSmall>
              <CardsSmall title="Lesi Mukosa Oral">
                <Row>
                  <Col xl={12}>
                    <Tooltip>
                      <Row>
                        <ListGroup key="xl" horizontal="xl">
                          <ListGroup.Item>
                            <ListGroup variant="flush">
                              <ListGroup.Item className="d-flex">
                                <div className="col-xl-1 me-3">0</div>{" "}
                                <div className="col-xl-11 light">
                                  {" "}
                                  Tidak ada kondisi abnormal
                                </div>
                              </ListGroup.Item>
                              <ListGroup.Item className="d-flex">
                                <div className="col-xl-1 me-3">1</div>{" "}
                                <div className="col-xl-11 light">
                                  {" "}
                                  Tumor maligna (kanker mulut)
                                </div>
                              </ListGroup.Item>
                              <ListGroup.Item className="d-flex">
                                <div className="col-xl-1 me-3">2</div>{" "}
                                <div className="col-xl-11 light">
                                  {" "}
                                  Leukoplakia
                                </div>
                              </ListGroup.Item>
                              <ListGroup.Item className="d-flex">
                                <div className="col-xl-1 me-3">3</div>{" "}
                                <div className="col-xl-11 light">
                                  {" "}
                                  Lichen planus
                                </div>
                              </ListGroup.Item>
                              <ListGroup.Item className="d-flex">
                                <div className="col-xl-1 me-3">4</div>{" "}
                                <div className="col-xl-11 light">
                                  {" "}
                                  Ulserasi (apthous, herpetic, traumatic).
                                </div>
                              </ListGroup.Item>
                              <ListGroup.Item className="d-flex">
                                <div className="col-xl-1 me-3">5</div>{" "}
                                <div className="col-xl-11 light">
                                  {" "}
                                  Nekrosis ulseratif gingivitis akut
                                </div>
                              </ListGroup.Item>
                            </ListGroup>
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <ListGroup variant="flush">
                              <ListGroup.Item className="d-flex">
                                <div className="col-xl-1 me-3">6</div>{" "}
                                <div className="col-xl-11 light">
                                  {" "}
                                  Candidiasis
                                </div>
                              </ListGroup.Item>
                              <ListGroup.Item className="d-flex">
                                <div className="col-xl-1 me-3">7</div>{" "}
                                <div className="col-xl-11 light"> Abses</div>
                              </ListGroup.Item>
                              <ListGroup.Item className="d-flex">
                                <div className="col-xl-1 me-3">8</div>{" "}
                                <div className="col-xl-11 light">
                                  {" "}
                                  Lesi hipersensitivitas
                                </div>
                              </ListGroup.Item>
                              <ListGroup.Item className="d-flex">
                                <div className="col-xl-1 me-3">9</div>{" "}
                                <div className="col-xl-11 light">
                                  {" "}
                                  Kondisi lain
                                </div>
                              </ListGroup.Item>
                              <ListGroup.Item className="d-flex">
                                <div className="col-xl-1 me-3">X</div>{" "}
                                <div className="col-xl-11 light">
                                  {" "}
                                  Tidak dapat dicatat
                                </div>
                              </ListGroup.Item>
                            </ListGroup>
                          </ListGroup.Item>
                        </ListGroup>
                      </Row>
                    </Tooltip>
                  </Col>
                </Row>
                <Row>
                  <Col
                    xl="8"
                    md="8"
                    sm="8"
                    xs="8"
                    className="d-flex flex-column justify-content-center text-center"
                  >
                    STATUS
                  </Col>
                  <Col
                    xl="4"
                    md="4"
                    sm="4"
                    xs="4"
                    className="d-flex flex-column justify-content-center"
                  >
                    <div className="width-box">
                      <Form.Control
                        onKeyPress={(event) => {
                          if (!/[0-9,x,X]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        name="lesiMukosaOral"
                        type="text"
                        autoComplete="off"
                        required
                        maxLength={1}
                        className="text-align input"
                        onChange={updateAssesmentDataLain}
                      />
                    </div>
                  </Col>
                </Row>
              </CardsSmall>
              <CardsSmall title="Kebutuhan Perawatan Segera">
                <Row>
                  <Col xl={12}>
                    <Tooltip>
                      <Row>
                        <ListGroup>
                          <ListGroup.Item className="d-flex">
                            <div className="col-xl-1 me-3">0</div>{" "}
                            <div className="col-xl-11 light">
                              {" "}
                              Tidak perlu perawatan
                            </div>
                          </ListGroup.Item>
                          <ListGroup.Item className="d-flex">
                            <div className="col-xl-1 me-3">1</div>{" "}
                            <div className="col-xl-11 light">
                              {" "}
                              Diperlukan perawatan preventif dan rutin
                            </div>
                          </ListGroup.Item>
                          <ListGroup.Item className="d-flex">
                            <div className="col-xl-1 me-3">2</div>{" "}
                            <div className="col-xl-11 light">
                              {" "}
                              Diperlukan perawatan dengan scaling
                            </div>
                          </ListGroup.Item>
                          <ListGroup.Item className="d-flex">
                            <div className="col-xl-1 me-3">3</div>{" "}
                            <div className="col-xl-11 light">
                              {" "}
                              Diperlukan perawatan segera
                            </div>
                          </ListGroup.Item>
                          <ListGroup.Item className="d-flex">
                            <div className="col-xl-1 me-3">4</div>{" "}
                            <div className="col-xl-11 light">
                              {" "}
                              Dirujuk ke pemeriksaan komprehensif (terdapat
                              kondisi penyakit sistemik)
                            </div>
                          </ListGroup.Item>
                        </ListGroup>
                      </Row>
                    </Tooltip>
                  </Col>
                </Row>
                <Row>
                  <Col
                    xl="8"
                    md="8"
                    sm="8"
                    xs="8"
                    className="d-flex flex-column justify-content-center text-center"
                  >
                    STATUS
                  </Col>
                  <Col
                    xl="4"
                    md="4"
                    sm="4"
                    xs="4"
                    className="d-flex flex-column justify-content-center"
                  >
                    <div className="width-box">
                      <Form.Control
                        onKeyPress={(event) => {
                          if (!/[0-5,8-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        name="kebutuhanPerawatanSegera"
                        type="text"
                        autoComplete="off"
                        required
                        maxLength={1}
                        className="text-align input"
                        onChange={updateAssesmentDataLain}
                      />
                    </div>
                  </Col>
                </Row>
              </CardsSmall>
              <CardsSmall title="Gigi Tiruan Responden Umur Lebih dari 3 Tahun">
                <Row>
                  <Col xl={12}>
                    <Tooltip>
                      <Row>
                        <ListGroup>
                          <ListGroup.Item className="d-flex">
                            <div className="col-xl-1 me-3">0</div>{" "}
                            <div className="col-xl-11 light">
                              {" "}
                              Tidak ada gigi tiruan
                            </div>
                          </ListGroup.Item>
                          <ListGroup.Item className="d-flex">
                            <div className="col-xl-1 me-3">1</div>{" "}
                            <div className="col-xl-11 light">
                              {" "}
                              Gigi tiruan sebagian
                            </div>
                          </ListGroup.Item>
                          <ListGroup.Item className="d-flex">
                            <div className="col-xl-1 me-3">2</div>{" "}
                            <div className="col-xl-11 light">
                              {" "}
                              Gigi tiruan penuh
                            </div>
                          </ListGroup.Item>
                          <ListGroup.Item className="d-flex">
                            <div className="col-xl-1 me-3">3</div>{" "}
                            <div className="col-xl-11 light">
                              {" "}
                              Tidak dapat dicatat
                            </div>
                          </ListGroup.Item>
                        </ListGroup>
                      </Row>
                    </Tooltip>
                  </Col>
                </Row>
                <Row>
                  <Col
                    xl="2"
                    md="2"
                    sm="2"
                    xs="3"
                    className="d-flex flex-column justify-content-center"
                  >
                    RA
                  </Col>
                  <Col
                    xl="4"
                    md="4"
                    sm="4"
                    xs="3"
                    className="d-flex flex-column justify-content-center"
                  >
                    <div className="width-box">
                      <Form.Control
                        onKeyPress={(event) => {
                          if (!/[0-3]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        name="gigiTiruanAtasTigaTahunRA"
                        type="text"
                        autoComplete="off"
                        required
                        maxLength={1}
                        className="text-align input"
                        onChange={updateAssesmentDataLain}
                      />
                    </div>
                  </Col>
                  <Col
                    xl="2"
                    md="2"
                    sm="2"
                    xs="3"
                    className="d-flex flex-column justify-content-center"
                  >
                    RB
                  </Col>
                  <Col
                    xl="4"
                    md="4"
                    sm="4"
                    xs="3"
                    className="d-flex flex-column justify-content-center"
                  >
                    <div className="width-box">
                      <Form.Control
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        name="gigiTiruanAtasTigaTahunRB"
                        type="text"
                        autoComplete="off"
                        required
                        maxLength={1}
                        className="text-align input"
                        onChange={updateAssesmentDataLain}
                      />
                    </div>
                  </Col>
                </Row>
              </CardsSmall>
              <CardsSmall title="Trauma Dental">
                <Row>
                  <Col xl={12}>
                    <Tooltip>
                      <Row>
                        <ListGroup key="xl" horizontal="xl">
                          <ListGroup.Item>
                            <ListGroup variant="flush">
                              <ListGroup.Item className="d-flex">
                                <div className="col-xl-1 me-3">0</div>{" "}
                                <div className="col-xl-11 light">
                                  {" "}
                                  Tidak ada tanda cedera
                                </div>
                              </ListGroup.Item>
                              <ListGroup.Item className="d-flex">
                                <div className="col-xl-1 me-3">1</div>{" "}
                                <div className="col-xl-11 light">
                                  {" "}
                                  Cedera yang dirawat
                                </div>
                              </ListGroup.Item>
                              <ListGroup.Item className="d-flex">
                                <div className="col-xl-1 me-3">2</div>{" "}
                                <div className="col-xl-11 light">
                                  {" "}
                                  Hanya fraktur enamel
                                </div>
                              </ListGroup.Item>
                              <ListGroup.Item className="d-flex">
                                <div className="col-xl-1 me-3">3</div>{" "}
                                <div className="col-xl-11 light">
                                  {" "}
                                  Fraktur enamel dan dentin
                                </div>
                              </ListGroup.Item>
                            </ListGroup>
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <ListGroup variant="flush">
                              <ListGroup.Item className="d-flex">
                                <div className="col-xl-1 me-3">4</div>{" "}
                                <div className="col-xl-11 light">
                                  {" "}
                                  Keterlibatan pulpa
                                </div>
                              </ListGroup.Item>
                              <ListGroup.Item className="d-flex">
                                <div className="col-xl-1 me-3">5</div>{" "}
                                <div className="col-xl-11 light">
                                  {" "}
                                  Kehilangan gigi karena trauma
                                </div>
                              </ListGroup.Item>
                              <ListGroup.Item className="d-flex">
                                <div className="col-xl-1 me-3">6</div>{" "}
                                <div className="col-xl-11 light">
                                  {" "}
                                  Kerusakan lainnya
                                </div>
                              </ListGroup.Item>
                              <ListGroup.Item className="d-flex">
                                <div className="col-xl-1 me-3">7</div>{" "}
                                <div className="col-xl-11 light">
                                  {" "}
                                  Gigi yang dikecualikan
                                </div>
                              </ListGroup.Item>
                            </ListGroup>
                          </ListGroup.Item>
                        </ListGroup>
                      </Row>
                    </Tooltip>
                  </Col>
                </Row>
                <Row>
                  <Col
                    xl="3"
                    md="3"
                    sm="3"
                    xs="3"
                    className="d-flex flex-column justify-content-center"
                  >
                    Status
                  </Col>
                  <Col
                    xl="2"
                    md="2"
                    sm="2"
                    xs="2"
                    className="d-flex flex-column justify-content-center"
                  >
                    <div className="width-box">
                      <Form.Control
                        onKeyPress={(event) => {
                          if (!/[0-7]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        name="traumaDentalStatus"
                        type="text"
                        autoComplete="off"
                        required
                        maxLength={1}
                        className="text-align input"
                        onChange={updateAssesmentDataLain}
                      />
                    </div>
                  </Col>
                  <Col
                    xl="3"
                    md="3"
                    sm="3"
                    xs="3"
                    className="d-flex flex-column justify-content-center"
                  >
                    Jumlah
                  </Col>
                  <Col
                    xl="2"
                    md="2"
                    sm="2"
                    xs="2"
                    className="d-flex flex-column justify-content-center"
                  >
                    <div className="width-box">
                      <Form.Control
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        name="traumaDentalJumlah"
                        type="text"
                        autoComplete="off"
                        required
                        maxLength={2}
                        className="text-align input"
                        onChange={updateAssesmentDataLain}
                      />
                    </div>
                  </Col>
                </Row>
              </CardsSmall>
              <CardsSmall title="Tabel Penilaian Debris">
                <Row className="overflow-auto">
                  <div className="d-flex justify-content-center">
                    <FormPenilaian
                      placeholderInput="Bukal 16"
                      name="bukal16"
                      onChangeInput={updatePenilaianDebris}
                    />
                    <FormPenilaian
                      placeholderInput="Labial 11"
                      name="labial11"
                      onChangeInput={updatePenilaianDebris}
                    />
                    <FormPenilaian
                      placeholderInput="Bukal 26"
                      name="bukal26"
                      onChangeInput={updatePenilaianDebris}
                    />
                  </div>
                  <div className="d-flex justify-content-center">
                    <FormPenilaian
                      placeholderInput="Lingual 46"
                      name="lingual46"
                      onChangeInput={updatePenilaianDebris}
                    />
                    <FormPenilaian
                      placeholderInput="Labial 31"
                      name="labial31"
                      onChangeInput={updatePenilaianDebris}
                    />
                    <FormPenilaian
                      placeholderInput="Lingual 36"
                      name="lingual36"
                      onChangeInput={updatePenilaianDebris}
                    />
                  </div>
                </Row>
              </CardsSmall>
              <CardsSmall title="Tabel Penilain Kalkulus">
                <Row className="overflow-auto">
                  <div className="d-flex justify-content-center">
                    <FormPenilaian
                      placeholderInput="Bukal 16"
                      name="bukal16"
                      onChangeInput={updatePenilaianKalkulus}
                    />
                    <FormPenilaian
                      placeholderInput="Labial 11"
                      name="labial11"
                      onChangeInput={updatePenilaianKalkulus}
                    />
                    <FormPenilaian
                      placeholderInput="Bukal 26"
                      name="bukal26"
                      onChangeInput={updatePenilaianKalkulus}
                    />
                  </div>
                  <div className="d-flex justify-content-center">
                    <FormPenilaian
                      placeholderInput="Lingual 46"
                      name="lingual46"
                      onChangeInput={updatePenilaianKalkulus}
                    />
                    <FormPenilaian
                      placeholderInput="Labial 31"
                      name="labial31"
                      onChangeInput={updatePenilaianKalkulus}
                    />
                    <FormPenilaian
                      placeholderInput="Lingual 36"
                      name="lingual36"
                      onChangeInput={updatePenilaianKalkulus}
                    />
                  </div>
                </Row>
              </CardsSmall>
            </Row>
            <Cards title="Rencana Tindakan Perawatan">
              <Row>
                <Form.Group as={Row} className="mb-3" controlId="diagnosis">
                  <Form.Label column xl="3">
                    Diagnosis Klinis
                  </Form.Label>
                  <Col xl="9">
                    <Select
                      options={diagnosis}
                      onChange={handleChangeTindakanPerawatan}
                      placeholder="Pilih salah satu" required isMulti
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" controlId="tindakan">
                  <Form.Label column xl="3">
                  Tindakan Perawatan
                  </Form.Label>
                  <Col xl="9">
                    <Select
                      options={tindakan}
                      onChange={handleTindakan}
                      placeholder="Pilih salah satu" required isMulti
                    />
                  </Col>
                </Form.Group>
                

                <Form.Group as={Row} className="mb-3" controlId="deskripsi">
                  <Form.Label column lg="3" md="12" sm="12">
                    Deskripsi
                  </Form.Label>
                  <Col lg="9" md="12" sm="12">
                    <Form.Control
                      as="textarea"
                      name="deskripsi"
                      onChange={updateAssesmentDataLain}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" controlId="attachment">
                  <Form.Label column lg="3" md="12" sm="12">
                    Attachment
                  </Form.Label>
                  <Col lg="9" md="12" sm="12">
                    <Form.Control
                      type="file"
                      className="mb-2"
                      size="sm"
                      multiple
                      accept="image/png, image/jpeg, image/jpg"
                      onChange={handleUpload}
                    />
                    <Button className="red" onClick={upload}>
                      Upload
                    </Button>
                  </Col>
                </Form.Group>
              </Row>

              <Row>
                <Col xl={12}>
                  <Button className="primary float-end" type="submit">
                    Submit
                  </Button>
                </Col>
              </Row>
            </Cards>
            <div className="mb-5 pb-5"></div>
          </Form>
        )}
      </ContainerCards>

      <Modals
        show={showPopup}
        handleClose={(e) => setShowPopup(false)}
        header="Hasil Pencarian"
      >
        <TablePaginationSelect
          header={["Name", "Jenis Kelamin", "Tanggal Lahir", "Action"]}
          data={pasien}
          dataArray={["name", "jenis_kelamin", "tanggal_lahir"]}
          changePage={changePage}
          page={page}
          pages={pages}
          rows={rows}
          selectButton={select}
        />
      </Modals>

      <ModalConfirmation
        show={showConfirmation}
        handleClose={() => setShowConfirmation(false)}
        handleSubmit={submitAssesment}
        message="Apakah anda yakin?"
      />
      {response?.status && (
        <ToastPopup
          message={response.data.msg}
          showToast={showToast}
          setShowToast={setShowToast}
          response={response?.status}
        />
      )}
      {response?.status && (
        <ToastPopup
          message={message}
          showToast={showToastUpload}
          setShowToast={setShowToastUpload}
          response={response?.status}
        />
      )}
    </>
  );
};

export default AssesmentAdd;
