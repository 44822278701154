import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axiosApi from "../api/axios";
import { TablePagination } from "../component/TableData";
import Cards from "../component/Cards";
import Filters from "../component/Filters";
import { FormContainer } from "../component/Forms";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

const Users = () => {
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(0);
  // const [limit,setLimit] = useState(10);
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);

  const [keyword, setKeyword] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const searchData = (e) => {
    e.preventDefault();
    setPage(0);
    setKeyword(searchQuery);
  };

  const clearSearch = (e) => {
    e.preventDefault();
    setPage(0);
    setSearchQuery("");

    setKeyword("");
  };

  useEffect(() => {
    const getUsers = async () => {
      const response = await axiosApi.get(
        `/users/findAll?search_query=${keyword}&page=${page}&limit=${10}`
      );
      setUsers(response.data.result);
      setPage(response.data.page);
      setPages(response.data.totalPage);
      setRows(response.data.totalRows);
    };
    getUsers();
  }, [page, keyword]);

  const changePage = ({ selected }) => {
    setPage(selected);
  };

  const linkData = [
    {
      name: "Edit",
      path: "edit",
    },
    {
      name: "Reset",
      path: "reset",
    }
  ];

  return (
    <>
      <Container fluid>
        <Row className="justify-content-md-center">
          <Col md={11}>
            <Row>
              <Col xl="12">
                <Link
                  to={"/user/add"}
                  className="mb-2 btn btn-primary purple float-end "
                >
                  Tambah User
                </Link>
              </Col>
            </Row>
            <Cards>
              <Form onSubmit={searchData}>
                <Row className="d-flex flex-row justify-content-evenly">
                  <Col xl="9">
                    <Row>
                      <Col xl="12">
                        <input
                          type="text"
                          value={searchQuery}
                          className="search-input"
                          placeholder="Search"
                          onChange={(e) => setSearchQuery(e.target.value)}
                          autoComplete="off"
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col xl="3">
                    <Row>
                      <Col
                        xl="12"
                        className="d-flex flex-row justify-content-evenly"
                      >
                        <button className="button-search" type="submit">
                          Search
                        </button>
                        <button
                          className="button-clear-search"
                          onClick={clearSearch}
                        >
                          Clear
                        </button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
              <TablePagination
                header={["Name", "Email", "NIM / NIK", "Status", "Action"]}
                data={users}
                dataArray={["name", "email", "nim_or_nik", "status"]}
                changePage={changePage}
                page={page}
                pages={pages}
                rows={rows}
                linkButton={linkData}
              />
            </Cards>
          </Col>
        </Row>
      </Container>

      {/* <div className='d-flex'>
        <input type="text" placeholder='search' className='form-control' value={query} onChange={(e) => { setQuery(e.target.value) }} />
        <button className='btn btn-primary' onClick={searchData}>Search</button>
      </div>
      <Table striped="table-striped table-hover">
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Nim / Nik</th>
            <th>Status</th>

          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr className='align-center' key={user.id}>
              <td>{user.name}</td>
              <td>{user.email}</td>
              <td>{user.nimOrNik}</td>
              <td>{user.status}</td>
            </tr>
          ))}

        </tbody>
      </Table>
      <p>Total Rows : {rows}  Page: {rows ? page + 1 : 0} of {pages}</p>
      <nav className='pagination justify-content-center' key={rows}>
        <ReactPaginate
          previousLabel={"Prev"}
          nextLabel={"Next"}
          pageCount={pages}
          onPageChange={changePage}
          containerClassName="pagination"
          pageLinkClassName='page-link'
          previousLinkClassName='page-link'
          nextLinkClassName='page-link'
          activeLinkClassName='page-link active'
          disabledLinkClassName='page-link disabled'
        />

      </nav> */}
    </>
  );
};

export default Users;
