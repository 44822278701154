import React, { useEffect } from "react";
import ContainerCards, { PanelForm } from "../component/ContainerCards";
import { ModalConfirmation } from "../component/ModalPopup";
import Toastbar, { ToastPopup } from "../component/Toastbar";
import Form from "react-bootstrap/Form";
import axiosApi from "../api/axios";
import { useState } from "react";
import Cards, { PanelCard } from "../component/Cards";
import { useParams } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { ErrorMessage } from "../component/Forms";

const UsersResetPassword = () => {
  const navigate = useNavigate();

  const [showToast, setShowToast] = useState(false);
  const [show, setShow] = useState(false);
  const [username, setUsername] = useState("");
  const [response, setResponse] = useState({});
  const { id } = useParams();
  const [userId, setUserId] = useState("");
  const [password, setPassword] = useState('');

  const [error, setError] = useState({
    type: "",
    msg: "",
});

  const handleEdit = async () => {
    try {
      const body = {
        id: userId,
        username,
        password,
      };
      const response = await axiosApi.put(
        "/users/resetPassword",
        JSON.stringify(body)
      );
      setResponse(response);
      setShow(false);
      setShowToast(true);
      const sleep = (ms) => new Promise((r) => setTimeout(r, ms));
      await sleep(1000);
      navigate("/user", { replace: true });
    } catch (error) {
      console.log(error);
      setResponse(error.response);
      setShow(false);
      setShowToast(true);
    }
  };

  useEffect(() => {
    const getUsersById = async () => {
      try {
        const response = await axiosApi.get(`/users/${id}`);
        setUserId(response.data.result.id);
        setUsername(response.data.result.username);
      } catch (error) {
        console.log(error);
      }
    };

    getUsersById();
  }, [id]);

  const handleConfirm = (e) => {
    e.preventDefault();
    setShow(true);
  };

  const confirmationClose = () => setShow(false);

  const validateConfirmPassword = (e) => {
    let pass = e.target.value;

    if (pass === password) {
        setPassword(password);
        setError({
            type: "",
            msg: "",
        });
    } else {
        setError({
            type: "pwd",
            msg: "Password tidak sama!",
        });
    }
}

  return (
    <>
      <PanelForm>
        <PanelCard>
          <Form onSubmit={handleConfirm}>
            <Form.Group className="mb-3" controlId="username">
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="text"
                defaultValue={username}
                disabled
                autoComplete="off"
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="password">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="password1">
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                className={error.type === "email" ? "form-error-border" : ""}
                type="password"
                onChange={validateConfirmPassword}
                required
              />
              <ErrorMessage type={error.type === "pwd"} message={error.msg} />
            </Form.Group>

            <div className="mt-3 mb-3">
              <Link
                to="/user"
                className="btn btn-secondary me-xl-2 secondary-color"
                variant="secondary"
              >
                Back
              </Link>
              <Button variant="primary" className="primary" type="submit">
                Update
              </Button>
            </div>
          </Form>
        </PanelCard>
      </PanelForm>

      <ModalConfirmation
        show={show}
        handleClose={confirmationClose}
        handleSubmit={handleEdit}
        message="Apakah anda yakin mau update?"
      />
      {response?.status && (
        <ToastPopup
          message={response.data.msg}
          showToast={showToast}
          setShowToast={setShowToast}
          response={response?.status}
        />
      )}
    </>
  );
};

export default UsersResetPassword;
