import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import { axiosBasic } from '../api/axios';
import { ModalConfirmation } from '../component/ModalPopup';
import Toastbar from '../component/Toastbar';
import { ErrorMessage, FormContainer, ValidationMessage } from '../component/Forms';
import Cards from '../component/Cards';
import validator from "validator";

const Register = () => {

    const navigate = useNavigate();

    const [show, setShow] = useState(false);
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [nimOrNik, setNimOrNik] = useState('');
    const [tanggalLahir, setTanggalLahir] = useState('');
    const [jenisKelamin, setJenisKelamin] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showToast, setShowToast] = useState(false);
    const [invalidPassword, setInvalidPassword] = useState(true);
    const [response, setResponse] = useState({});
    const [error, setError] = useState({
        type: "",
        msg: "",
    });

    const handleConfirm = (e) => {
        e.preventDefault();
        setShow(true);
    }

    const confirmationClose = () => setShow(false);

    const handleRegister = async (e) => {
        e.preventDefault();
        try {
            const body = { username, password, email, name, nimOrNik, tanggalLahir, jenisKelamin };

            // const response = await axios.post(LOGIN_URL, body);

            const response = await axiosBasic.post("/register/createPendingTask", body);
            setShow(false);
            setResponse(response);
            setShowToast(true);

            const sleep = ms => new Promise(r => setTimeout(r, ms));
            await sleep(3000);

            navigate("/login", { replace: true });
        } catch (err) {
            console.log(err.response);
            setResponse(err.response);
            setShow(false);
            setShowToast(true);

        }

    }

    const handleSelectGender = (e) => {
        setJenisKelamin(e.target.value);
    }

    const validateEmail = (e) => {
        let email = e.target.value;

        if (validator.isEmail(email)) {
            setEmail(email)
            setError({
                type: "",
                msg: "",
            });
        } else {
            setError({
                type: "email",
                msg: "Format email tidak valid!",
            });
        }
    }

    const validateConfirmPassword = (e) => {
        let pass = e.target.value;

        if (pass === password) {
            setPassword(password);
            setError({
                type: "",
                msg: "",
            });
        } else {
            setError({
                type: "pwd",
                msg: "Password tidak sama!",
            });
        }
    }
    return (<>
        <div className='container-fluid vh-100'>
            <div className='row p-0 vh-100'>
                <div className="col-12 col-xl-6 ps-5 pe-5 pt-2">
                    <Cards title="Register">
                        <Col xl="12" className='d-flex flex-column p-5'>
                            <Form onSubmit={handleConfirm}>
                                <Row>
                                    <Form.Group className="mb-3" controlId="name">
                                        <Form.Label>Nama</Form.Label>
                                        <Form.Control type="text" onChange={(e) => setName(e.target.value)} required autoComplete='off' />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="nimOrNik">
                                        <Form.Label>NIM/NIK</Form.Label>
                                        <Form.Control type="text" onChange={(e) => setNimOrNik(e.target.value)} required autoComplete='off' />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="password">
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control type="password" onChange={(e) => setPassword(e.target.value)} required />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="password1">
                                        <Form.Label>Confirm Password</Form.Label>
                                        <Form.Control className={error.type === 'email' ? "form-error-border" : ""} type="password" onChange={validateConfirmPassword} required />
                                        <ErrorMessage type={error.type === "pwd"} message={error.msg} />
                                    </Form.Group>


                                    <Form.Group className="mb-3" controlId="username">
                                        <Form.Label>Username</Form.Label>
                                        <Form.Control type="text" onChange={(e) => setUsername(e.target.value)} required autoComplete='off' />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="email">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control type="text" onChange={validateEmail} required autoComplete='off' />
                                    </Form.Group>
                                    <ErrorMessage type={error.type === "email"} message={error.msg} />

                                    <Form.Group className="mb-3" controlId="tanggalLahir">
                                        <Form.Label>Tanggal Lahir</Form.Label>
                                        <Form.Control type="date" onChange={(e) => setTanggalLahir(e.target.value)} required autoComplete='off' />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="jenisKelamin">
                                        <Form.Label>Jenis kelamin</Form.Label>
                                        <Form.Select value={jenisKelamin} onChange={handleSelectGender} required aria-label="jenis-kelamin" className="mb-3">
                                            <option value="">-Pilih salah satu-</option>
                                            <option value="Laki-laki">Laki-laki</option>
                                            <option value="Perempuan">Perempuan</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Row>

                                <Row>
                                    <Col xl={12}>
                                        <Button className='red float-end' type='submit'>Register</Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Cards>
                </div>
                <div className="col-12 col-xl-6 d-none d-xl-block p-0">
                    <div className='login-title d-flex flex-column justify-content-center'>
                        <div className='login-layer'>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        {/* <Container fluid className='vh-100'>
            <Row className='justify-content-lg-center align-items-center vh-100'>
                <Col md={3}>
                    <Form onSubmit={handleConfirm}>
                        <Card className='col-md-auto'>
                            <Card.Header>Login</Card.Header>
                            <Card.Body>
                                <Form.Group className="mb-3" controlId="name">
                                    <Form.Label>Nama</Form.Label>
                                    <Form.Control type="text" onChange={(e) => setName(e.target.value)} required autoComplete='off' />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="nimOrNik">
                                    <Form.Label>NIM/NIK</Form.Label>
                                    <Form.Control type="text" onChange={(e) => setNimOrNik(e.target.value)} required autoComplete='off' />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="password">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control type="password" onChange={(e) => setPassword(e.target.value)} required />
                                </Form.Group>
                                {!invalidPassword ? <p>Password tidak sama!</p> : ""}
                                <Form.Group className="mb-3" controlId="password1">
                                    <Form.Label>Confirm Password</Form.Label>
                                    <Form.Control type="password" onChange={(e) => setConfirmPassword(e.target.value)} required />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="username">
                                    <Form.Label>Username</Form.Label>
                                    <Form.Control type="text" onChange={(e) => setUsername(e.target.value)} required autoComplete='off' />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="email">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="text" onChange={(e) => setEmail(e.target.value)} required autoComplete='off' />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="tanggalLahir">
                                    <Form.Label>Tanggal Lahir</Form.Label>
                                    <Form.Control type="date" onChange={(e) => setTanggalLahir(e.target.value)} required autoComplete='off' />
                                </Form.Group>
                                <Form.Label>Jenis kelamin</Form.Label>
                                <Form.Select aria-label="Default select example" className="mb-3">
                                    <option>Pilih salah satu</option>
                                    <option value="Laki-laki" onChange={handleSelectGender}>Laki-laki</option>
                                    <option value="Perempuan" onChange={handleSelectGender}>Perempuan</option>
                                </Form.Select>

                                <div className='d-grid mb-3'>
                                    <Button variant="primary" type="submit">
                                        Register
                                    </Button>
                                </div>
                            </Card.Body>

                            <Card.Footer className='text-center'>Copyright 2022</Card.Footer>

                        </Card>
                    </Form>
                </Col>
            </Row>
        </Container> */}


        <ModalConfirmation show={show} handleClose={confirmationClose} handleSubmit={handleRegister} message="Apakah anda yakin mau register?" />

        {response.status === 200 && <Toastbar message={response.data.msg} showToast={showToast} setShowToast={setShowToast} type={"info"} />
        }
        {response.status === 409 && <Toastbar message={response.data.msg} showToast={showToast} setShowToast={setShowToast} type={"warning"} />
        }

    </>

    )
}

export default Register