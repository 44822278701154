import { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { ContainerWithCard, PanelForm } from "../component/ContainerCards";
import Table from "react-bootstrap/Table";
import axiosApi from "../api/axios";
import Button from "react-bootstrap/Button";
import { Link, useNavigate } from "react-router-dom";
import { ModalConfirmation } from "../component/ModalPopup";
import Toastbar from "../component/Toastbar";
import { PanelCard } from "../component/Cards";

const RolesAdd = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [permissionList, setPermissionList] = useState([]);
  const [permission, setPermission] = useState([]);
  const [response, setResponse] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [show, setShow] = useState(false);
  const [isCheckAll, setIsCheckAll] = useState(false);

  const handleCreate = async (e) => {
    e.preventDefault();
    try {
      const body = { name, permission };

      const response = await axiosApi.post(
        "/roles/createRole",
        JSON.stringify(body)
      );
      setResponse(response);
      handlePopup();

      const sleep = (ms) => new Promise((r) => setTimeout(r, ms));
      await sleep(1000);
      navigate("/role", { replace: true });
    } catch (error) {
      setResponse(error.response);
      handlePopup();
    }
  };

  const handlePopup = () => {
    setShow(false);
    setShowToast(true);
  };

  useEffect(() => {
    const getPermissons = async () => {
      const response = await axiosApi.get("/permissions/findAll");
      setPermissionList(response.data.result);
    };
    getPermissons();
  }, []);

  const handleOnChange = (e) => {
    setIsCheckAll(false);
    if (e.target.checked) {
      const permissionCode = permissionList.filter(
        (data) => data.id === e.target.value
      )[0];
      setPermission([...permission, permissionCode]);
    } else {
      setPermission(
        permission.filter((permission) => permission.id !== e.target.value)
      );
    }
  };

  const handleConfirm = (e) => {
    e.preventDefault();
    setShow(true);
  };

  const handleCheckAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setPermission(permissionList.map((data) => data));
    if (isCheckAll) {
      setPermission([]);
    }
  };

  const confirmationClose = () => setShow(false);
  return (
    <>
      <PanelForm>
        <PanelCard>
          <Form onSubmit={handleConfirm}>
            <Form.Group className="mb-3" controlId="name">
              <Form.Label>Nama Role</Form.Label>
              <Form.Control
                type="text"
                onChange={(e) => setName(e.target.value)}
                value={name}
                required
                autoComplete="off"
              />
            </Form.Group>

            <Table className='table-sm'>
              <thead>
                <tr>
                  <th>
                    <Form.Check
                      type="checkbox"
                      onChange={handleCheckAll}
                      checked={isCheckAll}
                    />
                  </th>
                  <th>Permission</th>
                </tr>
              </thead>
              <tbody>
                {permissionList.map((item) => (
                  <tr key={item.id}>
                    <td>
                      <Form.Check
                        type="checkbox"
                        value={item.id}
                        onChange={handleOnChange}
                        checked={permission.includes(item)}
                      />
                    </td>
                    <td>{item.name}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Link
              to={"/role"}
              className="btn btn-secondary secondary-color me-2"
            >
              Back
            </Link>
            <Button type="submit" className="primary">
              Create
            </Button>
          </Form>
        </PanelCard>
      </PanelForm>

      <ModalConfirmation
        show={show}
        handleClose={confirmationClose}
        handleSubmit={handleCreate}
        message="Apakah anda yakin?"
      />

      {response.status === 200 && (
        <Toastbar
          message={response.data.msg}
          showToast={showToast}
          setShowToast={setShowToast}
          type={"info"}
        />
      )}
      {response.status === 400 && (
        <Toastbar
          message={response.data.msg}
          showToast={showToast}
          setShowToast={setShowToast}
          type={"warning"}
        />
      )}
    </>
  );
};

export default RolesAdd;
