import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

const Forms = ({ controlId, label, type, value, onChange }) => {
    return (
        <Form.Group className="mb-3" controlId={controlId}>
            <Form.Label>{label}</Form.Label>
            <Form.Control type={type} value={value} onChange={onChange} />
        </Form.Group>
    )
}

const handleJustNumber = (event) => {
    if (!/[0-9]/.test(event.key)) {
        event.preventDefault();
    }
}

const handleJustAlpha = (event) => {
    if (!/[A-G,a-g]/.test(event.key)) {
        event.preventDefault();
    }
}

export const FormBody = ({ controlId, label, type, value, onChange, required }) => {
    return (
        <Form.Group className="mb-3" controlId={controlId}>
            <Form.Label>{label}</Form.Label>
            <Form.Control type={type} value={value} onChange={onChange} autoComplete="off" required={required} />
        </Form.Group>
    )
}

export const FormTextArea = ({ controlId, label, type, value, onChange, row, required }) => {
    return (
        <Form.Group className="mb-3" controlId={controlId}>
            <Form.Label>{label}</Form.Label>
            <Form.Control as={type} value={value} onChange={onChange} rows={row} required={required} />
        </Form.Group>
    )
}

export const FormRadio = ({ controlId, label, type, value, onChange, row }) => {
    return (
        <div className='mb-3'>
            <Form.Check
                label={label}
                name="group1"
                type="radio"
                id={controlId}
            />
        </div>

    )
}

export const FormControl = ({ controlId, label, type, value, onChange, required, className, refValue, disabled, name }) => {
    return (
        <Form.Group as={Row} className="mb-3" controlId={controlId}>
            <Form.Label column lg="3" md="12" sm="12">
                {label}
            </Form.Label>
            <Col lg="9" md="12" sm="12">
                <Form.Control name={name} ref={refValue} className={className} type={type} value={value} onChange={onChange} autoComplete="off" required={required} disabled={disabled} />
            </Col>
        </Form.Group>
    );
}

export const FormControlCheck = ({ controlId, label, children }) => {
    return (
        <Form.Group as={Row} className="mb-3" controlId={controlId}>
            <Form.Label column lg="3" md="12" sm="12">
                {label}
            </Form.Label>
            <Col lg="9" md="12" sm="12">
                {children}
            </Col>
        </Form.Group>
    );
}

export const FormFooter = ({ children }) => {
    return (
        <>
            <div className='mt-3 mb-3'>
                <div className='row'>
                    <div className='col-xl-2 col-md-12 col-sm-12'></div>
                    <div className=' col-xl-10 col-md-12 col-sm-12'>
                        {children}
                    </div>
                </div>
            </div>
        </>
    );
}


export const ValidationMessage = ({ type, message }) => {
    return (
        type ? <div className='row'>
            <div className='col-xl-2 col-md-12 col-sm-12'></div>
            <p className='required col-xl-10 col-md-12 col-sm-12'>{message}</p>
        </div> : ""
    );
}

export const ErrorMessage = ({ type, message }) => {
    return (
        type ? <div className='row'>
            {/* <div className='col-xl-2 col-md-12 col-sm-12'></div> */}
            <p className='required col-xl-12 col-md-12 col-sm-12'>{message}</p>
        </div> : ""
    );
}

export const FormSingle = ({ name, placeholderInput, onChangeInput, autoFocus, defaultValue,disabled }) => {
    return (
        <div className='width-box'>
            <Form.Control
                onKeyPress={handleJustNumber}
                name={name}
                type="text"
                autoComplete='off'
                required
                maxLength={1}
                className="text-align input"
                placeholder={placeholderInput}
                onChange={onChangeInput}
                autoFocus={autoFocus}
                defaultValue={defaultValue}
                disabled={disabled}
            />
        </div>

    )
}

export const FormSingleNotReq = ({ name, placeholderInput, onChangeInput, autoFocus, defaultValue,disabled }) => {
    return (
        <div className='width-box'>
            <Form.Control
                onKeyPress={handleJustNumber}
                name={name}
                type="text"
                autoComplete='off'
                maxLength={1}
                className="text-align input"
                placeholder={placeholderInput}
                onChange={onChangeInput}
                autoFocus={autoFocus}
                defaultValue={defaultValue}
                disabled={disabled}
            />
        </div>

    )
}

export const FormImpaksi = ({ name, placeholderInput, onChangeInput, disabled }) => {
    return (
        <div className='width-box'>
            <Form.Control
                onKeyPress={handleJustNumber}
                name={name}
                type="text"
                autoComplete='off'
                maxLength={1}
                className="text-align input"
                placeholder={placeholderInput}
                onChange={onChangeInput}
                disabled={disabled}
            />
        </div>

    )
}

export const FormSingleLabel = ({ name, placeholderInput, onChangeInput, controlId, label }) => {
    return (

        <Form.Group as={Row} className="mb-3" controlId={controlId}>
            <Form.Label column lg="4" md="12" sm="12">
                {label}
            </Form.Label>
            <Col lg="8" md="12" sm="12">
                <div className='width-box'>
                    <Form.Control
                        onKeyPress={handleJustNumber}
                        name={name}
                        type="text"
                        autoComplete='off'
                        required
                        maxLength={1}
                        className="text-align input"
                        placeholder={placeholderInput}
                        onChange={onChangeInput} />
                </div></Col>
        </Form.Group>

    )
}


export const FormSingleAnak = ({ name, placeholderInput, onChangeInput, defaultValue,disabled }) => {
    return (
        <div className='width-box'>
            <Form.Control
                onKeyPress={handleJustAlpha}
                name={name}
                type="text"
                autoComplete='off'
                required
                maxLength={1}
                className="text-align input"
                placeholder={placeholderInput}
                onChange={onChangeInput}
                defaultValue={defaultValue}
                disabled={disabled}
            />
        </div>

    )
}

export const FormSingleAnakNotReq = ({ name, placeholderInput, onChangeInput, defaultValue,disabled }) => {
    return (
        <div className='width-box'>
            <Form.Control
                onKeyPress={handleJustAlpha}
                name={name}
                type="text"
                autoComplete='off'
                maxLength={1}
                className="text-align input"
                placeholder={placeholderInput}
                onChange={onChangeInput}
                defaultValue={defaultValue}
                disabled={disabled}
            />
        </div>

    )
}

export const FormImpaksiKelas = ({ name, placeholderInput, onChangeInput, disabled, autoFocus, defaultValue }) => {
    return (
        <div className='width-impaksi'>
            <Form.Control
                onKeyPress={(event) => {
                    if (!/[I,i]/.test(event.key)) {
                        event.preventDefault();
                    }
                }}
                name={name}
                type="text"
                autoComplete='off'
                maxLength={3}
                className="text-align input-impaksi color-blue"
                placeholder={placeholderInput}
                onChange={onChangeInput}
                disabled={disabled}
                autoFocus={autoFocus}
                defaultValue={defaultValue}
            />
        </div>

    )
}

export const FormImpaksiPosisi = ({ name, placeholderInput, onChangeInput, disabled, defaultValue }) => {
    return (
        <div className='width-impaksi'>
            <Form.Control
                onKeyPress={(event) => {
                    if (!/[A-C,a-c]/.test(event.key)) {
                        event.preventDefault();
                    }
                }}
                name={name}
                type="text"
                autoComplete='off'
                maxLength={1}
                className="text-align input-impaksi color-yellow"
                placeholder={placeholderInput}
                onChange={onChangeInput}
                disabled={disabled}
                defaultValue={defaultValue}
            />
        </div>

    )
}

export const FormImpaksiKlasifikasi = ({ name, placeholderInput, onChangeInput, disabled, defaultValue }) => {
    return (
        <div className='width-impaksi'>
            <Form.Control
                onKeyPress={(event) => {
                    if (!/[1-8]/.test(event.key)) {
                        event.preventDefault();
                    }
                }}
                name={name}
                type="text"
                autoComplete='off'
                maxLength={1}
                className="text-align input-impaksi color-red"
                placeholder={placeholderInput}
                onChange={onChangeInput}
                disabled={disabled}
                defaultValue={defaultValue}
            />
        </div>

    )
}

export const FormSingleBleed = ({ name, placeholderInput, onChangeInput, autoFocus, defaultValue,disabled }) => {
    return (
        <div className='width-box'>
            <Form.Control
                onKeyPress={(event) => {
                    if (!/[0-1,9,x,X]/.test(event.key)) {
                        event.preventDefault();
                    }
                }}
                name={name}
                type="text"
                autoComplete='off'
                required
                maxLength={1}
                className="text-align input"
                placeholder={placeholderInput}
                onChange={onChangeInput}
                autoFocus={autoFocus}
                defaultValue={defaultValue}
                disabled={disabled}
            />
        </div>

    )
}

export const FormSinglePoket = ({ name, placeholderInput, onChangeInput, defaultValue,disabled }) => {
    return (
        <div className='width-box'>
            <Form.Control
                onKeyPress={(event) => {
                    if (!/[0-2,9,x,X]/.test(event.key)) {
                        event.preventDefault();
                    }
                }}
                name={name}
                type="text"
                autoComplete='off'
                required
                maxLength={1}
                className="text-align input"
                placeholder={placeholderInput}
                onChange={onChangeInput}
                defaultValue={defaultValue}
                disabled={disabled}
            />
        </div>

    )
}

export const FormPenilaian = ({ name, placeholderInput, onChangeInput }) => {
    return (
        <div className='width-impaksi'>
            <Form.Control
                onKeyPress={(event) => {
                    if (!/[0-3]/.test(event.key)) {
                        event.preventDefault();
                    }
                }}
                name={name}
                type="text"
                autoComplete='off'
                required
                maxLength={1}
                className="text-align input-impaksi"
                placeholder={placeholderInput}
                onChange={onChangeInput} />
        </div>

    )
}

export const FormContainer = ({ controlId, label, children }) => {
    return (
        <Form.Group as={Row} className="mb-3" controlId={controlId}>
            <Form.Label column lg="3" md="12" sm="12">
                {label}
            </Form.Label>
            <Col lg="9" md="12" sm="12">
                {children}
            </Col>
        </Form.Group>
    );
}

export const FormFilter = ({ controlId, children }) => {
    return (
        <Form.Group as={Row} className="mb-3 fixed-field" controlId={controlId}>
            <Col lg="12" md="12" sm="12">
                {children}
            </Col>
        </Form.Group>
    );
}


export const FormContainerCol = ({ controlId, label, children }) => {
    return (
        <Form.Group as={Row} className="mb-3" controlId={controlId}>
            <Form.Label column lg="3" md="12" sm="12">
                {label}
            </Form.Label>
            <Col lg="9" md="12" sm="12">
                {children}
            </Col>
        </Form.Group>
    );
}

export default Forms