import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axiosApi from "../api/axios";
import { GridPagination, TablePagination } from "../component/TableData";
import Cards from "../component/Cards";
import Filters from "../component/Filters";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import InputGroup from "react-bootstrap/InputGroup";
import { ModalForm } from "../component/ModalPopup";
import { FormControl } from "../component/Forms";
import { ToastPopup } from "../component/Toastbar";

const Questioner = () => {
  const [pasien, setPasien] = useState([]);
  const [page, setPage] = useState(0);
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [keyword1, setKeyword1] = useState("");

  const [startDate, setStartDate] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const [name, setName] = useState("");

  const [modal, setModal] = useState(false);

  const [showToast, setShowToast] = useState(false);

  useEffect(() => {
    const getPasiens = async () => {
      const response = await axiosApi.get(
        `/kuesioner/viewKuesioner?search_query=${keyword}&start_date=${keyword1}&page=${page}&limit=${10}`
      );
      setPasien(response.data.result);
      setPage(response.data.page);
      setPages(response.data.totalPage);
      setRows(response.data.totalRows);
    };
    getPasiens();
  }, [page, keyword, keyword1]);

  const changePage = ({ selected }) => {
    setPage(selected);
  };

  const searchData = (e) => {
    e.preventDefault();
    setPage(0);
    setKeyword(searchQuery);
    setKeyword1(startDate);
  };
  const linkData = [
    {
      name: "Edit",
      path: "edit",
      type: "link",
    },
    {
      name: "View",
      path: "view",
      type: "link",
    },
  ];

  const clearSearch = (e) => {
    e.preventDefault();
    setPage(0);
    setSearchQuery("");
    setStartDate("");

    setKeyword("");
    setKeyword1("");
  };

  const [param, setParam] = useState({
    filename: "",
    startDate: "",
    endDate: "",
    umur: "",
    namaKuesioner: "",
    pengisi: "",
  });

  const updateParam = (e) => {
    setParam({
      ...param,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      await axiosApi.get(`/download/kuesioner?filename=${name}`);
      setModal(false);
      setShowToast(true);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Container fluid>
        <Row className="justify-content-md-center">
          <Col md={11}>
            <Row>
              <Col xl="12">
                <Link
                  to={"/kuesioner/add"}
                  className="mb-2 btn btn-primary purple float-end "
                >
                  Tambah Kuesioner
                </Link>
              </Col>
            </Row>
            <Cards>
              <Form onSubmit={searchData}>
                <Row className="d-flex flex-row justify-content-evenly">
                  <Col xl="9">
                    <Row>
                      <Col xl="6">
                        <input
                          type="text"
                          value={searchQuery}
                          className="search-input"
                          placeholder="Search"
                          onChange={(e) => setSearchQuery(e.target.value)}
                          autoComplete="off"
                        />
                      </Col>
                      <Col xl="6">
                        <input
                          type="date"
                          value={startDate}
                          className="search-input"
                          placeholder="Search"
                          onChange={(e) => setStartDate(e.target.value)}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col xl="3">
                    <Row>
                      <Col
                        xl="12"
                        className="d-flex flex-row justify-content-evenly"
                      >
                        <button className="button-search" type="submit">
                          Search
                        </button>
                        <button
                          className="button-clear-search"
                          onClick={clearSearch}
                        >
                          Clear
                        </button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
              <GridPagination
                header={[
                  "Nama",
                  "Umur",
                  "Tanggal Kuesioner",
                  "Pengisi",
                  "Provinsi",
                  "Kabupaten/Kota",
                  "Kecamatan",
                  "Action",
                ]}
                data={pasien}
                dataArray={[
                  "pasien_name",
                  "umur",
                  "tanggal_kuesioner",
                  "pengisi_name",
                  "provinsi",
                  "kabupaten_or_kota",
                  "kecamatan",
                ]}
                changePage={changePage}
                page={page}
                pages={pages}
                rows={rows}
                actionButton={linkData}
              />
            </Cards>
            <Row>
              <Col xl="12">
                <Button onClick={() => setModal(true)}>Download</Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      <ModalForm
        title={"Download"}
        show={modal}
        onHide={() => setModal(false)}
        handleClose={() => setModal(false)}
        onSubmit={onSubmit}
      >
        <FormControl
          controlId="name"
          label="Nama"
          type="text"
          required
          onChange={(e) => setName(e.target.value)}
        />
      </ModalForm>

      <ToastPopup
        message={"Harap ke menu report untuk mendownload file"}
        showToast={showToast}
        setShowToast={() => setShowToast(false)}
        response={200}
      />
    </>
  );
};

export default Questioner;
