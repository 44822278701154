import React from 'react'
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { MdQuestionAnswer } from 'react-icons/md';


const Tooltip = ({ children }) => {
    const popover = (
        <Popover id="popover-contained">
            <Popover.Header as="h3">Keterangan</Popover.Header>
            <Popover.Body>
                {children}
            </Popover.Body>
        </Popover>
    );

    return (
        <OverlayTrigger trigger="click" placement="right" overlay={popover}>
            <Button variant="outline-dark"><MdQuestionAnswer /></Button>
        </OverlayTrigger>
    )
}

export default Tooltip