import Table from 'react-bootstrap/Table';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';
import { MdRemoveRedEye, MdModeEditOutline, MdRestartAlt } from "react-icons/md";

const TableData = ({ header, data, dataArray, buttonData }) => {
    return (
        <>
            <Table striped="columns">
                <thead>
                    <tr>
                        {header.map((item, index) => (
                            <th key={index} className='text-center'>{item}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {data.map((item1, index) => (
                        <tr key={index}>
                            {dataArray.map((dataItem, index) => (
                                <td key={index} className='text-center'>{item1[dataItem].toString()}</td>
                            ))}
                            {buttonData != null && buttonData.map((item, index) => (
                                <td key={index} className='text-center'><button type="button" className="btn btn-primary" onClick={() => item.handle(item1)}>{item.name}</button></td>
                            ))}
                        </tr>
                    ))}

                </tbody>
            </Table>
        </>
    )
}


export const TablePagination = ({ header, data, dataArray, buttonData, rows, pages, changePage, page, linkButton, selectButton, functionButton }) => {
    return (
        <>
            <div className='table-responsive'>
                <Table className='table-sm'> 
                    <thead>
                        <tr>
                            {header.map((item, index) => (
                                <th key={index} className='text-right fw-bolder font-purple pt-3 pb-3 font-15'>{item}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((item1) => (
                            <tr key={item1.id}>
                                {dataArray.map((dataItem, index) => (
                                    <td key={index} className='text-right'>{item1[dataItem]}</td>
                                ))}
                                {buttonData != null && buttonData.map((item, index) => (
                                    <td key={index} className='text-right'><button type="button" className="btn btn-primary" onClick={() => item.handle(item1)}>{item.name}</button></td>
                                ))}

                                {linkButton != null && <td className='text-right'>{linkButton.map((item, index) => (
                                    <Link key={index} to={`${item.path}/${item1.id}`} className="btn btn-primary red me-1" >{item.name === "Edit" ? <MdModeEditOutline /> : item.name === "Reset" ? <MdRestartAlt /> : <MdRemoveRedEye />}</Link>
                                ))}</td>}

                                {selectButton != null && <td className='text-right'><button type="button" className="btn btn-primary primary" onClick={(e) => selectButton.handle(item1)}>{selectButton.name}</button></td>}

                                {functionButton != null && <td className='text-right'><button type="button" className="btn btn-primary red" onClick={(e) => functionButton(item1)}><MdRemoveRedEye /></button></td>}

                            </tr>
                        ))}

                    </tbody>
                </Table>
            </div>

            <p>Total Rows : {rows}  Page: {rows ? page + 1 : 0} of {pages}</p>
            <nav className='pagination justify-content-center' key={rows}>
                <ReactPaginate
                    previousLabel={"Prev"}
                    nextLabel={"Next"}
                    pageCount={pages}
                    onPageChange={changePage}
                    containerClassName="pagination"
                    pageLinkClassName='page-link font-black'
                    previousLinkClassName='page-link font-orange'
                    nextLinkClassName='page-link font-orange'
                    activeLinkClassName='page-link active third font-white'
                    disabledLinkClassName='page-link disabled font-disable'
                />

            </nav>
        </>
    )
}


export const TablePaginationSelect = ({ header, data, dataArray, rows, pages, changePage, page, selectButton }) => {
    return (
        <>
            <div className='table-responsive'>
                <Table striped="columns" bordered hover className='table-sm'>
                    <thead>
                        <tr>
                            {header.map((item, index) => (
                                <th key={index} className='text-center fs-7 fw-bolder font-purple'>{item}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((item) => (
                            <tr key={item.id}>
                                {dataArray.map((dataItem, index) => (
                                    <td key={index} className='text-center'>{item[dataItem]}</td>
                                ))}
                                {selectButton != null && <td className='text-center'><button type="button" className="btn btn-primary primary" onClick={(e) => selectButton.handle(item)}>{selectButton.name}</button></td>}
                            </tr>
                        ))}

                    </tbody>
                </Table>
            </div>

            <p>Total Rows : {rows}  Page: {rows ? page + 1 : 0} of {pages}</p>
            <nav className='pagination justify-content-center' key={rows}>
                <ReactPaginate
                    previousLabel={"Prev"}
                    nextLabel={"Next"}
                    pageCount={pages}
                    onPageChange={changePage}
                    containerClassName="pagination"
                    pageLinkClassName='page-link font-black'
                    previousLinkClassName='page-link font-orange'
                    nextLinkClassName='page-link font-orange'
                    activeLinkClassName='page-link active third font-white'
                    disabledLinkClassName='page-link disabled font-disable'
                />

            </nav>
        </>
    )
}

export const GridPagination = ({ header, data, dataArray, rows, pages, changePage, page,  functionButton, actionButton }) => {
    return (
        <>
            <div className='table-responsive'>
                <Table className='table-sm'>
                    <thead>
                        <tr>
                            {header.map((item, index) => (
                                <th key={index} className='text-right fw-bolder font-purple pt-3 pb-3 font-15'>{item}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((item1) => (
                            <tr key={item1.id}>
                                {dataArray.map((dataItem, index) => (
                                    <td key={index} className='text-right'>{item1[dataItem]}</td>
                                ))}
                                {actionButton != null
                                    &&
                                    <td className='text-right'>
                                        {actionButton.map((item, index) => {
                                            if (item.type === "link") {
                                                return <Link key={index} to={`${item.path}/${item1.id}`} className="btn btn-primary red me-1" >{item.name === "Edit" ? <MdModeEditOutline /> : <MdRemoveRedEye />}</Link>
                                            }else{
                                                return <button key={index} type="button" className="btn btn-primary red" onClick={(e) => functionButton(item1)}><MdRemoveRedEye /></button>
                                            }
                                        })}
                                    </td>}

                            </tr>
                        ))}

                    </tbody>
                </Table>
            </div>

            <p>Total Rows : {rows}  Page: {rows ? page + 1 : 0} of {pages}</p>
            <nav className='pagination justify-content-center' key={rows}>
                <ReactPaginate
                    previousLabel={"Prev"}
                    nextLabel={"Next"}
                    pageCount={pages}
                    onPageChange={changePage}
                    containerClassName="pagination"
                    pageLinkClassName='page-link font-black'
                    previousLinkClassName='page-link font-orange'
                    nextLinkClassName='page-link font-orange'
                    activeLinkClassName='page-link active third font-white'
                    disabledLinkClassName='page-link disabled font-disable'
                />

            </nav>
        </>
    )
}


export default TableData