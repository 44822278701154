import { useLocation, Navigate, Outlet } from "react-router-dom"
import useAuth from "./hooks/useAuth"

const REFRESH_URL = "/auth/refreshToken";

const RequireAuth = ({ allowedRoles }) => {
    const { auth } = useAuth();
    const location = useLocation();

    return (
        auth?.permission?.find(permission => allowedRoles?.includes(permission))
            ? <Outlet />
            : auth?.name
                ? <Navigate to="/unauthorized" state={{ from: location }} replace />
                : <Navigate to="/login" state={{ from: location }} replace />
    );
}
export const RequireAuthLogin = () => {
    const { auth } = useAuth();
    const location = useLocation();

    return (
        auth?.name
            ? <Outlet />
            : <Navigate to="/login" state={{ from: location }} replace />
    );
}

export default RequireAuth;