import { useState, useEffect } from "react";
import axiosApi from "../api/axios";
import {
  ContainerWithCard,
  Panel,
  PanelForm,
} from "../component/ContainerCards";
import Filters from "../component/Filters";
import Form from "react-bootstrap/Form";
import { FormBody, FormContainer } from "../component/Forms";
import Button from "react-bootstrap/Button";
import { TablePagination } from "../component/TableData";
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { PanelCard } from "../component/Cards";

const Roles = () => {
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(0);
  // const [limit,setLimit] = useState(10);
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);

  const [keyword, setKeyword] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const searchData = (e) => {
    e.preventDefault();
    setPage(0);
    setKeyword(searchQuery);
  };

  const clearSearch = (e) => {
    e.preventDefault();
    setPage(0);
    setSearchQuery("");

    setKeyword("");
  };

  useEffect(() => {
    const getUsers = async () => {
      const response = await axiosApi.get(
        `/roles/findAll?search_query=${keyword}&page=${page}&limit=${10}`
      );
      setUsers(response.data.result);
      setPage(response.data.page);
      setPages(response.data.totalPage);
      setRows(response.data.totalRows);
    };
    getUsers();
  }, [page, keyword]);

  const changePage = ({ selected }) => {
    setPage(selected);
  };

  const linkData = [
    {
      name: "Edit",
      path: "edit",
    },
  ];
  return (
    <>
      <PanelForm>
        <Row>
          <Col xl="12">
            <Link
              to={"/role/add"}
              className="mb-2 btn btn-primary purple float-end "
            >
              Tambah Role
            </Link>
          </Col>
        </Row>
        <PanelCard>
          <Form onSubmit={searchData}>
            <Row className="d-flex flex-row justify-content-evenly">
              <Col xl="9">
                <Row>
                  <Col xl="12">
                    <input
                      type="text"
                      value={searchQuery}
                      className="search-input"
                      placeholder="Search"
                      onChange={(e) => setSearchQuery(e.target.value)}
                      autoComplete="off"
                    />
                  </Col>
                </Row>
              </Col>
              <Col xl="3">
                <Row>
                  <Col
                    xl="12"
                    className="d-flex flex-row justify-content-evenly"
                  >
                    <button className="button-search" type="submit">
                      Search
                    </button>
                    <button
                      className="button-clear-search ms-1"
                      onClick={clearSearch}
                    >
                      Clear
                    </button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
          <TablePagination
            header={["Name", "Action"]}
            data={users}
            dataArray={["name"]}
            changePage={changePage}
            page={page}
            pages={pages}
            rows={rows}
            linkButton={linkData}
          />
        </PanelCard>
      </PanelForm>
    </>
  );
};

export default Roles;
