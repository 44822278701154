import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

export const ModalPopup = ({header, show, handleClose, handleTransfer, children }) => {
    return (
        <Modal show={show} onHide={handleClose} size="md" backdrop="static" centered>
            <Modal.Header closeButton>
                <Modal.Title>{header}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {children}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" className='secondary-color' onClick={handleClose}>
                    Tidak
                </Button>
                <Button variant="primary" className='primary' onClick={handleTransfer}>
                    Ya
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export const ModalConfirmation = ({ show, handleClose, message, handleSubmit }) => {
    return (
        <Modal show={show} onHide={handleClose} size="md" backdrop="static" centered>
            <Modal.Header closeButton>
                <Modal.Title>Konfirmasi</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {message}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" className='secondary-color' onClick={handleClose}>
                    Tidak
                </Button>
                <Button variant="primary" className='primary' onClick={handleSubmit}>
                    Ya
                </Button>
            </Modal.Footer>
        </Modal>
    )
}


export const ModalForm = ({ title, show, onHide, onSubmit, children, handleClose }) => {
    return (
        <Modal show={show} onHide={onHide} size="md" centered>
            <Form onSubmit={onSubmit}>

                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {children}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" className='secondary-color' onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" className='primary' type="submit">
                        Submit
                    </Button>
                </Modal.Footer>
            </Form>

        </Modal>
    )


}


export const Modals = ({header, show, handleClose, handleTransfer, children }) => {
    return (
        <Modal show={show} onHide={handleClose} size="md" backdrop="static" centered>
            <Modal.Header closeButton>
                <Modal.Title>{header}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {children}
            </Modal.Body>
        </Modal>
    )
}

export default ModalPopup