import { Outlet, useLocation, useNavigate } from "react-router-dom";
import "./Layout.css";
import Sidebar from "../sidebar/Sidebar";
import Button from "react-bootstrap/Button";
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import useAuth from "../../hooks/useAuth";
import { MdOutlineSettings, MdOutlineMenu } from "react-icons/md";
import axiosApi, { axiosBasic } from "../../api/axios";
import { ModalConfirmation } from "../ModalPopup";
import menu from "../../menu.json";

const Layout = () => {
  const location = useLocation();
  const activeItem = location.pathname;
  const [show, setShow] = useState(true);
  const { auth, setAuth } = useAuth();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);

  const [showSetting, setShowSetting] = useState(false);

  const isTablet = useMediaQuery({
    query: "(max-width: 1024px)",
  });

  const handleLogout = async () => {
    try {
      await axiosBasic.post("/auth/logout");

      localStorage.removeItem("persist");
      // navigate("/login", { replace: true });
      setAuth({});
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (isTablet) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [isTablet]);

  const handleShow = () => {
    setShow(!show);
  };

  const handleSetting = () => {
    setShowSetting(!showSetting);
  };

  const onClickSetting = () => {
    navigate("/accountSetting");
  };

  const logoutConfirmation = () => {
    setShowModal(true);
  };

  const confirmationClose = () => setShowModal(false);

  const getInitials = (fullName) => {
    const allNames = fullName.trim().split(" ");
    const initials = allNames.reduce((acc, curr, index) => {
      if (index === 0 || index === allNames.length - 1) {
        acc = `${acc}${curr.charAt(0).toUpperCase()}`;
      }
      return acc;
    }, "");
    return initials;
  };

  return (
    <>
      <div className="container-fluid vh-100 p-0">
        <div className="d-flex vh-100">
          <Sidebar show={show} closeShow={handleShow} />
          <div className={`main ${show ? "toggle" : ""} p-0`}>
            <div className={`toggle-button ${show ? "toggle" : ""}  d-flex justify-content-between align-items-center p-2 top-bar`}>
              <Button
                variant="light"
                className="toggle-color"
                onClick={handleShow}
              >
                <MdOutlineMenu className="toggle-color" size={26} />
              </Button>
              <div className="d-flex pe-5">
                <div className="pe-3 account-info">
                  <span>{auth.name}</span>
                </div>
                <div
                  className="avatar-account setting-button"
                  onClick={handleSetting}
                >
                  <span>{getInitials(auth.name)}</span>
                </div>
                {/* <MdOutlineSettings onClick={handleSetting} className="setting-button" size={26} /> */}
                {/* <div className="account-setting me-5 shadow-lg primary"> */}
                <div
                  className={`me-5 shadow-lg ${
                    !show
                      ? "account-setting"
                      : `account-setting-tablet ${
                          showSetting ? "d-flex" : "d-none"
                        }`
                  }`}
                >
                  <div onClick={onClickSetting} className="p-2 setting-item">
                    Setting
                  </div>
                  <div
                    onClick={logoutConfirmation}
                    className="p-2 setting-item"
                  >
                    Logout
                  </div>
                </div>
              </div>
              {/* <div className={`account-setting-tablet me-5 shadow-lg primary d-flex flex-column justify-content-center align-items-center ${showSetting ? 'd-block' : 'd-none'}`}> */}
            </div>
            {/* <div>
              {menu.map((item) => {
                if (activeItem.includes(item.path)) {
                  return <p>{item.menuName}</p>
                }
              })}
            </div> */}
            <div className="main-outlet">
              <Outlet />
            </div>
          </div>
        </div>
      </div>

      <ModalConfirmation
        show={showModal}
        handleClose={confirmationClose}
        handleSubmit={handleLogout}
        message="Apakah anda yakin?"
      />
    </>
  );
};

export default Layout;
