import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import axiosApi from '../api/axios';
import ContainerCards, { PanelForm } from '../component/ContainerCards';
import Form from 'react-bootstrap/Form';
import Cards, { PanelCard } from '../component/Cards';
import { FormContainer, FormControl, FormControlCheck } from '../component/Forms';
import AsyncSelect from 'react-select/async';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { ModalConfirmation } from '../component/ModalPopup';
import { ToastPopup } from '../component/Toastbar';
import { Link } from 'react-router-dom';

const PatientAdd = () => {

    const navigate = useNavigate();
    const [response, setResponse] = useState({});
    const [asKepalaKeluarga, setAsKepalaKeluarga] = useState(false);
    const [provinceId, setProvinceId] = useState("");
    const [regenciesId, setRegenciesId] = useState("");
    const [districtId, setDistrictId] = useState("");
    const [enableRegencies, setEnableRegencies] = useState(true);
    const [enableDistrict, setEnableDistrict] = useState(true);
    const [enableVillage, setEnableVillage] = useState(true);


    const [data, setData] = useState({
        setKepalaKeluarga: false,
        name: "",
        tanggalLahir: "",
        jenisKelamin: "",
        provinsi: "",
        kabupatenOrKota: "",
        kecamatan: "",
        desa: "",
        rt: "",
        rw: "",
        idKepalaKeluarga: 0,
        riwayatSistematik: []
    });

    const [showToast, setShowToast] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);

    const onConfirm = (e) => {
        e.preventDefault();
        setData({
            ...data,
            "setKepalaKeluarga": asKepalaKeluarga
        })
        setShowConfirmation(true);
    }

    const onSubmit = async (e) => {
        try {
            const response = await axiosApi.post('/pasien/create', { data: data });
            setResponse(response);

            setShowConfirmation(false);
            setShowToast(true);
            const sleep = ms => new Promise(r => setTimeout(r, ms));
            await sleep(1000);

            navigate("/patient", { replace: true });
        } catch (error) {
            setResponse(error.response);
            setShowConfirmation(false);
            setShowToast(true);
        }
    }

    const updateData = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    const handleChangeKepalaKeluarga = () => {
        setAsKepalaKeluarga(!asKepalaKeluarga);
        setData({
            ...data,
            "idKepalaKeluarga": 0
        })
    }

    const loadOption = async (inputValue, callBack) => {

        const result = await axiosApi.get(`keluarga/list?search_query=${inputValue}`);
        callBack(result.data.result);
    }

    const loadProvince = async (inputValue, callBack) => {

        const result = await axiosApi.get(`address/province?search_query=${inputValue}`);
        callBack(result.data.result);
    }
    const loadRegencies = async (inputValue, callBack) => {

        const result = await axiosApi.get(`address/regencies?search_query=${inputValue}&id=${provinceId}`);
        callBack(result.data.result);
    }

    const loadDistrict = async (inputValue, callBack) => {

        const result = await axiosApi.get(`address/district?search_query=${inputValue}&id=${regenciesId}`);
        callBack(result.data.result);
    }

    const loadVillage = async (inputValue, callBack) => {

        const result = await axiosApi.get(`address/village?search_query=${inputValue}&id=${districtId}`);
        callBack(result.data.result);
    }

    const loadRiwayat = async (inputValue, callBack) => {

        const result = await axiosApi.get(`setting/riwayatSistematik/list?search_query=${inputValue}`);
        callBack(result.data.result);
    }

    const handleKepalaKeluarga = (e) => {
        setData({
            ...data,
            "idKepalaKeluarga": e.value
        })
    }

    const handleProvince = (e) => {
        setProvinceId(e.value);
        setEnableDistrict(true);
        setEnableVillage(true);
        setEnableRegencies(false);
        setData({
            ...data,
            "provinsi": e.label
        })
    }
    const handleRegencies = (e) => {
        setRegenciesId(e.value);
        setEnableDistrict(false);
        setEnableVillage(true);
        setData({
            ...data,
            "kabupatenOrKota": e.label
        })
    }

    const handleDistrict = (e) => {
        setDistrictId(e.value);
        setEnableVillage(false);
        setData({
            ...data,
            "kecamatan": e.label
        })
    }
    const handleVillage = (e) => {
        // setDistrictId(e.value);
        // setEnableVillage(false);
        setData({
            ...data,
            "desa": e.label
        })
    }

    const handleRiwayat = (e) => {
        let temp = [];
        e.forEach((e) => {
            temp.push(e.value);
        })
        setData({
            ...data,
            "riwayatSistematik": temp
        })
    }
    return (
        <>
            <PanelForm>
                <PanelCard>

                    <Form onSubmit={onConfirm}>
                        <Row>
                            <FormContainer controlId="name" label="Nama Lengkap">
                                <Form.Control type='text' required onChange={updateData} name="name" />
                            </FormContainer>

                            <FormControlCheck controlId="kepalaKeluarga">
                                <Form.Check type='checkbox' id='kepalaKeluarga' label="Kepala Keluarga" onChange={handleChangeKepalaKeluarga} />
                            </FormControlCheck>
                            <FormContainer controlId="kepalaKeluarga" label="Kepala Keluarga">
                                <AsyncSelect loadOptions={loadOption} onChange={handleKepalaKeluarga} isDisabled={asKepalaKeluarga} />
                            </FormContainer>

                            <FormControl
                                name="tanggalLahir" controlId="tanggalLahir" label="Tanggal Lahir" type="date" required onChange={updateData}
                            />
                            <FormControlCheck controlId="jenisKelamin" label="Jenis Kelamin">
                                <Form.Select name='jenisKelamin' onChange={updateData} required aria-label="jenis-kelamin" className="mb-3">
                                    <option value="">-Pilih salah satu-</option>
                                    <option value="Laki-laki">Laki-laki</option>
                                    <option value="Perempuan">Perempuan</option>
                                </Form.Select>
                            </FormControlCheck>
                            <FormContainer controlId="province" label="Provinsi">
                                <AsyncSelect loadOptions={loadProvince} onChange={handleProvince} placeholder="Provinsi"/>
                            </FormContainer>
                            <FormContainer controlId="regencies" label="Kabupaten / Kota">
                                <AsyncSelect loadOptions={loadRegencies} onChange={handleRegencies} isDisabled={enableRegencies} placeholder="Kabupaten / kota" />
                            </FormContainer>
                            <FormContainer controlId="district" label="Kecamatan">
                                <AsyncSelect loadOptions={loadDistrict} onChange={handleDistrict} isDisabled={enableDistrict} placeholder="Kecamatan"/>
                            </FormContainer>
                            <FormContainer controlId="village" label="Kelurahan">
                                <AsyncSelect loadOptions={loadVillage} onChange={handleVillage} isDisabled={enableVillage} placeholder="Kelurahan"/>
                            </FormContainer>
                            <FormContainer controlId="rtrw" label="RT/RW">
                                <InputGroup className="mb-3">
                                    <Form.Control onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }} type='text' placeholder='RT' name='rt' onChange={updateData} />
                                    <Form.Control onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }} type='text' placeholder='RW' name='rw' onChange={updateData} />
                                </InputGroup>
                            </FormContainer>
                            <FormContainer controlId="riwayat" label="Riwayat Sistematik">
                                <AsyncSelect isMulti loadOptions={loadRiwayat} onChange={handleRiwayat} />
                            </FormContainer>

                        </Row>
                        <Row className='mt-3 mb-3'>
                            <Row>
                                <Col xl={12}>
                                    <Button className='primary float-end ms-2' type='submit'>Submit</Button>
                                    <Link to="/patient" className="btn btn-secondary secondary-color float-end" variant="secondary">
                                        Back
                                    </Link>
                                </Col>
                            </Row>
                        </Row>
                    </Form>
                </PanelCard>
            </PanelForm>

            <ModalConfirmation show={showConfirmation} handleClose={() => setShowConfirmation(false)} handleSubmit={onSubmit} message="Apakah anda yakin?" />
            {response?.status && <ToastPopup message={response.data.msg} showToast={showToast} setShowToast={setShowToast} response={response?.status} />
            }

        </>
    )
}

export default PatientAdd