import axios from '../api/axios';
import useAuth from './useAuth';
import jwt_decode from "jwt-decode";


const useRefreshToken = () => {
    const { setAuth } = useAuth();

    const refresh = async () => {
        const response = await axios.get('/auth/refreshToken', {
            withCredentials: true
        });
        const decode = jwt_decode(response.data.accessToken);

        const accessToken = response.data.accessToken;
        const id = decode?.id;
        const name = decode?.name;
        const email = decode?.email;
        const userNama = decode?.username;
        const permission = decode?.permission;

        setAuth(prev => {
            return {
                ...prev,
                accessToken, id, name, email, userNama, permission
            }
        });
        return accessToken;
    }
    return refresh;
};

export default useRefreshToken;