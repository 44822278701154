import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import "./Cards.css";

const Cards = ({ title, children }) => {
  return (
    <Card className=' mb-2 cards' >
      {title && <Card.Header className='text-center' as={'h3'}>{title}</Card.Header>}
      <Card.Body>{children}</Card.Body>
    </Card>
  )
}

export const PanelCard = ({ title, children }) => {
  return (
    <Card className=' mb-2 cards' >
      {title && <Card.Header className='text-center' as={'h3'}>{title}</Card.Header>}
      <Card.Body>{children}</Card.Body>
    </Card>
  )
}


export const CardsSmall = ({ title, children}) => {
  return (
    <Col xl="6">
      <Card className=' mb-2 card-height cards'>
        {title &&
          <Card.Header className='text-center' as={'h4'}>
              {title}
          </Card.Header>}
        <Card.Body>{children}</Card.Body>
      </Card>
    </Col>

  )
}

export default Cards