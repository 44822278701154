import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import "./Sidebar.css";
import menu from "../../menu.json";
import {
  MdReceipt,
  MdAnalytics,
  MdDashboard,
  MdAccessibleForward,
  MdAssignment,
  MdSupervisorAccount,
  MdClose,
  MdLibraryBooks,
  MdQuestionAnswer,
  MdImportContacts,
  MdInventory,
  MdOutlineSummarize,
  MdAssignmentInd,
} from "react-icons/md";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import Logo from "../../images/Logo.png";
import { useState } from "react";

const Sidebar = ({ show, closeShow }) => {
  const location = useLocation();
  const activeItem = location.pathname;
  const { auth } = useAuth();
  const [sectionAssesment, setSectionAssesment] = useState(true);
  const [sectionManagement, setSectionManagement] = useState(false);
  const section = ["Dashboard", "Assesment", "Management"];
  return (
    <>
      <div className={`sidebar ${show ? "toggle" : ""} d-flex flex-column`}>
        <div className="close-button">
          <Button className="me-2 transparent" onClick={closeShow}>
            <MdClose size={26} className="m-2 transparent" />
          </Button>
        </div>
        <div className="d-flex flex-column">
          <div className="logo">
            <Image className="logo-image" src={Logo} />
          </div>
          <div className="d-flex flex-column ms-3">
            {section.map((data, index) => {
              let found = false;

              menu.map((item) => {
                if (
                  (auth?.permission?.find((permission) =>
                    permission.includes(item.code)
                  ) ||
                    item.code === "000") &&
                  data === item.section
                ) {
                  found = true;
                }
              });
              if (found) {
                return (
                  <div key={index}>
                    <div className="sidebar-section">
                      <span className="section-title">{data}</span>
                    </div>

                    {menu.map((item, index1) => {
                      if (
                        (auth?.permission?.find((permission) =>
                          permission.includes(item.code)
                        ) ||
                          item.code === "000") &&
                        data === item.section
                      ) {
                        return (
                          <div
                            key={index1}
                            className={`d-flex sidebar__item ${
                              activeItem.includes(item.path) ? "active" : ""
                            }`}
                          >
                            <b></b>
                            <b></b>
                            <div className="me-3 ms-3 icon">
                              {item.icon === "MdDashboard" ? (
                                <MdDashboard />
                              ) : item.icon === "MdAssignment" ? (
                                <MdAssignment />
                              ) : item.icon === "MdSupervisorAccount" ? (
                                <MdSupervisorAccount />
                              ) : item.icon === "MdLibraryBooks" ? (
                                <MdLibraryBooks />
                              ) : item.icon === "MdQuestionAnswer" ? (
                                <MdQuestionAnswer />
                              ) : item.icon === "MdImportContacts" ? (
                                <MdImportContacts />
                              ) : item.icon === "MdInventory" ? (
                                <MdInventory />
                              ) : item.icon === "MdOutlineSummarize" ? (
                                <MdOutlineSummarize />
                              ) : item.icon === "MdSupervisorAccount" ? (
                                <MdSupervisorAccount />
                              ) : item.icon === "MdAssignmentInd" ? (
                                <MdAssignmentInd />
                              ) : item.icon === "MdAnalytics" ? (
                                <MdAnalytics />
                              ) : item.icon === "MdReceipt" ? (
                                <MdReceipt />
                              ) : (
                                <MdAccessibleForward />
                              )}
                            </div>

                            <Link
                              to={item.path}
                              className={`text-decoration-none`}
                            >
                              <span
                                className={`sidebar__menu_name ${
                                  activeItem.includes(item.path) ? "active" : ""
                                }`}
                              >
                                {item.menuName}
                              </span>
                            </Link>
                          </div>
                        );
                      }
                    })}
                  </div>
                );
              }
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
