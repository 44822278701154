import ListGroup from 'react-bootstrap/ListGroup';
import Row from 'react-bootstrap/esm/Row';
import Table from 'react-bootstrap/esm/Table';
import Col from 'react-bootstrap/esm/Col';



export const AssesmentKeteranganImpaksiGigi = () => {
    return (
        <>
            <Row className='mt-5'>
                <h5>KELAS (Pell & Gregory)</h5>
                <ListGroup >
                    <ListGroup.Item className='d-flex'><div className='col-xl-1 me-3'>I</div> <div className='col-xl-11 light'> Mesio-distal molar III {"<"} jarak distal molar II dengan ramus mandibula.</div></ListGroup.Item>
                    <ListGroup.Item className='d-flex'><div className='col-xl-1 me-3'>II</div> <div className='col-xl-11 light'> Mesio-distal molar III {">"} jarak distal molar II dengan ramus mandibula.</div></ListGroup.Item>
                    <ListGroup.Item className='d-flex'><div className='col-xl-1 me-3'>III</div> <div className='col-xl-11 light'> Seluruh atau sebagian besar molar III berada di dalam ramus mandibula.</div></ListGroup.Item>
                </ListGroup>
            </Row>
            <Row className='mt-5'>
                <h5>POSISI (Pell & Gregory)</h5>
                <ListGroup>
                    <ListGroup.Item className='d-flex'><div className='col-xl-1 me-3'>A</div> <div className='col-xl-11 light'> Bagian tertinggi molar III setinggi garis oklusal.</div></ListGroup.Item>
                    <ListGroup.Item className='d-flex'><div className='col-xl-1 me-3'>B</div> <div className='col-xl-11 light'> Bagian tertinggi molar III dibawah bidang oklusal, diatas garis servikal molar II.</div></ListGroup.Item>
                    <ListGroup.Item className='d-flex'><div className='col-xl-1 me-3'>C</div> <div className='col-xl-11 light'> Bagian tertinggi molar III dibawah garis servikal molar II.</div></ListGroup.Item>
                </ListGroup>
            </Row>
            <Row className='mt-5'>
                <h5>KLASIFIKASI WINTER</h5>
                <ListGroup key="xl" horizontal="xl">
                    <ListGroup.Item className='d-flex'>
                        <ListGroup variant='flush'>
                            <ListGroup.Item className='d-flex'><div className='col-xl-1 me-3'>1</div> <div className='col-xl-11 light'>Mesioangular.</div></ListGroup.Item>
                            <ListGroup.Item className='d-flex'><div className='col-xl-1 me-3'>2</div> <div className='col-xl-11 light'>Vertical.</div></ListGroup.Item>
                        </ListGroup>
                    </ListGroup.Item>
                    <ListGroup.Item className='d-flex'>
                        <ListGroup variant='flush'>
                            <ListGroup.Item className='d-flex'><div className='col-xl-1 me-3'>3</div> <div className='col-xl-11 light'>Horizontal.</div></ListGroup.Item>
                            <ListGroup.Item className='d-flex'><div className='col-xl-1 me-3'>4</div> <div className='col-xl-11 light'>Distoangular.</div></ListGroup.Item>
                        </ListGroup>
                    </ListGroup.Item>
                    <ListGroup.Item className='d-flex'>
                        <ListGroup variant='flush'>
                            <ListGroup.Item className='d-flex'><div className='col-xl-1 me-3'>5</div> <div className='col-xl-11 light'>Inverted.</div></ListGroup.Item>
                            <ListGroup.Item className='d-flex'><div className='col-xl-1 me-3'>6</div> <div className='col-xl-11 light'>Unusual.</div></ListGroup.Item>
                        </ListGroup>
                    </ListGroup.Item>
                    <ListGroup.Item className='d-flex'>
                        <ListGroup variant='flush'>
                            <ListGroup.Item className='d-flex'><div className='col-xl-1 me-3'>7</div> <div className='col-xl-11 light'>Buccoangular.</div></ListGroup.Item>
                            <ListGroup.Item className='d-flex'><div className='col-xl-1 me-3'>8</div> <div className='col-xl-11 light'>Linguoangular.</div></ListGroup.Item>
                        </ListGroup>
                    </ListGroup.Item>
                </ListGroup>
            </Row>
        </>
    )
}

export const KeteranganStatusGeligi = () => {
    return (
        <>
            <Row>
                <Col xl={12}>
                    <h5>Keterangan</h5>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th colSpan={3} className="text-center">Koding</th>
                                <th colSpan={1} rowSpan={3} className="text-center">Status</th>
                            </tr>
                            <tr>
                                <th colSpan={1} className="text-center">Gigi Sulung</th>
                                <th colSpan={2} className="text-center">Gigi Tetap</th>
                            </tr>
                            <tr>
                                <th scope='col' className="text-center">Mahkota</th>
                                <th scope='col' className="text-center">Mahkota</th>
                                <th scope='col' className="text-center">Akar</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="text-center">A</td>
                                <td className="text-center">0</td>
                                <td className="text-center">0</td>
                                <td>Sehat</td>
                            </tr>
                            <tr>
                                <td className="text-center">B</td>
                                <td className="text-center">1</td>
                                <td className="text-center">1</td>
                                <td>Gigi berlubang/karies gigi</td>
                            </tr>
                            <tr>
                                <td className="text-center">C</td>
                                <td className="text-center">2</td>
                                <td className="text-center">2</td>
                                <td>Tumpatan dengan karies</td>
                            </tr>
                            <tr>
                                <td className="text-center">D</td>
                                <td className="text-center">3</td>
                                <td className="text-center">3</td>
                                <td>Tumpatan tanpa karies</td>
                            </tr>
                            <tr>
                                <td className="text-center">E</td>
                                <td className="text-center">4</td>
                                <td className="text-center">--</td>
                                <td>Gigi tidak ada karena karies</td>
                            </tr>
                            <tr>
                                <td className="text-center">--</td>
                                <td className="text-center">5</td>
                                <td className="text-center">--</td>
                                <td>Gigi tidak ada karena sebab lain</td>
                            </tr>
                            <tr>
                                <td className="text-center">F</td>
                                <td className="text-center">6</td>
                                <td className="text-center">--</td>
                                <td>Gigi diulas dengan sealant/varnish</td>
                            </tr>
                            <tr>
                                <td className="text-center">G</td>
                                <td className="text-center">7</td>
                                <td className="text-center">7</td>
                                <td>Gigi dijadikan penyangga
                                    jembatan atau mahkota khusus
                                    /implant/veneer.</td>
                            </tr>
                            <tr>
                                <td className="text-center">--</td>
                                <td className="text-center">8</td>
                                <td className="text-center">8</td>
                                <td>Gigi tidak tumbuh atau untuk
                                    keadaan akar yang tidak
                                    tereksponansi (unexposed root).</td>
                            </tr>
                            <tr>
                                <td className="text-center">--</td>
                                <td className="text-center">9</td>
                                <td className="text-center">9</td>
                                <td>Lain - lain/Tidak termasuk dalam
                                    kriteria diatas</td>
                            </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </>
    )
}