import { useRef, useState } from 'react'
import ContainerCards from '../component/ContainerCards'
import Form from 'react-bootstrap/Form';
import Cards from '../component/Cards';
import { FormControl, FormFooter } from '../component/Forms';
import { Link, useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { ModalConfirmation } from '../component/ModalPopup';
import axiosApi from '../api/axios';
import useAuth from '../hooks/useAuth';
import { ToastPopup } from '../component/Toastbar';

const AccountSetting = () => {

    const navigate = useNavigate();
    const { auth } = useAuth();
    const oldPassRef = useRef();
    const newPassRef = useRef();
    const newPassConfirmationRef = useRef();
    const [showConfirm, setShowConfirm] = useState(false);
    const [response, setResponse] = useState({});
    const [showToast, setShowToast] = useState(false);

    const [body, setBody] = useState({
        username: "",
        password: "",
        newPassword: ""
    });

    const onSubmit = async () => {
        try {
            const result = await axiosApi.put('/users/changePassword', body);
            setResponse(result);
            onClose();
            setShowToast(true);

            const sleep = ms => new Promise(r => setTimeout(r, ms));
            await sleep(1000);
            navigate("/", { replace: true });
        } catch (error) {
            setResponse(error);
            onClose();
            setShowToast(true);
        }
    }

    const onClose = () => {
        setShowConfirm(false);
    }

    const onConfirmation = (e) => {
        e.preventDefault();
        setBody({
            username: auth.userNama,
            password: oldPassRef.current.value,
            newPassword: newPassRef.current.value

        })
        setShowConfirm(true);
    }

    return (
        <>
            <ContainerCards>
                <Form onSubmit={onConfirmation}>
                    <Cards>
                        <FormControl
                            controlId="username" label="Username" type="text" disabled value={auth.userNama}
                        />
                        <FormControl
                            controlId="oldPassword" label="Old Password" type="password" required refValue={oldPassRef}
                        />
                        <FormControl
                            controlId="newPassword" label="New Password" type="password" required refValue={newPassRef}
                        />
                        <FormControl
                            controlId="newPasswordConfirm" label="Confirm New Password" type="password" required refValue={newPassConfirmationRef}
                        />
                        <FormFooter>
                            <Link to="/user" className="btn btn-secondary me-xl-2 primary" variant="secondary">
                                Back
                            </Link>
                            <Button variant="primary" className='ms-2 red' type="submit">
                                Update
                            </Button>
                        </FormFooter>
                    </Cards>
                </Form>


            </ContainerCards>
            <ModalConfirmation show={showConfirm} handleClose={onClose} handleSubmit={onSubmit} message="Apakah anda yakin?" />
            {response?.status && <ToastPopup message={response.data.msg} showToast={showToast} setShowToast={setShowToast} response={response?.status} />
            }
        </>
    )
}

export default AccountSetting