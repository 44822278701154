import { useEffect, useState } from "react";
import Button from "react-bootstrap/esm/Button";
import Container from "react-bootstrap/esm/Container";
import "../css/dashboard.css";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import axiosApi, { axiosBasic } from "../api/axios";
import { FormFilter } from "../component/Forms";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Dashboard = () => {
  const [countData, setCountData] = useState({});
  const [provinceId, setProvinceId] = useState("");
  const [regenciesId, setRegenciesId] = useState("");
  const [districtId, setDistrictId] = useState("");
  const [provinceName, setProvinceName] = useState("");
  const [regenciesName, setRegenciesName] = useState("");
  const [districtName, setDistrictName] = useState("");
  const [enableDistrict, setEnableDistrict] = useState(true);
  const [enableRegencies, setEnableRegencies] = useState(true);
  const [enableVillage, setEnableVillage] = useState(true);
  const [listYear, setListYear] = useState([]);
  const [filterYear, setFilterYear] = useState(new Date().getFullYear());
  const [filterMonth, setFilterMonth] = useState("");
  const [blok, setBlok] = useState([]);
  const [blokValue, setBlokValue] = useState("");

  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [maloklusiData, setMaloklusiData] = useState([]);
  const [fluorosisData, setFluorosisData] = useState([]);
  const [keparahanErosiData, setKeparahanErosiData] = useState([]);
  const [lesiMukosaData, setLesiMukosaData] = useState([]);
  const [kebutuhanPerawatanData, setKebutuhanPerawatanData] = useState([]);
  const [traumaDentalData, setTraumaDentalData] = useState([]);

  const [listMonth, setListMonth] = useState([
    {
      value: 1,
      label: "Januari",
    },
    {
      value: 2,
      label: "Februari",
    },
    {
      value: 3,
      label: "Maret",
    },
    {
      value: 4,
      label: "April",
    },
    {
      value: 5,
      label: "Mei",
    },
    {
      value: 6,
      label: "Juni",
    },

    {
      value: 7,
      label: "Juli",
    },

    {
      value: 8,
      label: "Agustus",
    },

    {
      value: 9,
      label: "September",
    },

    {
      value: 10,
      label: "Oktober",
    },

    {
      value: 11,
      label: "November",
    },

    {
      value: 12,
      label: "Desember",
    },
  ]);

  useEffect(() => {
    const getCountData = async () => {
      const response = await axiosBasic.get(`/dashboard/countData`);
      setCountData(response.data);
    };
    getCountData();
    getYear();
    getDataFilter();
    getBlok();
  }, []);

  const getBlok = async () => {
    const response = await axiosApi.get("/setting/blok/findList");
    setBlok(response?.data?.result);
  };

  const loadProvince = async (inputValue, callBack) => {
    const result = await axiosApi.get(
      `address/province?search_query=${inputValue}`
    );
    callBack(result.data.result);
  };

  const handleProvince = (e) => {
    setProvinceId(e.value);
    setProvinceName(e.label);

    setEnableDistrict(true);
    setEnableVillage(true);
    setEnableRegencies(false);
  };
  const handleRegencies = (e) => {
    setRegenciesId(e.value);
    setRegenciesName(e.label);
    setEnableDistrict(false);
    setEnableVillage(true);
  };

  const handleDistrict = (e) => {
    setDistrictId(e.value);
    setDistrictName(e.label);
    setEnableVillage(false);
  };

  const loadRegencies = async (inputValue, callBack) => {
    const result = await axiosApi.get(
      `address/regencies?search_query=${inputValue}&id=${provinceId}`
    );
    callBack(result.data.result);
  };

  const loadDistrict = async (inputValue, callBack) => {
    const result = await axiosApi.get(
      `address/district?search_query=${inputValue}&id=${regenciesId}`
    );
    callBack(result.data.result);
  };

  const getYear = () => {
    const year = new Date().getFullYear();
    let array = [];
    const result = Array.from({ length: 50 }, (v, i) => year - i);
    result.forEach((e) => {
      const item = {
        value: e,
        label: e,
      };
      array.push(item);
    });
    setListYear(array);
  };

  const handleYear = (e) => {
    setFilterYear(e.value);
  };
  const handleMonth = (e) => {
    setFilterMonth(e.value);
  };

  const handleBlok = (e) => {
    setBlokValue(e.value);
  };

  const getDataFilter = async () => {
    const data = {
      year: filterYear,
      month: filterMonth,
      blok: blokValue,
      provinsi: provinceName,
      kabupatenOrKota: regenciesName,
      kecamatan: districtName,
    };

    const response = await axiosBasic.post(`/dashboard/chart`, data);
    setMaloklusiData(response.data.maloklusi);
    setFluorosisData(response.data.fluorosis);
    setKeparahanErosiData(response.data.keparahanErosiGigi);
    setLesiMukosaData(response.data.lesiMukosaOral);
    setKebutuhanPerawatanData(response.data.kebutuhanPerawatanSegera);
    setTraumaDentalData(response.data.traumaDental);

  };

  return (
    <>
      <Container fluid>
        <Row className="justify-content-md-center">
          <Col md={11}>
            <div className="info-total">
              <MiniCard
                title={"TOTAL PASIEN"}
                desc={countData.pasien}
                color="blue"
              />
              <MiniCard
                title={"TOTAL KA KELUARGA"}
                desc={countData.kepalaKeluarga}
                color="pink"
              />
              <MiniCard
                title={"TOTAL ASSESMEN"}
                desc={countData.assesment}
                color="orange"
              />
              <MiniCard
                title={"TOTAL KUESIONER"}
                desc={countData.kuesioner}
                color="green"
              />
            </div>
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col md={11}>
            <div className="filter-search">
              <div className="filter-place">
                <FormFilter controlId="province" label="Provinsi">
                  <AsyncSelect
                    loadOptions={loadProvince}
                    onChange={handleProvince}
                    placeholder="Provinsi"
                  />
                </FormFilter>
                <FormFilter controlId="regencies" label="Kabupaten / Kota">
                  <AsyncSelect
                    loadOptions={loadRegencies}
                    onChange={handleRegencies}
                    isDisabled={enableRegencies}
                    placeholder="Kab / Kota"
                  />
                </FormFilter>
                <FormFilter controlId="district" label="Kecamatan">
                  <AsyncSelect
                    loadOptions={loadDistrict}
                    onChange={handleDistrict}
                    isDisabled={enableDistrict}
                    placeholder="Kecamatan"
                  />
                </FormFilter>
                <FormFilter controlId="year" label="year">
                  <Select
                    options={blok}
                    onChange={handleBlok}
                    placeholder="Blok"
                  />
                </FormFilter>

                <FormFilter controlId="year" label="year">
                  <Select
                    options={listYear}
                    onChange={handleYear}
                    placeholder="Tahun"
                  />
                </FormFilter>

                <FormFilter controlId="month" label="month">
                  <Select
                    options={listMonth}
                    onChange={handleMonth}
                    placeholder="Bulan"
                  />
                </FormFilter>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col md={11} className="d-flex justify-content-center">
            <Button
              className="primary ms-2"
              type="submit"
              onClick={getDataFilter}
            >
              Filter
            </Button>
          </Col>
        </Row>

        <Row className="justify-content-md-center">
          <Col md={11}>
            <div className="chart-card">
              <div className="item-chart-card">
                <LineChartMaloklusi maloklusiData={maloklusiData} />
              </div>
              <div className="item-chart-card">
                <LineChartFluorosisEmail param={fluorosisData} />
              </div>
            </div>
            <div className="chart-card">
              <div className="item-chart-card">
                <BarLesiMukosa param={lesiMukosaData} />
              </div>
              <div className="item-chart-card">
                <LineChartKeparahanErosi param={keparahanErosiData} />
              </div>
            </div>
            <div className="chart-card">
              <div className="item-chart-card">
                <ChartKebutuhanPerawatanSegera param={kebutuhanPerawatanData} />
              </div>
              <div className="item-chart-card">
                <ChartTraumaDental param={traumaDentalData} />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default Dashboard;

const MiniCard = ({ title, desc, color }) => {
  return (
    <>
      <div className={`mini-card ${color}`}>
        <h3>{title}</h3>
        <h1>{desc}</h1>
      </div>
    </>
  );
};

const LineChartMaloklusi = ({ maloklusiData }) => {

  const options = {
    plugins: {
      title: {
        display: true,
        text: 'Fluorosis Email',
      },
    },
    responsive: true,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    scales: {
      x: {
        stacked: false,
      },
      y: {
        stacked: false,
      },
    },
  };

  // const options = {
  //   responsive: true,
  //   plugins: {
  //     legend: {
  //       position: "top",
  //     },
  //     title: {
  //       display: true,
  //       text: "Maloklusi",
  //     },
  //   },
  // };

  // const labels = maloklusiData.map((index) => index.bulan);
  const labels = maloklusiData.map((index) => index.bulan);

  const data = {
    labels,
    datasets: [
      {
        label: "Relasi molar kelas I, tanpa kelainan anterior.",
        data: maloklusiData.map((index) => index.maloklusi_dental0),
        // borderColor: "rgb(248,144,227)",
        stack: 'Stack 0',
        backgroundColor: "rgba(248,144,227, 0.5)",
      },
      {
        label:
          "Relasi molar kelas I, dengan crowding anterior/kelainan overjet.",
        data: maloklusiData.map((index) => index.maloklusi_dental1),
        // borderColor: "rgb(148,178,235)",
        stack: 'Stack 0',
        backgroundColor: "rgba(148,178,235,0.5)",
      },
      {
        label: "Relasi molar kelas II.",
        data: maloklusiData.map((index) => index.maloklusi_dental2),
        // borderColor: "rgb(248,201,144)",
        stack: 'Stack 0',
        backgroundColor: "rgba(248,201,144,0.5)",
      },
      {
        label: "Relasi molar kelas III.",
        data: maloklusiData.map((index) => index.maloklusi_dental3),
        // borderColor: "rgb(191,248,144)",
        stack: 'Stack 0',
        backgroundColor: "rgba(191,248,144,0.5)",
      },
    ],
  };

  return (
    <>
      <Bar options={options} data={data} />
    </>
  );
};

const LineChartFluorosisEmail = ({ param }) => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Fluorosis Email",
      },
    },
  };

  // const options = {
  //   plugins: {
  //     title: {
  //       display: true,
  //       text: 'Fluorosis Email',
  //     },
  //   },
  //   responsive: true,
  //   interaction: {
  //     mode: 'index',
  //     intersect: false,
  //   },
  //   scales: {
  //     x: {
  //       stacked: false,
  //     },
  //     y: {
  //       stacked: false,
  //     },
  //   },
  // };

  // const labels = maloklusiData.map((index) => index.bulan);
  const labels = param.map((index) => index.bulan);

  const data = {
    labels,
    datasets: [
      {
        label: "Normal",
        data: param.map((index) => index.fluorosis_email0),
        // stack: 'Stack 0',
        borderColor: "rgb(248,144,227)",
        backgroundColor: "rgba(248,144,227, 0.5)",
      },
      {
        label: "Meragukan",
        data: param.map((index) => index.fluorosis_email1),
        // stack: 'Stack 0',
        borderColor: "rgb(148,178,235)",
        backgroundColor: "rgba(148,178,235,0.5)",
      },
      {
        label: "Sangat ringan",
        data: param.map((index) => index.fluorosis_email2),
        // stack: 'Stack 0',
        borderColor: "rgb(248,201,144)",
        backgroundColor: "rgba(248,201,144,0.5)",
      },
      {
        label: "Ringan",
        data: param.map((index) => index.fluorosis_email3),
        // stack: 'Stack 0',
        borderColor: "rgb(191,248,144)",
        backgroundColor: "rgba(191,248,144,0.5)",
      },
      {
        label: "Sedang",
        data: param.map((index) => index.fluorosis_email4),
        // stack: 'Stack 0',
        borderColor: "rgb(248, 144, 182)",
        backgroundColor: "rgba(248, 144, 182, 0.5)",
      },
      {
        label: "Berat",
        data: param.map((index) => index.fluorosis_email5),
        // stack: 'Stack 0',
        borderColor: "rgb(160, 148, 235)",
        backgroundColor: "rgba(160, 148, 235,0.5)",
      },
      {
        label: "Gigi di-ekslusi (mahkota, tambahan, “bracket”)",
        data: param.map((index) => index.fluorosis_email8),
        // stack: 'Stack 0',
        borderColor: "rgb(248, 241, 144)",
        backgroundColor: "rgba(248, 241, 144,0.5)",
      },
      {
        label: "Tidak dapat dicatat (gigi belum erupsi)",
        data: param.map((index) => index.fluorosis_email9),
        // stack: 'Stack 0',
        borderColor: "rgb(144, 248, 203)",
        backgroundColor: "rgba(144, 248, 203,0.5)",
      },
    ],
  };

  return (
    <>
      <Line options={options} data={data} />
    </>
  );
};

const ChartTraumaDental = ({ param }) => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Trauma Dental",
      },
    },
  };

  // const options = {
  //   plugins: {
  //     title: {
  //       display: true,
  //       text: 'Fluorosis Email',
  //     },
  //   },
  //   responsive: true,
  //   interaction: {
  //     mode: 'index',
  //     intersect: false,
  //   },
  //   scales: {
  //     x: {
  //       stacked: false,
  //     },
  //     y: {
  //       stacked: false,
  //     },
  //   },
  // };

  // const labels = maloklusiData.map((index) => index.bulan);
  const labels = param.map((index) => index.bulan);

  const data = {
    labels,
    datasets: [
      {
        label: "Tidak ada tanda cedera",
        data: param.map((index) => index.trauma_dental0),
        // stack: 'Stack 0',
        borderColor: "rgb(248,144,227)",
        backgroundColor: "rgba(248,144,227, 0.5)",
      },
      {
        label: "Cedera yang dirawat",
        data: param.map((index) => index.trauma_dental1),
        // stack: 'Stack 0',
        borderColor: "rgb(148,178,235)",
        backgroundColor: "rgba(148,178,235,0.5)",
      },
      {
        label: "Hanya fraktur enamel",
        data: param.map((index) => index.trauma_dental2),
        // stack: 'Stack 0',
        borderColor: "rgb(248,201,144)",
        backgroundColor: "rgba(248,201,144,0.5)",
      },
      {
        label: "Fraktur enamel dan dentin",
        data: param.map((index) => index.trauma_dental3),
        // stack: 'Stack 0',
        borderColor: "rgb(191,248,144)",
        backgroundColor: "rgba(191,248,144,0.5)",
      },
      {
        label: "Keterlibatan pulpa",
        data: param.map((index) => index.trauma_dental4),
        // stack: 'Stack 0',
        borderColor: "rgb(248, 144, 182)",
        backgroundColor: "rgba(248, 144, 182, 0.5)",
      },
      {
        label: "Kehilangan gigi karena trauma",
        data: param.map((index) => index.trauma_dental5),
        // stack: 'Stack 0',
        borderColor: "rgb(160, 148, 235)",
        backgroundColor: "rgba(160, 148, 235,0.5)",
      },
      {
        label: "Kerusakan lainnya",
        data: param.map((index) => index.trauma_dental6),
        // stack: 'Stack 0',
        borderColor: "rgb(248, 241, 144)",
        backgroundColor: "rgba(248, 241, 144,0.5)",
      },
      {
        label: "Gigi yang dikecualikan",
        data: param.map((index) => index.trauma_dental7),
        // stack: 'Stack 0',
        borderColor: "rgb(144, 248, 203)",
        backgroundColor: "rgba(144, 248, 203,0.5)",
      },
    ],
  };

  return (
    <>
      <Line options={options} data={data} />
    </>
  );
};

const LineChartKeparahanErosi = ({ param }) => {
  // const options = {
  //   responsive: true,
  //   plugins: {
  //     legend: {
  //       position: "top",
  //     },
  //     title: {
  //       display: true,
  //       text: "Keparahan Erosi Gigi",
  //     },
  //   },
  // };

  const options = {
    plugins: {
      title: {
        display: true,
        text: 'Keparahan Erosi Gigi',
      },
    },
    responsive: true,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    scales: {
      x: {
        stacked: false,
      },
      y: {
        stacked: false,
      },
    },
  };

  // const labels = maloklusiData.map((index) => index.bulan);
  const labels = param.map((index) => index.bulan);

  const data = {
    labels,
    datasets: [
      {
        label: "Tidak ada tanda erosi",
        data: param.map((index) => index.keparahan_erosi_gigi0),
        // borderColor: "rgb(248,144,227)",
        stack: 'Stack 0',
        backgroundColor: "rgba(248,144,227, 0.5)",
      },
      {
        label: "Erosi pada email",
        data: param.map((index) => index.keparahan_erosi_gigi1),
        // borderColor: "rgb(148,178,235)",
        stack: 'Stack 0',
        backgroundColor: "rgba(148,178,235,0.5)",
      },
      {
        label: "Erosi pada dentin",
        data: param.map((index) => index.keparahan_erosi_gigi2),
        // borderColor: "rgb(248,201,144)",
        stack: 'Stack 0',
        backgroundColor: "rgba(248,201,144,0.5)",
      },
      {
        label: "Keterlibatan pulpa",
        data: param.map((index) => index.keparahan_erosi_gigi3),
        // borderColor: "rgb(191,248,144)",
        stack: 'Stack 0',
        backgroundColor: "rgba(191,248,144,0.5)",
      },
    ],
  };

  return (
    <>
      <Bar options={options} data={data} />
    </>
  );
};

const ChartKebutuhanPerawatanSegera = ({ param }) => {
  // const options = {
  //   responsive: true,
  //   plugins: {
  //     legend: {
  //       position: "top",
  //     },
  //     title: {
  //       display: true,
  //       text: "Keparahan Erosi Gigi",
  //     },
  //   },
  // };

  const options = {
    plugins: {
      title: {
        display: true,
        text: 'Kebutuhan Perawatan Segera',
      },
    },
    responsive: true,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    scales: {
      x: {
        stacked: false,
      },
      y: {
        stacked: false,
      },
    },
  };

  // const labels = maloklusiData.map((index) => index.bulan);
  const labels = param.map((index) => index.bulan);

  const data = {
    labels,
    datasets: [
      {
        label: "Tidak perlu perawatan",
        data: param.map((index) => index.kebutuhan_perawatan_segera0),
        // borderColor: "rgb(248,144,227)",
        stack: 'Stack 0',
        backgroundColor: "rgba(248,144,227, 0.5)",
      },
      {
        label: "Diperlukan perawatan preventif dan rutin",
        data: param.map((index) => index.kebutuhan_perawatan_segera1),
        // borderColor: "rgb(148,178,235)",
        stack: 'Stack 0',
        backgroundColor: "rgba(148,178,235,0.5)",
      },
      {
        label: "Diperlukan perawatan dengan scaling",
        data: param.map((index) => index.kebutuhan_perawatan_segera2),
        // borderColor: "rgb(248,201,144)",
        stack: 'Stack 0',
        backgroundColor: "rgba(248,201,144,0.5)",
      },
      {
        label: "Diperlukan perawatan segera",
        data: param.map((index) => index.kebutuhan_perawatan_segera3),
        // borderColor: "rgb(191,248,144)",
        stack: 'Stack 0',
        backgroundColor: "rgba(191,248,144,0.5)",
      },
      {
        label: "Dirujuk ke pemeriksaan komprehensif (terdapat kondisi penyakit sistemik)",
        data: param.map((index) => index.kebutuhan_perawatan_segera3),
        // borderColor: "rgb(191,248,144)",
        stack: 'Stack 0',
        backgroundColor: "rgba(160, 148, 235,0.5)",
      },
    ],
  };

  return (
    <>
      <Bar options={options} data={data} />
    </>
  );
};

const BarLesiMukosa = ({ param }) => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Lesi Mukosa Oral",
      },
    },
  };

  // const options = {
  //   plugins: {
  //     title: {
  //       display: true,
  //       text: 'Lesi Mukosa Oral',
  //     },
  //   },
  //   responsive: true,
  //   interaction: {
  //     mode: 'index',
  //     intersect: false,
  //   },
  //   scales: {
  //     x: {
  //       stacked: false,
  //     },
  //     y: {
  //       stacked: true,
  //     },
  //   },
  // };

  // const labels = maloklusiData.map((index) => index.bulan);
  const labels = param.map((index) => index.bulan);

  const data = {
    labels,
    datasets: [
      {
        label: "Tidak ada kondisi abnormal",
        data: param.map((index) => index.lesi_mukosa_oral0),
        borderColor: "rgb(248,144,227)",
        backgroundColor: "rgba(248,144,227, 0.5)",
      },
      {
        label: "Tumor maligna (kanker mulut)",
        data: param.map((index) => index.lesi_mukosa_oral1),
        borderColor: "rgb(148,178,235)",
        backgroundColor: "rgba(148,178,235,0.5)",
      },
      {
        label: "Leukoplakia",
        data: param.map((index) => index.lesi_mukosa_oral2),
        borderColor: "rgb(248,201,144)",
        backgroundColor: "rgba(248,201,144,0.5)",
      },
      {
        label: "Lichen planus",
        data: param.map((index) => index.lesi_mukosa_oral3),
        borderColor: "rgb(191,248,14)",
        backgroundColor: "rgba(191,248,144,0.5)",
      },
      {
        label: "Ulserasi (apthous, herpetic, traumatic)",
        data: param.map((index) => index.lesi_mukosa_oral4),
        borderColor: "rgb(248, 144, 182)",
        backgroundColor: "rgba(248, 144, 182, 0.5)",
      },
      {
        label: "Nekrosis ulseratif gingivitis akut",
        data: param.map((index) => index.lesi_mukosa_oral5),
        borderColor: "rgb(160, 148, 235)",
        backgroundColor: "rgba(160, 148, 235,0.5)",
      },
      {
        label: "Candidiasis",
        data: param.map((index) => index.lesi_mukosa_oral6),
        borderColor: "rgb(248, 241, 144)",
        backgroundColor: "rgba(248, 241, 144,0.5)",
      },
      {
        label: "Abses",
        data: param.map((index) => index.lesi_mukosa_oral7),
        borderColor: "rgb(144, 248, 203)",
        backgroundColor: "rgba(144, 248, 203,0.5)",
      },
      {
        label: "Lesi hipersensitivitas",
        data: param.map((index) => index.lesi_mukosa_oral8),
        borderColor: "rgb(248, 206, 144)",
        backgroundColor: "rgba(248, 206, 144,0.9)",
      },
      {
        label: "Kondisi lain",
        data: param.map((index) => index.lesi_mukosa_oral9),
        borderColor: "rgb(144, 239, 248)",
        backgroundColor: "rgba(144, 239, 248,0.5)",
      },
      {
        label: "Tidak dapat dicatat",
        data: param.map((index) => index.lesi_mukosa_oralx),
        borderColor: "rgb(144, 248, 210)",
        backgroundColor: "rgba(144, 248, 210,0.8)",
      },
    ],
  };

  return (
    <>
      <Line options={options} data={data} />
    </>
  );
};
