import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Cards from "./Cards";

const ContainerCards = ({ children }) => {
    return (<>
        <Container fluid>
            <Row className='justify-content-md-center'>
                <Col md={11}>
                    {children}
                </Col>
            </Row>

        </Container>
    </>
    )
}

export const PanelForm = ({ children }) => {
    return (<>
        <Container fluid>
            <Row className='justify-content-md-center'>
                <Col xl={7} lg="12" md="12">
                    {children}
                </Col>
            </Row>
        </Container>
    </>
    )
}

export const FreePanel = ({ children,width }) => {
    return (<>
        <Container fluid>
            <Row>
                <Col xl={width}>
                    {children}
                </Col>
            </Row>
        </Container>
    </>
    )
}


export const ContainerWithCard = ({ children, title }) => {
    return (<>
        <Container fluid>
            <Row className='justify-content-md-center'>
                <Col md={11}>
                    <Cards title={title}>
                        {children}
                    </Cards>

                </Col>
            </Row>

        </Container>
    </>
    )
}


export const Panel = ({children}) =>{
    return(
        <>
        <Container>
            
        </Container>
        </>
    )
}

export default ContainerCards