import { useEffect, useState } from 'react';
import axiosApi from '../api/axios';
import Cards from '../component/Cards';
import Filters from '../component/Filters';
import { FormBody, FormContainer, FormControlCheck } from '../component/Forms';
import Form from 'react-bootstrap/Form';
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Table from 'react-bootstrap/esm/Table';
import ReactPaginate from 'react-paginate';
import { MdCheck, MdClose } from 'react-icons/md';
import { ModalConfirmation } from '../component/ModalPopup';
import { ToastPopup } from '../component/Toastbar';
import Modal from 'react-bootstrap/Modal';

const PendingTask = () => {

    const [pendingTask, setPendingTask] = useState([]);
    const [page, setPage] = useState(0);
    const [pages, setPages] = useState(0);
    const [rows, setRows] = useState(0);
    // const [keyword, setKeyword] = useState("");
    const [query, setQuery] = useState("");
    const [showApprove, setShowApprove] = useState(false);
    const [showReject, setShowReject] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [response, setResponse] = useState({});
    const [listRole, setListRole] = useState([]);
    const [roleId, setRoleId] = useState('');
    const [id, setId] = useState('');


    const [keyword, setKeyword] = useState("");
    const [keyword1, setKeyword1] = useState("");
    const [startDate, setStartDate] = useState("");
    const [searchQuery, setSearchQuery] = useState("");

    useEffect(() => {
        const getUsers = async () => {
            const response = await axiosApi.get(`/users/pendingTask?search_query=${keyword}&start_date=${keyword1}&page=${page}&limit=${10}`);
            setPendingTask(response.data.result);
            setPage(response.data.page);
            setPages(response.data.totalPage);
            setRows(response.data.totalRows);

        };
        getUsers();
    }, [page, keyword, response, keyword1])

    const searchData = (e) => {
        e.preventDefault();
        setPage(0);
        setKeyword(searchQuery);
        setKeyword1(startDate);
    }

    const clearSearch = (e) => {
        e.preventDefault();
        setPage(0);
        setSearchQuery("")
        setStartDate("");

        setKeyword("");
        setKeyword1("");
    }
    


    useEffect(() => {
        const getAllRole = async () => {
            try {
                const response = await axiosApi.get('roles/findAll');
                setListRole(response.data.result);

            } catch (error) {
                console.log(error);
            }
        }
        getAllRole();
    }, [])


    const changePage = ({ selected }) => {
        setPage(selected);
    }

    

    const buttonApprove = (e) => {
        setShowApprove(true)
        setId(e);
    }

    const buttonReject = (e) => {
        setShowReject(true)
        setId(e);
    }

    const handleApprove = async (e) => {
        e.preventDefault();
        try {
            const result = await axiosApi.post('users/approveUser', {id, roleId});
            setResponse(result);
            setShowApprove(false);
            setShowToast(true);
        } catch (error) {
            setResponse(error.response);
            setShowApprove(false);
            setShowToast(true);
        }
    }

    const handleReject = async () => {
        try {
            const result = await axiosApi.post('users/rejectUser', {id});
            setResponse(result);
            setShowReject(false);
            setShowToast(true);
        } catch (error) {
            setResponse(error.response);
            setShowReject(false);
            setShowToast(true);
        }
    }

    const onSelectRole = (e) => {
        setRoleId(e.target.value);
    }
    return (
        <>
            <Container fluid>
                <Row className='justify-content-md-center'>
                    <Col md={11}>
                        <Cards>
                            <Form onSubmit={searchData}>
                                <Row className='d-flex flex-row justify-content-evenly'>
                                    <Col xl="9">
                                        <Row>
                                            <Col xl="6">
                                                <input type="text" value={searchQuery} className='search-input' placeholder='Search' onChange={(e) => setSearchQuery(e.target.value)} autoComplete="off" />
                                            </Col>
                                            <Col xl="6">
                                                <input type="date" value={startDate} className='search-input' placeholder='Search' onChange={(e) => setStartDate(e.target.value)} />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xl="3">
                                        <Row>
                                            <Col xl="12" className='d-flex flex-row justify-content-evenly'>
                                                <button className='button-search' type="submit">Search</button>
                                                <button className='button-clear-search' onClick={clearSearch} >Clear</button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Form>
                            <div className='table-responsive'>
                                <Table className='table-sm'>
                                    <thead>
                                        <tr>
                                            <th className='text-left fs-7 fw-bolder font-purple pt-3 pb-3 font-15'>Username</th>
                                            <th className='text-left fs-7 fw-bolder font-purple pt-3 pb-3 font-15'>Email</th>
                                            <th className='text-left fs-7 fw-bolder font-purple pt-3 pb-3 font-15'>Created Time</th>
                                            <th className='text-left fs-7 fw-bolder font-purple pt-3 pb-3 font-15'>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {pendingTask.map((item, index) => (
                                            <tr key={index} >
                                                <td className='text-left'>{item.username}</td>
                                                <td className='text-left'>{item.email}</td>
                                                <td className='text-left'>{item.created_timestamp.replace("T", " ").replace("Z", " ")}</td>

                                                <td className='text-left'>
                                                    <Button onClick={() => { buttonApprove(item.id) }} className='btn btn-primary red me-2'><MdCheck /></Button>
                                                    <Button onClick={() => { buttonReject(item.id) }} className='btn btn-primary red'><MdClose /></Button>
                                                </td>
                                            </tr>

                                        ))}

                                    </tbody>
                                </Table>
                            </div>

                            <p>Total Rows : {rows}  Page: {rows ? page + 1 : 0} of {pages}</p>
                            <nav className='pagination justify-content-center' key={rows}>
                                <ReactPaginate
                                    previousLabel={"Prev"}
                                    nextLabel={"Next"}
                                    pageCount={pages}
                                    onPageChange={changePage}
                                    containerClassName="pagination"
                                    pageLinkClassName='page-link font-black'
                                    previousLinkClassName='page-link font-orange'
                                    nextLinkClassName='page-link font-orange'
                                    activeLinkClassName='page-link active third font-white'
                                    disabledLinkClassName='page-link disabled font-disable'
                                />

                            </nav>
                        </Cards>
                    </Col>
                </Row>

            </Container>
            <Modal show={showApprove} onHide={() => { setShowApprove(false) }} size="md" backdrop="static" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Konfirmasi</Modal.Title>
                </Modal.Header>
                <Form onSubmit={handleApprove}>
                    <Modal.Body>
                        <p>Apakah anda yakin untuk setuju?</p>

                        <Form.Group controlId='role'>
                            <Form.Label>Pilih Role :</Form.Label>
                            <Form.Select value={roleId} onChange={onSelectRole} size="5" required>
                                <option value="">-Pilih salah satu-</option>
                                {listRole.map((item, index) => (
                                    <option key={index} value={item.id} >{item.name}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" className='secondary-color' onClick={() => { setShowApprove(false) }}>
                            Tidak
                        </Button>
                        <Button variant="primary" className='primary' type='submit'>
                            Ya
                        </Button>
                    </Modal.Footer>
                </Form>

            </Modal>
            <ModalConfirmation show={showReject} handleClose={() => { setShowReject(false) }} handleSubmit={handleReject} message="Apakah anda yakin menolak?" />

            {response?.status && <ToastPopup message={response.data.msg} showToast={showToast} setShowToast={setShowToast} response={response?.status} />
            }
        </>
    )
}

export default PendingTask