import React, { useEffect, useState } from 'react'
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import axiosApi from '../api/axios';
import ContainerCards from '../component/ContainerCards';
import Form from 'react-bootstrap/Form';
import Cards from '../component/Cards';
import { FormBody, FormContainer, FormContainerCol } from '../component/Forms';
import { GridPagination, TablePagination } from '../component/TableData';
import { useParams } from 'react-router-dom';
import Filters from '../component/Filters';
import { Link } from 'react-router-dom';

const PatientView = () => {

    const [query, setQuery] = useState("");
    const [page, setPage] = useState(0);
    const [pages, setPages] = useState(0);
    const [rows, setRows] = useState(0);
    const [page1, setPage1] = useState(0);
    const [pages1, setPages1] = useState(0);
    const [rows1, setRows1] = useState(0);
    const [date, setDate] = useState('');
    const { id } = useParams();
    const [data, setData] = useState([]);
    const [dataKuesioner, setDataKuesioner] = useState([]);

    const [pasien, setPasien] = useState({});
    const [riwayat, setRiwayat] = useState('');
    const [keyword, setKeyword] = useState("");
    const [keyword1, setKeyword1] = useState("");

    const [startDate, setStartDate] = useState("");
    const [searchQuery, setSearchQuery] = useState("");

    const [startDate1, setStartDate1] = useState("");
    const [searchQuery1, setSearchQuery1] = useState("");

    const [keyword2, setKeyword2] = useState("");
    const [keyword3, setKeyword3] = useState("");
    useEffect(() => {
        const getPasien = async () => {
            const result = await axiosApi.get(`/pasien/findById?id=${id}`)
            setPasien(result.data.result.pasien);
            setRiwayat(result.data.result.namaRiwayat.toString());
        }
        getPasien();
    }, [id])


    useEffect(() => {
        const getPasiens = async () => {
            const response = await axiosApi.get(`/assesment/viewAssesment?search_query=${keyword}&start_date=${keyword1}&pasien_id=${id}&page=${page}&limit=${10}`);
            setData(response.data.result)
            setPage(response.data.page);
            setPages(response.data.totalPage);
            setRows(response.data.totalRows);

        };
        getPasiens();
    }, [page, keyword, keyword1, id])

    useEffect(() => {
        const getKuesioner = async () => {
            const response = await axiosApi.get(`/kuesioner/viewKuesioner?search_query=${keyword2}&start_date=${keyword3}&pasien_id=${id}&page=${page1}&limit=${10}`);
            setDataKuesioner(response.data.result)
            setPage1(response.data.page);
            setPages1(response.data.totalPage);
            setRows1(response.data.totalRows);

        };
        getKuesioner();
    }, [page1, keyword2, keyword3, id])

    const searchDataKuesioner = (e) => {
        e.preventDefault();
        setPage(0);
        setKeyword2(searchQuery1);
        setKeyword3(startDate1);
    }

    const searchData = (e) => {
        e.preventDefault();
        setPage(0);
        setKeyword(searchQuery);
        setKeyword1(startDate);
    }

    const clearSearch = (e) => {
        e.preventDefault();
        setPage(0);
        setSearchQuery("")
        setStartDate("");

        setKeyword("");
        setKeyword1("");
    }

    const clearSearch1 = (e) => {
        e.preventDefault();
        setPage(0);
        setSearchQuery1("")
        setStartDate1("");

        setKeyword2("");
        setKeyword3("");
    }

    const changePage = ({ selected }) => {
        setPage(selected);
    }
    const changePage1 = ({ selected }) => {
        setPage1(selected);
    }

    const functionButton = async (e) => {
        fetch(`/api/report/create?id_assesment=${e.id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(async res => {
            if (res.status === 200) {
                const blob = await res.blob();
                const file = new Blob(
                    [blob],
                    { type: 'application/pdf' }
                );
                //Build a URL from the file
                const fileURL = URL.createObjectURL(file);
                //Open the URL on new Window
                window.open(fileURL);
            }
        })
    }

    const linkData = [
        {
            name: "Edit",
            path: "editAssesment",
            type: "link"
        },
        {
            name: "View",
            path: "viewAssesment",
            type: "link"
        }
    ]

    const linkData1 = [
        {
            name: "Edit",
            path: "editKuesioner",
            type: "link"
        },
        {
            name: "View",
            path: "viewKuesioner",
            type: "link"
        }
    ]
    return (
        <>
            <ContainerCards>
                <h4>Data Pasien</h4>
                <Cards>
                    <Row>
                        <Col lg="6" md="12" sm="12">
                            <FormContainerCol controlId="name" label="Nama">
                                <Form.Control type='text' autoComplete="off" defaultValue={pasien.name} disabled />
                            </FormContainerCol>
                            <FormContainerCol controlId="name" label="Tanggal Lahir">
                                <Form.Control type='text' autoComplete="off" defaultValue={pasien.tanggal_lahir} disabled />
                            </FormContainerCol>
                            <FormContainerCol controlId="name" label="Jenis Kelamin">
                                <Form.Control type='text' autoComplete="off" defaultValue={pasien.jenis_kelamin} disabled />
                            </FormContainerCol>
                            <FormContainerCol controlId="name" label="Provinsi">
                                <Form.Control type='text' autoComplete="off" defaultValue={pasien.provinsi} disabled />
                            </FormContainerCol>
                            <FormContainerCol controlId="name" label="Kabupaten / Kota">
                                <Form.Control type='text' autoComplete="off" defaultValue={pasien.kabupaten_or_kota} disabled />
                            </FormContainerCol>
                        </Col>
                        <Col lg="6" md="12" sm="12">
                            <FormContainerCol controlId="name" label="Kecamatan">
                                <Form.Control type='text' defaultValue={pasien.kecamatan} disabled />
                            </FormContainerCol>
                            <FormContainerCol controlId="name" label="Kelurahan">
                                <Form.Control type='text' defaultValue={pasien.desa} disabled />
                            </FormContainerCol>
                            <FormContainerCol controlId="name" label="RT/RW">
                                <InputGroup className="mb-3">
                                    <Form.Control type='text' defaultValue={pasien.rt} disabled />
                                    <Form.Control type='text' defaultValue={pasien.rw} disabled />
                                </InputGroup>
                            </FormContainerCol>
                            <FormContainerCol controlId="name" label="Riwayat Sistematik">
                                <Form.Control as="textarea" rows={5} defaultValue={riwayat} disabled />
                            </FormContainerCol>
                        </Col>
                    </Row>
                </Cards>
                <Row className='justify-content-md-center mt-5'>
                    <h4>Assesment</h4>
                    <Col>
                        <Cards>
                            <Form onSubmit={searchData}>
                                <Row className='d-flex flex-row justify-content-evenly'>
                                    <Col xl="9">
                                        <Row>
                                            <Col xl="6">
                                                <input type="text" value={searchQuery} className='search-input' placeholder='Search' onChange={(e) => setSearchQuery(e.target.value)} autoComplete="off" />
                                            </Col>
                                            <Col xl="6">
                                                <input type="date" value={startDate} className='search-input' placeholder='Search' onChange={(e) => setStartDate(e.target.value)} />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xl="3">
                                        <Row>
                                            <Col xl="12" className='d-flex flex-row justify-content-evenly'>
                                                <button className='button-search' type="submit">Search</button>
                                                <button className='button-clear-search' onClick={clearSearch} >Clear</button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Form>
                            <GridPagination
                                header={["Tanggal Kunjungan", "Nama Pasien", "Nama Pemeriksa", "Blok", "Tahun Ajaran", "Action"]}
                                data={data}
                                dataArray={["tanggal_kunjungan", "pasien_name", "pemeriksa_name", "blok_name", "tahun_ajaran_name"]}
                                changePage={changePage}
                                page={page}
                                pages={pages}
                                rows={rows}
                                actionButton={linkData}

                            />
                        </Cards>
                    </Col>
                </Row>
                <Row className='justify-content-md-center mt-5'>
                    <h4>Kuesioner</h4>
                    <Col>
                        <Cards>
                            <Form onSubmit={searchDataKuesioner}>
                                <Row className='d-flex flex-row justify-content-evenly'>
                                    <Col xl="9">
                                        <Row>
                                            <Col xl="6">
                                                <input type="text" value={searchQuery1} className='search-input' placeholder='Search' onChange={(e) => setSearchQuery1(e.target.value)} autoComplete="off" />
                                            </Col>
                                            <Col xl="6">
                                                <input type="date" value={startDate1} className='search-input' placeholder='Search' onChange={(e) => setStartDate1(e.target.value)} />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xl="3">
                                        <Row>
                                            <Col xl="12" className='d-flex flex-row justify-content-evenly'>
                                                <button className='button-search' type="submit">Search</button>
                                                <button className='button-clear-search' onClick={clearSearch1} >Clear</button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Form>
                            <GridPagination
                                header={["Nama", "Umur", "Tanggal Kuesioner", "Pengisi", "Action"]}
                                data={dataKuesioner}
                                dataArray={["pasien_name", "umur", "tanggal_kuesioner", "pengisi_name"]}
                                changePage={changePage1}
                                page={page1}
                                pages={pages1}
                                rows={rows1}
                                actionButton={linkData1}
                            />
                        </Cards>
                    </Col>
                </Row>
            </ContainerCards>

        </>
    )
}

export default PatientView