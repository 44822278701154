import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';

const Toastbar = ({ message, showToast, setShowToast, type }) => {
  return (
    <ToastContainer className="p-3" position="top-end" style={{ zIndex: 10 }}>
      <Toast bg={type} onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide>
        <Toast.Header>
          <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
          <strong className="me-auto">Warning</strong>
          <small>now</small>
        </Toast.Header>
        <Toast.Body>{message}</Toast.Body>
      </Toast>
    </ToastContainer>
  )
}

export const ToastPopup = ({ response, message, showToast, setShowToast }) => {

  let variant;

  switch (response) {
    case 200:
      variant = "info"
      break;
    case 400:
      variant = "danger"
      break;
    case 409:
    case 404:
      variant = "warning"
      break;
  }

  return (
    <ToastContainer containerPosition='fixed' className="p-3" position="top-end" style={{ zIndex: 10 }}>
      <Toast bg={variant} onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide>
        <Toast.Header>
          <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
          <strong className="me-auto">Info</strong>
          <small>now</small>
        </Toast.Header>
        <Toast.Body>{message}</Toast.Body>
      </Toast>
    </ToastContainer>
  )
}

export default Toastbar