import { useEffect, useState } from "react";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import { useParams } from "react-router-dom";
import axiosApi from "../api/axios";
import ContainerCards, { ContainerWithCard } from "../component/ContainerCards";
import Form from "react-bootstrap/Form";
import Parter from "../component/Parter";
import {
  FormImpaksiKelas,
  FormImpaksiKlasifikasi,
  FormImpaksiPosisi,
  FormPenilaian,
  FormSingle,
  FormSingleAnak,
  FormSingleAnakNotReq,
  FormSingleBleed,
  FormSingleNotReq,
  FormSinglePoket,
} from "../component/Forms";
import Button from "react-bootstrap/esm/Button";
import Tooltip from "../component/Tooltip";
import {
  AssesmentKeteranganImpaksiGigi,
  KeteranganStatusGeligi,
} from "../component/AssesmentKeterangan";
import ListGroup from "react-bootstrap/ListGroup";
import axios from "axios";
import AsyncSelect from "react-select/async";
import Cards from "../component/Cards";

const AssesmentView = () => {
  const { id } = useParams();
  const [assesment, setAssesment] = useState({});
  const [assesmentDataLain, setAssesmentDataLain] = useState({});
  const [assesmentSGGAkar, setAssesmentSGGAkar] = useState({});
  const [assesmentSGGMahkota, setAssesmentSGGMahkota] = useState({});
  const [assesmentSIG, setAssesmentSIG] = useState({});
  const [assesmentSPBleed, setAssesmentSPBleed] = useState({});
  const [assesmentSPPoket, setAssesmentSPPoket] = useState({});
  const [pasien, setPasien] = useState({});
  const [riwayat, setRiwayat] = useState([]);
  const [age, setAge] = useState("");
  const [blok, setBlok] = useState([]);
  const [tahunAjaran, setTahunAjaran] = useState([]);
  const [tindakanPerawatan, setTindakanPerawatan] = useState([]);
  const [diagnosis, setDiagnosis] = useState([]);
  const [blokValue, setBlokValue] = useState({});
  const [tahunAjaranValue, setTahunAjaranValue] = useState({});
  useEffect(() => {
    const getAssesment = async () => {
      const result = await axiosApi.get(`/assesment/findById?id=${id}`);
      setPasien(result.data.pasien);
      setRiwayat(result.data.riwayat);
      setAssesment(result.data.assesment);
      setAssesmentSGGAkar(result.data.assesmentSGGAkar);
      setAssesmentSGGMahkota(result.data.assesmentSGGMahkota);
      setAssesmentSPBleed(result.data.assesmentSPBleed);
      setAssesmentSPPoket(result.data.assesmentSPPoket);
      setAssesmentDataLain(result.data.assesmentDataLain);
      let temp = result.data.assesmentDataLain.tindakan_perawatan.split(",");
      let diagnosisTemp =
        result.data.assesmentDataLain.diagnosis_klinis.split(",");
      let split = [];
      let splitDia = [];

      temp.forEach((e) => {
        let data = { label: e, value: e };
        split.push(data);
      });
      diagnosisTemp.forEach((e) => {
        let data = { label: e, value: e };
        splitDia.push(data);
      });
      setBlokValue({
        value: result.data.assesment.blok_id,
        label: result.data.assesment.blok_name,
      });
      setTahunAjaranValue({
        value: result.data.assesment.tahun_ajaran_id,
        label: result.data.assesment.tahun_ajaran_name,
      });
      setDiagnosis(splitDia);
      setTindakanPerawatan(split);
      setAge(calculateAge(result.data.pasien.tanggal_lahir));
    };

    const getField = async () => {
      const response = await axiosApi.get("/setting/blok/list");
      const response1 = await axiosApi.get("/setting/tahunAjaran/list");

      setBlok(response?.data?.response);
      setTahunAjaran(response1?.data?.response);
    };
    getAssesment();
    getField();
  }, []);

  const loadBlok = async (inputValue, callBack) => {
    const result = await axiosApi.get(
      `setting/blok/findList?search_query=${inputValue}`
    );
    callBack(result.data.result);
  };

  const handleBlok = (e) => {
    let temp = [];
    e.forEach((e) => {
      temp.push(e.value);
    });
    setBlokValue(e.value);
    setAssesmentDataLain({
      ...assesmentDataLain,
      blok_id: e.value,
    });
  };

  const loadTahunAjaran = async (inputValue, callBack) => {
    const result = await axiosApi.get(
      `setting/tahunAjaran/findList?search_query=${inputValue}`
    );
    callBack(result.data.result);
  };

  const handleTahunAjaran = (e) => {
    let temp = [];
    e.forEach((e) => {
      temp.push(e.label);
    });
    setTahunAjaranValue(e.value);
    setAssesmentDataLain({
      ...assesmentDataLain,
      tahun_ajaran_id: e.value,
    });
  };

  const calculateAge = (param) => {
    let dob = new Date(param);
    let month_diff = Date.now() - dob.getTime();
    let age_dt = new Date(month_diff);

    let year = age_dt.getUTCFullYear();

    let age = Math.abs(year - 1970);
    return age.toString();
  };

  const updateAssesmentData = (e) => {
    setAssesment({
      ...assesment,
      [e.target.name]: e.target.value,
    });
  };

  const updateAssesmentSGGAkar = (e) => {
    setAssesmentSGGAkar({
      ...assesmentSGGAkar,
      [e.target.name]: e.target.value,
    });
  };

  const updateAssesmentSGGMahkota = (e) => {
    setAssesmentSGGMahkota({
      ...assesmentSGGMahkota,
      [e.target.name]: e.target.value.toUpperCase(),
    });
  };

  const updateAssesmentSIG = (e) => {
    setAssesmentSIG({
      ...assesmentSIG,
      [e.target.name]: e.target.value.toUpperCase(),
    });
  };

  const updateAssesmentBleed = (e) => {
    setAssesmentSPBleed({
      ...assesmentSPBleed,
      [e.target.name]: e.target.value.toUpperCase(),
    });
  };

  const updateAssesmentPoket = (e) => {
    setAssesmentSPPoket({
      ...assesmentSPPoket,
      [e.target.name]: e.target.value.toUpperCase(),
    });
  };

  const [kelas18, setKelas18] = useState(true);
  const [kelas28, setKelas28] = useState(true);
  const [kelas38, setKelas38] = useState(true);
  const [kelas48, setKelas48] = useState(true);
  const [tindakan, setTindakan] = useState("");

  const disableImpaksi = () => {
    if (assesmentSGGAkar.a18 === "8" || assesmentSGGMahkota.m18 === "8") {
      setKelas18(false);
    } else {
      setKelas18(true);
    }
    if (assesmentSGGAkar.a28 === "8" || assesmentSGGMahkota.m28 === "8") {
      setKelas28(false);
    } else {
      setKelas28(true);
    }
    if (assesmentSGGAkar.a38 === "8" || assesmentSGGMahkota.m38 === "8") {
      setKelas38(false);
    } else {
      setKelas38(true);
    }
    if (assesmentSGGAkar.a48 === "8" || assesmentSGGMahkota.m48 === "8") {
      setKelas48(false);
    } else {
      setKelas48(true);
    }
  };

  const onChange18Mahkota = (e) => {
    setAssesmentSGGMahkota({
      ...assesmentSGGMahkota,
      [e.target.name]: e.target.value.toUpperCase(),
    });
    if (e.target.value === "8") {
      setKelas18(false);
    } else {
      setKelas18(true);
    }
  };

  const onChange18Akar = (e) => {
    setAssesmentSGGMahkota({
      ...assesmentSGGMahkota,
      [e.target.name]: e.target.value.toUpperCase(),
    });
    if (e.target.value === "8") {
      setKelas18(false);
    } else {
      setKelas18(true);
    }
  };

  const onChange28Mahkota = (e) => {
    setAssesmentSGGMahkota({
      ...assesmentSGGMahkota,
      [e.target.name]: e.target.value.toUpperCase(),
    });
    if (e.target.value === "8") {
      setKelas28(false);
    } else {
      setKelas28(true);
    }
  };

  const onChange28Akar = (e) => {
    setAssesmentSGGMahkota({
      ...assesmentSGGMahkota,
      [e.target.name]: e.target.value.toUpperCase(),
    });
    if (e.target.value === "8") {
      setKelas28(false);
    } else {
      setKelas28(true);
    }
  };

  const onChange38Mahkota = (e) => {
    setAssesmentSGGMahkota({
      ...assesmentSGGMahkota,
      [e.target.name]: e.target.value.toUpperCase(),
    });
    if (e.target.value === "8") {
      setKelas38(false);
    } else {
      setKelas38(true);
    }
  };

  const onChange38Akar = (e) => {
    setAssesmentSGGMahkota({
      ...assesmentSGGMahkota,
      [e.target.name]: e.target.value.toUpperCase(),
    });
    if (e.target.value === "8") {
      setKelas38(false);
    } else {
      setKelas38(true);
    }
  };

  const onChange48Mahkota = (e) => {
    setAssesmentSGGMahkota({
      ...assesmentSGGMahkota,
      [e.target.name]: e.target.value.toUpperCase(),
    });
    if (e.target.value === "8") {
      setKelas48(false);
    } else {
      setKelas48(true);
    }
  };

  const onChange48Akar = (e) => {
    setAssesmentSGGMahkota({
      ...assesmentSGGMahkota,
      [e.target.name]: e.target.value.toUpperCase(),
    });
    if (e.target.value === "8") {
      setKelas48(false);
    } else {
      setKelas48(true);
    }
  };

  const updateAssesmentDataLain = (e) => {
    setAssesmentDataLain({
      ...assesmentDataLain,
      [e.target.name]: e.target.value.toUpperCase(),
    });
  };

  const [data, setData] = useState("");
  const [option, setOption] = useState([]);
  const [optionList, setOptionList] = useState([]);

  const loadOption = async (inputValue, callBack) => {
    setOption([]);

    const result = await axios.get(
      `https://clinicaltables.nlm.nih.gov/api/icd10cm/v3/search?sf=code,name&terms=${inputValue}`,
      {
        withCredentials: false,
      }
    );
    setData(result.data[3]);
    result.data[3].forEach((element) => {
      setOption((prevArray) => [
        ...prevArray,
        { value: element[0], label: element[0] + " | " + element[1] },
      ]);
    });
    setOptionList(option);
    callBack(optionList);
  };

  const loadTindakan = async (inputValue, callBack) => {
    const result = await axiosApi.get(
      `setting/tindakan/findList?search_query=${inputValue}`
    );
    callBack(result.data.result);
  };

  const handleTindakan = (e) => {
    let temp = [];
    e.forEach((e) => {
      temp.push(e.value);
    });
    setTindakanPerawatan(e);
    setAssesmentDataLain({
      ...assesmentDataLain,
      tindakan_perawatan: temp,
    });
  };

  const handleChangeTindakanPerawatan = (e) => {
    let temp = [];
    e.forEach((e) => {
      temp.push(e.value);
    });
    setAssesmentDataLain({
      ...assesmentDataLain,
      diagnosisKlinis: temp.toString(),
    });
  };

  const handleDiagnosis = (e) => {
    let temp = [];
    e.forEach((e) => {
      temp.push(e.id);
    });
    setDiagnosis(e);
    setAssesmentDataLain({
      ...assesmentDataLain,
      diagnosis_klinis: temp.toString(),
    });
  };

  const functionButton = async (e) => {
    fetch(`/api/report/create?id_assesment=${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then(async (res) => {
      if (res.status === 200) {
        const blob = await res.blob();
        const file = new Blob([blob], { type: "application/pdf" });
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        //Open the URL on new Window
        window.open(fileURL);
      }
    });
  };

  return (
    <>
      <ContainerCards>
        <Row>
          <Col xl="12">
            <Button className="purple float-end" onClick={functionButton}>
              Download
            </Button>
          </Col>
        </Row>
        <Cards>
          <Parter title={"Pemeriksaan Gigi Responden"}>
            <div>
              <Row>
                <Col xl={6}>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="namaResponden"
                  >
                    <Form.Label column xl="4">
                      Nama Responden
                    </Form.Label>
                    <Col xl="8">
                      <Form.Control
                        type="text"
                        autoComplete="off"
                        disabled
                        defaultValue={pasien.name}
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col xl={6}>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="tanggalLahir"
                  >
                    <Form.Label column xl="4">
                      Tanggal Lahir
                    </Form.Label>
                    <Col xl="8">
                      <Form.Control
                        type="date"
                        autoComplete="off"
                        disabled
                        defaultValue={pasien.tanggal_lahir}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xl={6}>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="jenisKelamin"
                  >
                    <Form.Label column xl="4">
                      Jenis Kelamin
                    </Form.Label>
                    <Col xl="8">
                      <Form.Control
                        type="text"
                        autoComplete="off"
                        disabled
                        defaultValue={pasien.jenis_kelamin}
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col xl={6}>
                  <Form.Group as={Row} className="mb-3" controlId="umur">
                    <Form.Label column xl="4">
                      Umur
                    </Form.Label>
                    <Col xl="8">
                      <Form.Control
                        type="text"
                        autoComplete="off"
                        disabled
                        defaultValue={age}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xl={12}>
                  <Form.Group as={Row} className="mb-3" controlId="riwayat">
                    <Form.Label column xl="2">
                      Riwayat Sistematik
                    </Form.Label>
                    <Col xl="10">
                      <Form.Control
                        type="text"
                        autoComplete="off"
                        disabled
                        defaultValue={riwayat.toString()}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </div>
          </Parter>

          <Parter title={"Keterangan Pemeriksa Gigi"}>
            <Row>
              <Col xl={6}>
                <Form.Group as={Row} className="mb-3" controlId="tanggalLahir">
                  <Form.Label column xl="5">
                    Tanggal Kunjungan
                  </Form.Label>
                  <Col xl="7">
                    <Form.Control
                      disabled
                      name="tanggal_kunjungan"
                      type="date"
                      autoComplete="off"
                      onChange={updateAssesmentData}
                      required
                      defaultValue={assesment.tanggal_kunjungan}
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col xl={6}>
                <Form.Group as={Row} className="mb-3" controlId="namaResponden">
                  <Form.Label column xl="5">
                    Nama Pemeriksa Gigi
                  </Form.Label>
                  <Col xl="7">
                    <Form.Control
                      name="nama_pemeriksa_id"
                      type="text"
                      autoComplete="off"
                      disabled
                      defaultValue={assesment.pemeriksa_name}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xl={6}>
                <Form.Group as={Row} className="mb-3" controlId="tanggalLahir">
                  <Form.Label column xl="5">
                    Blok
                  </Form.Label>
                  <Col xl="7">
                    <Form.Control
                      name="blok_id"
                      type="text"
                      autoComplete="off"
                      disabled
                      defaultValue={assesment.blok_name}
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col xl={6}>
                <Form.Group as={Row} className="mb-3" controlId="namaResponden">
                  <Form.Label column xl="5">
                    Tahun Ajaran
                  </Form.Label>
                  <Col xl="7">
                    <Form.Control
                      name="blok_id"
                      type="text"
                      autoComplete="off"
                      disabled
                      defaultValue={assesment.tahun_ajaran_name}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Parter>

          <Parter title={"Status Gigi Geligi"}>
            <Row>
              <Col xl="12">
                <Tooltip>
                  <KeteranganStatusGeligi />
                </Tooltip>
              </Col>
            </Row>

            {assesment.assesment_type === "dewasa" && (
              <div>
                <div className="d-flex flex-row flex-wrap overflow-auto">
                  <div className="d-flex ">
                    <div className="width-box align-middle">
                      <p className="pt-2">M</p>
                    </div>

                    <div className="d-flex me-4">
                      <FormSingle
                        disabled
                        defaultValue={assesmentSGGMahkota.m18}
                        placeholderInput="18"
                        name="m18"
                        onChangeInput={onChange18Mahkota}
                      />
                      <FormSingle
                        disabled
                        defaultValue={assesmentSGGMahkota.m17}
                        placeholderInput="17"
                        name="m17"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                      <FormSingle
                        disabled
                        defaultValue={assesmentSGGMahkota.m16}
                        placeholderInput="16"
                        name="m17"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                      <FormSingle
                        disabled
                        defaultValue={assesmentSGGMahkota.m15}
                        placeholderInput="15"
                        name="m15"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                      <FormSingle
                        disabled
                        defaultValue={assesmentSGGMahkota.m14}
                        placeholderInput="14"
                        name="m14"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                      <FormSingle
                        disabled
                        defaultValue={assesmentSGGMahkota.m13}
                        placeholderInput="13"
                        name="m13"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                      <FormSingle
                        disabled
                        defaultValue={assesmentSGGMahkota.m12}
                        placeholderInput="12"
                        name="m12"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                      <FormSingle
                        disabled
                        defaultValue={assesmentSGGMahkota.m11}
                        placeholderInput="11"
                        name="m11"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                    </div>
                    <div className="d-flex">
                      <FormSingle
                        disabled
                        defaultValue={assesmentSGGMahkota.m21}
                        placeholderInput="21"
                        name="m21"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                      <FormSingle
                        disabled
                        defaultValue={assesmentSGGMahkota.m22}
                        placeholderInput="22"
                        name="m22"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                      <FormSingle
                        disabled
                        defaultValue={assesmentSGGMahkota.m23}
                        placeholderInput="23"
                        name="m23"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                      <FormSingle
                        disabled
                        defaultValue={assesmentSGGMahkota.m24}
                        placeholderInput="24"
                        name="m24"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                      <FormSingle
                        disabled
                        defaultValue={assesmentSGGMahkota.m25}
                        placeholderInput="25"
                        name="m25"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                      <FormSingle
                        disabled
                        defaultValue={assesmentSGGMahkota.m26}
                        placeholderInput="26"
                        name="m26"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                      <FormSingle
                        disabled
                        defaultValue={assesmentSGGMahkota.m27}
                        placeholderInput="27"
                        name="m27"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                      <FormSingle
                        disabled
                        defaultValue={assesmentSGGMahkota.m28}
                        placeholderInput="28"
                        name="m28"
                        onChangeInput={onChange28Mahkota}
                      />
                    </div>

                    <div className="width-box align-middle">
                      <p className="pt-2">M</p>
                    </div>
                  </div>

                  <div className="d-flex ">
                    <div className="width-box align-middle">
                      <p className="pt-2">A</p>
                    </div>

                    <div className="d-flex me-4">
                      <FormSingle
                        disabled
                        defaultValue={assesmentSGGAkar.a18}
                        placeholderInput="18"
                        name="a18"
                        onChangeInput={onChange18Akar}
                      />
                      <FormSingle
                        disabled
                        defaultValue={assesmentSGGAkar.a17}
                        placeholderInput="17"
                        name="a17"
                        onChangeInput={updateAssesmentSGGAkar}
                      />
                      <FormSingle
                        disabled
                        defaultValue={assesmentSGGAkar.a16}
                        placeholderInput="16"
                        name="a16"
                        onChangeInput={updateAssesmentSGGAkar}
                      />
                      <FormSingle
                        disabled
                        defaultValue={assesmentSGGAkar.a15}
                        placeholderInput="15"
                        name="a15"
                        onChangeInput={updateAssesmentSGGAkar}
                      />
                      <FormSingle
                        disabled
                        defaultValue={assesmentSGGAkar.a14}
                        placeholderInput="14"
                        name="a14"
                        onChangeInput={updateAssesmentSGGAkar}
                      />
                      <FormSingle
                        disabled
                        defaultValue={assesmentSGGAkar.a13}
                        placeholderInput="13"
                        name="a13"
                        onChangeInput={updateAssesmentSGGAkar}
                      />
                      <FormSingle
                        disabled
                        defaultValue={assesmentSGGAkar.a12}
                        placeholderInput="12"
                        name="a12"
                        onChangeInput={updateAssesmentSGGAkar}
                      />
                      <FormSingle
                        disabled
                        defaultValue={assesmentSGGAkar.a11}
                        placeholderInput="11"
                        name="a11"
                        onChangeInput={updateAssesmentSGGAkar}
                      />
                    </div>
                    <div className="d-flex">
                      <FormSingle
                        disabled
                        defaultValue={assesmentSGGAkar.a21}
                        placeholderInput="21"
                        name="a21"
                        onChangeInput={updateAssesmentSGGAkar}
                      />
                      <FormSingle
                        disabled
                        defaultValue={assesmentSGGAkar.a22}
                        placeholderInput="22"
                        name="a22"
                        onChangeInput={updateAssesmentSGGAkar}
                      />
                      <FormSingle
                        disabled
                        defaultValue={assesmentSGGAkar.a23}
                        placeholderInput="23"
                        name="a23"
                        onChangeInput={updateAssesmentSGGAkar}
                      />
                      <FormSingle
                        disabled
                        defaultValue={assesmentSGGAkar.a24}
                        placeholderInput="24"
                        name="a24"
                        onChangeInput={updateAssesmentSGGAkar}
                      />
                      <FormSingle
                        disabled
                        defaultValue={assesmentSGGAkar.a25}
                        placeholderInput="25"
                        name="a25"
                        onChangeInput={updateAssesmentSGGAkar}
                      />
                      <FormSingle
                        disabled
                        defaultValue={assesmentSGGAkar.a26}
                        placeholderInput="26"
                        name="a26"
                        onChangeInput={updateAssesmentSGGAkar}
                      />
                      <FormSingle
                        disabled
                        defaultValue={assesmentSGGAkar.a27}
                        placeholderInput="27"
                        name="a27"
                        onChangeInput={updateAssesmentSGGAkar}
                      />
                      <FormSingle
                        disabled
                        defaultValue={assesmentSGGAkar.a28}
                        placeholderInput="28"
                        name="a28"
                        onChangeInput={onChange28Akar}
                      />
                    </div>

                    <div className="width-box align-middle">
                      <p className="pt-2">A</p>
                    </div>
                  </div>
                  <div className="d-flex ">
                    <div className="width-box align-middle">
                      <p className="pt-2">M</p>
                    </div>

                    <div className="d-flex me-4">
                      <FormSingle
                        disabled
                        defaultValue={assesmentSGGMahkota.m48}
                        placeholderInput="48"
                        name="m48"
                        onChangeInput={onChange48Mahkota}
                      />
                      <FormSingle
                        disabled
                        defaultValue={assesmentSGGMahkota.m47}
                        placeholderInput="47"
                        name="m47"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                      <FormSingle
                        disabled
                        defaultValue={assesmentSGGMahkota.m46}
                        placeholderInput="46"
                        name="m46"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                      <FormSingle
                        disabled
                        defaultValue={assesmentSGGMahkota.m45}
                        placeholderInput="45"
                        name="m45"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                      <FormSingle
                        disabled
                        defaultValue={assesmentSGGMahkota.m44}
                        placeholderInput="44"
                        name="m44"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                      <FormSingle
                        disabled
                        defaultValue={assesmentSGGMahkota.m43}
                        placeholderInput="43"
                        name="m43"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                      <FormSingle
                        disabled
                        defaultValue={assesmentSGGMahkota.m42}
                        placeholderInput="42"
                        name="m42"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                      <FormSingle
                        disabled
                        defaultValue={assesmentSGGMahkota.m41}
                        placeholderInput="41"
                        name="m41"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                    </div>
                    <div className="d-flex">
                      <FormSingle
                        disabled
                        defaultValue={assesmentSGGMahkota.m31}
                        placeholderInput="31"
                        name="m31"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                      <FormSingle
                        disabled
                        defaultValue={assesmentSGGMahkota.m32}
                        placeholderInput="32"
                        name="m32"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                      <FormSingle
                        disabled
                        defaultValue={assesmentSGGMahkota.m33}
                        placeholderInput="33"
                        name="m33"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                      <FormSingle
                        disabled
                        defaultValue={assesmentSGGMahkota.m34}
                        placeholderInput="34"
                        name="m34"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                      <FormSingle
                        disabled
                        defaultValue={assesmentSGGMahkota.m35}
                        placeholderInput="35"
                        name="m35"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                      <FormSingle
                        disabled
                        defaultValue={assesmentSGGMahkota.m36}
                        placeholderInput="36"
                        name="m36"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                      <FormSingle
                        disabled
                        defaultValue={assesmentSGGMahkota.m37}
                        placeholderInput="37"
                        name="m37"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                      <FormSingle
                        disabled
                        defaultValue={assesmentSGGMahkota.m38}
                        placeholderInput="38"
                        name="m38"
                        onChangeInput={onChange38Mahkota}
                      />
                    </div>

                    <div className="width-box align-middle">
                      <p className="pt-2">M</p>
                    </div>
                  </div>
                  <div className="d-flex ">
                    <div className="width-box align-middle">
                      <p className="pt-2">A</p>
                    </div>

                    <div className="d-flex me-4">
                      <FormSingle
                        disabled
                        defaultValue={assesmentSGGAkar.a48}
                        placeholderInput="48"
                        name="a48"
                        onChangeInput={onChange48Akar}
                      />
                      <FormSingle
                        disabled
                        defaultValue={assesmentSGGAkar.a47}
                        placeholderInput="47"
                        name="a47"
                        onChangeInput={updateAssesmentSGGAkar}
                      />
                      <FormSingle
                        disabled
                        defaultValue={assesmentSGGAkar.a46}
                        placeholderInput="46"
                        name="a46"
                        onChangeInput={updateAssesmentSGGAkar}
                      />
                      <FormSingle
                        disabled
                        defaultValue={assesmentSGGAkar.a45}
                        placeholderInput="45"
                        name="a45"
                        onChangeInput={updateAssesmentSGGAkar}
                      />
                      <FormSingle
                        disabled
                        defaultValue={assesmentSGGAkar.a44}
                        placeholderInput="44"
                        name="a44"
                        onChangeInput={updateAssesmentSGGAkar}
                      />
                      <FormSingle
                        disabled
                        defaultValue={assesmentSGGAkar.a43}
                        placeholderInput="43"
                        name="a43"
                        onChangeInput={updateAssesmentSGGAkar}
                      />
                      <FormSingle
                        disabled
                        defaultValue={assesmentSGGAkar.a42}
                        placeholderInput="42"
                        name="a42"
                        onChangeInput={updateAssesmentSGGAkar}
                      />
                      <FormSingle
                        disabled
                        defaultValue={assesmentSGGAkar.a41}
                        placeholderInput="41"
                        name="a41"
                        onChangeInput={updateAssesmentSGGAkar}
                      />
                    </div>
                    <div className="d-flex">
                      <FormSingle
                        disabled
                        defaultValue={assesmentSGGAkar.a31}
                        placeholderInput="31"
                        name="a31"
                        onChangeInput={updateAssesmentSGGAkar}
                      />
                      <FormSingle
                        disabled
                        defaultValue={assesmentSGGAkar.a32}
                        placeholderInput="32"
                        name="a32"
                        onChangeInput={updateAssesmentSGGAkar}
                      />
                      <FormSingle
                        disabled
                        defaultValue={assesmentSGGAkar.a33}
                        placeholderInput="33"
                        name="a33"
                        onChangeInput={updateAssesmentSGGAkar}
                      />
                      <FormSingle
                        disabled
                        defaultValue={assesmentSGGAkar.a34}
                        placeholderInput="34"
                        name="a34"
                        onChangeInput={updateAssesmentSGGAkar}
                      />
                      <FormSingle
                        disabled
                        defaultValue={assesmentSGGAkar.a35}
                        placeholderInput="35"
                        name="a35"
                        onChangeInput={updateAssesmentSGGAkar}
                      />
                      <FormSingle
                        disabled
                        defaultValue={assesmentSGGAkar.a36}
                        placeholderInput="36"
                        name="a36"
                        onChangeInput={updateAssesmentSGGAkar}
                      />
                      <FormSingle
                        disabled
                        defaultValue={assesmentSGGAkar.a37}
                        placeholderInput="37"
                        name="a37"
                        onChangeInput={updateAssesmentSGGAkar}
                      />
                      <FormSingle
                        disabled
                        defaultValue={assesmentSGGAkar.a38}
                        placeholderInput="38"
                        name="a38"
                        onChangeInput={onChange38Akar}
                      />
                    </div>

                    <div className="width-box align-middle">
                      <p className="pt-2">A</p>
                    </div>
                  </div>
                </div>
                <Row>
                  {assesment.assesment_type === "dewasa" && (
                    <Parter title={"Data Tambahan"}>
                      <div className="d-flex flex-column flex-wrap overflow-auto">
                        <div className="d-flex ">
                          <div className="width-box align-middle pe-4">
                            <p className="pt-2">M</p>
                          </div>

                          <div className="d-flex me-5">
                            <FormSingleAnakNotReq
                              disabled
                              defaultValue={assesmentSGGMahkota.m55}
                              placeholderInput="55"
                              name="m55"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                            <FormSingleAnakNotReq
                              disabled
                              defaultValue={assesmentSGGMahkota.m54}
                              placeholderInput="54"
                              name="m54"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                            <FormSingleAnakNotReq
                              disabled
                              defaultValue={assesmentSGGMahkota.m53}
                              placeholderInput="53"
                              name="m53"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                            <FormSingleAnakNotReq
                              disabled
                              defaultValue={assesmentSGGMahkota.m52}
                              placeholderInput="52"
                              name="m52"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                            <FormSingleAnakNotReq
                              disabled
                              defaultValue={assesmentSGGMahkota.m51}
                              placeholderInput="51"
                              name="m51"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                          </div>
                          <div className="d-flex">
                            <FormSingleAnakNotReq
                              disabled
                              defaultValue={assesmentSGGMahkota.m61}
                              placeholderInput="61"
                              name="m61"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                            <FormSingleAnakNotReq
                              disabled
                              defaultValue={assesmentSGGMahkota.m62}
                              placeholderInput="62"
                              name="m62"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                            <FormSingleAnakNotReq
                              disabled
                              defaultValue={assesmentSGGMahkota.m63}
                              placeholderInput="63"
                              name="m63"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                            <FormSingleAnakNotReq
                              disabled
                              defaultValue={assesmentSGGMahkota.m64}
                              placeholderInput="64"
                              name="m64"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                            <FormSingleAnakNotReq
                              disabled
                              defaultValue={assesmentSGGMahkota.m65}
                              placeholderInput="65"
                              name="m65"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                          </div>

                          <div className="width-box align-middle ms-4">
                            <p className="pt-2">M</p>
                          </div>
                        </div>

                        <div className="d-flex ">
                          <div className="width-box align-middle pe-4">
                            <p className="pt-2">M</p>
                          </div>

                          <div className="d-flex me-5">
                            <FormSingleAnakNotReq
                              disabled
                              defaultValue={assesmentSGGMahkota.m85}
                              placeholderInput="85"
                              name="m85"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                            <FormSingleAnakNotReq
                              disabled
                              defaultValue={assesmentSGGMahkota.m84}
                              placeholderInput="84"
                              name="m84"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                            <FormSingleAnakNotReq
                              disabled
                              defaultValue={assesmentSGGMahkota.m83}
                              placeholderInput="83"
                              name="m83"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                            <FormSingleAnakNotReq
                              disabled
                              defaultValue={assesmentSGGMahkota.m82}
                              placeholderInput="82"
                              name="m82"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                            <FormSingleAnakNotReq
                              disabled
                              defaultValue={assesmentSGGMahkota.m81}
                              placeholderInput="81"
                              name="m81"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                          </div>
                          <div className="d-flex">
                            <FormSingleAnakNotReq
                              disabled
                              defaultValue={assesmentSGGMahkota.m71}
                              placeholderInput="71"
                              name="m71"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                            <FormSingleAnakNotReq
                              disabled
                              defaultValue={assesmentSGGMahkota.m72}
                              placeholderInput="72"
                              name="m72"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                            <FormSingleAnakNotReq
                              disabled
                              defaultValue={assesmentSGGMahkota.m73}
                              placeholderInput="73"
                              name="m73"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                            <FormSingleAnakNotReq
                              disabled
                              defaultValue={assesmentSGGMahkota.m74}
                              placeholderInput="74"
                              name="m74"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                            <FormSingleAnakNotReq
                              disabled
                              defaultValue={assesmentSGGMahkota.m75}
                              placeholderInput="75"
                              name="m75"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                          </div>

                          <div className="width-box align-middle ms-4">
                            <p className="pt-2">M</p>
                          </div>
                        </div>
                      </div>
                    </Parter>
                  )}
                </Row>
              </div>
            )}

            {assesment.assesment_type === "anak-anak" && (
              <div>
                <div className="d-flex flex-column flex-wrap overflow-auto">
                  <div className="d-flex ">
                    <div className="width-box align-middle pe-4">
                      <p className="pt-2">M</p>
                    </div>

                    <div className="d-flex me-5">
                      <FormSingleAnak
                        disabled
                        defaultValue={assesmentSGGMahkota.m55}
                        placeholderInput="55"
                        name="m55"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                      <FormSingleAnak
                        disabled
                        defaultValue={assesmentSGGMahkota.m54}
                        placeholderInput="54"
                        name="m54"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                      <FormSingleAnak
                        disabled
                        defaultValue={assesmentSGGMahkota.m53}
                        placeholderInput="53"
                        name="m53"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                      <FormSingleAnak
                        disabled
                        defaultValue={assesmentSGGMahkota.m52}
                        placeholderInput="52"
                        name="m52"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                      <FormSingleAnak
                        disabled
                        defaultValue={assesmentSGGMahkota.m51}
                        placeholderInput="51"
                        name="m51"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                    </div>
                    <div className="d-flex">
                      <FormSingleAnak
                        disabled
                        defaultValue={assesmentSGGMahkota.m61}
                        placeholderInput="61"
                        name="m61"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                      <FormSingleAnak
                        disabled
                        defaultValue={assesmentSGGMahkota.m62}
                        placeholderInput="62"
                        name="m62"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                      <FormSingleAnak
                        disabled
                        defaultValue={assesmentSGGMahkota.m63}
                        placeholderInput="63"
                        name="m63"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                      <FormSingleAnak
                        disabled
                        defaultValue={assesmentSGGMahkota.m64}
                        placeholderInput="64"
                        name="m64"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                      <FormSingleAnak
                        disabled
                        defaultValue={assesmentSGGMahkota.m65}
                        placeholderInput="65"
                        name="m65"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                    </div>

                    <div className="width-box align-middle ms-4">
                      <p className="pt-2">M</p>
                    </div>
                  </div>

                  <div className="d-flex ">
                    <div className="width-box align-middle pe-4">
                      <p className="pt-2">M</p>
                    </div>

                    <div className="d-flex me-5">
                      <FormSingleAnak
                        disabled
                        defaultValue={assesmentSGGMahkota.m85}
                        placeholderInput="85"
                        name="m85"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                      <FormSingleAnak
                        disabled
                        defaultValue={assesmentSGGMahkota.m84}
                        placeholderInput="84"
                        name="m84"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                      <FormSingleAnak
                        disabled
                        defaultValue={assesmentSGGMahkota.m83}
                        placeholderInput="83"
                        name="m83"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                      <FormSingleAnak
                        disabled
                        defaultValue={assesmentSGGMahkota.m82}
                        placeholderInput="82"
                        name="m82"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                      <FormSingleAnak
                        disabled
                        defaultValue={assesmentSGGMahkota.m81}
                        placeholderInput="81"
                        name="m81"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                    </div>
                    <div className="d-flex">
                      <FormSingleAnak
                        disabled
                        defaultValue={assesmentSGGMahkota.m71}
                        placeholderInput="71"
                        name="m71"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                      <FormSingleAnak
                        disabled
                        defaultValue={assesmentSGGMahkota.m72}
                        placeholderInput="72"
                        name="m72"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                      <FormSingleAnak
                        disabled
                        defaultValue={assesmentSGGMahkota.m73}
                        placeholderInput="73"
                        name="m73"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                      <FormSingleAnak
                        disabled
                        defaultValue={assesmentSGGMahkota.m74}
                        placeholderInput="74"
                        name="m74"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                      <FormSingleAnak
                        disabled
                        defaultValue={assesmentSGGMahkota.m75}
                        placeholderInput="75"
                        name="m75"
                        onChangeInput={updateAssesmentSGGMahkota}
                      />
                    </div>

                    <div className="width-box align-middle ms-4">
                      <p className="pt-2">M</p>
                    </div>
                  </div>
                </div>
                <Row>
                  {assesment.assesment_type === "anak-anak" && (
                    <Parter title={"Data Tambahan"}>
                      <div className="d-flex flex-row flex-wrap overflow-auto">
                        <div className="d-flex ">
                          <div className="width-box align-middle">
                            <p className="pt-2">M</p>
                          </div>

                          <div className="d-flex me-4">
                            <FormSingleNotReq
                              disabled
                              defaultValue={assesmentSGGMahkota.m18}
                              placeholderInput="18"
                              name="m18"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                            <FormSingleNotReq
                              disabled
                              defaultValue={assesmentSGGMahkota.m17}
                              placeholderInput="17"
                              name="m17"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                            <FormSingleNotReq
                              disabled
                              defaultValue={assesmentSGGMahkota.m16}
                              placeholderInput="16"
                              name="m17"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                            <FormSingleNotReq
                              disabled
                              defaultValue={assesmentSGGMahkota.m15}
                              placeholderInput="15"
                              name="m15"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                            <FormSingleNotReq
                              disabled
                              defaultValue={assesmentSGGMahkota.m14}
                              placeholderInput="14"
                              name="m14"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                            <FormSingleNotReq
                              disabled
                              defaultValue={assesmentSGGMahkota.m13}
                              placeholderInput="13"
                              name="m13"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                            <FormSingleNotReq
                              disabled
                              defaultValue={assesmentSGGMahkota.m12}
                              placeholderInput="12"
                              name="m12"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                            <FormSingleNotReq
                              disabled
                              defaultValue={assesmentSGGMahkota.m11}
                              placeholderInput="11"
                              name="m11"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                          </div>
                          <div className="d-flex">
                            <FormSingleNotReq
                              disabled
                              defaultValue={assesmentSGGMahkota.m21}
                              placeholderInput="21"
                              name="m21"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                            <FormSingleNotReq
                              disabled
                              defaultValue={assesmentSGGMahkota.m22}
                              placeholderInput="22"
                              name="m22"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                            <FormSingleNotReq
                              disabled
                              defaultValue={assesmentSGGMahkota.m23}
                              placeholderInput="23"
                              name="m23"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                            <FormSingleNotReq
                              disabled
                              defaultValue={assesmentSGGMahkota.m24}
                              placeholderInput="24"
                              name="m24"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                            <FormSingleNotReq
                              disabled
                              defaultValue={assesmentSGGMahkota.m25}
                              placeholderInput="25"
                              name="m25"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                            <FormSingleNotReq
                              disabled
                              defaultValue={assesmentSGGMahkota.m26}
                              placeholderInput="26"
                              name="m26"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                            <FormSingleNotReq
                              disabled
                              defaultValue={assesmentSGGMahkota.m27}
                              placeholderInput="27"
                              name="m27"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                            <FormSingleNotReq
                              disabled
                              defaultValue={assesmentSGGMahkota.m28}
                              placeholderInput="28"
                              name="m28"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                          </div>
                          <div className="width-box align-middle">
                            <p className="pt-2">M</p>
                          </div>
                        </div>

                        <div className="d-flex ">
                          <div className="width-box align-middle">
                            <p className="pt-2">A</p>
                          </div>

                          <div className="d-flex me-4">
                            <FormSingleNotReq
                              disabled
                              defaultValue={assesmentSGGAkar.a18}
                              placeholderInput="18"
                              name="a18"
                              onChangeInput={updateAssesmentSGGAkar}
                            />
                            <FormSingleNotReq
                              disabled
                              defaultValue={assesmentSGGAkar.a17}
                              placeholderInput="17"
                              name="a17"
                              onChangeInput={updateAssesmentSGGAkar}
                            />
                            <FormSingleNotReq
                              disabled
                              defaultValue={assesmentSGGAkar.a16}
                              placeholderInput="16"
                              name="a16"
                              onChangeInput={updateAssesmentSGGAkar}
                            />
                            <FormSingleNotReq
                              disabled
                              defaultValue={assesmentSGGAkar.a15}
                              placeholderInput="15"
                              name="a15"
                              onChangeInput={updateAssesmentSGGAkar}
                            />
                            <FormSingleNotReq
                              disabled
                              defaultValue={assesmentSGGAkar.a14}
                              placeholderInput="14"
                              name="a14"
                              onChangeInput={updateAssesmentSGGAkar}
                            />
                            <FormSingleNotReq
                              disabled
                              defaultValue={assesmentSGGAkar.a13}
                              placeholderInput="13"
                              name="a13"
                              onChangeInput={updateAssesmentSGGAkar}
                            />
                            <FormSingleNotReq
                              disabled
                              defaultValue={assesmentSGGAkar.a12}
                              placeholderInput="12"
                              name="a12"
                              onChangeInput={updateAssesmentSGGAkar}
                            />
                            <FormSingleNotReq
                              disabled
                              defaultValue={assesmentSGGAkar.a11}
                              placeholderInput="11"
                              name="a11"
                              onChangeInput={updateAssesmentSGGAkar}
                            />
                          </div>
                          <div className="d-flex">
                            <FormSingleNotReq
                              disabled
                              defaultValue={assesmentSGGAkar.a21}
                              placeholderInput="21"
                              name="a21"
                              onChangeInput={updateAssesmentSGGAkar}
                            />
                            <FormSingleNotReq
                              disabled
                              defaultValue={assesmentSGGAkar.a22}
                              placeholderInput="22"
                              name="a22"
                              onChangeInput={updateAssesmentSGGAkar}
                            />
                            <FormSingleNotReq
                              disabled
                              defaultValue={assesmentSGGAkar.a23}
                              placeholderInput="23"
                              name="a23"
                              onChangeInput={updateAssesmentSGGAkar}
                            />
                            <FormSingleNotReq
                              disabled
                              defaultValue={assesmentSGGAkar.a24}
                              placeholderInput="24"
                              name="a24"
                              onChangeInput={updateAssesmentSGGAkar}
                            />
                            <FormSingleNotReq
                              disabled
                              defaultValue={assesmentSGGAkar.a25}
                              placeholderInput="25"
                              name="a25"
                              onChangeInput={updateAssesmentSGGAkar}
                            />
                            <FormSingleNotReq
                              disabled
                              defaultValue={assesmentSGGAkar.a26}
                              placeholderInput="26"
                              name="a26"
                              onChangeInput={updateAssesmentSGGAkar}
                            />
                            <FormSingleNotReq
                              disabled
                              defaultValue={assesmentSGGAkar.a27}
                              placeholderInput="27"
                              name="a27"
                              onChangeInput={updateAssesmentSGGAkar}
                            />
                            <FormSingleNotReq
                              disabled
                              defaultValue={assesmentSGGAkar.a28}
                              placeholderInput="28"
                              name="a28"
                              onChangeInput={updateAssesmentSGGAkar}
                            />
                          </div>

                          <div className="width-box align-middle">
                            <p className="pt-2">A</p>
                          </div>
                        </div>
                        <div className="d-flex ">
                          <div className="width-box align-middle">
                            <p className="pt-2">M</p>
                          </div>

                          <div className="d-flex me-4">
                            <FormSingleNotReq
                              disabled
                              defaultValue={assesmentSGGMahkota.m48}
                              placeholderInput="48"
                              name="m48"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                            <FormSingleNotReq
                              disabled
                              defaultValue={assesmentSGGMahkota.m47}
                              placeholderInput="47"
                              name="m47"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                            <FormSingleNotReq
                              disabled
                              defaultValue={assesmentSGGMahkota.m46}
                              placeholderInput="46"
                              name="m46"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                            <FormSingleNotReq
                              disabled
                              defaultValue={assesmentSGGMahkota.m45}
                              placeholderInput="45"
                              name="m45"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                            <FormSingleNotReq
                              disabled
                              defaultValue={assesmentSGGMahkota.m44}
                              placeholderInput="44"
                              name="m44"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                            <FormSingleNotReq
                              disabled
                              defaultValue={assesmentSGGMahkota.m43}
                              placeholderInput="43"
                              name="m43"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                            <FormSingleNotReq
                              disabled
                              defaultValue={assesmentSGGMahkota.m42}
                              placeholderInput="42"
                              name="m42"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                            <FormSingleNotReq
                              disabled
                              defaultValue={assesmentSGGMahkota.m41}
                              placeholderInput="41"
                              name="m41"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                          </div>
                          <div className="d-flex">
                            <FormSingleNotReq
                              disabled
                              defaultValue={assesmentSGGMahkota.m31}
                              placeholderInput="31"
                              name="m31"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                            <FormSingleNotReq
                              disabled
                              defaultValue={assesmentSGGMahkota.m32}
                              placeholderInput="32"
                              name="m32"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                            <FormSingleNotReq
                              disabled
                              defaultValue={assesmentSGGMahkota.m33}
                              placeholderInput="33"
                              name="m33"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                            <FormSingleNotReq
                              disabled
                              defaultValue={assesmentSGGMahkota.m34}
                              placeholderInput="34"
                              name="m34"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                            <FormSingleNotReq
                              disabled
                              defaultValue={assesmentSGGMahkota.m35}
                              placeholderInput="35"
                              name="m35"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                            <FormSingleNotReq
                              disabled
                              defaultValue={assesmentSGGMahkota.m36}
                              placeholderInput="36"
                              name="m36"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                            <FormSingleNotReq
                              disabled
                              defaultValue={assesmentSGGMahkota.m37}
                              placeholderInput="37"
                              name="m37"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                            <FormSingleNotReq
                              disabled
                              defaultValue={assesmentSGGMahkota.m38}
                              placeholderInput="38"
                              name="m38"
                              onChangeInput={updateAssesmentSGGMahkota}
                            />
                          </div>

                          <div className="width-box align-middle">
                            <p className="pt-2">M</p>
                          </div>
                        </div>
                        <div className="d-flex ">
                          <div className="width-box align-middle">
                            <p className="pt-2">A</p>
                          </div>

                          <div className="d-flex me-4">
                            <FormSingleNotReq
                              disabled
                              defaultValue={assesmentSGGAkar.a48}
                              placeholderInput="48"
                              name="a48"
                              onChangeInput={updateAssesmentSGGAkar}
                            />
                            <FormSingleNotReq
                              disabled
                              defaultValue={assesmentSGGAkar.a47}
                              placeholderInput="47"
                              name="a47"
                              onChangeInput={updateAssesmentSGGAkar}
                            />
                            <FormSingleNotReq
                              disabled
                              defaultValue={assesmentSGGAkar.a46}
                              placeholderInput="46"
                              name="a46"
                              onChangeInput={updateAssesmentSGGAkar}
                            />
                            <FormSingleNotReq
                              disabled
                              defaultValue={assesmentSGGAkar.a45}
                              placeholderInput="45"
                              name="a45"
                              onChangeInput={updateAssesmentSGGAkar}
                            />
                            <FormSingleNotReq
                              disabled
                              defaultValue={assesmentSGGAkar.a44}
                              placeholderInput="44"
                              name="a44"
                              onChangeInput={updateAssesmentSGGAkar}
                            />
                            <FormSingleNotReq
                              disabled
                              defaultValue={assesmentSGGAkar.a43}
                              placeholderInput="43"
                              name="a43"
                              onChangeInput={updateAssesmentSGGAkar}
                            />
                            <FormSingleNotReq
                              disabled
                              defaultValue={assesmentSGGAkar.a42}
                              placeholderInput="42"
                              name="a42"
                              onChangeInput={updateAssesmentSGGAkar}
                            />
                            <FormSingleNotReq
                              disabled
                              defaultValue={assesmentSGGAkar.a41}
                              placeholderInput="41"
                              name="a41"
                              onChangeInput={updateAssesmentSGGAkar}
                            />
                          </div>
                          <div className="d-flex">
                            <FormSingleNotReq
                              disabled
                              defaultValue={assesmentSGGAkar.a31}
                              placeholderInput="31"
                              name="a31"
                              onChangeInput={updateAssesmentSGGAkar}
                            />
                            <FormSingleNotReq
                              disabled
                              defaultValue={assesmentSGGAkar.a32}
                              placeholderInput="32"
                              name="a32"
                              onChangeInput={updateAssesmentSGGAkar}
                            />
                            <FormSingleNotReq
                              disabled
                              defaultValue={assesmentSGGAkar.a33}
                              placeholderInput="33"
                              name="a33"
                              onChangeInput={updateAssesmentSGGAkar}
                            />
                            <FormSingleNotReq
                              disabled
                              defaultValue={assesmentSGGAkar.a34}
                              placeholderInput="34"
                              name="a34"
                              onChangeInput={updateAssesmentSGGAkar}
                            />
                            <FormSingleNotReq
                              disabled
                              defaultValue={assesmentSGGAkar.a35}
                              placeholderInput="35"
                              name="a35"
                              onChangeInput={updateAssesmentSGGAkar}
                            />
                            <FormSingleNotReq
                              disabled
                              defaultValue={assesmentSGGAkar.a36}
                              placeholderInput="36"
                              name="a36"
                              onChangeInput={updateAssesmentSGGAkar}
                            />
                            <FormSingleNotReq
                              disabled
                              defaultValue={assesmentSGGAkar.a37}
                              placeholderInput="37"
                              name="a37"
                              onChangeInput={updateAssesmentSGGAkar}
                            />
                            <FormSingleNotReq
                              disabled
                              defaultValue={assesmentSGGAkar.a38}
                              placeholderInput="38"
                              name="a38"
                              onChangeInput={updateAssesmentSGGAkar}
                            />
                          </div>

                          <div className="width-box align-middle">
                            <p className="pt-2">A</p>
                          </div>
                        </div>
                      </div>
                    </Parter>
                  )}
                </Row>
              </div>
            )}
          </Parter>

          {assesment.assesment_type === "dewasa" && (
            <Parter title="Status Impaksi Gigi">
              <Row className="mb-3">
                <Col xl={12}>
                  <Tooltip>
                    <AssesmentKeteranganImpaksiGigi />
                  </Tooltip>
                </Col>
              </Row>
              <div className="d-flex flex-row flex-wrap overflow-auto">
                <div className="d-flex ">
                  <div className="width-box align-middle">
                    <p className="pt-2">18</p>
                  </div>

                  <div className="d-flex me-2">
                    <FormImpaksiKelas
                      placeholderInput="kelas 18"
                      name="kelas18"
                      onChangeInput={updateAssesmentSIG}
                      disabled
                    />
                    <FormImpaksiPosisi
                      placeholderInput="posisi 18"
                      name="posisi18"
                      onChangeInput={updateAssesmentSIG}
                      disabled
                    />
                    <FormImpaksiKlasifikasi
                      placeholderInput="klasifikasi 18"
                      name="klasifikasi18"
                      onChangeInput={updateAssesmentSIG}
                      disabled
                    />
                  </div>
                  <div className="d-flex">
                    <FormImpaksiKelas
                      placeholderInput="kelas 28"
                      name="kelas28"
                      onChangeInput={updateAssesmentSIG}
                      disabled
                    />
                    <FormImpaksiPosisi
                      placeholderInput="posisi 28"
                      name="posisi28"
                      onChangeInput={updateAssesmentSIG}
                      disabled
                    />
                    <FormImpaksiKlasifikasi
                      placeholderInput="klasifikasi 28"
                      name="klasifikasi28"
                      onChangeInput={updateAssesmentSIG}
                      disabled
                    />
                  </div>

                  <div className="width-box align-middle ps-4">
                    <p className="pt-2">28</p>
                  </div>
                </div>

                <div className="d-flex ">
                  <div className="width-box align-middle">
                    <p className="pt-2">48</p>
                  </div>

                  <div className="d-flex me-2">
                    <FormImpaksiKelas
                      placeholderInput="kelas 48"
                      name="kelas48"
                      onChangeInput={updateAssesmentSIG}
                      disabled
                    />
                    <FormImpaksiPosisi
                      placeholderInput="posisi 48"
                      name="posisi48"
                      onChangeInput={updateAssesmentSIG}
                      disabled
                    />
                    <FormImpaksiKlasifikasi
                      placeholderInput="klasifikasi 48"
                      name="klasifikasi48"
                      onChangeInput={updateAssesmentSIG}
                      disabled
                    />
                  </div>
                  <div className="d-flex">
                    <FormImpaksiKelas
                      placeholderInput="kelas 38"
                      name="kelas38"
                      onChangeInput={updateAssesmentSIG}
                      disabled
                    />
                    <FormImpaksiPosisi
                      placeholderInput="posisi 38"
                      name="posisi38"
                      onChangeInput={updateAssesmentSIG}
                      disabled
                    />
                    <FormImpaksiKlasifikasi
                      placeholderInput="klasifikasi 38"
                      name="klasifikasi38"
                      onChangeInput={updateAssesmentSIG}
                      disabled
                    />
                  </div>

                  <div className="width-box align-middle ps-4">
                    <p className="pt-2">38</p>
                  </div>
                </div>
              </div>
            </Parter>
          )}

          <Parter title={"Status Periodontal"}>
            {assesment.assesment_type === "dewasa" && (
              <div>
                <Row className="mb-3">
                  <Col xl={12}>
                    <Tooltip>
                      <Row>
                        <ListGroup key="xl" horizontal="xl">
                          <ListGroup.Item>
                            <h5>Perdarahan Gingiva</h5>
                            <ListGroup variant="flush">
                              <ListGroup.Item className="d-flex">
                                <div className="col-xl-1 me-3">0</div>{" "}
                                <div className="col-xl-11 light">
                                  {" "}
                                  Keadaan gusi sehat
                                </div>
                              </ListGroup.Item>
                              <ListGroup.Item className="d-flex">
                                <div className="col-xl-1 me-3">1</div>{" "}
                                <div className="col-xl-11 light">
                                  {" "}
                                  Ada perdarahan
                                </div>
                              </ListGroup.Item>
                              <ListGroup.Item className="d-flex">
                                <div className="col-xl-1 me-3">9</div>{" "}
                                <div className="col-xl-11 light">
                                  {" "}
                                  Gigi eksklusi
                                </div>
                              </ListGroup.Item>
                              <ListGroup.Item className="d-flex">
                                <div className="col-xl-1 me-3">X</div>{" "}
                                <div className="col-xl-11 light">
                                  {" "}
                                  Gigi tidak ada
                                </div>
                              </ListGroup.Item>
                            </ListGroup>
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <h5>Poket Skor</h5>
                            <ListGroup variant="flush">
                              <ListGroup.Item className="d-flex">
                                <div className="col-xl-1 me-3">0</div>{" "}
                                <div className="col-xl-11 light">
                                  {" "}
                                  Tidak ada poket
                                </div>
                              </ListGroup.Item>
                              <ListGroup.Item className="d-flex">
                                <div className="col-xl-1 me-3">1</div>{" "}
                                <div className="col-xl-11 light">
                                  {" "}
                                  Poket 4 - 5 mm
                                </div>
                              </ListGroup.Item>
                              <ListGroup.Item className="d-flex">
                                <div className="col-xl-1 me-3">2</div>{" "}
                                <div className="col-xl-11 light">
                                  {" "}
                                  Poket 6 mm atau lebih
                                </div>
                              </ListGroup.Item>
                              <ListGroup.Item className="d-flex">
                                <div className="col-xl-1 me-3">9</div>{" "}
                                <div className="col-xl-11 light">
                                  {" "}
                                  Gigi tidak dapat dicatat
                                </div>
                              </ListGroup.Item>
                              <ListGroup.Item className="d-flex">
                                <div className="col-xl-1 me-3">X</div>{" "}
                                <div className="col-xl-11 light">
                                  {" "}
                                  Gigi tidak erupsi
                                </div>
                              </ListGroup.Item>
                            </ListGroup>
                          </ListGroup.Item>
                        </ListGroup>
                      </Row>
                    </Tooltip>
                  </Col>
                </Row>

                <div className="d-flex flex-row flex-wrap overflow-auto">
                  <div className="d-flex ">
                    <div className="width-periodontal align-middle">
                      <p className="pt-2">Bleed</p>
                    </div>

                    <div className="d-flex me-4">
                      <FormSingleBleed
                        disabled
                        defaultValue={assesmentSPBleed.b18}
                        placeholderInput="18"
                        name="b18"
                        onChangeInput={updateAssesmentBleed}
                      />
                      <FormSingleBleed
                        disabled
                        defaultValue={assesmentSPBleed.b17}
                        placeholderInput="17"
                        name="b17"
                        onChangeInput={updateAssesmentBleed}
                      />
                      <FormSingleBleed
                        disabled
                        defaultValue={assesmentSPBleed.b16}
                        placeholderInput="16"
                        name="b16"
                        onChangeInput={updateAssesmentBleed}
                      />
                      <FormSingleBleed
                        disabled
                        defaultValue={assesmentSPBleed.b15}
                        placeholderInput="15"
                        name="b15"
                        onChangeInput={updateAssesmentBleed}
                      />
                      <FormSingleBleed
                        disabled
                        defaultValue={assesmentSPBleed.b14}
                        placeholderInput="14"
                        name="b14"
                        onChangeInput={updateAssesmentBleed}
                      />
                      <FormSingleBleed
                        disabled
                        defaultValue={assesmentSPBleed.b13}
                        placeholderInput="13"
                        name="b13"
                        onChangeInput={updateAssesmentBleed}
                      />
                      <FormSingleBleed
                        disabled
                        defaultValue={assesmentSPBleed.b12}
                        placeholderInput="12"
                        name="b12"
                        onChangeInput={updateAssesmentBleed}
                      />
                      <FormSingleBleed
                        disabled
                        defaultValue={assesmentSPBleed.b11}
                        placeholderInput="11"
                        name="b11"
                        onChangeInput={updateAssesmentBleed}
                      />
                    </div>
                    <div className="d-flex">
                      <FormSingleBleed
                        disabled
                        defaultValue={assesmentSPBleed.b21}
                        placeholderInput="21"
                        name="b21"
                        onChangeInput={updateAssesmentBleed}
                      />
                      <FormSingleBleed
                        disabled
                        defaultValue={assesmentSPBleed.b22}
                        placeholderInput="22"
                        name="b22"
                        onChangeInput={updateAssesmentBleed}
                      />
                      <FormSingleBleed
                        disabled
                        defaultValue={assesmentSPBleed.b23}
                        placeholderInput="23"
                        name="b23"
                        onChangeInput={updateAssesmentBleed}
                      />
                      <FormSingleBleed
                        disabled
                        defaultValue={assesmentSPBleed.b24}
                        placeholderInput="24"
                        name="b24"
                        onChangeInput={updateAssesmentBleed}
                      />
                      <FormSingleBleed
                        disabled
                        defaultValue={assesmentSPBleed.b25}
                        placeholderInput="25"
                        name="b25"
                        onChangeInput={updateAssesmentBleed}
                      />
                      <FormSingleBleed
                        disabled
                        defaultValue={assesmentSPBleed.b26}
                        placeholderInput="26"
                        name="b26"
                        onChangeInput={updateAssesmentBleed}
                      />
                      <FormSingleBleed
                        disabled
                        defaultValue={assesmentSPBleed.b27}
                        placeholderInput="27"
                        name="b27"
                        onChangeInput={updateAssesmentBleed}
                      />
                      <FormSingleBleed
                        disabled
                        defaultValue={assesmentSPBleed.b28}
                        placeholderInput="28"
                        name="b28"
                        onChangeInput={updateAssesmentBleed}
                      />
                    </div>

                    <div className="width-periodontal align-middle">
                      <p className="pt-2">Bleed</p>
                    </div>
                  </div>

                  <div className="d-flex ">
                    <div className="width-periodontal align-middle">
                      <p className="pt-2">Poket</p>
                    </div>

                    <div className="d-flex me-4">
                      <FormSinglePoket
                        disabled
                        defaultValue={assesmentSPPoket.p18}
                        placeholderInput="18"
                        name="p18"
                        onChangeInput={updateAssesmentPoket}
                      />
                      <FormSinglePoket
                        disabled
                        defaultValue={assesmentSPPoket.p17}
                        placeholderInput="17"
                        name="p17"
                        onChangeInput={updateAssesmentPoket}
                      />
                      <FormSinglePoket
                        disabled
                        defaultValue={assesmentSPPoket.p16}
                        placeholderInput="16"
                        name="p16"
                        onChangeInput={updateAssesmentPoket}
                      />
                      <FormSinglePoket
                        disabled
                        defaultValue={assesmentSPPoket.p15}
                        placeholderInput="15"
                        name="p15"
                        onChangeInput={updateAssesmentPoket}
                      />
                      <FormSinglePoket
                        disabled
                        defaultValue={assesmentSPPoket.p14}
                        placeholderInput="14"
                        name="p14"
                        onChangeInput={updateAssesmentPoket}
                      />
                      <FormSinglePoket
                        disabled
                        defaultValue={assesmentSPPoket.p13}
                        placeholderInput="13"
                        name="p13"
                        onChangeInput={updateAssesmentPoket}
                      />
                      <FormSinglePoket
                        disabled
                        defaultValue={assesmentSPPoket.p12}
                        placeholderInput="12"
                        name="p12"
                        onChangeInput={updateAssesmentPoket}
                      />
                      <FormSinglePoket
                        disabled
                        defaultValue={assesmentSPPoket.p11}
                        placeholderInput="11"
                        name="p11"
                        onChangeInput={updateAssesmentPoket}
                      />
                    </div>
                    <div className="d-flex">
                      <FormSinglePoket
                        disabled
                        defaultValue={assesmentSPPoket.p21}
                        placeholderInput="21"
                        name="p21"
                        onChangeInput={updateAssesmentPoket}
                      />
                      <FormSinglePoket
                        disabled
                        defaultValue={assesmentSPPoket.p22}
                        placeholderInput="22"
                        name="p22"
                        onChangeInput={updateAssesmentPoket}
                      />
                      <FormSinglePoket
                        disabled
                        defaultValue={assesmentSPPoket.p23}
                        placeholderInput="23"
                        name="p23"
                        onChangeInput={updateAssesmentPoket}
                      />
                      <FormSinglePoket
                        disabled
                        defaultValue={assesmentSPPoket.p24}
                        placeholderInput="24"
                        name="p24"
                        onChangeInput={updateAssesmentPoket}
                      />
                      <FormSinglePoket
                        disabled
                        defaultValue={assesmentSPPoket.p25}
                        placeholderInput="25"
                        name="p25"
                        onChangeInput={updateAssesmentPoket}
                      />
                      <FormSinglePoket
                        disabled
                        defaultValue={assesmentSPPoket.p26}
                        placeholderInput="26"
                        name="p26"
                        onChangeInput={updateAssesmentPoket}
                      />
                      <FormSinglePoket
                        disabled
                        defaultValue={assesmentSPPoket.p27}
                        placeholderInput="27"
                        name="p27"
                        onChangeInput={updateAssesmentPoket}
                      />
                      <FormSinglePoket
                        disabled
                        defaultValue={assesmentSPPoket.p28}
                        placeholderInput="28"
                        name="p28"
                        onChangeInput={updateAssesmentPoket}
                      />
                    </div>

                    <div className="width-periodontal align-middle">
                      <p className="pt-2">Poket</p>
                    </div>
                  </div>
                  <div className="d-flex ">
                    <div className="width-periodontal align-middle">
                      <p className="pt-2">Bleed</p>
                    </div>

                    <div className="d-flex me-4">
                      <FormSingleBleed
                        disabled
                        defaultValue={assesmentSPBleed.b48}
                        placeholderInput="48"
                        name="b48"
                        onChangeInput={updateAssesmentBleed}
                      />
                      <FormSingleBleed
                        disabled
                        defaultValue={assesmentSPBleed.b47}
                        placeholderInput="47"
                        name="b47"
                        onChangeInput={updateAssesmentBleed}
                      />
                      <FormSingleBleed
                        disabled
                        defaultValue={assesmentSPBleed.b46}
                        placeholderInput="46"
                        name="b46"
                        onChangeInput={updateAssesmentBleed}
                      />
                      <FormSingleBleed
                        disabled
                        defaultValue={assesmentSPBleed.b45}
                        placeholderInput="45"
                        name="b45"
                        onChangeInput={updateAssesmentBleed}
                      />
                      <FormSingleBleed
                        disabled
                        defaultValue={assesmentSPBleed.b44}
                        placeholderInput="44"
                        name="b44"
                        onChangeInput={updateAssesmentBleed}
                      />
                      <FormSingleBleed
                        disabled
                        defaultValue={assesmentSPBleed.b43}
                        placeholderInput="43"
                        name="b43"
                        onChangeInput={updateAssesmentBleed}
                      />
                      <FormSingleBleed
                        disabled
                        defaultValue={assesmentSPBleed.b42}
                        placeholderInput="42"
                        name="b42"
                        onChangeInput={updateAssesmentBleed}
                      />
                      <FormSingleBleed
                        disabled
                        defaultValue={assesmentSPBleed.b41}
                        placeholderInput="41"
                        name="b41"
                        onChangeInput={updateAssesmentBleed}
                      />
                    </div>
                    <div className="d-flex">
                      <FormSingleBleed
                        disabled
                        defaultValue={assesmentSPBleed.b31}
                        placeholderInput="31"
                        name="b31"
                        onChangeInput={updateAssesmentBleed}
                      />
                      <FormSingleBleed
                        disabled
                        defaultValue={assesmentSPBleed.b32}
                        placeholderInput="32"
                        name="b32"
                        onChangeInput={updateAssesmentBleed}
                      />
                      <FormSingleBleed
                        disabled
                        defaultValue={assesmentSPBleed.b33}
                        placeholderInput="33"
                        name="b33"
                        onChangeInput={updateAssesmentBleed}
                      />
                      <FormSingleBleed
                        disabled
                        defaultValue={assesmentSPBleed.b34}
                        placeholderInput="34"
                        name="b34"
                        onChangeInput={updateAssesmentBleed}
                      />
                      <FormSingleBleed
                        disabled
                        defaultValue={assesmentSPBleed.b35}
                        placeholderInput="35"
                        name="b35"
                        onChangeInput={updateAssesmentBleed}
                      />
                      <FormSingleBleed
                        disabled
                        defaultValue={assesmentSPBleed.b36}
                        placeholderInput="36"
                        name="b36"
                        onChangeInput={updateAssesmentBleed}
                      />
                      <FormSingleBleed
                        disabled
                        defaultValue={assesmentSPBleed.b37}
                        placeholderInput="37"
                        name="b37"
                        onChangeInput={updateAssesmentBleed}
                      />
                      <FormSingleBleed
                        disabled
                        defaultValue={assesmentSPBleed.b38}
                        placeholderInput="38"
                        name="b38"
                        onChangeInput={updateAssesmentBleed}
                      />
                    </div>

                    <div className="width-periodontal align-middle">
                      <p className="pt-2">Bleed</p>
                    </div>
                  </div>
                  <div className="d-flex ">
                    <div className="width-periodontal align-middle">
                      <p className="pt-2">Poket</p>
                    </div>

                    <div className="d-flex me-4">
                      <FormSinglePoket
                        disabled
                        defaultValue={assesmentSPPoket.p48}
                        placeholderInput="48"
                        name="p48"
                        onChangeInput={updateAssesmentPoket}
                      />
                      <FormSinglePoket
                        disabled
                        defaultValue={assesmentSPPoket.p47}
                        placeholderInput="47"
                        name="p47"
                        onChangeInput={updateAssesmentPoket}
                      />
                      <FormSinglePoket
                        disabled
                        defaultValue={assesmentSPPoket.p46}
                        placeholderInput="46"
                        name="p46"
                        onChangeInput={updateAssesmentPoket}
                      />
                      <FormSinglePoket
                        disabled
                        defaultValue={assesmentSPPoket.p45}
                        placeholderInput="45"
                        name="p45"
                        onChangeInput={updateAssesmentPoket}
                      />
                      <FormSinglePoket
                        disabled
                        defaultValue={assesmentSPPoket.p44}
                        placeholderInput="44"
                        name="p44"
                        onChangeInput={updateAssesmentPoket}
                      />
                      <FormSinglePoket
                        disabled
                        defaultValue={assesmentSPPoket.p43}
                        placeholderInput="43"
                        name="p43"
                        onChangeInput={updateAssesmentPoket}
                      />
                      <FormSinglePoket
                        disabled
                        defaultValue={assesmentSPPoket.p42}
                        placeholderInput="42"
                        name="p42"
                        onChangeInput={updateAssesmentPoket}
                      />
                      <FormSinglePoket
                        disabled
                        defaultValue={assesmentSPPoket.p41}
                        placeholderInput="41"
                        name="p41"
                        onChangeInput={updateAssesmentPoket}
                      />
                    </div>
                    <div className="d-flex">
                      <FormSinglePoket
                        disabled
                        defaultValue={assesmentSPPoket.p31}
                        placeholderInput="31"
                        name="p31"
                        onChangeInput={updateAssesmentPoket}
                      />
                      <FormSinglePoket
                        disabled
                        defaultValue={assesmentSPPoket.p32}
                        placeholderInput="32"
                        name="p32"
                        onChangeInput={updateAssesmentPoket}
                      />
                      <FormSinglePoket
                        disabled
                        defaultValue={assesmentSPPoket.p33}
                        placeholderInput="33"
                        name="p33"
                        onChangeInput={updateAssesmentPoket}
                      />
                      <FormSinglePoket
                        disabled
                        defaultValue={assesmentSPPoket.p34}
                        placeholderInput="34"
                        name="p34"
                        onChangeInput={updateAssesmentPoket}
                      />
                      <FormSinglePoket
                        disabled
                        defaultValue={assesmentSPPoket.p35}
                        placeholderInput="35"
                        name="p35"
                        onChangeInput={updateAssesmentPoket}
                      />
                      <FormSinglePoket
                        disabled
                        defaultValue={assesmentSPPoket.p36}
                        placeholderInput="36"
                        name="p36"
                        onChangeInput={updateAssesmentPoket}
                      />
                      <FormSinglePoket
                        disabled
                        defaultValue={assesmentSPPoket.p37}
                        placeholderInput="37"
                        name="p37"
                        onChangeInput={updateAssesmentPoket}
                      />
                      <FormSinglePoket
                        disabled
                        defaultValue={assesmentSPPoket.p38}
                        placeholderInput="38"
                        name="p38"
                        onChangeInput={updateAssesmentPoket}
                      />
                    </div>

                    <div className="width-periodontal align-middle">
                      <p className="pt-2">Poket</p>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {assesment.assesment_type === "anak-anak" && (
              <div>
                <Row className="mb-3">
                  <Col xl={12}>
                    <Tooltip>
                      <Row>
                        <ListGroup key="xl" horizontal="xl">
                          <ListGroup.Item>
                            <h5>Perdarahan Gingiva</h5>
                            <ListGroup variant="flush">
                              <ListGroup.Item className="d-flex">
                                <div className="col-xl-1 me-3">0</div>{" "}
                                <div className="col-xl-11 light">
                                  {" "}
                                  Keadaan gusi sehat
                                </div>
                              </ListGroup.Item>
                              <ListGroup.Item className="d-flex">
                                <div className="col-xl-1 me-3">1</div>{" "}
                                <div className="col-xl-11 light">
                                  {" "}
                                  Ada perdarahan
                                </div>
                              </ListGroup.Item>
                              <ListGroup.Item className="d-flex">
                                <div className="col-xl-1 me-3">9</div>{" "}
                                <div className="col-xl-11 light">
                                  {" "}
                                  Gigi eksklusi
                                </div>
                              </ListGroup.Item>
                              <ListGroup.Item className="d-flex">
                                <div className="col-xl-1 me-3">X</div>{" "}
                                <div className="col-xl-11 light">
                                  {" "}
                                  Gigi tidak ada
                                </div>
                              </ListGroup.Item>
                            </ListGroup>
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <h5>Poket Skor</h5>
                            <ListGroup variant="flush">
                              <ListGroup.Item className="d-flex">
                                <div className="col-xl-1 me-3">0</div>{" "}
                                <div className="col-xl-11 light">
                                  {" "}
                                  Tidak ada poket
                                </div>
                              </ListGroup.Item>
                              <ListGroup.Item className="d-flex">
                                <div className="col-xl-1 me-3">1</div>{" "}
                                <div className="col-xl-11 light">
                                  {" "}
                                  Poket 4 - 5 mm
                                </div>
                              </ListGroup.Item>
                              <ListGroup.Item className="d-flex">
                                <div className="col-xl-1 me-3">2</div>{" "}
                                <div className="col-xl-11 light">
                                  {" "}
                                  Poket 6 mm atau lebih
                                </div>
                              </ListGroup.Item>
                              <ListGroup.Item className="d-flex">
                                <div className="col-xl-1 me-3">9</div>{" "}
                                <div className="col-xl-11 light">
                                  {" "}
                                  Gigi tidak dapat dicatat
                                </div>
                              </ListGroup.Item>
                              <ListGroup.Item className="d-flex">
                                <div className="col-xl-1 me-3">X</div>{" "}
                                <div className="col-xl-11 light">
                                  {" "}
                                  Gigi tidak erupsi
                                </div>
                              </ListGroup.Item>
                            </ListGroup>
                          </ListGroup.Item>
                        </ListGroup>
                      </Row>
                    </Tooltip>
                  </Col>
                </Row>
                <div className="d-flex flex-column flex-wrap overflow-auto ps-5">
                  <div className="d-flex ">
                    <div className="width-periodontal align-middle pe-4">
                      <p className="pt-2">Bleed</p>
                    </div>

                    <div className="d-flex me-5">
                      <FormSingleBleed
                        disabled
                        defaultValue={assesmentSPBleed.b55}
                        placeholderInput="55"
                        name="b55"
                        onChangeInput={updateAssesmentBleed}
                      />
                      <FormSingleBleed
                        disabled
                        defaultValue={assesmentSPBleed.b54}
                        placeholderInput="54"
                        name="b54"
                        onChangeInput={updateAssesmentBleed}
                      />
                      <FormSingleBleed
                        disabled
                        defaultValue={assesmentSPBleed.b53}
                        placeholderInput="53"
                        name="b53"
                        onChangeInput={updateAssesmentBleed}
                      />
                      <FormSingleBleed
                        disabled
                        defaultValue={assesmentSPBleed.b52}
                        placeholderInput="52"
                        name="b52"
                        onChangeInput={updateAssesmentBleed}
                      />
                      <FormSingleBleed
                        disabled
                        defaultValue={assesmentSPBleed.b51}
                        placeholderInput="51"
                        name="b51"
                        onChangeInput={updateAssesmentBleed}
                      />
                    </div>
                    <div className="d-flex">
                      <FormSingleBleed
                        disabled
                        defaultValue={assesmentSPBleed.b61}
                        placeholderInput="61"
                        name="b61"
                        onChangeInput={updateAssesmentBleed}
                      />
                      <FormSingleBleed
                        disabled
                        defaultValue={assesmentSPBleed.b62}
                        placeholderInput="62"
                        name="b62"
                        onChangeInput={updateAssesmentBleed}
                      />
                      <FormSingleBleed
                        disabled
                        defaultValue={assesmentSPBleed.b63}
                        placeholderInput="63"
                        name="b63"
                        onChangeInput={updateAssesmentBleed}
                      />
                      <FormSingleBleed
                        disabled
                        defaultValue={assesmentSPBleed.b64}
                        placeholderInput="64"
                        name="b64"
                        onChangeInput={updateAssesmentBleed}
                      />
                      <FormSingleBleed
                        disabled
                        defaultValue={assesmentSPBleed.b65}
                        placeholderInput="65"
                        name="b65"
                        onChangeInput={updateAssesmentBleed}
                      />
                    </div>

                    <div className="width-periodontal align-middle ms-4">
                      <p className="pt-2">Bleed</p>
                    </div>
                  </div>

                  <div className="d-flex ">
                    <div className="width-periodontal align-middle pe-4">
                      <p className="pt-2">Poket</p>
                    </div>

                    <div className="d-flex me-5">
                      <FormSinglePoket
                        disabled
                        defaultValue={assesmentSPPoket.p55}
                        placeholderInput="55"
                        name="p55"
                        onChangeInput={updateAssesmentPoket}
                      />
                      <FormSinglePoket
                        disabled
                        defaultValue={assesmentSPPoket.p54}
                        placeholderInput="54"
                        name="p54"
                        onChangeInput={updateAssesmentPoket}
                      />
                      <FormSinglePoket
                        disabled
                        defaultValue={assesmentSPPoket.p53}
                        placeholderInput="53"
                        name="p53"
                        onChangeInput={updateAssesmentPoket}
                      />
                      <FormSinglePoket
                        disabled
                        defaultValue={assesmentSPPoket.p52}
                        placeholderInput="52"
                        name="p52"
                        onChangeInput={updateAssesmentPoket}
                      />
                      <FormSinglePoket
                        disabled
                        defaultValue={assesmentSPPoket.p51}
                        placeholderInput="51"
                        name="p51"
                        onChangeInput={updateAssesmentPoket}
                      />
                    </div>
                    <div className="d-flex">
                      <FormSinglePoket
                        disabled
                        defaultValue={assesmentSPPoket.p61}
                        placeholderInput="61"
                        name="p61"
                        onChangeInput={updateAssesmentPoket}
                      />
                      <FormSinglePoket
                        disabled
                        defaultValue={assesmentSPPoket.p62}
                        placeholderInput="62"
                        name="p62"
                        onChangeInput={updateAssesmentPoket}
                      />
                      <FormSinglePoket
                        disabled
                        defaultValue={assesmentSPPoket.p63}
                        placeholderInput="63"
                        name="p63"
                        onChangeInput={updateAssesmentPoket}
                      />
                      <FormSinglePoket
                        disabled
                        defaultValue={assesmentSPPoket.p64}
                        placeholderInput="64"
                        name="p64"
                        onChangeInput={updateAssesmentPoket}
                      />
                      <FormSinglePoket
                        disabled
                        defaultValue={assesmentSPPoket.p65}
                        placeholderInput="65"
                        name="p65"
                        onChangeInput={updateAssesmentPoket}
                      />
                    </div>

                    <div className="width-periodontal align-middle ms-4">
                      <p className="pt-2">Poket</p>
                    </div>
                  </div>

                  <div className="d-flex ">
                    <div className="width-periodontal align-middle pe-4">
                      <p className="pt-2">Bleed</p>
                    </div>

                    <div className="d-flex me-5">
                      <FormSingleBleed
                        disabled
                        defaultValue={assesmentSPBleed.b85}
                        placeholderInput="85"
                        name="b85"
                        onChangeInput={updateAssesmentBleed}
                      />
                      <FormSingleBleed
                        disabled
                        defaultValue={assesmentSPBleed.b84}
                        placeholderInput="84"
                        name="b84"
                        onChangeInput={updateAssesmentBleed}
                      />
                      <FormSingleBleed
                        disabled
                        defaultValue={assesmentSPBleed.b83}
                        placeholderInput="83"
                        name="b83"
                        onChangeInput={updateAssesmentBleed}
                      />
                      <FormSingleBleed
                        disabled
                        defaultValue={assesmentSPBleed.b82}
                        placeholderInput="82"
                        name="b82"
                        onChangeInput={updateAssesmentBleed}
                      />
                      <FormSingleBleed
                        disabled
                        defaultValue={assesmentSPBleed.b81}
                        placeholderInput="81"
                        name="b81"
                        onChangeInput={updateAssesmentBleed}
                      />
                    </div>
                    <div className="d-flex">
                      <FormSingleBleed
                        disabled
                        defaultValue={assesmentSPBleed.b71}
                        placeholderInput="71"
                        name="b71"
                        onChangeInput={updateAssesmentBleed}
                      />
                      <FormSingleBleed
                        disabled
                        defaultValue={assesmentSPBleed.b72}
                        placeholderInput="72"
                        name="b72"
                        onChangeInput={updateAssesmentBleed}
                      />
                      <FormSingleBleed
                        disabled
                        defaultValue={assesmentSPBleed.b73}
                        placeholderInput="73"
                        name="b73"
                        onChangeInput={updateAssesmentBleed}
                      />
                      <FormSingleBleed
                        disabled
                        defaultValue={assesmentSPBleed.b74}
                        placeholderInput="74"
                        name="b74"
                        onChangeInput={updateAssesmentBleed}
                      />
                      <FormSingleBleed
                        disabled
                        defaultValue={assesmentSPBleed.b75}
                        placeholderInput="75"
                        name="b75"
                        onChangeInput={updateAssesmentBleed}
                      />
                    </div>

                    <div className="width-periodontal align-middle ms-4">
                      <p className="pt-2">Bleed</p>
                    </div>
                  </div>
                  <div className="d-flex ">
                    <div className="width-periodontal align-middle pe-4">
                      <p className="pt-2">Poket</p>
                    </div>

                    <div className="d-flex me-5">
                      <FormSinglePoket
                        disabled
                        defaultValue={assesmentSPPoket.p85}
                        placeholderInput="85"
                        name="p85"
                        onChangeInput={updateAssesmentPoket}
                      />
                      <FormSinglePoket
                        disabled
                        defaultValue={assesmentSPPoket.p84}
                        placeholderInput="84"
                        name="p84"
                        onChangeInput={updateAssesmentPoket}
                      />
                      <FormSinglePoket
                        disabled
                        defaultValue={assesmentSPPoket.p83}
                        placeholderInput="83"
                        name="p83"
                        onChangeInput={updateAssesmentPoket}
                      />
                      <FormSinglePoket
                        disabled
                        defaultValue={assesmentSPPoket.p82}
                        placeholderInput="82"
                        name="p82"
                        onChangeInput={updateAssesmentPoket}
                      />
                      <FormSinglePoket
                        disabled
                        defaultValue={assesmentSPPoket.p81}
                        placeholderInput="81"
                        name="p81"
                        onChangeInput={updateAssesmentPoket}
                      />
                    </div>
                    <div className="d-flex">
                      <FormSinglePoket
                        disabled
                        defaultValue={assesmentSPPoket.p71}
                        placeholderInput="71"
                        name="p71"
                        onChangeInput={updateAssesmentPoket}
                      />
                      <FormSinglePoket
                        disabled
                        defaultValue={assesmentSPPoket.p72}
                        placeholderInput="72"
                        name="p72"
                        onChangeInput={updateAssesmentPoket}
                      />
                      <FormSinglePoket
                        disabled
                        defaultValue={assesmentSPPoket.p73}
                        placeholderInput="73"
                        name="p73"
                        onChangeInput={updateAssesmentPoket}
                      />
                      <FormSinglePoket
                        disabled
                        defaultValue={assesmentSPPoket.p74}
                        placeholderInput="74"
                        name="p74"
                        onChangeInput={updateAssesmentPoket}
                      />
                      <FormSinglePoket
                        disabled
                        defaultValue={assesmentSPPoket.p75}
                        placeholderInput="75"
                        name="p75"
                        onChangeInput={updateAssesmentPoket}
                      />
                    </div>

                    <div className="width-periodontal align-middle ms-4">
                      <p className="pt-2">Poket</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Parter>

          <Parter title={"Maloklusi Dental"}>
            <Row>
              <Col xl={12}>
                <Tooltip>
                  <Row>
                    <ListGroup>
                      <ListGroup.Item className="d-flex">
                        <div className="col-xl-1 me-3">0</div>{" "}
                        <div className="col-xl-11 light">
                          Relasi molar kelas I, tanpa kelainan anterior.
                        </div>
                      </ListGroup.Item>
                      <ListGroup.Item className="d-flex">
                        <div className="col-xl-1 me-3">1</div>{" "}
                        <div className="col-xl-11 light">
                          Relasi molar kelas I, dengan crowding
                          anterior/kelainan overjet.
                        </div>
                      </ListGroup.Item>
                      <ListGroup.Item className="d-flex">
                        <div className="col-xl-1 me-3">2</div>{" "}
                        <div className="col-xl-11 light">
                          Relasi molar kelas II.
                        </div>
                      </ListGroup.Item>
                      <ListGroup.Item className="d-flex">
                        <div className="col-xl-1 me-3">3</div>{" "}
                        <div className="col-xl-11 light">
                          Relasi molar kelas III.
                        </div>
                      </ListGroup.Item>
                    </ListGroup>
                  </Row>
                </Tooltip>
              </Col>
            </Row>
            <Row>
              <Col xl="12" md="12" lg="12" sm="12" x="12">
                <Row>
                  <Col
                    xl="5"
                    md="6"
                    sm="6"
                    xs="5"
                    className="d-flex flex-column justify-content-center"
                  >
                    Relasi Molar
                  </Col>
                </Row>
                <Row>
                  <Col
                    xl="2"
                    md="2"
                    sm="2"
                    xs="3"
                    className="d-flex flex-column justify-content-center"
                  >
                    Status
                  </Col>
                  <Col
                    xl="4"
                    md="4"
                    sm="4"
                    xs="3"
                    className="d-flex flex-column justify-content-center"
                  >
                    <div className="width-box">
                      <Form.Control
                        onKeyPress={(event) => {
                          if (!/[0-3]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        name="maloklusi_dental"
                        type="text"
                        autoComplete="off"
                        disabled
                        maxLength={1}
                        className="text-align input"
                        onChange={updateAssesmentDataLain}
                        defaultValue={assesmentDataLain.maloklusi_dental}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Parter>

          <Parter title={"Kehilangan Perekatan"}>
            <Row>
              <Col>
                <Row>
                  <Col xl={12}>
                    <Tooltip>
                      <Row>
                        <ListGroup>
                          <ListGroup.Item className="d-flex">
                            <div className="col-xl-1 me-3">0</div>{" "}
                            <div className="col-xl-11 light"> 0 - 3 mm</div>
                          </ListGroup.Item>
                          <ListGroup.Item className="d-flex">
                            <div className="col-xl-1 me-3">1</div>{" "}
                            <div className="col-xl-11 light">
                              {" "}
                              4 - 5 mm CEJ belum melewati Band Hitam
                            </div>
                          </ListGroup.Item>
                          <ListGroup.Item className="d-flex">
                            <div className="col-xl-1 me-3">2</div>{" "}
                            <div className="col-xl-11 light">
                              {" "}
                              6 - 8 mm CEJ antara Band Hitam II & III 3 9 - 11
                              mm CEJ antara Band Hitam III & I
                            </div>
                          </ListGroup.Item>
                          <ListGroup.Item className="d-flex">
                            <div className="col-xl-1 me-3">4</div>{" "}
                            <div className="col-xl-11 light">
                              {" "}
                              12 mm kurang dari CEJ, lebih dari Band Hitam IV
                            </div>
                          </ListGroup.Item>
                          <ListGroup.Item className="d-flex">
                            <div className="col-xl-1 me-3">X</div>{" "}
                            <div className="col-xl-11 light">
                              {" "}
                              Sextan tidak diperiksa
                            </div>
                          </ListGroup.Item>
                          <ListGroup.Item className="d-flex">
                            <div className="col-xl-1 me-3">9</div>{" "}
                            <div className="col-xl-11 light">
                              {" "}
                              Gigi tidak dicatat
                            </div>
                          </ListGroup.Item>
                        </ListGroup>
                      </Row>
                    </Tooltip>
                  </Col>
                </Row>
                <Row>
                  <p className="light">
                    {" "}
                    <i className="light">
                      *Diukur pada responden usia lebih dari 15 Tahun
                    </i>{" "}
                  </p>
                </Row>
                <Row className="perekatan-font">
                  <Col
                    xl="2"
                    md="2"
                    sm="2"
                    xs="3"
                    className="d-flex flex-column justify-content-center"
                  >
                    17/16
                  </Col>
                  <Col
                    xl="4"
                    md="4"
                    sm="4"
                    xs="3"
                    className="d-flex flex-column justify-content-center"
                  >
                    <div className="width-box">
                      <Form.Control
                        onKeyPress={(event) => {
                          if (!/[0-4,9,x,X]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        name="kehilangan_perlekatan1716"
                        type="text"
                        autoComplete="off"
                        disabled
                        maxLength={1}
                        className="text-align input"
                        onChange={updateAssesmentDataLain}
                        defaultValue={
                          assesmentDataLain.kehilangan_perlekatan1716
                        }
                      />
                    </div>
                  </Col>
                  <Col
                    xl="2"
                    md="2"
                    sm="2"
                    xs="3"
                    className="d-flex flex-column justify-content-center"
                  >
                    11
                  </Col>
                  <Col
                    xl="4"
                    md="4"
                    sm="4"
                    xs="3"
                    className="d-flex flex-column justify-content-center"
                  >
                    <div className="width-box">
                      <Form.Control
                        onKeyPress={(event) => {
                          if (!/[0-4,9,x,X]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        name="kehilangan_perlekatan11"
                        type="text"
                        autoComplete="off"
                        disabled
                        maxLength={1}
                        className="text-align input"
                        onChange={updateAssesmentDataLain}
                        defaultValue={assesmentDataLain.kehilangan_perlekatan11}
                      />
                    </div>
                  </Col>
                  <Col
                    xl="2"
                    md="2"
                    sm="2"
                    xs="3"
                    className="d-flex flex-column justify-content-center"
                  >
                    26/27
                  </Col>
                  <Col
                    xl="4"
                    md="4"
                    sm="4"
                    xs="3"
                    className="d-flex flex-column justify-content-center"
                  >
                    <div className="width-box">
                      <Form.Control
                        onKeyPress={(event) => {
                          if (!/[0-4,9,x,X]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        name="kehilangan_perlekatan2627"
                        type="text"
                        autoComplete="off"
                        disabled
                        maxLength={1}
                        className="text-align input"
                        onChange={updateAssesmentDataLain}
                        defaultValue={
                          assesmentDataLain.kehilangan_perlekatan2627
                        }
                      />
                    </div>
                  </Col>
                  <Col
                    xl="2"
                    md="2"
                    sm="2"
                    xs="3"
                    className="d-flex flex-column justify-content-center"
                  >
                    47/46
                  </Col>
                  <Col
                    xl="4"
                    md="4"
                    sm="4"
                    xs="3"
                    className="d-flex flex-column justify-content-center"
                  >
                    <div className="width-box">
                      <Form.Control
                        onKeyPress={(event) => {
                          if (!/[0-4,9,x,X]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        name="kehilangan_perlekatan4746"
                        type="text"
                        autoComplete="off"
                        disabled
                        maxLength={1}
                        className="text-align input"
                        onChange={updateAssesmentDataLain}
                        defaultValue={
                          assesmentDataLain.kehilangan_perlekatan4746
                        }
                      />
                    </div>
                  </Col>
                  <Col
                    xl="2"
                    md="2"
                    sm="2"
                    xs="3"
                    className="d-flex flex-column justify-content-center"
                  >
                    31
                  </Col>
                  <Col
                    xl="4"
                    md="4"
                    sm="4"
                    xs="3"
                    className="d-flex flex-column justify-content-center"
                  >
                    <div className="width-box">
                      <Form.Control
                        onKeyPress={(event) => {
                          if (!/[0-4,9,x,X]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        name="kehilangan_perlekatan31"
                        type="text"
                        autoComplete="off"
                        disabled
                        maxLength={1}
                        className="text-align input"
                        onChange={updateAssesmentDataLain}
                        defaultValue={assesmentDataLain.kehilangan_perlekatan31}
                      />
                    </div>
                  </Col>
                  <Col
                    xl="2"
                    md="2"
                    sm="2"
                    xs="3"
                    className="d-flex flex-column justify-content-center"
                  >
                    36/37
                  </Col>
                  <Col
                    xl="4"
                    md="4"
                    sm="4"
                    xs="3"
                    className="d-flex flex-column justify-content-center"
                  >
                    <div className="width-box">
                      <Form.Control
                        onKeyPress={(event) => {
                          if (!/[0-4,9,x,X]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        name="kehilangan_perlekatan3637"
                        type="text"
                        autoComplete="off"
                        disabled
                        maxLength={1}
                        className="text-align input"
                        onChange={updateAssesmentDataLain}
                        defaultValue={
                          assesmentDataLain.kehilangan_perlekatan3637
                        }
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Parter>

          <Parter title="Fluorosis Email*">
            <Row>
              <Col xl={12}>
                <Row>
                  <Col xl={12}>
                    <Tooltip>
                      <Row>
                        <ListGroup key="xl" horizontal="xl">
                          <ListGroup.Item>
                            <ListGroup variant="flush">
                              <ListGroup.Item className="d-flex">
                                <div className="col-xl-1 me-3">0</div>{" "}
                                <div className="col-xl-11 light"> Normal</div>
                              </ListGroup.Item>
                              <ListGroup.Item className="d-flex">
                                <div className="col-xl-1 me-3">1</div>{" "}
                                <div className="col-xl-11 light">
                                  {" "}
                                  Meragukan
                                </div>
                              </ListGroup.Item>
                              <ListGroup.Item className="d-flex">
                                <div className="col-xl-1 me-3">2</div>{" "}
                                <div className="col-xl-11 light">
                                  {" "}
                                  Sangat ringan
                                </div>
                              </ListGroup.Item>
                              <ListGroup.Item className="d-flex">
                                <div className="col-xl-1 me-3">3</div>{" "}
                                <div className="col-xl-11 light"> Ringan</div>
                              </ListGroup.Item>
                            </ListGroup>
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <ListGroup variant="flush">
                              <ListGroup.Item className="d-flex">
                                <div className="col-xl-1 me-3">4</div>{" "}
                                <div className="col-xl-11 light"> Sedang</div>
                              </ListGroup.Item>
                              <ListGroup.Item className="d-flex">
                                <div className="col-xl-1 me-3">5</div>{" "}
                                <div className="col-xl-11 light"> Berat</div>
                              </ListGroup.Item>
                              <ListGroup.Item className="d-flex">
                                <div className="col-xl-1 me-3">8</div>{" "}
                                <div className="col-xl-11 light">
                                  {" "}
                                  Gigi di-ekslusi (mahkota, tambahan, “bracket”)
                                </div>
                              </ListGroup.Item>
                              <ListGroup.Item className="d-flex">
                                <div className="col-xl-1 me-3">9</div>{" "}
                                <div className="col-xl-11 light">
                                  {" "}
                                  Tidak dapat dicatat (gigi belum erupsi)
                                </div>
                              </ListGroup.Item>
                            </ListGroup>
                          </ListGroup.Item>
                        </ListGroup>
                      </Row>
                    </Tooltip>
                  </Col>
                </Row>
                <Row>
                  <p className="light">
                    {" "}
                    <i className="light">
                      *Diukur pada responden usia lebih dari 3 Tahun
                    </i>{" "}
                  </p>
                </Row>
                <Row>
                  <Col
                    xl="5"
                    md="6"
                    sm="6"
                    xs="5"
                    className="d-flex flex-column justify-content-center"
                  >
                    Fluorosis Email
                  </Col>
                </Row>
                <Row>
                  <Col
                    xl="2"
                    md="2"
                    sm="2"
                    xs="3"
                    className="d-flex flex-column justify-content-center"
                  >
                    Status
                  </Col>
                  <Col
                    xl="4"
                    md="4"
                    sm="4"
                    xs="3"
                    className="d-flex flex-column justify-content-center"
                  >
                    <div className="width-box">
                      <Form.Control
                        onKeyPress={(event) => {
                          if (!/[0-5,8-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        name="fluorosis_email"
                        type="text"
                        autoComplete="off"
                        disabled
                        maxLength={1}
                        className="text-align input"
                        onChange={updateAssesmentDataLain}
                        defaultValue={assesmentDataLain.fluorosis_email}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Parter>

          <Parter title={"Keparahan"}>
            <Row>
              <Col xl="12">
                <Row>
                  <Col xl={12}>
                    <Tooltip>
                      <Row>
                        <ListGroup>
                          <ListGroup.Item className="d-flex">
                            <div className="col-xl-1 me-3">0</div>{" "}
                            <div className="col-xl-11 light">
                              {" "}
                              Tidak ada tanda erosi
                            </div>
                          </ListGroup.Item>
                          <ListGroup.Item className="d-flex">
                            <div className="col-xl-1 me-3">1</div>{" "}
                            <div className="col-xl-11 light">
                              {" "}
                              Erosi pada email
                            </div>
                          </ListGroup.Item>
                          <ListGroup.Item className="d-flex">
                            <div className="col-xl-1 me-3">2</div>{" "}
                            <div className="col-xl-11 light">
                              {" "}
                              Erosi pada dentin
                            </div>
                          </ListGroup.Item>
                          <ListGroup.Item className="d-flex">
                            <div className="col-xl-1 me-3">3</div>{" "}
                            <div className="col-xl-11 light">
                              {" "}
                              Keterlibatan pulpa
                            </div>
                          </ListGroup.Item>
                        </ListGroup>
                      </Row>
                    </Tooltip>
                  </Col>
                </Row>
                <Row>
                  <Col
                    xl="2"
                    md="2"
                    sm="2"
                    xs="3"
                    className="d-flex flex-column justify-content-center"
                  >
                    Erosi Gigi
                  </Col>
                  <Col
                    xl="4"
                    md="4"
                    sm="4"
                    xs="3"
                    className="d-flex flex-column justify-content-center"
                  >
                    <div className="width-box">
                      <Form.Control
                        onKeyPress={(event) => {
                          if (!/[0-3]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        name="keparahan_erosi_gigi"
                        type="text"
                        autoComplete="off"
                        disabled
                        maxLength={1}
                        className="text-align input"
                        onChange={updateAssesmentDataLain}
                        defaultValue={assesmentDataLain.keparahan_erosi_gigi}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col
                    xl="2"
                    md="2"
                    sm="2"
                    xs="3"
                    className="d-flex flex-column justify-content-center"
                  >
                    Jumlah
                  </Col>
                  <Col
                    xl="4"
                    md="4"
                    sm="4"
                    xs="3"
                    className="d-flex flex-column justify-content-center"
                  >
                    <div className="width-box">
                      <Form.Control
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        name="keparahan_jumlah"
                        type="text"
                        autoComplete="off"
                        disabled
                        maxLength={2}
                        className="text-align input"
                        onChange={updateAssesmentDataLain}
                        defaultValue={assesmentDataLain.keparahan_jumlah}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Parter>

          <Parter title={"Lesi Mukosa Oral"}>
            <Row>
              <Col xl="12">
                <Row>
                  <Col xl={12}>
                    <Tooltip>
                      <Row>
                        <ListGroup key="xl" horizontal="xl">
                          <ListGroup.Item>
                            <ListGroup variant="flush">
                              <ListGroup.Item className="d-flex">
                                <div className="col-xl-1 me-3">0</div>{" "}
                                <div className="col-xl-11 light">
                                  {" "}
                                  Tidak ada kondisi abnormal
                                </div>
                              </ListGroup.Item>
                              <ListGroup.Item className="d-flex">
                                <div className="col-xl-1 me-3">1</div>{" "}
                                <div className="col-xl-11 light">
                                  {" "}
                                  Tumor maligna (kanker mulut)
                                </div>
                              </ListGroup.Item>
                              <ListGroup.Item className="d-flex">
                                <div className="col-xl-1 me-3">2</div>{" "}
                                <div className="col-xl-11 light">
                                  {" "}
                                  Leukoplakia
                                </div>
                              </ListGroup.Item>
                              <ListGroup.Item className="d-flex">
                                <div className="col-xl-1 me-3">3</div>{" "}
                                <div className="col-xl-11 light">
                                  {" "}
                                  Lichen planus
                                </div>
                              </ListGroup.Item>
                              <ListGroup.Item className="d-flex">
                                <div className="col-xl-1 me-3">4</div>{" "}
                                <div className="col-xl-11 light">
                                  {" "}
                                  Ulserasi (apthous, herpetic, traumatic).
                                </div>
                              </ListGroup.Item>
                              <ListGroup.Item className="d-flex">
                                <div className="col-xl-1 me-3">5</div>{" "}
                                <div className="col-xl-11 light">
                                  {" "}
                                  Nekrosis ulseratif gingivitis akut
                                </div>
                              </ListGroup.Item>
                            </ListGroup>
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <ListGroup variant="flush">
                              <ListGroup.Item className="d-flex">
                                <div className="col-xl-1 me-3">6</div>{" "}
                                <div className="col-xl-11 light">
                                  {" "}
                                  Candidiasis
                                </div>
                              </ListGroup.Item>
                              <ListGroup.Item className="d-flex">
                                <div className="col-xl-1 me-3">7</div>{" "}
                                <div className="col-xl-11 light"> Abses</div>
                              </ListGroup.Item>
                              <ListGroup.Item className="d-flex">
                                <div className="col-xl-1 me-3">8</div>{" "}
                                <div className="col-xl-11 light">
                                  {" "}
                                  Lesi hipersensitivitas
                                </div>
                              </ListGroup.Item>
                              <ListGroup.Item className="d-flex">
                                <div className="col-xl-1 me-3">9</div>{" "}
                                <div className="col-xl-11 light">
                                  {" "}
                                  Kondisi lain
                                </div>
                              </ListGroup.Item>
                              <ListGroup.Item className="d-flex">
                                <div className="col-xl-1 me-3">X</div>{" "}
                                <div className="col-xl-11 light">
                                  {" "}
                                  Tidak dapat dicatat
                                </div>
                              </ListGroup.Item>
                            </ListGroup>
                          </ListGroup.Item>
                        </ListGroup>
                      </Row>
                    </Tooltip>
                  </Col>
                </Row>
                <Row>
                  <Col
                    xl="2"
                    md="2"
                    sm="2"
                    xs="3"
                    className="d-flex flex-column justify-content-center"
                  >
                    Status
                  </Col>
                  <Col
                    xl="4"
                    md="4"
                    sm="4"
                    xs="3"
                    className="d-flex flex-column justify-content-center"
                  >
                    <div className="width-box">
                      <Form.Control
                        onKeyPress={(event) => {
                          if (!/[0-9,x,X]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        name="lesi_mukosa_oral"
                        type="text"
                        autoComplete="off"
                        disabled
                        maxLength={1}
                        className="text-align input"
                        onChange={updateAssesmentDataLain}
                        defaultValue={assesmentDataLain.lesi_mukosa_oral}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Parter>

          <Parter title={"Kebutuhan Perawatan Segera"}>
            <Row>
              <Col xl="12">
                <Row>
                  <Col xl={12}>
                    <Tooltip>
                      <Row>
                        <ListGroup>
                          <ListGroup.Item className="d-flex">
                            <div className="col-xl-1 me-3">0</div>{" "}
                            <div className="col-xl-11 light">
                              {" "}
                              Tidak perlu perawatan
                            </div>
                          </ListGroup.Item>
                          <ListGroup.Item className="d-flex">
                            <div className="col-xl-1 me-3">1</div>{" "}
                            <div className="col-xl-11 light">
                              {" "}
                              Diperlukan perawatan preventif dan rutin
                            </div>
                          </ListGroup.Item>
                          <ListGroup.Item className="d-flex">
                            <div className="col-xl-1 me-3">2</div>{" "}
                            <div className="col-xl-11 light">
                              {" "}
                              Diperlukan perawatan dengan scaling
                            </div>
                          </ListGroup.Item>
                          <ListGroup.Item className="d-flex">
                            <div className="col-xl-1 me-3">3</div>{" "}
                            <div className="col-xl-11 light">
                              {" "}
                              Diperlukan perawatan segera
                            </div>
                          </ListGroup.Item>
                          <ListGroup.Item className="d-flex">
                            <div className="col-xl-1 me-3">4</div>{" "}
                            <div className="col-xl-11 light">
                              {" "}
                              Dirujuk ke pemeriksaan komprehensif (terdapat
                              kondisi penyakit sistemik)
                            </div>
                          </ListGroup.Item>
                        </ListGroup>
                      </Row>
                    </Tooltip>
                  </Col>
                </Row>
                <Row>
                  <Col
                    xl="2"
                    md="2"
                    sm="2"
                    xs="3"
                    className="d-flex flex-column justify-content-center"
                  >
                    Status
                  </Col>
                  <Col
                    xl="4"
                    md="4"
                    sm="4"
                    xs="3"
                    className="d-flex flex-column justify-content-center"
                  >
                    <div className="width-box">
                      <Form.Control
                        onKeyPress={(event) => {
                          if (!/[0-5,8-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        name="kebutuhan_perawatan_segera"
                        type="text"
                        autoComplete="off"
                        disabled
                        maxLength={1}
                        className="text-align input"
                        onChange={updateAssesmentDataLain}
                        defaultValue={
                          assesmentDataLain.kebutuhan_perawatan_segera
                        }
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Parter>
          <Parter title={"Gigi Tiruan Responden Umur Lebih dari 3 Tahun"}>
            <Row>
              <Col xl="12">
                <Row>
                  <Col xl={12}>
                    <Tooltip>
                      <Row>
                        <ListGroup>
                          <ListGroup.Item className="d-flex">
                            <div className="col-xl-1 me-3">0</div>{" "}
                            <div className="col-xl-11 light">
                              {" "}
                              Tidak ada gigi tiruan
                            </div>
                          </ListGroup.Item>
                          <ListGroup.Item className="d-flex">
                            <div className="col-xl-1 me-3">1</div>{" "}
                            <div className="col-xl-11 light">
                              {" "}
                              Gigi tiruan sebagian
                            </div>
                          </ListGroup.Item>
                          <ListGroup.Item className="d-flex">
                            <div className="col-xl-1 me-3">2</div>{" "}
                            <div className="col-xl-11 light">
                              {" "}
                              Gigi tiruan penuh
                            </div>
                          </ListGroup.Item>
                          <ListGroup.Item className="d-flex">
                            <div className="col-xl-1 me-3">3</div>{" "}
                            <div className="col-xl-11 light">
                              {" "}
                              Tidak dapat dicatat
                            </div>
                          </ListGroup.Item>
                        </ListGroup>
                      </Row>
                    </Tooltip>
                  </Col>
                </Row>
                <Row>
                  <Col
                    xl="2"
                    md="2"
                    sm="2"
                    xs="3"
                    className="d-flex flex-column justify-content-center"
                  >
                    RA
                  </Col>
                  <Col
                    xl="4"
                    md="4"
                    sm="4"
                    xs="3"
                    className="d-flex flex-column justify-content-center"
                  >
                    <div className="width-box">
                      <Form.Control
                        onKeyPress={(event) => {
                          if (!/[0-3]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        name="gigi_tiruan_atas_tiga_tahun_ra"
                        type="text"
                        autoComplete="off"
                        disabled
                        maxLength={1}
                        className="text-align input"
                        onChange={updateAssesmentDataLain}
                        defaultValue={
                          assesmentDataLain.gigi_tiruan_atas_tiga_tahun_ra
                        }
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col
                    xl="2"
                    md="2"
                    sm="2"
                    xs="3"
                    className="d-flex flex-column justify-content-center"
                  >
                    RB
                  </Col>
                  <Col
                    xl="4"
                    md="4"
                    sm="4"
                    xs="3"
                    className="d-flex flex-column justify-content-center"
                  >
                    <div className="width-box">
                      <Form.Control
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        name="gigi_tiruan_atas_tiga_tahun_rb"
                        type="text"
                        autoComplete="off"
                        disabled
                        maxLength={1}
                        className="text-align input"
                        onChange={updateAssesmentDataLain}
                        defaultValue={
                          assesmentDataLain.gigi_tiruan_atas_tiga_tahun_rb
                        }
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Parter>
          <Parter title={"Trauma Dental"}>
            <Row>
              <Col xl="12">
                <Row>
                  <Col xl={12}>
                    <Tooltip>
                      <Row>
                        <ListGroup key="xl" horizontal="xl">
                          <ListGroup.Item>
                            <ListGroup variant="flush">
                              <ListGroup.Item className="d-flex">
                                <div className="col-xl-1 me-3">0</div>{" "}
                                <div className="col-xl-11 light">
                                  {" "}
                                  Tidak ada tanda cedera
                                </div>
                              </ListGroup.Item>
                              <ListGroup.Item className="d-flex">
                                <div className="col-xl-1 me-3">1</div>{" "}
                                <div className="col-xl-11 light">
                                  {" "}
                                  Cedera yang dirawat
                                </div>
                              </ListGroup.Item>
                              <ListGroup.Item className="d-flex">
                                <div className="col-xl-1 me-3">2</div>{" "}
                                <div className="col-xl-11 light">
                                  {" "}
                                  Hanya fraktur enamel
                                </div>
                              </ListGroup.Item>
                              <ListGroup.Item className="d-flex">
                                <div className="col-xl-1 me-3">3</div>{" "}
                                <div className="col-xl-11 light">
                                  {" "}
                                  Fraktur enamel dan dentin
                                </div>
                              </ListGroup.Item>
                            </ListGroup>
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <ListGroup variant="flush">
                              <ListGroup.Item className="d-flex">
                                <div className="col-xl-1 me-3">4</div>{" "}
                                <div className="col-xl-11 light">
                                  {" "}
                                  Keterlibatan pulpa
                                </div>
                              </ListGroup.Item>
                              <ListGroup.Item className="d-flex">
                                <div className="col-xl-1 me-3">5</div>{" "}
                                <div className="col-xl-11 light">
                                  {" "}
                                  Kehilangan gigi karena trauma
                                </div>
                              </ListGroup.Item>
                              <ListGroup.Item className="d-flex">
                                <div className="col-xl-1 me-3">6</div>{" "}
                                <div className="col-xl-11 light">
                                  {" "}
                                  Kerusakan lainnya
                                </div>
                              </ListGroup.Item>
                              <ListGroup.Item className="d-flex">
                                <div className="col-xl-1 me-3">7</div>{" "}
                                <div className="col-xl-11 light">
                                  {" "}
                                  Gigi yang dikecualikan
                                </div>
                              </ListGroup.Item>
                            </ListGroup>
                          </ListGroup.Item>
                        </ListGroup>
                      </Row>
                    </Tooltip>
                  </Col>
                </Row>
                <Row>
                  <Col
                    xl="2"
                    md="2"
                    sm="2"
                    xs="3"
                    className="d-flex flex-column justify-content-center"
                  >
                    Status
                  </Col>
                  <Col
                    xl="4"
                    md="4"
                    sm="4"
                    xs="3"
                    className="d-flex flex-column justify-content-center"
                  >
                    <div className="width-box">
                      <Form.Control
                        onKeyPress={(event) => {
                          if (!/[0-7]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        name="trauma_dental_status"
                        type="text"
                        autoComplete="off"
                        disabled
                        maxLength={1}
                        className="text-align input"
                        onChange={updateAssesmentDataLain}
                        defaultValue={assesmentDataLain.trauma_dental_status}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col
                    xl="2"
                    md="2"
                    sm="2"
                    xs="3"
                    className="d-flex flex-column justify-content-center"
                  >
                    Jumlah
                  </Col>
                  <Col
                    xl="4"
                    md="4"
                    sm="4"
                    xs="3"
                    className="d-flex flex-column justify-content-center"
                  >
                    <div className="width-box">
                      <Form.Control
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        name="trauma_dental_jumlah"
                        type="text"
                        autoComplete="off"
                        disabled
                        maxLength={2}
                        className="text-align input"
                        onChange={updateAssesmentDataLain}
                        defaultValue={assesmentDataLain.trauma_dental_jumlah}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Parter>
          <Parter title={"Indeks Ohis"}>
            <Row>
              <Col xl="12">
                <Row>
                  <Col
                    xl="2"
                    md="2"
                    sm="2"
                    xs="3"
                    className="d-flex flex-column justify-content-center"
                  >
                    Penilaian Debris
                  </Col>
                  <Col
                    xl="4"
                    md="4"
                    sm="4"
                    xs="3"
                    className="d-flex flex-column justify-content-center"
                  >
                    <div className="width-box">
                      <Form.Control
                        onKeyPress={(event) => {
                          if (!/[0-3]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        name="table_penilaian_debris"
                        type="text"
                        autoComplete="off"
                        disabled
                        maxLength={1}
                        className="text-align input"
                        onChange={updateAssesmentDataLain}
                        defaultValue={assesmentDataLain.table_penilaian_debris}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col
                    xl="2"
                    md="2"
                    sm="2"
                    xs="3"
                    className="d-flex flex-column justify-content-center"
                  >
                    Penilaian Kalkulus
                  </Col>
                  <Col
                    xl="4"
                    md="4"
                    sm="4"
                    xs="3"
                    className="d-flex flex-column justify-content-center"
                  >
                    <div className="width-box">
                      <Form.Control
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        name="table_penilaian_kalkulus"
                        type="text"
                        autoComplete="off"
                        disabled
                        maxLength={1}
                        className="text-align input"
                        onChange={updateAssesmentDataLain}
                        defaultValue={
                          assesmentDataLain.table_penilaian_kalkulus
                        }
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col
                    xl="2"
                    md="2"
                    sm="2"
                    xs="3"
                    className="d-flex flex-column justify-content-center"
                  >
                    INDEKS OHIS
                  </Col>
                  <Col
                    xl="4"
                    md="4"
                    sm="4"
                    xs="3"
                    className="d-flex flex-column justify-content-center"
                  >
                    <div className="width-box">
                      <Form.Control
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        name="indeks_ohis"
                        type="text"
                        autoComplete="off"
                        disabled
                        maxLength={1}
                        className="text-align input"
                        onChange={updateAssesmentDataLain}
                        defaultValue={assesmentDataLain.indeks_ohis}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Parter>
          <Parter title={"Rencana Tindakan Perawatan"}>
            <Row>
              <Col xl="12">
                <Row>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="diagnosisKlinis"
                  >
                    <Form.Label column lg="3" md="12" sm="12">
                      Diagnosis Klinis
                    </Form.Label>
                    <Col lg="9" md="12" sm="12">
                      <Form.Control
                        name="diagnosis_klinis"
                        type="text"
                        autoComplete="off"
                        disabled
                        defaultValue={assesmentDataLain.diagnosis_klinis}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="tindakanPerawatan"
                  >
                    <Form.Label column lg="3" md="12" sm="12">
                      Tindakan Perawatan
                    </Form.Label>
                    <Col lg="9" md="12" sm="12">
                      <Form.Control
                        name="tindakan_perawatan"
                        type="text"
                        autoComplete="off"
                        disabled
                        defaultValue={assesmentDataLain.tindakan_perawatan}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3" controlId="deskripsi">
                    <Form.Label column lg="3" md="12" sm="12">
                      Deskripsi
                    </Form.Label>
                    <Col lg="9" md="12" sm="12">
                      <Form.Control
                        as="textarea"
                        name="deskripsi"
                        onChange={updateAssesmentDataLain}
                        defaultValue={assesmentDataLain.deskripsi}
                        disabled
                      />
                    </Col>
                  </Form.Group>
                </Row>
              </Col>
            </Row>
          </Parter>
        </Cards>
      </ContainerCards>
    </>
  );
};

export default AssesmentView;
