import React, { useEffect } from "react";
import ContainerCards, { PanelForm } from "../component/ContainerCards";
import { ModalConfirmation } from "../component/ModalPopup";
import { ToastPopup } from "../component/Toastbar";
import Form from "react-bootstrap/Form";
import axiosApi from "../api/axios";
import { useState } from "react";
import Cards, { PanelCard } from "../component/Cards";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  FormControl,
  FormControlCheck,
  FormFooter,
  ValidationMessage,
} from "../component/Forms";
import validator from "validator";

const UsersAdd = () => {
  const navigate = useNavigate();

  const [showToast, setShowToast] = useState(false);
  const [show, setShow] = useState(false);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [nimOrNik, setNimOrNik] = useState("");
  const [tanggalLahir, setTanggalLahir] = useState("");
  const [jenisKelamin, setJenisKelamin] = useState("");
  const [response, setResponse] = useState({});
  const [listRole, setListRole] = useState([]);
  const [roleId, setRoleId] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState({
    type: "",
    msg: "",
  });

  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      const body = {
        username,
        password,
        email,
        name,
        nimOrNik,
        tanggalLahir,
        jenisKelamin,
        roleId,
      };

      const response = await axiosApi.post("/users/createUser", body);
      setShow(false);
      setResponse(response);
      setShowToast(true);

      const sleep = (ms) => new Promise((r) => setTimeout(r, ms));
      await sleep(1000);

      navigate("/user", { replace: true });
    } catch (err) {
      console.log(err.response);
      setResponse(err.response);
      setShow(false);
      setShowToast(true);
    }
  };

  const validateEmail = (e) => {
    let email = e.target.value;

    if (validator.isEmail(email)) {
      setEmail(email);
      setError({
        type: "",
        msg: "",
      });
    } else {
      setError({
        type: "email",
        msg: "Format email tidak valid!",
      });
    }
  };

  const validateConfirmPassword = (e) => {
    let pass = e.target.value;

    if (pass === password) {
      setPassword(password);
      setError({
        type: "",
        msg: "",
      });
    } else {
      setError({
        type: "pwd",
        msg: "Password tidak sama!",
      });
    }
  };

  const handleConfirm = (e) => {
    e.preventDefault();
    setShow(true);
  };

  useEffect(() => {
    const getAllRole = async () => {
      try {
        const response = await axiosApi.get("roles/findAll");
        setListRole(response.data.result);
      } catch (error) {
        console.log(error);
      }
    };
    getAllRole();
  }, []);

  const handleSelectGender = (e) => {
    setJenisKelamin(e.target.value);
  };

  const confirmationClose = () => setShow(false);

  const onSelectRole = (e) => {
    setRoleId(e.target.value);
  };

  return (
    <>
      <PanelForm>
        <PanelCard>
          <Form onSubmit={handleConfirm}>
            <FormControl
              controlId="name"
              label="Nama"
              type="text"
              required
              onChange={(e) => setName(e.target.value)}
            />
            <FormControl
              controlId="nimOrNik"
              label="NIM/NIK"
              type="text"
              required
              onChange={(e) => setNimOrNik(e.target.value)}
            />
            <FormControl
              controlId="username"
              label="Username"
              type="text"
              required
              onChange={(e) => setUsername(e.target.value)}
            />
            <FormControlCheck controlId="role" label="Role">
              <Form.Select
                value={roleId}
                onChange={onSelectRole}
                size="5"
                required
              >
                <option value="">-Pilih salah satu-</option>
                {listRole.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </Form.Select>
            </FormControlCheck>
            <FormControl
              className={error.type === "email" ? "form-error-border" : ""}
              controlId="email"
              label="Email"
              type="text"
              required
              onChange={validateEmail}
            />
            <ValidationMessage
              type={error.type === "email"}
              message={error.msg}
            />
            <FormControl
              controlId="password"
              label="Password"
              type="password"
              required
              onChange={(e) => setPassword(e.target.value)}
            />
            <FormControl
              controlId="password1"
              label="Confirm Password"
              type="password"
              required
              onChange={validateConfirmPassword}
            />
            <ValidationMessage
              type={error.type === "pwd"}
              message={error.msg}
            />
            <FormControl
              controlId="tanggalLahir"
              label="Tanggal Lahir"
              type="date"
              required
              onChange={(e) => setTanggalLahir(e.target.value)}
            />
            <FormControlCheck controlId="jenisKelamin" label="Jenis Kelamin">
              <Form.Select
                value={jenisKelamin}
                onChange={handleSelectGender}
                required
                aria-label="jenis-kelamin"
                className="mb-3"
              >
                <option value="">-Pilih salah satu-</option>
                <option value="Laki-laki">Laki-laki</option>
                <option value="Perempuan">Perempuan</option>
              </Form.Select>
            </FormControlCheck>

            <FormFooter>
              <div>
                <Link
                  to="/user"
                  className="btn btn-secondary me-xl-2 red"
                  variant="secondary"
                >
                  Back
                </Link>
                <Button
                  variant="primary"
                  className="ms-2 primary"
                  type="submit"
                >
                  Register
                </Button>
              </div>
            </FormFooter>
          </Form>
        </PanelCard>
      </PanelForm>

      <ModalConfirmation
        show={show}
        handleClose={confirmationClose}
        handleSubmit={handleRegister}
        message="Apakah anda yakin?"
      />

      {response?.status && (
        <ToastPopup
          message={response.data.msg}
          showToast={showToast}
          setShowToast={setShowToast}
          response={response?.status}
        />
      )}
    </>
  );
};

export default UsersAdd;
