import { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { ContainerWithCard, PanelForm } from "../component/ContainerCards";
import Table from "react-bootstrap/Table";
import axiosApi from "../api/axios";
import Button from "react-bootstrap/Button";
import { Link, useNavigate } from "react-router-dom";
import { ModalConfirmation } from "../component/ModalPopup";
import Toastbar from "../component/Toastbar";
import { useParams } from "react-router-dom";
import { PanelCard } from "../component/Cards";

const RolesEdit = () => {
  const navigate = useNavigate();
  const [permissionList, setPermissionList] = useState([]);
  const [permission, setPermission] = useState([]);
  const [response, setResponse] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [show, setShow] = useState(false);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const { id } = useParams();
  const [rolesId, setRolesId] = useState("");
  const [rolesName, setRolesName] = useState("");
  const [permissionsRoles, setPermissionsRoles] = useState([]);

  const handleCreate = async (e) => {
    e.preventDefault();
    try {
      const body = { id: rolesId, name: rolesName, permission };
      console.log(body);
      const response = await axiosApi.put(
        "/roles/editRole",
        JSON.stringify(body)
      );
      setResponse(response);
      handlePopup();

      const sleep = (ms) => new Promise((r) => setTimeout(r, ms));
      await sleep(1000);
      navigate("/role", { replace: true });
    } catch (error) {
      setResponse(error.response);
      handlePopup();
    }
  };

  useEffect(() => {
    const getPermissons = async () => {
      const response = await axiosApi.get("/permissions/findAll");
      setPermissionList(response.data.result);
    };

    const getRolesById = async () => {
      try {
        const response = await axiosApi.get(`/roles/${id}`);
        setRolesId(response.data.result.id);
        setRolesName(response.data.result.name);
        setPermissionsRoles(response.data.permission);
      } catch (error) {
        console.log(error);
      }
    };

    getPermissons();
    getRolesById();
  }, [id]);

  useEffect(() => {
    const data = permissionList.filter((data) =>
      permissionsRoles.includes(data.id)
    );
    setPermission(data);
  }, [permissionsRoles, permissionList]);

  const handlePopup = () => {
    setShow(false);
    setShowToast(true);
  };

  const handleOnChange = (e) => {
    setIsCheckAll(false);
    if (e.target.checked) {
      const permissionCode = permissionList.filter(
        (data) => data.id === e.target.value
      )[0];
      setPermission([...permission, permissionCode]);
    } else {
      setPermission(
        permission.filter((permission) => permission.id !== e.target.value)
      );
    }
  };

  const handleConfirm = (e) => {
    e.preventDefault();
    setShow(true);
  };

  const handleCheckAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setPermission(permissionList.map((data) => data));
    if (isCheckAll) {
      setPermission([]);
    }
  };

  const confirmationClose = () => setShow(false);
  return (
    <>
      <PanelForm>
        <PanelCard>
          <Form onSubmit={handleConfirm}>
            <Form.Group className="mb-3" controlId="name">
              <Form.Label>Nama Role</Form.Label>
              <Form.Control
                type="text"
                defaultValue={rolesName}
                onChange={(e) => {setRolesName(e.target.value)}}
                autoComplete="off"
              />
            </Form.Group>

            <Table className='table-sm'>
              <thead>
                <tr>
                  <th>
                    <Form.Check
                      type="checkbox"
                      onChange={handleCheckAll}
                      checked={isCheckAll}
                    />
                  </th>
                  <th>Permission</th>
                </tr>
              </thead>
              <tbody>
                {permissionList.map((item) => (
                  <tr key={item.id}>
                    <td>
                      <Form.Check
                        type="checkbox"
                        value={item.id}
                        onChange={handleOnChange}
                        checked={permission.includes(item)}
                      />
                    </td>
                    <td>{item.name}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Link to={"/role"} className="btn btn-secondary me-2 secondary-color">
              Back
            </Link>
            <Button type="submit" className="primary">
              Update
            </Button>
          </Form>
        </PanelCard>
      </PanelForm>

      <ModalConfirmation
        show={show}
        handleClose={confirmationClose}
        handleSubmit={handleCreate}
        message="Apakah anda yakin?"
      />

      {response.status === 200 && (
        <Toastbar
          message={response.data.msg}
          showToast={showToast}
          setShowToast={setShowToast}
          type={"info"}
        />
      )}
      {response.status === 400 && (
        <Toastbar
          message={response.data.msg}
          showToast={showToast}
          setShowToast={setShowToast}
          type={"warning"}
        />
      )}
    </>
  );
};

export default RolesEdit;
