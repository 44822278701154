import React, { useEffect } from "react";
import ContainerCards, { PanelForm } from "../component/ContainerCards";
import { ModalConfirmation } from "../component/ModalPopup";
import Toastbar, { ToastPopup } from "../component/Toastbar";
import Form from "react-bootstrap/Form";
import axiosApi from "../api/axios";
import { useState } from "react";
import Cards, { PanelCard } from "../component/Cards";
import { useParams } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

const UsersEdit = () => {
  const navigate = useNavigate();

  const [showToast, setShowToast] = useState(false);
  const [show, setShow] = useState(false);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [nimOrNik, setNimOrNik] = useState("");
  const [tanggalLahir, setTanggalLahir] = useState("");
  const [jenisKelamin, setJenisKelamin] = useState("");
  const [response, setResponse] = useState({});
  const { id } = useParams();
  const [userId, setUserId] = useState("");
  const [listRole, setListRole] = useState([]);
  const [roleId, setRoleId] = useState("");

  const handleEdit = async () => {
    try {
      const body = {
        id: userId,
        username,
        email,
        name,
        roleId,
        nimOrNik,
        tanggalLahir,
        jenisKelamin,
      };
      const response = await axiosApi.put(
        "/users/updateUser",
        JSON.stringify(body)
      );
      setResponse(response);
      setShow(false);
      setShowToast(true);
      const sleep = (ms) => new Promise((r) => setTimeout(r, ms));
      await sleep(1000);
      navigate("/user", { replace: true });
    } catch (error) {
      console.log(error);
      setResponse(error.response);
      setShow(false);
      setShowToast(true);
    }
  };

  useEffect(() => {
    const getUsersById = async () => {
      try {
        const response = await axiosApi.get(`/users/${id}`);
        setUserId(response.data.result.id);
        setJenisKelamin(response.data.result.jenis_kelamin);
        setName(response.data.result.name);
        setEmail(response.data.result.email);
        setNimOrNik(response.data.result.nim_or_nik);
        setTanggalLahir(response.data.result.tanggal_lahir);
        setUsername(response.data.result.username);
        setRoleId(response.data.result.role_id);
      } catch (error) {
        console.log(error);
      }
    };

    const getAllRole = async () => {
      try {
        const response = await axiosApi.get("roles/findAll");
        setListRole(response.data.result);
      } catch (error) {
        console.log(error);
      }
    };

    getUsersById();
    getAllRole();
  }, [id]);

  const handleSelectGender = (e) => {
    setJenisKelamin(e.target.value);
  };

  const handleConfirm = (e) => {
    e.preventDefault();
    setShow(true);
  };

  const confirmationClose = () => setShow(false);

  const onSelectRole = (e) => {
    setRoleId(e.target.value);
  };

  return (
    <>
      <PanelForm>
        <PanelCard>
          <Form onSubmit={handleConfirm}>
            <Form.Group className="mb-3" controlId="name">
              <Form.Label>Nama</Form.Label>
              <Form.Control
                type="text"
                onChange={(e) => setName(e.target.value)}
                value={name}
                required
                autoComplete="off"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="nimOrNik">
              <Form.Label>NIM/NIK</Form.Label>
              <Form.Control
                type="text"
                onChange={(e) => setNimOrNik(e.target.value)}
                value={nimOrNik}
                required
                autoComplete="off"
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="username">
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="text"
                defaultValue={username}
                disabled
                autoComplete="off"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="text"
                defaultValue={email}
                disabled
                autoComplete="off"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="role">
              <Form.Label>Role</Form.Label>
              <Form.Select value={roleId} onChange={onSelectRole}>
                {listRole.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3" controlId="tanggalLahir">
              <Form.Label>Tanggal Lahir</Form.Label>
              <Form.Control
                type="date"
                onChange={(e) => setTanggalLahir(e.target.value)}
                value={tanggalLahir}
                required
                autoComplete="off"
              />
            </Form.Group>
            <Form.Label>Jenis kelamin</Form.Label>

            <Form.Check
              type="radio"
              label="Laki-laki"
              value="Laki-laki"
              onChange={handleSelectGender}
              checked={jenisKelamin === "Laki-laki"}
              name="jenisKelamin"
            />
            <Form.Check
              type="radio"
              label="Perempuan"
              value="Perempuan"
              onChange={handleSelectGender}
              checked={jenisKelamin === "Perempuan"}
              name="jenisKelamin"
            />

            <div className="mt-3 mb-3">
              <Link
                to="/user"
                className="btn btn-secondary me-xl-2 secondary-color"
                variant="secondary"
              >
                Back
              </Link>
              <Button variant="primary" className="primary" type="submit">
                Update
              </Button>
            </div>
          </Form>
        </PanelCard>
      </PanelForm>

      <ModalConfirmation
        show={show}
        handleClose={confirmationClose}
        handleSubmit={handleEdit}
        message="Apakah anda yakin mau update?"
      />
      {response?.status && (
        <ToastPopup
          message={response.data.msg}
          showToast={showToast}
          setShowToast={setShowToast}
          response={response?.status}
        />
      )}
    </>
  );
};

export default UsersEdit;
