import React, { useEffect, useState } from 'react'
import Row from 'react-bootstrap/esm/Row';
import { useNavigate, useParams } from 'react-router-dom';
import axiosApi from '../api/axios';
import { ContainerWithCard } from '../component/ContainerCards'
import { FormContainer } from '../component/Forms';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/esm/Col';
import Button from 'react-bootstrap/esm/Button';
import { ModalConfirmation } from '../component/ModalPopup';
import { ToastPopup } from '../component/Toastbar';

const QuestionerView = () => {

    const navigate = useNavigate();

    const { id } = useParams();
    const [kuesioner, setKuesioner] = useState({});
    const [pasien, setPasien] = useState({});
    const [disableLainnya1, setDisableLainnya1] = useState(true);
    const [disableLainnya2, setDisableLainnya2] = useState(true);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [response, setResponse] = useState({})

    useEffect(() => {
        const getKuesioner = async () => {
            try {
                const response = await axiosApi.get(`/kuesioner/findById?id=${id}`)
                setKuesioner(response.data.result);
                setPasien(response.data.pasien);
            } catch (error) {
                console.log(error);
            }
        }
        getKuesioner();
    }, [id])

    const updateData = (e) => {
        setKuesioner({
            ...kuesioner,
            [e.target.name]: e.target.value
        });
    }

    const updateDataLainnya1 = (e) => {
        setKuesioner({
            ...kuesioner,
            [e.target.name]: e.target.value
        });
        if (e.target.value === "Ya") {
            setDisableLainnya1(false);
        } else {
            setDisableLainnya1(true);

        }
    }
    const updateDataLainnya2 = (e) => {
        setKuesioner({
            ...kuesioner,
            [e.target.name]: e.target.value
        });
        if (e.target.value === "Tidak pernah") {
            setDisableLainnya2(true);
        } else {
            setDisableLainnya2(false);

        }
    }

    const onSubmit = async () => {
        try {
            const response = await axiosApi.put('kuesioner/edit', { id: id, data: kuesioner })
            setResponse(response);

            setShowConfirmation(false);
            setShowToast(true);
            const sleep = ms => new Promise(r => setTimeout(r, ms));
            await sleep(1000);

            navigate("/kuesioner", { replace: true });
        } catch (error) {
            setResponse(error.response);
            setShowConfirmation(false);
            setShowToast(true);
        }
    }

    const handleConfirm = (e) => {
        e.preventDefault();
        setShowConfirmation(true);
    }


    const functionButton = async (e) => {
        fetch(`/api/report/create/kuesioner?id_kuesioner=${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(async res => {
            if (res.status === 200) {
                const blob = await res.blob();
                const file = new Blob(
                    [blob],
                    { type: 'application/pdf' }
                );
                //Build a URL from the file
                const fileURL = URL.createObjectURL(file);
                //Open the URL on new Window
                window.open(fileURL);
            }
        })
    }
    return (
        <>
            <ContainerWithCard>
                <Row className='mt-1 mb-3'>
                    <Col xl="12">
                        <Button className='purple float-end' onClick={functionButton}>Download</Button>
                    </Col>
                </Row>
                <Form onSubmit={handleConfirm}>
                    <Row>
                        <FormContainer controlId="name" label="Nama">
                            <Form.Control type='text' disabled defaultValue={pasien.name} />
                        </FormContainer>
                        <FormContainer controlId="name" label="Jenis Kelamin">
                            <Form.Control type='text' disabled defaultValue={pasien.jenis_kelamin} />
                        </FormContainer>
                        <FormContainer controlId="name" label="Umur">
                            <Form.Control type='text' disabled defaultValue={kuesioner.umur} />
                        </FormContainer>
                        <FormContainer controlId="name" label="Tanggal Kuesioner">
                            <Form.Control type='date' onChange={updateData} name="tanggal_kuesioner" required defaultValue={kuesioner.tanggal_kuesioner} disabled />
                        </FormContainer>
                    </Row>

                    {kuesioner.type === "dewasa" && <>
                        <Row className='mt-3'>
                            <h5 className={`text-center mb-3 ${kuesioner.type === "dewasa" ? "kuesioner-title-blue" : "kuesioner-title-yellow"}`}>JUMLAH GIGI YANG DAPAT DIIDENTIFIKASI SECARA MANDIRI</h5>
                            <p>Berapa jumlah gigi asli yang Anda milki saat ini?</p>
                            <hr />

                            <Col xl="12">
                                <Form.Check disabled inline value="Tidak punya" onChange={updateData} name="jumlah_gigi" type='radio' label="Tidak punya" id="q1" checked={kuesioner.jumlah_gigi === "Tidak punya"} />
                                <Form.Check disabled inline value="1 - 9 gigi" onChange={updateData} name="jumlah_gigi" type='radio' label="1 - 9 gigi" id="q12" checked={kuesioner.jumlah_gigi === "1 - 9 gigi"} />
                                <Form.Check disabled inline value="10 - 19 gigi" onChange={updateData} name="jumlah_gigi" type='radio' label="10 - 19 gigi" id="q13" checked={kuesioner.jumlah_gigi === "10 - 19 gigi"} />
                                <Form.Check disabled inline value="20 gigi atau lebih" onChange={updateData} name="jumlah_gigi" type='radio' label="20 gigi atau lebih" id="q14" checked={kuesioner.jumlah_gigi === "20 gigi atau lebih"} />
                            </Col>
                        </Row>
                    </>}

                    <Row className='mt-3'>
                        <h5 className={`text-center mb-3 ${kuesioner.type === "dewasa" ? "kuesioner-title-blue" : "kuesioner-title-yellow"}`}>PENGALAMAN RASA SAKIT/TIDAK NYAMAN PADA GIGI DAN MULUT</h5>
                        <p>Dalam 12 bulan terakhir, apakah Anda pernah mengeluhkan rasa sakit atau
                            tidak nyaman pada gigi atau mulut Anda?</p>
                        <hr />

                        <Col xl="12">

                            <Form.Check disabled inline value="Tidak" onChange={updateData} name="pengalaman_rasa_sakit" type='radio' label="Tidak" id="q21" checked={kuesioner.pengalaman_rasa_sakit === "Tidak"} />
                            <Form.Check disabled inline value="Ya" onChange={updateData} name="pengalaman_rasa_sakit" type='radio' label="Ya" id="q22" checked={kuesioner.pengalaman_rasa_sakit === "Ya"} />
                            <Form.Check disabled inline value="Tidak tahu" onChange={updateData} name="pengalaman_rasa_sakit" type='radio' label="Tidak tahu" id="q23" checked={kuesioner.pengalaman_rasa_sakit === "Tidak tahu"} />
                            <Form.Check disabled inline value="Tidak menjawab" onChange={updateData} name="pengalaman_rasa_sakit" type='radio' label="Tidak menjawab" id="q24" checked={kuesioner.pengalaman_rasa_sakit === "Tidak menjawab"} />
                        </Col>
                    </Row>

                    {kuesioner.type === "dewasa" && <Row className='mt-3'>
                        <h5 className={`text-center mb-3 ${kuesioner.type === "dewasa" ? "kuesioner-title-blue" : "kuesioner-title-yellow"}`}>PENGGUNAAN GIGI PALSU LEPASAN</h5>
                        <p>Apakah Anda menggunakan gigi palsu yang dapat dilepas?</p>
                        <hr />
                        <Col xl="12">
                            <Row>
                                <Form.Label className='col-xl-6 col-lg-6 col-sm-12 col-md-6'>
                                    1. Gigi palsu lepasan sebagian
                                </Form.Label>
                                <Col xl="6" lg="6" sm="12" md="6">
                                    <Form.Check disabled inline value="Ya" onChange={updateData} name="penggunaan_gigi_palsu_lepasan_q1" type='radio' label="Ya" id="q311" checked={kuesioner.penggunaan_gigi_palsu_lepasan_q1 === "Ya"} />
                                    <Form.Check disabled inline value="Tidak" onChange={updateData} name="penggunaan_gigi_palsu_lepasan_q1" type='radio' label="Tidak" id="q312" checked={kuesioner.penggunaan_gigi_palsu_lepasan_q1 === "Tidak"} />
                                    <Form.Check disabled inline value="Tidak Tahu" onChange={updateData} name="penggunaan_gigi_palsu_lepasan_q1" type='radio' label="Tidak Tahu" id="q313" checked={kuesioner.penggunaan_gigi_palsu_lepasan_q1 === "Tidak Tahu"} />
                                </Col>
                            </Row>
                        </Col>
                        <Col xl="12">
                            <Row>
                                <Form.Label className='col-xl-6 col-lg-6 col-sm-12 col-md-6'>
                                    2. Gigi palsu lepasan atas penuh
                                </Form.Label>
                                <Col xl="6" lg="6" sm="12" md="6">
                                    <Form.Check disabled inline value="Ya" onChange={updateData} name="penggunaan_gigi_palsu_lepasan_q2" type='radio' label="Ya" id="q321" checked={kuesioner.penggunaan_gigi_palsu_lepasan_q2 === "Ya"} />
                                    <Form.Check disabled inline value="Tidak" onChange={updateData} name="penggunaan_gigi_palsu_lepasan_q2" type='radio' label="Tidak" id="q322" checked={kuesioner.penggunaan_gigi_palsu_lepasan_q2 === "Tidak"} />
                                    <Form.Check disabled inline value="Tidak Tahu" onChange={updateData} name="penggunaan_gigi_palsu_lepasan_q2" type='radio' label="Tidak Tahu" id="q323" checked={kuesioner.penggunaan_gigi_palsu_lepasan_q2 === "Tidak Tahu"} />
                                </Col>
                            </Row>
                        </Col>
                        <Col xl="12">
                            <Row>
                                <Form.Label className='col-xl-6 col-lg-6 col-sm-12 col-md-6'>
                                    3. Gigi palsu lepasan bawah penuh
                                </Form.Label>
                                <Col xl="6" lg="6" sm="12" md="6">
                                    <Form.Check disabled inline value="Ya" onChange={updateData} name="penggunaan_gigi_palsu_lepasan_q3" type='radio' label="Ya" id="q331" checked={kuesioner.penggunaan_gigi_palsu_lepasan_q3 === "Ya"} />
                                    <Form.Check disabled inline value="Tidak" onChange={updateData} name="penggunaan_gigi_palsu_lepasan_q3" type='radio' label="Tidak" id="q332" checked={kuesioner.penggunaan_gigi_palsu_lepasan_q3 === "Tidak"} />
                                    <Form.Check disabled inline value="Tidak Tahu" onChange={updateData} name="penggunaan_gigi_palsu_lepasan_q3" type='radio' label="Tidak Tahu" id="q333" checked={kuesioner.penggunaan_gigi_palsu_lepasan_q3 === "Tidak Tahu"} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>}

                    <Row className='mt-3'>
                        <h5 className={`text-center mb-3 ${kuesioner.type === "dewasa" ? "kuesioner-title-blue" : "kuesioner-title-yellow"}`}>IDENTIFIKASI MANDIRI KONDISI GIGI DAN GUSI</h5>
                        <p>Bagaimana Anda menggambarkan keadaan gigi dan gusi Anda?</p>
                        <hr />

                        <Col xl="12">
                            <Row>
                                <Form.Label className='col-xl-3 col-lg-3 col-md-12 col-sm-12'>
                                    1. Gigi ?
                                </Form.Label>
                                <Col xl="9" lg="9" sm="12" md="12">
                                    <Form.Check disabled inline value="Sempurna" onChange={updateData} name="identifikasi_mandiri_q1" type='radio' label="Sempurna" id="q411" checked={kuesioner.identifikasi_mandiri_q1 === "Sempurna"} />
                                    <Form.Check disabled inline value="Sangat baik" onChange={updateData} name="identifikasi_mandiri_q1" type='radio' label="Sangat baik" id="q412" checked={kuesioner.identifikasi_mandiri_q1 === "Sangat baik"} />
                                    <Form.Check disabled inline value="Baik" onChange={updateData} name="identifikasi_mandiri_q1" type='radio' label="Baik" id="q413" checked={kuesioner.identifikasi_mandiri_q1 === "Baik"} />
                                    <Form.Check disabled inline value="Rata-rata" onChange={updateData} name="identifikasi_mandiri_q1" type='radio' label="Rata-rata" id="q414" checked={kuesioner.identifikasi_mandiri_q1 === "Rata-rata"} />
                                    <Form.Check disabled inline value="Buruk" onChange={updateData} name="identifikasi_mandiri_q1" type='radio' label="Buruk" id="q415" checked={kuesioner.identifikasi_mandiri_q1 === "Buruk"} />
                                    <Form.Check disabled inline value="Sangat Buruk" onChange={updateData} name="identifikasi_mandiri_q1" type='radio' label="Sangat Buruk" id="q416" checked={kuesioner.identifikasi_mandiri_q1 === "Sangat Buruk"} />
                                </Col>
                            </Row>

                        </Col>
                        <Col xl="12">
                            <Row>
                                <Form.Label className='col-xl-3 col-lg-3 col-md-12 col-sm-12'>
                                    2. Gusi ?
                                </Form.Label>
                                <Col xl="9" lg="9" sm="12" md="12">
                                    <Form.Check disabled inline value="Sempurna" onChange={updateData} name="identifikasi_mandiri_q2" type='radio' label="Sempurna" id="q411" checked={kuesioner.identifikasi_mandiri_q2 === "Sempurna"} />
                                    <Form.Check disabled inline value="Sangat baik" onChange={updateData} name="identifikasi_mandiri_q2" type='radio' label="Sangat baik" id="q422" checked={kuesioner.identifikasi_mandiri_q2 === "Sangat baik"} />
                                    <Form.Check disabled inline value="Baik" onChange={updateData} name="identifikasi_mandiri_q2" type='radio' label="Baik" id="q423" checked={kuesioner.identifikasi_mandiri_q2 === "Baik"} />
                                    <Form.Check disabled inline value="Rata-rata" onChange={updateData} name="identifikasi_mandiri_q2" type='radio' label="Rata-rata" id="q424" checked={kuesioner.identifikasi_mandiri_q2 === "Rata-rata"} />
                                    <Form.Check disabled inline value="Buruk" onChange={updateData} name="identifikasi_mandiri_q2" type='radio' label="Buruk" id="q425" checked={kuesioner.identifikasi_mandiri_q2 === "Buruk"} />
                                    <Form.Check disabled inline value="Sangat Buruk" onChange={updateData} name="identifikasi_mandiri_q2" type='radio' label="Sangat Buruk" id="q426" checked={kuesioner.identifikasi_mandiri_q2 === "Sangat Buruk"} />
                                </Col>
                            </Row>

                        </Col>
                    </Row>

                    <Row className='mt-3'>
                        <h5 className={`text-center mb-3 ${kuesioner.type === "dewasa" ? "kuesioner-title-blue" : "kuesioner-title-yellow"}`}>FREKUENSI MEMBERSIHKAN GIGI</h5>
                        <p>Seberapa sering Anda membersihkan gigi Anda?</p>
                        <hr />
                        <Col xl="12">
                            <Row className="ps-4">
                                <Col xl="6">
                                    <Row>
                                        <Form.Check disabled inline value="Tidak pernah" onChange={updateData} name="frekuensi_membersihkan_gigi" type='radio' label="Tidak pernah" id="q511" checked={kuesioner.frekuensi_membersihkan_gigi === "Tidak pernah"} />
                                        <Form.Check disabled inline value="1 kali sebulan" onChange={updateData} name="frekuensi_membersihkan_gigi" type='radio' label="1 kali sebulan" id="q512" checked={kuesioner.frekuensi_membersihkan_gigi === "1 kali sebulan"} />
                                        <Form.Check disabled inline value="2 - 3 kali sebulan" onChange={updateData} name="frekuensi_membersihkan_gigi" type='radio' label="2 - 3 kali sebulan" id="q513" checked={kuesioner.frekuensi_membersihkan_gigi === "2 - 3 kali sebulan"} />
                                        <Form.Check disabled inline value="1 kali seminggu" onChange={updateData} name="frekuensi_membersihkan_gigi" type='radio' label="1 kali seminggu" id="q514" checked={kuesioner.frekuensi_membersihkan_gigi === "1 kali seminggu"} />
                                    </Row>
                                </Col>
                                <Col xl="6">
                                    <Row>
                                        <Form.Check disabled inline value="2 - 6 kali seminggu" onChange={updateData} name="frekuensi_membersihkan_gigi" type='radio' label="2 - 6 kali seminggu" id="q515" checked={kuesioner.frekuensi_membersihkan_gigi === "2 - 6 kali seminggu"} />
                                        <Form.Check disabled inline value="1 kali sehari" onChange={updateData} name="frekuensi_membersihkan_gigi" type='radio' label="1 kali sehari" id="q516" checked={kuesioner.frekuensi_membersihkan_gigi === "1 kali sehari"} />
                                        <Form.Check disabled inline value="2 kali atau lebih setiap hari" onChange={updateData} name="frekuensi_membersihkan_gigi" type='radio' label="2 kali atau lebih setiap hari" id="q517" checked={kuesioner.frekuensi_membersihkan_gigi === "2 kali atau lebih setiap hari"} />
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row className='mt-3'>
                        <h5 className={`text-center mb-3 ${kuesioner.type === "dewasa" ? "kuesioner-title-blue" : "kuesioner-title-yellow"}`}>PENGGUNAAN BAHAN/PERALATAN PENDUKUNG KEBERSIHAN MULUT</h5>
                        <p>Apakah Anda menggunakan bahan atau peralatan pendukung berikut untuk
                            membersihkan gigi atau mulut Anda?</p>
                        <hr />
                        <Col xl="12">
                            <Row>
                                <Col xl="6">
                                    <Col xl="12">
                                        <Form.Label className='col-xl-4 col-sm-6 col-md-6'>
                                            1. Sikat Gigi
                                        </Form.Label>
                                        <Form.Check disabled inline value="Ya" onChange={updateData} name="penggunaan_bahan_q1" type='radio' label="Ya" id="q611" checked={kuesioner.penggunaan_bahan_q1 === "Ya"} />
                                        <Form.Check disabled inline value="Tidak" onChange={updateData} name="penggunaan_bahan_q1" type='radio' label="Tidak" id="q612" checked={kuesioner.penggunaan_bahan_q1 === "Tidak"} />
                                    </Col>
                                    <Col xl="12">
                                        <Form.Label className='col-xl-4 col-sm-6 col-md-6'>
                                            2. Sikat gigi kayu
                                        </Form.Label>
                                        <Form.Check disabled inline value="Ya" onChange={updateData} name="penggunaan_bahan_q2" type='radio' label="Ya" id="q621" checked={kuesioner.penggunaan_bahan_q2 === "Ya"} />
                                        <Form.Check disabled inline value="Tidak" onChange={updateData} name="penggunaan_bahan_q2" type='radio' label="Tidak" id="q622" checked={kuesioner.penggunaan_bahan_q2 === "Tidak"} />
                                    </Col>
                                    <Col xl="12">
                                        <Form.Label className='col-xl-4 col-sm-6 col-md-6'>
                                            3. Sikat gigi plastik
                                        </Form.Label>
                                        <Form.Check disabled inline value="Ya" onChange={updateData} name="penggunaan_bahan_q3" type='radio' label="Ya" id="q631" checked={kuesioner.penggunaan_bahan_q3 === "Ya"} />
                                        <Form.Check disabled inline value="Tidak" onChange={updateData} name="penggunaan_bahan_q3" type='radio' label="Tidak" id="q632" checked={kuesioner.penggunaan_bahan_q3 === "Tidak"} />
                                    </Col>
                                    <Col xl="12">
                                        <Form.Label className='col-xl-4 col-sm-6 col-md-6'>
                                            4. Benang gigi
                                        </Form.Label>
                                        <Form.Check disabled inline value="Ya" onChange={updateData} name="penggunaan_bahan_q4" type='radio' label="Ya" id="q641" checked={kuesioner.penggunaan_bahan_q4 === "Ya"} />
                                        <Form.Check disabled inline value="Tidak" onChange={updateData} name="penggunaan_bahan_q4" type='radio' label="Tidak" id="q642" checked={kuesioner.penggunaan_bahan_q4 === "Tidak"} />
                                    </Col>
                                </Col>
                                <Col xl="6">
                                    <Col xl="12">
                                        <Form.Label className='col-xl-4 col-sm-6 col-md-6'>
                                            5. Arang
                                        </Form.Label>
                                        <Form.Check disabled inline value="Ya" onChange={updateData} name="penggunaan_bahan_q5" type='radio' label="Ya" id="q651" checked={kuesioner.penggunaan_bahan_q5 === "Ya"} />
                                        <Form.Check disabled inline value="Tidak" onChange={updateData} name="penggunaan_bahan_q5" type='radio' label="Tidak" id="q652" checked={kuesioner.penggunaan_bahan_q5 === "Tidak"} />
                                    </Col>
                                    <Col xl="12">
                                        <Form.Label className='col-xl-4 col-sm-6 col-md-6'>
                                            6. Siwak
                                        </Form.Label>
                                        <Form.Check disabled inline value="Ya" onChange={updateData} name="penggunaan_bahan_q6" type='radio' label="Ya" id="q661" checked={kuesioner.penggunaan_bahan_q6 === "Ya"} />
                                        <Form.Check disabled inline value="Tidak" onChange={updateData} name="penggunaan_bahan_q6" type='radio' label="Tidak" id="q662" checked={kuesioner.penggunaan_bahan_q6 === "Tidak"} />
                                    </Col>
                                    <Col xl="12">
                                        <Form.Label className='col-xl-4 col-sm-6 col-md-6'>
                                            7. Lainnya
                                        </Form.Label>
                                        <Form.Check disabled inline value="Ya" onChange={updateDataLainnya1} name="penggunaan_bahan_q7" type='radio' label="Ya" id="q671" checked={kuesioner.penggunaan_bahan_q7 === "Ya"} />
                                        <Form.Check disabled inline value="Tidak" onChange={updateDataLainnya1} name="penggunaan_bahan_q7" type='radio' label="Tidak" id="q672" checked={kuesioner.penggunaan_bahan_q7 === "Tidak"} />
                                    </Col>
                                    <Col xl="12">
                                        <Form.Group as={Row} className="mb-3" controlId="lainnya">
                                            <Form.Label column xl="4" md="6" sm="6">
                                                Lainnya
                                            </Form.Label>
                                            <Col xl="8" md="6" sm="6">
                                                <Form.Control type='text' onChange={updateData} name="penggunaan_bahan_lainnya" disabled={kuesioner.penggunaan_bahan_lainnya !== "" ? false : disableLainnya1} />
                                            </Col>
                                        </Form.Group>
                                    </Col>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row className='mt-3'>
                        <h5 className={`text-center mb-3 ${kuesioner.type === "dewasa" ? "kuesioner-title-blue" : "kuesioner-title-yellow"}`}>PENGGUNAAN PASTA GIGI MENGANDUNG FLORIDA</h5>
                        <hr />
                        <Col xl="12">
                            <Row>
                                <Form.Label className='col-xl-6 col-lg-6 col-sm-12 col-md-6'>
                                    1. Apakah Anda menggunakan pasta gigi saat membersihkan gigi Anda?
                                </Form.Label>
                                <Col xl="6" lg="6" sm="12" md="6">
                                    <Form.Check disabled inline value="Ya" onChange={updateData} name="penggunaan_pasta_q1" type='radio' label="Ya" id="q711" checked={kuesioner.penggunaan_pasta_q1 === "Ya"} />
                                    <Form.Check disabled inline value="Tidak" onChange={updateData} name="penggunaan_pasta_q1" type='radio' label="Tidak" id="q712" checked={kuesioner.penggunaan_pasta_q1 === "Tidak"} />
                                    <Form.Check disabled inline value="Tidak Tahu" onChange={updateData} name="penggunaan_pasta_q1" type='radio' label="Tidak Tahu" id="713" checked={kuesioner.penggunaan_pasta_q1 === "Tidak Tahu"} />
                                </Col>
                            </Row>
                            <Row>
                                <Form.Label className='col-xl-6 col-lg-6 col-sm-12 col-md-6'>
                                    2.Apakah Anda menggunakan pasta gigi yang
                                    mengandung florida saat membersihkan gigi Anda?
                                </Form.Label>
                                <Col xl="6" lg="6" sm="12" md="6">
                                    <Form.Check disabled inline value="Ya" onChange={updateData} name="penggunaan_pasta_q2" type='radio' label="Ya" id="q721" checked={kuesioner.penggunaan_pasta_q2 === "Ya"} />
                                    <Form.Check disabled inline value="Tidak" onChange={updateData} name="penggunaan_pasta_q2" type='radio' label="Tidak" id="q722" checked={kuesioner.penggunaan_pasta_q2 === "Tidak"} />
                                    <Form.Check disabled inline value="Tidak Tahu" onChange={updateData} name="penggunaan_pasta_q2" type='radio' label="Tidak Tahu" id="723" checked={kuesioner.penggunaan_pasta_q2 === "Tidak Tahu"} />
                                </Col>
                            </Row>

                        </Col>
                    </Row>

                    <Row className='mt-3'>
                        <h5 className={`text-center mb-3 ${kuesioner.type === "dewasa" ? "kuesioner-title-blue" : "kuesioner-title-yellow"}`}>PENGALAMAN PERIKSA KE DOKTER GIGI</h5>
                        <p>Sudah berapa lama Anda tidak ke dokter gigi untuk terakhir kalinya?</p>
                        <hr />
                        <Col xl="12">
                            <Row className="ps-4">
                                <Col xl="6">
                                    <Row>
                                        <Form.Check disabled inline value="< 6 bulan" onChange={updateData} name="pengalaman_periksa" type='radio' label="< 6 bulan" id="q81" checked={kuesioner.pengalaman_periksa === "< 6 bulan"} />
                                        <Form.Check disabled inline value="6 - 12 bulan" onChange={updateData} name="pengalaman_periksa" type='radio' label="6 - 12 bulan" id="q82" checked={kuesioner.pengalaman_periksa === "6 - 12 bulan"} />
                                        <Form.Check disabled inline value="> 1 tahun, < 2 tahun" onChange={updateData} name="pengalaman_periksa" type='radio' label="> 1 tahun, < 2 tahun" id="q83" checked={kuesioner.pengalaman_periksa === "> 1 tahun, < 2 tahun"} />
                                    </Row>
                                </Col>
                                <Col xl="6">
                                    <Row>
                                        <Form.Check disabled inline value="> 1 tahun, < 2 tahun" onChange={updateData} name="pengalaman_periksa" type='radio' label="> 1 tahun, < 2 tahun" id="q84" checked={kuesioner.pengalaman_periksa === "> 1 tahun, < 2 tahun"} />
                                        <Form.Check disabled inline value="5 tahun atau lebih" onChange={updateData} name="pengalaman_periksa" type='radio' label="5 tahun atau lebih" id="q85" checked={kuesioner.pengalaman_periksa === "5 tahun atau lebih"} />
                                        <Form.Check disabled inline value="Tidak pernah periksa" onChange={updateData} name="pengalaman_periksa" type='radio' label="Tidak pernah periksa" id="q86" checked={kuesioner.pengalaman_periksa === "Tidak pernah periksa"} />
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row className='mt-3'>
                        <h5 className={`text-center mb-3 ${kuesioner.type === "dewasa" ? "kuesioner-title-blue" : "kuesioner-title-yellow"}`}>ALASAN PERIKSA KE DOKTER GIGI</h5>
                        <p>Apa alasan Anda periksa ke dokter gigi?</p>
                        <hr />
                        <Col xl="12">
                            <Row className="ps-4">
                                <Form.Check disabled inline value="Konsultasi" onChange={updateData} name="alasan_periksa" type='radio' label="Konsultasi" id="q91" checked={kuesioner.alasan_periksa === "Konsultasi"} />
                                <Form.Check disabled inline value="Sakit atau mengalami masalah pada gigi, gusi, atau mulut" onChange={updateData} name="alasan_periksa" type='radio' label="Sakit atau mengalami masalah pada gigi, gusi, atau mulut" id="q92" checked={kuesioner.alasan_periksa === "Sakit atau mengalami masalah pada gigi, gusi, atau mulut"} />
                                <Form.Check disabled inline value="Pengobatan atau konsultasi paska pengobatan" onChange={updateData} name="alasan_periksa" type='radio' label="Pengobatan atau konsultasi paska pengobatan" id="q93" checked={kuesioner.alasan_periksa === "Pengobatan atau konsultasi paska pengobatan"} />
                                <Form.Check disabled inline value="Check Up (pengecekkan) atau pemeriksaan rutin" onChange={updateData} name="alasan_periksa" type='radio' label="Check Up (pengecekkan) atau pemeriksaan rutin" id="q94" checked={kuesioner.alasan_periksa === "Check Up (pengecekkan) atau pemeriksaan rutin"} />
                                <Form.Check disabled inline value="Tidak tahu atau tidak ingat" onChange={updateData} name="alasan_periksa" type='radio' label="Tidak tahu atau tidak ingat" id="q95" checked={kuesioner.alasan_periksa === "Tidak tahu atau tidak ingat"} />

                            </Row>
                        </Col>
                    </Row>

                    <Row className='mt-3'>
                        <h5 className={`text-center mb-3 ${kuesioner.type === "dewasa" ? "kuesioner-title-blue" : "kuesioner-title-yellow"}`}>PENGALAMAN TIDAK MENYENANGKAN MENGURANGI
                            KUALITAS HIDUP TERKAIT MASALAH PADA AREA MULUT</h5>
                        <p>Karena kondisi gigi dan mulut Anda, seberapa sering Anda mengalami
                            masalah berikut selama 12 bulan terakhir?</p>
                        <hr />
                        <Col xl="12">
                            <Row>
                                <Form.Label className='col-xl-12 col-lg-12 col-sm-12 col-md-12'>
                                    1. Kesulitan menggigit makanan
                                </Form.Label>
                                <Col xl="12" lg="12" sm="12" md="12" className="pb-2">
                                    <Row className="ps-4">
                                        <Col xl="6" lg="6" md="12" sm="12">
                                            <Row>
                                                <Form.Check disabled inline value="Tidak tahu" onChange={updateData} name="pengalaman_tidak_menyenangkan_q1" type='radio' label="Tidak tahu" id="q1011" checked={kuesioner.pengalaman_tidak_menyenangkan_q1 === "Tidak tahu"} />
                                                <Form.Check disabled inline value="Tidak" onChange={updateData} name="pengalaman_tidak_menyenangkan_q1" type='radio' label="Tidak" id="q1012" checked={kuesioner.pengalaman_tidak_menyenangkan_q1 === "Tidak"} />
                                                <Form.Check disabled inline value="Kadang" onChange={updateData} name="pengalaman_tidak_menyenangkan_q1" type='radio' label="Kadang" id="q1013" checked={kuesioner.pengalaman_tidak_menyenangkan_q1 === "Kadang"} />
                                            </Row>

                                        </Col>
                                        <Col xl="6" lg="6" md="12" sm="12">
                                            <Row>
                                                <Form.Check disabled inline value="Cukup sering" onChange={updateData} name="pengalaman_tidak_menyenangkan_q1" type='radio' label="Cukup sering" id="q1014" checked={kuesioner.pengalaman_tidak_menyenangkan_q1 === "Cukup sering"} />
                                                <Form.Check disabled inline value="Sangat sering" onChange={updateData} name="pengalaman_tidak_menyenangkan_q1" type='radio' label="Sangat sering" id="q1015" checked={kuesioner.pengalaman_tidak_menyenangkan_q1 === "Sangat sering"} />
                                            </Row>

                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col xl="12">
                            <Row>
                                <Form.Label className='col-xl-12 col-lg-12 col-sm-12 col-md-12'>
                                    2. Kesulitan mengunyah makanan
                                </Form.Label>
                                <Col xl="12" lg="12" sm="12" md="12" className="pb-2">
                                    <Row className="ps-4">
                                        <Col xl="6" lg="6" md="12" sm="12">
                                            <Row>
                                                <Form.Check disabled inline value="Tidak tahu" onChange={updateData} name="pengalaman_tidak_menyenangkan_q2" type='radio' label="Tidak tahu" id="q1021" checked={kuesioner.pengalaman_tidak_menyenangkan_q2 === "Tidak tahu"} />
                                                <Form.Check disabled inline value="Tidak" onChange={updateData} name="pengalaman_tidak_menyenangkan_q2" type='radio' label="Tidak" id="q1022" checked={kuesioner.pengalaman_tidak_menyenangkan_q2 === "Tidak"} />
                                                <Form.Check disabled inline value="Kadang" onChange={updateData} name="pengalaman_tidak_menyenangkan_q2" type='radio' label="Kadang" id="q1023" checked={kuesioner.pengalaman_tidak_menyenangkan_q2 === "Kadang"} />
                                            </Row>

                                        </Col>
                                        <Col xl="6" lg="6" md="12" sm="12">
                                            <Row>
                                                <Form.Check disabled inline value="Cukup sering" onChange={updateData} name="pengalaman_tidak_menyenangkan_q2" type='radio' label="Cukup sering" id="q1024" checked={kuesioner.pengalaman_tidak_menyenangkan_q2 === "Cukup sering"} />
                                                <Form.Check disabled inline value="Sangat sering" onChange={updateData} name="pengalaman_tidak_menyenangkan_q2" type='radio' label="Sangat sering" id="q1025" checked={kuesioner.pengalaman_tidak_menyenangkan_q2 === "Sangat sering"} />
                                            </Row>

                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col xl="12">
                            <Row>
                                <Form.Label className='col-xl-12 col-lg-12 col-sm-12 col-md-12'>
                                    3. Kesulitan berbicara
                                </Form.Label>
                                <Col xl="12" lg="12" sm="12" md="12" className="pb-2">
                                    <Row className="ps-4">
                                        <Col xl="6" lg="6" md="12" sm="12">
                                            <Row>
                                                <Form.Check disabled inline value="Tidak tahu" onChange={updateData} name="pengalaman_tidak_menyenangkan_q3" type='radio' label="Tidak tahu" id="q1031" checked={kuesioner.pengalaman_tidak_menyenangkan_q3 === "Tidak tahu"} />
                                                <Form.Check disabled inline value="Tidak" onChange={updateData} name="pengalaman_tidak_menyenangkan_q3" type='radio' label="Tidak" id="q1032" checked={kuesioner.pengalaman_tidak_menyenangkan_q3 === "Tidak"} />
                                                <Form.Check disabled inline value="Kadang" onChange={updateData} name="pengalaman_tidak_menyenangkan_q3" type='radio' label="Kadang" id="q1033" checked={kuesioner.pengalaman_tidak_menyenangkan_q3 === "Kadang"} />
                                            </Row>

                                        </Col>
                                        <Col xl="6" lg="6" md="12" sm="12">
                                            <Row>
                                                <Form.Check disabled inline value="Cukup sering" onChange={updateData} name="pengalaman_tidak_menyenangkan_q3" type='radio' label="Cukup sering" id="q1034" checked={kuesioner.pengalaman_tidak_menyenangkan_q3 === "Cukup sering"} />
                                                <Form.Check disabled inline value="Sangat sering" onChange={updateData} name="pengalaman_tidak_menyenangkan_q3" type='radio' label="Sangat sering" id="q1035" checked={kuesioner.pengalaman_tidak_menyenangkan_q3 === "Sangat sering"} />
                                            </Row>

                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col xl="12">
                            <Row>
                                <Form.Label className='col-xl-12 col-lg-12 col-sm-12 col-md-12'>
                                    4. Mulut kering
                                </Form.Label>
                                <Col xl="12" lg="12" sm="12" md="12" className="pb-2">
                                    <Row className="ps-4">
                                        <Col xl="6" lg="6" md="12" sm="12">
                                            <Row>
                                                <Form.Check disabled inline value="Tidak tahu" onChange={updateData} name="pengalaman_tidak_menyenangkan_q4" type='radio' label="Tidak tahu" id="q1041" checked={kuesioner.pengalaman_tidak_menyenangkan_q4 === "Tidak tahu"} />
                                                <Form.Check disabled inline value="Tidak" onChange={updateData} name="pengalaman_tidak_menyenangkan_q4" type='radio' label="Tidak" id="q1042" checked={kuesioner.pengalaman_tidak_menyenangkan_q4 === "Tidak"} />
                                                <Form.Check disabled inline value="Kadang" onChange={updateData} name="pengalaman_tidak_menyenangkan_q4" type='radio' label="Kadang" id="q1043" checked={kuesioner.pengalaman_tidak_menyenangkan_q4 === "Kadang"} />
                                            </Row>

                                        </Col>
                                        <Col xl="6" lg="6" md="12" sm="12">
                                            <Row>
                                                <Form.Check disabled inline value="Cukup sering" onChange={updateData} name="pengalaman_tidak_menyenangkan_q4" type='radio' label="Cukup sering" id="q1044" checked={kuesioner.pengalaman_tidak_menyenangkan_q4 === "Cukup sering"} />
                                                <Form.Check disabled inline value="Sangat sering" onChange={updateData} name="pengalaman_tidak_menyenangkan_q4" type='radio' label="Sangat sering" id="q1045" checked={kuesioner.pengalaman_tidak_menyenangkan_q4 === "Sangat sering"} />
                                            </Row>

                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col xl="12">
                            <Row>
                                <Form.Label className='col-xl-12 col-lg-12 col-sm-12 col-md-12'>
                                    5. Merasa malu terhadap kondisi gigi Anda
                                </Form.Label>
                                <Col xl="12" lg="12" sm="12" md="12" className="pb-2">
                                    <Row className="ps-4">
                                        <Col xl="6" lg="6" md="12" sm="12">
                                            <Row>
                                                <Form.Check disabled inline value="Tidak tahu" onChange={updateData} name="pengalaman_tidak_menyenangkan_q5" type='radio' label="Tidak tahu" id="q1051" checked={kuesioner.pengalaman_tidak_menyenangkan_q5 === "Tidak tahu"} />
                                                <Form.Check disabled inline value="Tidak" onChange={updateData} name="pengalaman_tidak_menyenangkan_q5" type='radio' label="Tidak" id="q1052" checked={kuesioner.pengalaman_tidak_menyenangkan_q5 === "Tidak"} />
                                                <Form.Check disabled inline value="Kadang" onChange={updateData} name="pengalaman_tidak_menyenangkan_q5" type='radio' label="Kadang" id="q1053" checked={kuesioner.pengalaman_tidak_menyenangkan_q5 === "Kadang"} />
                                            </Row>

                                        </Col>
                                        <Col xl="6" lg="6" md="12" sm="12">
                                            <Row>
                                                <Form.Check disabled inline value="Cukup sering" onChange={updateData} name="pengalaman_tidak_menyenangkan_q5" type='radio' label="Cukup sering" id="q1054" checked={kuesioner.pengalaman_tidak_menyenangkan_q5 === "Cukup sering"} />
                                                <Form.Check disabled inline value="Sangat sering" onChange={updateData} name="pengalaman_tidak_menyenangkan_q5" type='radio' label="Sangat sering" id="q1055" checked={kuesioner.pengalaman_tidak_menyenangkan_q5 === "Sangat sering"} />
                                            </Row>

                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col xl="12">
                            <Row>
                                <Form.Label className='col-xl-12 col-lg-12 col-sm-12 col-md-12'>
                                    6. Teman atau orang sekitar sering mengejek
                                    Anda karena gigi Anda
                                </Form.Label>
                                <Col xl="12" lg="12" sm="12" md="12" className="pb-2">
                                    <Row className="ps-4">
                                        <Col xl="6" lg="6" md="12" sm="12">
                                            <Row>
                                                <Form.Check disabled inline value="Tidak tahu" onChange={updateData} name="pengalaman_tidak_menyenangkan_q6" type='radio' label="Tidak tahu" id="q1061" checked={kuesioner.pengalaman_tidak_menyenangkan_q6 === "Tidak tahu"} />
                                                <Form.Check disabled inline value="Tidak" onChange={updateData} name="pengalaman_tidak_menyenangkan_q6" type='radio' label="Tidak" id="q1062" checked={kuesioner.pengalaman_tidak_menyenangkan_q6 === "Tidak"} />
                                                <Form.Check disabled inline value="Kadang" onChange={updateData} name="pengalaman_tidak_menyenangkan_q6" type='radio' label="Kadang" id="q1063" checked={kuesioner.pengalaman_tidak_menyenangkan_q6 === "Kadang"} />
                                            </Row>

                                        </Col>
                                        <Col xl="6" lg="6" md="12" sm="12">
                                            <Row>
                                                <Form.Check disabled inline value="Cukup sering" onChange={updateData} name="pengalaman_tidak_menyenangkan_q6" type='radio' label="Cukup sering" id="q1064" checked={kuesioner.pengalaman_tidak_menyenangkan_q6 === "Cukup sering"} />
                                                <Form.Check disabled inline value="Sangat sering" onChange={updateData} name="pengalaman_tidak_menyenangkan_q6" type='radio' label="Sangat sering" id="q1065" checked={kuesioner.pengalaman_tidak_menyenangkan_q6 === "Sangat sering"} />
                                            </Row>

                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col xl="12">
                            <Row>
                                <Form.Label className='col-xl-12 col-lg-12 col-sm-12 col-md-12'>
                                    7. Merasa kaku atau tegang karena masalah
                                    gigi dan mulut Anda
                                </Form.Label>
                                <Col xl="12" lg="12" sm="12" md="12" className="pb-2">
                                    <Row className="ps-4">
                                        <Col xl="6" lg="6" md="12" sm="12">
                                            <Row>
                                                <Form.Check disabled inline value="Tidak tahu" onChange={updateData} name="pengalaman_tidak_menyenangkan_q7" type='radio' label="Tidak tahu" id="q1071" checked={kuesioner.pengalaman_tidak_menyenangkan_q7 === "Tidak tahu"} />
                                                <Form.Check disabled inline value="Tidak" onChange={updateData} name="pengalaman_tidak_menyenangkan_q7" type='radio' label="Tidak" id="q1072" checked={kuesioner.pengalaman_tidak_menyenangkan_q7 === "Tidak"} />
                                                <Form.Check disabled inline value="Kadang" onChange={updateData} name="pengalaman_tidak_menyenangkan_q7" type='radio' label="Kadang" id="q1073" checked={kuesioner.pengalaman_tidak_menyenangkan_q7 === "Kadang"} />
                                            </Row>

                                        </Col>
                                        <Col xl="6" lg="6" md="12" sm="12">
                                            <Row>
                                                <Form.Check disabled inline value="Cukup sering" onChange={updateData} name="pengalaman_tidak_menyenangkan_q7" type='radio' label="Cukup sering" id="q1074" checked={kuesioner.pengalaman_tidak_menyenangkan_q7 === "Cukup sering"} />
                                                <Form.Check disabled inline value="Sangat sering" onChange={updateData} name="pengalaman_tidak_menyenangkan_q7" type='radio' label="Sangat sering" id="q1075" checked={kuesioner.pengalaman_tidak_menyenangkan_q7 === "Sangat sering"} />
                                            </Row>

                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col xl="12">
                            <Row>
                                <Form.Label className='col-xl-12 col-lg-12 col-sm-12 col-md-12'>
                                    8. Menghindari senyum karena kondisi gigi Anda
                                </Form.Label>
                                <Col xl="12" lg="12" sm="12" md="12" className="pb-2">
                                    <Row className="ps-4">
                                        <Col xl="6" lg="6" md="12" sm="12">
                                            <Row>
                                                <Form.Check disabled inline value="Tidak tahu" onChange={updateData} name="pengalaman_tidak_menyenangkan_q8" type='radio' label="Tidak tahu" id="q1081" checked={kuesioner.pengalaman_tidak_menyenangkan_q8 === "Tidak tahu"} />
                                                <Form.Check disabled inline value="Tidak" onChange={updateData} name="pengalaman_tidak_menyenangkan_q8" type='radio' label="Tidak" id="q1082" checked={kuesioner.pengalaman_tidak_menyenangkan_q8 === "Tidak"} />
                                                <Form.Check disabled inline value="Kadang" onChange={updateData} name="pengalaman_tidak_menyenangkan_q8" type='radio' label="Kadang" id="q1083" checked={kuesioner.pengalaman_tidak_menyenangkan_q8 === "Kadang"} />
                                            </Row>

                                        </Col>
                                        <Col xl="6" lg="6" md="12" sm="12">
                                            <Row>
                                                <Form.Check disabled inline value="Cukup sering" onChange={updateData} name="pengalaman_tidak_menyenangkan_q8" type='radio' label="Cukup sering" id="q1084" checked={kuesioner.pengalaman_tidak_menyenangkan_q8 === "Cukup sering"} />
                                                <Form.Check disabled inline value="Sangat sering" onChange={updateData} name="pengalaman_tidak_menyenangkan_q8" type='radio' label="Sangat sering" id="q1085" checked={kuesioner.pengalaman_tidak_menyenangkan_q8 === "Sangat sering"} />
                                            </Row>

                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col xl="12">
                            <Row>
                                <Form.Label className='col-xl-12 col-lg-12 col-sm-12 col-md-12'>
                                    9. Kualitas tidur terganggu
                                </Form.Label>
                                <Col xl="12" lg="12" sm="12" md="12" className="pb-2">
                                    <Row className="ps-4">
                                        <Col xl="6" lg="6" md="12" sm="12">
                                            <Row>
                                                <Form.Check disabled inline value="Tidak tahu" onChange={updateData} name="pengalaman_tidak_menyenangkan_q9" type='radio' label="Tidak tahu" id="q1091" checked={kuesioner.pengalaman_tidak_menyenangkan_q9 === "Tidak tahu"} />
                                                <Form.Check disabled inline value="Tidak" onChange={updateData} name="pengalaman_tidak_menyenangkan_q9" type='radio' label="Tidak" id="q1092" checked={kuesioner.pengalaman_tidak_menyenangkan_q9 === "Tidak"} />
                                                <Form.Check disabled inline value="Kadang" onChange={updateData} name="pengalaman_tidak_menyenangkan_q9" type='radio' label="Kadang" id="q1093" checked={kuesioner.pengalaman_tidak_menyenangkan_q9 === "Kadang"} />
                                            </Row>

                                        </Col>
                                        <Col xl="6" lg="6" md="12" sm="12">
                                            <Row>
                                                <Form.Check disabled inline value="Cukup sering" onChange={updateData} name="pengalaman_tidak_menyenangkan_q9" type='radio' label="Cukup sering" id="q1094" checked={kuesioner.pengalaman_tidak_menyenangkan_q9 === "Cukup sering"} />
                                                <Form.Check disabled inline value="Sangat sering" onChange={updateData} name="pengalaman_tidak_menyenangkan_q9" type='radio' label="Sangat sering" id="q1095" checked={kuesioner.pengalaman_tidak_menyenangkan_q9 === "Sangat sering"} />
                                            </Row>

                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col xl="12">
                            <Row>
                                <Form.Label className='col-xl-12 col-lg-12 col-sm-12 col-md-12'>
                                    10. Mengambil cuti kerja
                                </Form.Label>
                                <Col xl="12" lg="12" sm="12" md="12" className="pb-2">
                                    <Row className="ps-4">
                                        <Col xl="6" lg="6" md="12" sm="12">
                                            <Row>
                                                <Form.Check disabled inline value="Tidak tahu" onChange={updateData} name="pengalaman_tidak_menyenangkan_q10" type='radio' label="Tidak tahu" id="q10101" checked={kuesioner.pengalaman_tidak_menyenangkan_q10 === "Tidak tahu"} />
                                                <Form.Check disabled inline value="Tidak" onChange={updateData} name="pengalaman_tidak_menyenangkan_q10" type='radio' label="Tidak" id="q10102" checked={kuesioner.pengalaman_tidak_menyenangkan_q10 === "Tidak"} />
                                                <Form.Check disabled inline value="Kadang" onChange={updateData} name="pengalaman_tidak_menyenangkan_q10" type='radio' label="Kadang" id="q10103" checked={kuesioner.pengalaman_tidak_menyenangkan_q10 === "Kadang"} />
                                            </Row>

                                        </Col>
                                        <Col xl="6" lg="6" md="12" sm="12">
                                            <Row>
                                                <Form.Check disabled inline value="Cukup sering" onChange={updateData} name="pengalaman_tidak_menyenangkan_q10" type='radio' label="Cukup sering" id="q10104" checked={kuesioner.pengalaman_tidak_menyenangkan_q10 === "Cukup sering"} />
                                                <Form.Check disabled inline value="Sangat sering" onChange={updateData} name="pengalaman_tidak_menyenangkan_q10" type='radio' label="Sangat sering" id="q10105" checked={kuesioner.pengalaman_tidak_menyenangkan_q10 === "Sangat sering"} />
                                            </Row>

                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col xl="12">
                            <Row>
                                <Form.Label className='col-xl-12 col-lg-12 col-sm-12 col-md-12'>
                                    11. Kesulitan melakukan aktifitas rutin
                                </Form.Label>
                                <Col xl="12" lg="12" sm="12" md="12" className="pb-2">
                                    <Row className="ps-4">
                                        <Col xl="6" lg="6" md="12" sm="12">
                                            <Row>
                                                <Form.Check disabled inline value="Tidak tahu" onChange={updateData} name="pengalaman_tidak_menyenangkan_q11" type='radio' label="Tidak tahu" id="q10111" checked={kuesioner.pengalaman_tidak_menyenangkan_q11 === "Tidak tahu"} />
                                                <Form.Check disabled inline value="Tidak" onChange={updateData} name="pengalaman_tidak_menyenangkan_q11" type='radio' label="Tidak" id="q10112" checked={kuesioner.pengalaman_tidak_menyenangkan_q11 === "Tidak"} />
                                                <Form.Check disabled inline value="Kadang" onChange={updateData} name="pengalaman_tidak_menyenangkan_q11" type='radio' label="Kadang" id="q10113" checked={kuesioner.pengalaman_tidak_menyenangkan_q11 === "Kadang"} />
                                            </Row>

                                        </Col>
                                        <Col xl="6" lg="6" md="12" sm="12">
                                            <Row>
                                                <Form.Check disabled inline value="Cukup sering" onChange={updateData} name="pengalaman_tidak_menyenangkan_q11" type='radio' label="Cukup sering" id="q10114" checked={kuesioner.pengalaman_tidak_menyenangkan_q11 === "Cukup sering"} />
                                                <Form.Check disabled inline value="Sangat sering" onChange={updateData} name="pengalaman_tidak_menyenangkan_q11" type='radio' label="Sangat sering" id="q10115" checked={kuesioner.pengalaman_tidak_menyenangkan_q11 === "Sangat sering"} />
                                            </Row>

                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col xl="12">
                            <Row>
                                <Form.Label className='col-xl-12 col-lg-12 col-sm-12 col-md-12'>
                                    12. Merasa tidak toleransi terhada orang lain yang
                                    mendekat
                                </Form.Label>
                                <Col xl="12" lg="12" sm="12" md="12" className="pb-2">
                                    <Row className="ps-4">
                                        <Col xl="6" lg="6" md="12" sm="12">
                                            <Row>
                                                <Form.Check disabled inline value="Tidak tahu" onChange={updateData} name="pengalaman_tidak_menyenangkan_q12" type='radio' label="Tidak tahu" id="q10121" checked={kuesioner.pengalaman_tidak_menyenangkan_q12 === "Tidak tahu"} />
                                                <Form.Check disabled inline value="Tidak" onChange={updateData} name="pengalaman_tidak_menyenangkan_q12" type='radio' label="Tidak" id="q1012" checked={kuesioner.pengalaman_tidak_menyenangkan_q12 === "Tidak"} />
                                                <Form.Check disabled inline value="Kadang" onChange={updateData} name="pengalaman_tidak_menyenangkan_q12" type='radio' label="Kadang" id="q1013" checked={kuesioner.pengalaman_tidak_menyenangkan_q12 === "Kadang"} />
                                            </Row>

                                        </Col>
                                        <Col xl="6" lg="6" md="12" sm="12">
                                            <Row>
                                                <Form.Check disabled inline value="Cukup sering" onChange={updateData} name="pengalaman_tidak_menyenangkan_q12" type='radio' label="Cukup sering" id="q10124" checked={kuesioner.pengalaman_tidak_menyenangkan_q12 === "Cukup sering"} />
                                                <Form.Check disabled inline value="Sangat sering" onChange={updateData} name="pengalaman_tidak_menyenangkan_q12" type='radio' label="Sangat sering" id="q10125" checked={kuesioner.pengalaman_tidak_menyenangkan_q12 === "Sangat sering"} />
                                            </Row>

                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col xl="12">
                            <Row>
                                <Form.Label className='col-xl-12 col-lg-12 col-sm-12 col-md-12'>
                                    13. Mengurangi kegiatan sosial
                                </Form.Label>
                                <Col xl="12" lg="12" sm="12" md="12" className="pb-2">
                                    <Row className="ps-4">
                                        <Col xl="6" lg="6" md="12" sm="12">
                                            <Row>
                                                <Form.Check disabled inline value="Tidak tahu" onChange={updateData} name="pengalaman_tidak_menyenangkan_q13" type='radio' label="Tidak tahu" id="q10131" checked={kuesioner.pengalaman_tidak_menyenangkan_q13 === "Tidak tahu"} />
                                                <Form.Check disabled inline value="Tidak" onChange={updateData} name="pengalaman_tidak_menyenangkan_q13" type='radio' label="Tidak" id="q10132" checked={kuesioner.pengalaman_tidak_menyenangkan_q13 === "Tidak"} />
                                                <Form.Check disabled inline value="Kadang" onChange={updateData} name="pengalaman_tidak_menyenangkan_q13" type='radio' label="Kadang" id="q10133" checked={kuesioner.pengalaman_tidak_menyenangkan_q13 === "Kadang"} />
                                            </Row>

                                        </Col>
                                        <Col xl="6" lg="6" md="12" sm="12">
                                            <Row>
                                                <Form.Check disabled inline value="Cukup sering" onChange={updateData} name="pengalaman_tidak_menyenangkan_q13" type='radio' label="Cukup sering" id="q10134" checked={kuesioner.pengalaman_tidak_menyenangkan_q13 === "Cukup sering"} />
                                                <Form.Check disabled inline value="Sangat sering" onChange={updateData} name="pengalaman_tidak_menyenangkan_q13" type='radio' label="Sangat sering" id="q10135" checked={kuesioner.pengalaman_tidak_menyenangkan_q13 === "Sangat sering"} />
                                            </Row>

                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className='mt-3'>
                        <h5 className={`text-center mb-3 ${kuesioner.type === "dewasa" ? "kuesioner-title-blue" : "kuesioner-title-yellow"}`}>MENGKONSUMSI MAKANAN DAN MINUMAN MENGANDUNG GULA</h5>
                        <p>Seberapa sering Anda mengkonsumsi makanan atau minuman berikut,
                            walaupun dalam jumlah yang sedikit?</p>
                        <hr />
                        <Col xl="12">
                            <Row>
                                <Form.Label className='col-xl-12 col-lg-12 col-sm-12 col-md-12'>
                                    1. Buahan segar
                                </Form.Label>
                                <Col xl="12" lg="12" sm="12" md="12" className="pb-2">
                                    <Row className="ps-4">
                                        <Col xl="6" lg="6" md="12" sm="12">
                                            <Row>
                                                <Form.Check disabled inline value="Tidak pernah" onChange={updateData} name="mengkonsumsi_makanan_minuman_q1" type='radio' label="Tidak pernah" id="q1111" checked={kuesioner.mengkonsumsi_makanan_minuman_q1 === "Tidak pernah"} />
                                                <Form.Check disabled inline value="Beberapa kali sebulan" onChange={updateData} name="mengkonsumsi_makanan_minuman_q1" type='radio' label="Beberapa kali sebulan" id="q1112" checked={kuesioner.mengkonsumsi_makanan_minuman_q1 === "Beberapa kali sebulan"} />
                                                <Form.Check disabled inline value="1 kali seminggu" onChange={updateData} name="mengkonsumsi_makanan_minuman_q1" type='radio' label="1 kali seminggu" id="q1113" checked={kuesioner.mengkonsumsi_makanan_minuman_q1 === "1 kali seminggu"} />
                                            </Row>
                                        </Col>
                                        <Col xl="6" lg="6" md="12" sm="12">
                                            <Row>
                                                <Form.Check disabled inline value="Beberapa kali seminggu" onChange={updateData} name="mengkonsumsi_makanan_minuman_q1" type='radio' label="Beberapa kali seminggu" id="q1114" checked={kuesioner.mengkonsumsi_makanan_minuman_q1 === "Beberapa kali seminggu"} />
                                                <Form.Check disabled inline value="Setiap hari" onChange={updateData} name="mengkonsumsi_makanan_minuman_q1" type='radio' label="Setiap hari" id="q1115" checked={kuesioner.mengkonsumsi_makanan_minuman_q1 === "Setiap hari"} />
                                                <Form.Check disabled inline value="Beberapa kali sehari" onChange={updateData} name="mengkonsumsi_makanan_minuman_q1" type='radio' label="Beberapa kali sehari" id="q1116" checked={kuesioner.mengkonsumsi_makanan_minuman_q1 === "Beberapa kali sehari"} />
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col xl="12">
                            <Row>
                                <Form.Label className='col-xl-12 col-lg-12 col-sm-12 col-md-12'>
                                    2. Biskuit, kue, kue dengan krim
                                </Form.Label>
                                <Col xl="12" lg="12" sm="12" md="12" className="pb-2">
                                    <Row className="ps-4">
                                        <Col xl="6" lg="6" md="12" sm="12">
                                            <Row>
                                                <Form.Check disabled inline value="Tidak pernah" onChange={updateData} name="mengkonsumsi_makanan_minuman_q2" type='radio' label="Tidak pernah" id="q1121" checked={kuesioner.mengkonsumsi_makanan_minuman_q2 === "Tidak pernah"} />
                                                <Form.Check disabled inline value="Beberapa kali sebulan" onChange={updateData} name="mengkonsumsi_makanan_minuman_q2" type='radio' label="Beberapa kali sebulan" id="q1122" checked={kuesioner.mengkonsumsi_makanan_minuman_q2 === "Beberapa kali sebulan"} />
                                                <Form.Check disabled inline value="1 kali seminggu" onChange={updateData} name="mengkonsumsi_makanan_minuman_q2" type='radio' label="1 kali seminggu" id="q1113" checked={kuesioner.mengkonsumsi_makanan_minuman_q2 === "1 kali seminggu"} />
                                            </Row>
                                        </Col>
                                        <Col xl="6" lg="6" md="12" sm="12">
                                            <Row>
                                                <Form.Check disabled inline value="Beberapa kali seminggu" onChange={updateData} name="mengkonsumsi_makanan_minuman_q2" type='radio' label="Beberapa kali seminggu" id="q1124" checked={kuesioner.mengkonsumsi_makanan_minuman_q2 === "Beberapa kali seminggu"} />
                                                <Form.Check disabled inline value="Setiap hari" onChange={updateData} name="mengkonsumsi_makanan_minuman_q2" type='radio' label="Setiap hari" id="q1125" checked={kuesioner.mengkonsumsi_makanan_minuman_q2 === "Setiap hari"} />
                                                <Form.Check disabled inline value="Beberapa kali sehari" onChange={updateData} name="mengkonsumsi_makanan_minuman_q2" type='radio' label="Beberapa kali sehari" id="q1126" checked={kuesioner.mengkonsumsi_makanan_minuman_q2 === "Beberapa kali sehari"} />
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col xl="12">
                            <Row>
                                <Form.Label className='col-xl-12 col-lg-12 col-sm-12 col-md-12'>
                                    3. Pie atau roti manis
                                </Form.Label>
                                <Col xl="12" lg="12" sm="12" md="12" className="pb-2">
                                    <Row className="ps-4">
                                        <Col xl="6" lg="6" md="12" sm="12">
                                            <Row>
                                                <Form.Check disabled inline value="Tidak pernah" onChange={updateData} name="mengkonsumsi_makanan_minuman_q3" type='radio' label="Tidak pernah" id="q1131" checked={kuesioner.mengkonsumsi_makanan_minuman_q3 === "Tidak pernah"} />
                                                <Form.Check disabled inline value="Beberapa kali sebulan" onChange={updateData} name="mengkonsumsi_makanan_minuman_q3" type='radio' label="Beberapa kali sebulan" id="q1132" checked={kuesioner.mengkonsumsi_makanan_minuman_q3 === "Beberapa kali sebulan"} />
                                                <Form.Check disabled inline value="1 kali seminggu" onChange={updateData} name="mengkonsumsi_makanan_minuman_q3" type='radio' label="1 kali seminggu" id="q1133" checked={kuesioner.mengkonsumsi_makanan_minuman_q3 === "1 kali seminggu"} />
                                            </Row>
                                        </Col>
                                        <Col xl="6" lg="6" md="12" sm="12">
                                            <Row>
                                                <Form.Check disabled inline value="Beberapa kali seminggu" onChange={updateData} name="mengkonsumsi_makanan_minuman_q3" type='radio' label="Beberapa kali seminggu" id="q1134" checked={kuesioner.mengkonsumsi_makanan_minuman_q3 === "Beberapa kali seminggu"} />
                                                <Form.Check disabled inline value="Setiap hari" onChange={updateData} name="mengkonsumsi_makanan_minuman_q3" type='radio' label="Setiap hari" id="q1135" checked={kuesioner.mengkonsumsi_makanan_minuman_q3 === "Setiap hari"} />
                                                <Form.Check disabled inline value="Beberapa kali sehari" onChange={updateData} name="mengkonsumsi_makanan_minuman_q3" type='radio' label="Beberapa kali sehari" id="q1136" checked={kuesioner.mengkonsumsi_makanan_minuman_q3 === "Beberapa kali sehari"} />
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col xl="12">
                            <Row>
                                <Form.Label className='col-xl-12 col-lg-12 col-sm-12 col-md-12'>
                                    4. Selai atau madu
                                </Form.Label>
                                <Col xl="12" lg="12" sm="12" md="12" className="pb-2">
                                    <Row className="ps-4">
                                        <Col xl="6" lg="6" md="12" sm="12">
                                            <Row>
                                                <Form.Check disabled inline value="Tidak pernah" onChange={updateData} name="mengkonsumsi_makanan_minuman_q4" type='radio' label="Tidak pernah" id="q1141" checked={kuesioner.mengkonsumsi_makanan_minuman_q4 === "Tidak pernah"} />
                                                <Form.Check disabled inline value="Beberapa kali sebulan" onChange={updateData} name="mengkonsumsi_makanan_minuman_q4" type='radio' label="Beberapa kali sebulan" id="q1142" checked={kuesioner.mengkonsumsi_makanan_minuman_q4 === "Beberapa kali sebulan"} />
                                                <Form.Check disabled inline value="1 kali seminggu" onChange={updateData} name="mengkonsumsi_makanan_minuman_q4" type='radio' label="1 kali seminggu" id="q1143" checked={kuesioner.mengkonsumsi_makanan_minuman_q4 === "1 kali seminggu"} />
                                            </Row>
                                        </Col>
                                        <Col xl="6" lg="6" md="12" sm="12">
                                            <Row>
                                                <Form.Check disabled inline value="Beberapa kali seminggu" onChange={updateData} name="mengkonsumsi_makanan_minuman_q4" type='radio' label="Beberapa kali seminggu" id="q1144" checked={kuesioner.mengkonsumsi_makanan_minuman_q4 === "Beberapa kali seminggu"} />
                                                <Form.Check disabled inline value="Setiap hari" onChange={updateData} name="mengkonsumsi_makanan_minuman_q4" type='radio' label="Setiap hari" id="q1145" checked={kuesioner.mengkonsumsi_makanan_minuman_q4 === "Setiap hari"} />
                                                <Form.Check disabled inline value="Beberapa kali sehari" onChange={updateData} name="mengkonsumsi_makanan_minuman_q4" type='radio' label="Beberapa kali sehari" id="q1146" checked={kuesioner.mengkonsumsi_makanan_minuman_q4 === "Beberapa kali sehari"} />
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col xl="12">
                            <Row>
                                <Form.Label className='col-xl-12 col-lg-12 col-sm-12 col-md-12'>
                                    5. Permen karet mengandung gula
                                </Form.Label>
                                <Col xl="12" lg="12" sm="12" md="12" className="pb-2">
                                    <Row className="ps-4">
                                        <Col xl="6" lg="6" md="12" sm="12">
                                            <Row>
                                                <Form.Check disabled inline value="Tidak pernah" onChange={updateData} name="mengkonsumsi_makanan_minuman_q5" type='radio' label="Tidak pernah" id="q1151" checked={kuesioner.mengkonsumsi_makanan_minuman_q5 === "Tidak pernah"} />
                                                <Form.Check disabled inline value="Beberapa kali sebulan" onChange={updateData} name="mengkonsumsi_makanan_minuman_q5" type='radio' label="Beberapa kali sebulan" id="q1152" checked={kuesioner.mengkonsumsi_makanan_minuman_q5 === "Beberapa kali sebulan"} />
                                                <Form.Check disabled inline value="1 kali seminggu" onChange={updateData} name="mengkonsumsi_makanan_minuman_q5" type='radio' label="1 kali seminggu" id="q1153" checked={kuesioner.mengkonsumsi_makanan_minuman_q5 === "1 kali seminggu"} />
                                            </Row>
                                        </Col>
                                        <Col xl="6" lg="6" md="12" sm="12">
                                            <Row>
                                                <Form.Check disabled inline value="Beberapa kali seminggu" onChange={updateData} name="mengkonsumsi_makanan_minuman_q5" type='radio' label="Beberapa kali seminggu" id="q1154" checked={kuesioner.mengkonsumsi_makanan_minuman_q5 === "Beberapa kali seminggu"} />
                                                <Form.Check disabled inline value="Setiap hari" onChange={updateData} name="mengkonsumsi_makanan_minuman_q5" type='radio' label="Setiap hari" id="q1155" checked={kuesioner.mengkonsumsi_makanan_minuman_q5 === "Setiap hari"} />
                                                <Form.Check disabled inline value="Beberapa kali sehari" onChange={updateData} name="mengkonsumsi_makanan_minuman_q5" type='radio' label="Beberapa kali sehari" id="q1156" checked={kuesioner.mengkonsumsi_makanan_minuman_q5 === "Beberapa kali sehari"} />
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col xl="12">
                            <Row>
                                <Form.Label className='col-xl-12 col-lg-12 col-sm-12 col-md-12'>
                                    6. Permen
                                </Form.Label>
                                <Col xl="12" lg="12" sm="12" md="12" className="pb-2">
                                    <Row className="ps-4">
                                        <Col xl="6" lg="6" md="12" sm="12">
                                            <Row>
                                                <Form.Check disabled inline value="Tidak pernah" onChange={updateData} name="mengkonsumsi_makanan_minuman_q6" type='radio' label="Tidak pernah" id="q1161" checked={kuesioner.mengkonsumsi_makanan_minuman_q6 === "Tidak pernah"} />
                                                <Form.Check disabled inline value="Beberapa kali sebulan" onChange={updateData} name="mengkonsumsi_makanan_minuman_q6" type='radio' label="Beberapa kali sebulan" id="q1162" checked={kuesioner.mengkonsumsi_makanan_minuman_q6 === "Beberapa kali sebulan"} />
                                                <Form.Check disabled inline value="1 kali seminggu" onChange={updateData} name="mengkonsumsi_makanan_minuman_q6" type='radio' label="1 kali seminggu" id="q1163" checked={kuesioner.mengkonsumsi_makanan_minuman_q6 === "1 kali seminggu"} />
                                            </Row>
                                        </Col>
                                        <Col xl="6" lg="6" md="12" sm="12">
                                            <Row>
                                                <Form.Check disabled inline value="Beberapa kali seminggu" onChange={updateData} name="mengkonsumsi_makanan_minuman_q6" type='radio' label="Beberapa kali seminggu" id="q1164" checked={kuesioner.mengkonsumsi_makanan_minuman_q6 === "Beberapa kali seminggu"} />
                                                <Form.Check disabled inline value="Setiap hari" onChange={updateData} name="mengkonsumsi_makanan_minuman_q6" type='radio' label="Setiap hari" id="q1165" checked={kuesioner.mengkonsumsi_makanan_minuman_q6 === "Setiap hari"} />
                                                <Form.Check disabled inline value="Beberapa kali sehari" onChange={updateData} name="mengkonsumsi_makanan_minuman_q6" type='radio' label="Beberapa kali sehari" id="q1166" checked={kuesioner.mengkonsumsi_makanan_minuman_q6 === "Beberapa kali sehari"} />
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col xl="12">
                            <Row>
                                <Form.Label className='col-xl-12 col-lg-12 col-sm-12 col-md-12'>
                                    7. Minuman bersoda
                                </Form.Label>
                                <Col xl="12" lg="12" sm="12" md="12" className="pb-2">
                                    <Row className="ps-4">
                                        <Col xl="6" lg="6" md="12" sm="12">
                                            <Row>
                                                <Form.Check disabled inline value="Tidak pernah" onChange={updateData} name="mengkonsumsi_makanan_minuman_q7" type='radio' label="Tidak pernah" id="q1171" checked={kuesioner.mengkonsumsi_makanan_minuman_q7 === "Tidak pernah"} />
                                                <Form.Check disabled inline value="Beberapa kali sebulan" onChange={updateData} name="mengkonsumsi_makanan_minuman_q7" type='radio' label="Beberapa kali sebulan" id="q1172" checked={kuesioner.mengkonsumsi_makanan_minuman_q7 === "Beberapa kali sebulan"} />
                                                <Form.Check disabled inline value="1 kali seminggu" onChange={updateData} name="mengkonsumsi_makanan_minuman_q7" type='radio' label="1 kali seminggu" id="q1173" checked={kuesioner.mengkonsumsi_makanan_minuman_q7 === "1 kali seminggu"} />
                                            </Row>
                                        </Col>
                                        <Col xl="6" lg="6" md="12" sm="12">
                                            <Row>
                                                <Form.Check disabled inline value="Beberapa kali seminggu" onChange={updateData} name="mengkonsumsi_makanan_minuman_q7" type='radio' label="Beberapa kali seminggu" id="q1174" checked={kuesioner.mengkonsumsi_makanan_minuman_q7 === "Beberapa kali seminggu"} />
                                                <Form.Check disabled inline value="Setiap hari" onChange={updateData} name="mengkonsumsi_makanan_minuman_q7" type='radio' label="Setiap hari" id="q1175" checked={kuesioner.mengkonsumsi_makanan_minuman_q7 === "Setiap hari"} />
                                                <Form.Check disabled inline value="Beberapa kali sehari" onChange={updateData} name="mengkonsumsi_makanan_minuman_q7" type='radio' label="Beberapa kali sehari" id="q1176" checked={kuesioner.mengkonsumsi_makanan_minuman_q7 === "Beberapa kali sehari"} />
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col xl="12">
                            <Row>
                                <Form.Label className='col-xl-12 col-lg-12 col-sm-12 col-md-12'>
                                    8. Susu dengan gula
                                </Form.Label>
                                <Col xl="12" lg="12" sm="12" md="12" className="pb-2">
                                    <Row className="ps-4">
                                        <Col xl="6" lg="6" md="12" sm="12">
                                            <Row>
                                                <Form.Check disabled inline value="Tidak pernah" onChange={updateData} name="mengkonsumsi_makanan_minuman_q8" type='radio' label="Tidak pernah" id="q1181" checked={kuesioner.mengkonsumsi_makanan_minuman_q8 === "Tidak pernah"} />
                                                <Form.Check disabled inline value="Beberapa kali sebulan" onChange={updateData} name="mengkonsumsi_makanan_minuman_q8" type='radio' label="Beberapa kali sebulan" id="q1182" checked={kuesioner.mengkonsumsi_makanan_minuman_q8 === "Beberapa kali sebulan"} />
                                                <Form.Check disabled inline value="1 kali seminggu" onChange={updateData} name="mengkonsumsi_makanan_minuman_q8" type='radio' label="1 kali seminggu" id="q1183" checked={kuesioner.mengkonsumsi_makanan_minuman_q8 === "1 kali seminggu"} />
                                            </Row>
                                        </Col>
                                        <Col xl="6" lg="6" md="12" sm="12">
                                            <Row>
                                                <Form.Check disabled inline value="Beberapa kali seminggu" onChange={updateData} name="mengkonsumsi_makanan_minuman_q8" type='radio' label="Beberapa kali seminggu" id="q1184" checked={kuesioner.mengkonsumsi_makanan_minuman_q8 === "Beberapa kali seminggu"} />
                                                <Form.Check disabled inline value="Setiap hari" onChange={updateData} name="mengkonsumsi_makanan_minuman_q8" type='radio' label="Setiap hari" id="q1185" checked={kuesioner.mengkonsumsi_makanan_minuman_q8 === "Setiap hari"} />
                                                <Form.Check disabled inline value="Beberapa kali sehari" onChange={updateData} name="mengkonsumsi_makanan_minuman_q8" type='radio' label="Beberapa kali sehari" id="q1186" checked={kuesioner.mengkonsumsi_makanan_minuman_q8 === "Beberapa kali sehari"} />
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col xl="12">
                            <Row>
                                <Form.Label className='col-xl-12 col-lg-12 col-sm-12 col-md-12'>
                                    9. Teh manis
                                </Form.Label>
                                <Col xl="12" lg="12" sm="12" md="12" className="pb-2">
                                    <Row className="ps-4">
                                        <Col xl="6" lg="6" md="12" sm="12">
                                            <Row>
                                                <Form.Check disabled inline value="Tidak pernah" onChange={updateData} name="mengkonsumsi_makanan_minuman_q9" type='radio' label="Tidak pernah" id="q1191" checked={kuesioner.mengkonsumsi_makanan_minuman_q9 === "Tidak pernah"} />
                                                <Form.Check disabled inline value="Beberapa kali sebulan" onChange={updateData} name="mengkonsumsi_makanan_minuman_q9" type='radio' label="Beberapa kali sebulan" id="q1192" checked={kuesioner.mengkonsumsi_makanan_minuman_q9 === "Beberapa kali sebulan"} />
                                                <Form.Check disabled inline value="1 kali seminggu" onChange={updateData} name="mengkonsumsi_makanan_minuman_q9" type='radio' label="1 kali seminggu" id="q1193" checked={kuesioner.mengkonsumsi_makanan_minuman_q9 === "1 kali seminggu"} />
                                            </Row>
                                        </Col>
                                        <Col xl="6" lg="6" md="12" sm="12">
                                            <Row>
                                                <Form.Check disabled inline value="Beberapa kali seminggu" onChange={updateData} name="mengkonsumsi_makanan_minuman_q9" type='radio' label="Beberapa kali seminggu" id="q1194" checked={kuesioner.mengkonsumsi_makanan_minuman_q9 === "Beberapa kali seminggu"} />
                                                <Form.Check disabled inline value="Setiap hari" onChange={updateData} name="mengkonsumsi_makanan_minuman_q9" type='radio' label="Setiap hari" id="q1195" checked={kuesioner.mengkonsumsi_makanan_minuman_q9 === "Setiap hari"} />
                                                <Form.Check disabled inline value="Beberapa kali sehari" onChange={updateData} name="mengkonsumsi_makanan_minuman_q9" type='radio' label="Beberapa kali sehari" id="q1196" checked={kuesioner.mengkonsumsi_makanan_minuman_q9 === "Beberapa kali sehari"} />
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col xl="12">
                            <Row>
                                <Form.Label className='col-xl-12 col-lg-12 col-sm-12 col-md-12'>
                                    10. Kopi dengan gula
                                </Form.Label>
                                <Col xl="12" lg="12" sm="12" md="12" className="pb-2">
                                    <Row className="ps-4">
                                        <Col xl="6" lg="6" md="12" sm="12">
                                            <Row>
                                                <Form.Check disabled inline value="Tidak pernah" onChange={updateData} name="mengkonsumsi_makanan_minuman_q10" type='radio' label="Tidak pernah" id="q11101" checked={kuesioner.mengkonsumsi_makanan_minuman_q10 === "Tidak pernah"} />
                                                <Form.Check disabled inline value="Beberapa kali sebulan" onChange={updateData} name="mengkonsumsi_makanan_minuman_q10" type='radio' label="Beberapa kali sebulan" id="q11102" checked={kuesioner.mengkonsumsi_makanan_minuman_q10 === "Beberapa kali sebulan"} />
                                                <Form.Check disabled inline value="1 kali seminggu" onChange={updateData} name="mengkonsumsi_makanan_minuman_q10" type='radio' label="1 kali seminggu" id="q11103" checked={kuesioner.mengkonsumsi_makanan_minuman_q10 === "1 kali seminggu"} />
                                            </Row>
                                        </Col>
                                        <Col xl="6" lg="6" md="12" sm="12">
                                            <Row>
                                                <Form.Check disabled inline value="Beberapa kali seminggu" onChange={updateData} name="mengkonsumsi_makanan_minuman_q10" type='radio' label="Beberapa kali seminggu" id="q11104" checked={kuesioner.mengkonsumsi_makanan_minuman_q10 === "Beberapa kali seminggu"} />
                                                <Form.Check disabled inline value="Setiap hari" onChange={updateData} name="mengkonsumsi_makanan_minuman_q10" type='radio' label="Setiap hari" id="q11105" checked={kuesioner.mengkonsumsi_makanan_minuman_q10 === "Setiap hari"} />
                                                <Form.Check disabled inline value="Beberapa kali sehari" onChange={updateData} name="mengkonsumsi_makanan_minuman_q10" type='radio' label="Beberapa kali sehari" id="q11106" checked={kuesioner.mengkonsumsi_makanan_minuman_q10 === "Beberapa kali sehari"} />
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row className='mt-3'>
                        <h5 className={`text-center mb-3 ${kuesioner.type === "dewasa" ? "kuesioner-title-blue" : "kuesioner-title-yellow"}`}>KONSUMSI TEMBAKAU</h5>
                        <p>Seberapa sering Anda mengkonsumsi jenis tembakau berikut?</p>
                        <hr />
                        <Col xl="12">
                            <Row>
                                <Form.Label className='col-xl-12 col-lg-12 col-sm-12 col-md-12'>
                                    1. Sigaret
                                </Form.Label>
                                <Col xl="12" lg="12" sm="12" md="12" className="pb-2">
                                    <Row className="ps-4">
                                        <Col xl="6" lg="6" md="12" sm="12">
                                            <Row>
                                                <Form.Check disabled inline value="Tidak pernah" onChange={updateData} name="konsumsi_tembakau_q1" type='radio' label="Tidak pernah" id="q1211" checked={kuesioner.konsumsi_tembakau_q1 === "Tidak pernah"} />
                                                <Form.Check disabled inline value="Jarang" onChange={updateData} name="konsumsi_tembakau_q1" type='radio' label="Jarang" id="q1212" checked={kuesioner.konsumsi_tembakau_q1 === "Jarang"} />
                                                <Form.Check disabled inline value="Beberapa kali sebulan" onChange={updateData} name="konsumsi_tembakau_q1" type='radio' label="Beberapa kali sebulan" id="q1213" checked={kuesioner.konsumsi_tembakau_q1 === "Beberapa kali sebulan"} />
                                            </Row>
                                        </Col>
                                        <Col xl="6" lg="6" md="12" sm="12">
                                            <Row>
                                                <Form.Check disabled inline value="1 kali seminggu" onChange={updateData} name="konsumsi_tembakau_q1" type='radio' label="1 kali seminggu" id="q1214" checked={kuesioner.konsumsi_tembakau_q1 === "1 kali seminggu"} />
                                                <Form.Check disabled inline value="Beberapa kali seminggu" onChange={updateData} name="konsumsi_tembakau_q1" type='radio' label="Beberapa kali seminggu" id="q1215" checked={kuesioner.konsumsi_tembakau_q1 === "Beberapa kali seminggu"} />
                                                <Form.Check disabled inline value="Setiap hari" onChange={updateData} name="konsumsi_tembakau_q1" type='radio' label="Setiap hari" id="q1216" checked={kuesioner.konsumsi_tembakau_q1 === "Setiap hari"} />
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col xl="12">
                            <Row>
                                <Form.Label className='col-xl-12 col-lg-12 col-sm-12 col-md-12'>
                                    2. Cerutu
                                </Form.Label>
                                <Col xl="12" lg="12" sm="12" md="12" className="pb-2">
                                    <Row className="ps-4">
                                        <Col xl="6" lg="6" md="12" sm="12">
                                            <Row>
                                                <Form.Check disabled inline value="Tidak pernah" onChange={updateData} name="konsumsi_tembakau_q2" type='radio' label="Tidak pernah" id="q1221" checked={kuesioner.konsumsi_tembakau_q2 === "Tidak pernah"} />
                                                <Form.Check disabled inline value="Jarang" onChange={updateData} name="konsumsi_tembakau_q2" type='radio' label="Jarang" id="q1222" checked={kuesioner.konsumsi_tembakau_q2 === "Jarang"} />
                                                <Form.Check disabled inline value="Beberapa kali sebulan" onChange={updateData} name="konsumsi_tembakau_q2" type='radio' label="Beberapa kali sebulan" id="q1223" checked={kuesioner.konsumsi_tembakau_q2 === "Beberapa kali sebulan"} />
                                            </Row>
                                        </Col>
                                        <Col xl="6" lg="6" md="12" sm="12">
                                            <Row>
                                                <Form.Check disabled inline value="1 kali seminggu" onChange={updateData} name="konsumsi_tembakau_q2" type='radio' label="1 kali seminggu" id="q1224" checked={kuesioner.konsumsi_tembakau_q2 === "1 kali seminggu"} />
                                                <Form.Check disabled inline value="Beberapa kali seminggu" onChange={updateData} name="konsumsi_tembakau_q2" type='radio' label="Beberapa kali seminggu" id="q1225" checked={kuesioner.konsumsi_tembakau_q2 === "Beberapa kali seminggu"} />
                                                <Form.Check disabled inline value="Setiap hari" onChange={updateData} name="konsumsi_tembakau_q2" type='radio' label="Setiap hari" id="q1226" checked={kuesioner.konsumsi_tembakau_q2 === "Setiap hari"} />
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col xl="12">
                            <Row>
                                <Form.Label className='col-xl-12 col-lg-12 col-sm-12 col-md-12'>
                                    3. Pipa
                                </Form.Label>
                                <Col xl="12" lg="12" sm="12" md="12" className="pb-2">
                                    <Row className="ps-4">
                                        <Col xl="6" lg="6" md="12" sm="12">
                                            <Row>
                                                <Form.Check disabled inline value="Tidak pernah" onChange={updateData} name="konsumsi_tembakau_q3" type='radio' label="Tidak pernah" id="q1231" checked={kuesioner.konsumsi_tembakau_q3 === "Tidak pernah"} />
                                                <Form.Check disabled inline value="Jarang" onChange={updateData} name="konsumsi_tembakau_q3" type='radio' label="Jarang" id="q1232" checked={kuesioner.konsumsi_tembakau_q3 === "Jarang"} />
                                                <Form.Check disabled inline value="Beberapa kali sebulan" onChange={updateData} name="konsumsi_tembakau_q3" type='radio' label="Beberapa kali sebulan" id="q1233" checked={kuesioner.konsumsi_tembakau_q3 === "Beberapa kali sebulan"} />
                                            </Row>
                                        </Col>
                                        <Col xl="6" lg="6" md="12" sm="12">
                                            <Row>
                                                <Form.Check disabled inline value="1 kali seminggu" onChange={updateData} name="konsumsi_tembakau_q3" type='radio' label="1 kali seminggu" id="q1234" checked={kuesioner.konsumsi_tembakau_q3 === "1 kali seminggu"} />
                                                <Form.Check disabled inline value="Beberapa kali seminggu" onChange={updateData} name="konsumsi_tembakau_q3" type='radio' label="Beberapa kali seminggu" id="q1235" checked={kuesioner.konsumsi_tembakau_q3 === "Beberapa kali seminggu"} />
                                                <Form.Check disabled inline value="Setiap hari" onChange={updateData} name="konsumsi_tembakau_q3" type='radio' label="Setiap hari" id="q1236" checked={kuesioner.konsumsi_tembakau_q3 === "Setiap hari"} />
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col xl="12">
                            <Row>
                                <Form.Label className='col-xl-12 col-lg-12 col-sm-12 col-md-12'>
                                    4. Tembakau kunyah
                                </Form.Label>
                                <Col xl="12" lg="12" sm="12" md="12" className="pb-2">
                                    <Row className="ps-4">
                                        <Col xl="6" lg="6" md="12" sm="12">
                                            <Row>
                                                <Form.Check disabled inline value="Tidak pernah" onChange={updateData} name="konsumsi_tembakau_q4" type='radio' label="Tidak pernah" id="q1241" checked={kuesioner.konsumsi_tembakau_q4 === "Tidak pernah"} />
                                                <Form.Check disabled inline value="Jarang" onChange={updateData} name="konsumsi_tembakau_q4" type='radio' label="Jarang" id="q1242" checked={kuesioner.konsumsi_tembakau_q4 === "Jarang"} />
                                                <Form.Check disabled inline value="Beberapa kali sebulan" onChange={updateData} name="konsumsi_tembakau_q4" type='radio' label="Beberapa kali sebulan" id="q1243" checked={kuesioner.konsumsi_tembakau_q4 === "Beberapa kali sebulan"} />
                                            </Row>
                                        </Col>
                                        <Col xl="6" lg="6" md="12" sm="12">
                                            <Row>
                                                <Form.Check disabled inline value="1 kali seminggu" onChange={updateData} name="konsumsi_tembakau_q4" type='radio' label="1 kali seminggu" id="q1244" checked={kuesioner.konsumsi_tembakau_q4 === "1 kali seminggu"} />
                                                <Form.Check disabled inline value="Beberapa kali seminggu" onChange={updateData} name="konsumsi_tembakau_q4" type='radio' label="Beberapa kali seminggu" id="q1245" checked={kuesioner.konsumsi_tembakau_q4 === "Beberapa kali seminggu"} />
                                                <Form.Check disabled inline value="Setiap hari" onChange={updateData} name="konsumsi_tembakau_q4" type='radio' label="Setiap hari" id="q1246" checked={kuesioner.konsumsi_tembakau_q4 === "Setiap hari"} />
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col xl="12">
                            <Row>
                                <Form.Label className='col-xl-12 col-lg-12 col-sm-12 col-md-12'>
                                    5. Tembakau sirih
                                </Form.Label>
                                <Col xl="12" lg="12" sm="12" md="12" className="pb-2">
                                    <Row className="ps-4">
                                        <Col xl="6" lg="6" md="12" sm="12">
                                            <Row>
                                                <Form.Check disabled inline value="Tidak pernah" onChange={updateData} name="konsumsi_tembakau_q5" type='radio' label="Tidak pernah" id="q1251" checked={kuesioner.konsumsi_tembakau_q5 === "Tidak pernah"} />
                                                <Form.Check disabled inline value="Jarang" onChange={updateData} name="konsumsi_tembakau_q5" type='radio' label="Jarang" id="q1252" checked={kuesioner.konsumsi_tembakau_q5 === "Jarang"} />
                                                <Form.Check disabled inline value="Beberapa kali sebulan" onChange={updateData} name="konsumsi_tembakau_q5" type='radio' label="Beberapa kali sebulan" id="q1253" checked={kuesioner.konsumsi_tembakau_q5 === "Beberapa kali sebulan"} />
                                            </Row>
                                        </Col>
                                        <Col xl="6" lg="6" md="12" sm="12">
                                            <Row>
                                                <Form.Check disabled inline value="1 kali seminggu" onChange={updateData} name="konsumsi_tembakau_q5" type='radio' label="1 kali seminggu" id="q1254" checked={kuesioner.konsumsi_tembakau_q5 === "1 kali seminggu"} />
                                                <Form.Check disabled inline value="Beberapa kali seminggu" onChange={updateData} name="konsumsi_tembakau_q5" type='radio' label="Beberapa kali seminggu" id="q1255" checked={kuesioner.konsumsi_tembakau_q5 === "Beberapa kali seminggu"} />
                                                <Form.Check disabled inline value="Setiap hari" onChange={updateData} name="konsumsi_tembakau_q5" type='radio' label="Setiap hari" id="q1256" checked={kuesioner.konsumsi_tembakau_q5 === "Setiap hari"} />
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col xl="12">
                            <Row>
                                <Form.Label className='col-xl-12 col-lg-12 col-sm-12 col-md-12'>
                                    6. Lainnya
                                </Form.Label>
                                <Col xl="12" lg="12" sm="12" md="12" className="pb-2">
                                    <Row className="ps-4">
                                        <Col xl="6" lg="6" md="12" sm="12">
                                            <Row>
                                                <Form.Check disabled inline value="Tidak pernah" onChange={updateDataLainnya2} name="konsumsi_tembakau_q6" type='radio' label="Tidak pernah" id="q1261" checked={kuesioner.konsumsi_tembakau_q6 === "Tidak pernah"} />
                                                <Form.Check disabled inline value="Jarang" onChange={updateDataLainnya2} name="konsumsi_tembakau_q6" type='radio' label="Jarang" id="q1262" checked={kuesioner.konsumsi_tembakau_q6 === "Jarang"} />
                                                <Form.Check disabled inline value="Beberapa kali sebulan" onChange={updateDataLainnya2} name="konsumsi_tembakau_q6" type='radio' label="Beberapa kali sebulan" id="q1263" checked={kuesioner.konsumsi_tembakau_q6 === "Beberapa kali sebulan"} />
                                            </Row>
                                        </Col>
                                        <Col xl="6" lg="6" md="12" sm="12">
                                            <Row>
                                                <Form.Check disabled inline value="1 kali seminggu" onChange={updateDataLainnya2} name="konsumsi_tembakau_q6" type='radio' label="1 kali seminggu" id="q1264" checked={kuesioner.konsumsi_tembakau_q6 === "1 kali seminggu"} />
                                                <Form.Check disabled inline value="Beberapa kali seminggu" onChange={updateDataLainnya2} name="konsumsi_tembakau_q6" type='radio' label="Beberapa kali seminggu" id="q1265" checked={kuesioner.konsumsi_tembakau_q6 === "Beberapa kali seminggu"} />
                                                <Form.Check disabled inline value="Setiap hari" onChange={updateDataLainnya2} name="konsumsi_tembakau_q6" type='radio' label="Setiap hari" id="q1266" checked={kuesioner.konsumsi_tembakau_q6 === "Setiap hari"} />
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col xl="12">
                            <Form.Group as={Row} className="mb-3" controlId="lainnya2">
                                <Form.Label column xl="2" md="2" sm="2">
                                    Lainnya
                                </Form.Label>
                                <Col xl="10" md="10" sm="10">
                                    <Form.Control type='text' name='konsumsi_tembakau_lainnya' onChange={updateDataLainnya2} disabled={kuesioner.konsumsi_tembakau_lainnya === "Tidak pernah" ? true : disableLainnya2} />
                                </Col>
                            </Form.Group>
                        </Col>

                    </Row>

                    {kuesioner.type === "dewasa" && <Row className='mt-3'>
                        <h5 className={`text-center mb-3 ${kuesioner.type === "dewasa" ? "kuesioner-title-blue" : "kuesioner-title-yellow"}`}>KONSUMSI ALKOHOL</h5>
                        <p>Dalam kurun waktu 30 hari terakhir, dalam seharinya, berapa gelas sehari
                            biasanya Anda mengkonsumsi minuman yang mengandung alkohol?</p>
                        <hr />
                        <Col xl="12">
                            <Row className="ps-4">
                                <Col xl="6">
                                    <Row>
                                        <Form.Check disabled inline value="< 1 gelas" onChange={updateData} name="konsumsi_alkohol" type='radio' label="< 1 gelas" id="q131" checked={kuesioner.konsumsi_alkohol === "< 1 gelas"} />
                                        <Form.Check disabled inline value="1 gelas" onChange={updateData} name="konsumsi_alkohol" type='radio' label="1 gelas" id="q132" checked={kuesioner.konsumsi_alkohol === "1 gelas"} />
                                        <Form.Check disabled inline value="2 gelas" onChange={updateData} name="konsumsi_alkohol" type='radio' label="2 gelas" id="q133" checked={kuesioner.konsumsi_alkohol === "2 gelas"} />
                                        <Form.Check disabled inline value="3 gelas" onChange={updateData} name="konsumsi_alkohol" type='radio' label="3 gelas" id="q134" checked={kuesioner.konsumsi_alkohol === "3 gelas"} />

                                    </Row>
                                </Col>
                                <Col xl="6">
                                    <Row>
                                        <Form.Check disabled inline value="4 gelas" onChange={updateData} name="konsumsi_alkohol" type='radio' label="4 gelas" id="q135" checked={kuesioner.konsumsi_alkohol === "4 gelas"} />
                                        <Form.Check disabled inline value="5 gelas" onChange={updateData} name="konsumsi_alkohol" type='radio' label="5 gelas" id="q136" checked={kuesioner.konsumsi_alkohol === "5 gelas"} />
                                        <Form.Check disabled inline value="6 gelas" onChange={updateData} name="konsumsi_alkohol" type='radio' label="6 gelas" id="q137" checked={kuesioner.konsumsi_alkohol === "6 gelas"} />
                                        <Form.Check disabled inline value="Tidak mengkonsumsi alkohol" onChange={updateData} name="konsumsi_alkohol" type='radio' label="Tidak mengkonsumsi alkohol" id="q138" checked={kuesioner.konsumsi_alkohol === "Tidak mengkonsumsi alkohol"} />

                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    }
                    {kuesioner.type === "dewasa" && <Row className='mt-3'>
                        <h5 className={`text-center mb-3 ${kuesioner.type === "dewasa" ? "kuesioner-title-blue" : "kuesioner-title-yellow"}`}>PENDIDIKAN TERAKHIR</h5>
                        <p>Apa jenjang pendidikan terakhir Anda?</p>
                        <hr />
                        <Col xl="12">
                            <Row className="ps-4">
                                <Col xl="6">
                                    <Row>
                                        <Form.Check disabled inline value="Tidak menempuh pendidikan formal" onChange={updateData} name="pendidikan_terakhir" type='radio' label="Tidak menempuh pendidikan formal" id="q141" checked={kuesioner.pendidikan_terakhir === "Tidak menempuh pendidikan formal"} />
                                        <Form.Check disabled inline value="Sekolah Dasar (SD) atau sederajat" onChange={updateData} name="pendidikan_terakhir" type='radio' label="Sekolah Dasar (SD) atau sederajat" id="q142" checked={kuesioner.pendidikan_terakhir === "Sekolah Dasar (SD) atau sederajat"} />
                                        <Form.Check disabled inline value="Sekolah Menegah Pertama (SMP) atau sederajat" onChange={updateData} name="pendidikan_terakhir" type='radio' label="Sekolah Menegah Pertama (SMP) atau sederajat." id="q143" checked={kuesioner.pendidikan_terakhir === "Sekolah Menegah Pertama (SMP) atau sederajat"} />
                                        <Form.Check disabled inline value="Sekolah Menengah Akhir (SMA) atau sederajat" onChange={updateData} name="pendidikan_terakhir" type='radio' label="Sekolah Menengah Akhir (SMA) atau sederajat" id="q144" checked={kuesioner.pendidikan_terakhir === "Sekolah Menengah Akhir (SMA) atau sederajat"} />
                                        <Form.Check disabled inline value="Diploma I" onChange={updateData} name="pendidikan_terakhir" type='radio' label="Diploma I." id="q145" checked={kuesioner.pendidikan_terakhir === "Diploma I"} />
                                    </Row>
                                </Col>
                                <Col xl="6">
                                    <Row>
                                        <Form.Check disabled inline value="Diploma III" onChange={updateData} name="pendidikan_terakhir" type='radio' label="Diploma III" id="q146" checked={kuesioner.pendidikan_terakhir === "Diploma III"} />
                                        <Form.Check disabled inline value="Diploma IV" onChange={updateData} name="pendidikan_terakhir" type='radio' label="Diploma IV" id="q147" checked={kuesioner.pendidikan_terakhir === "Diploma IV"} />
                                        <Form.Check disabled inline value="Sarjana (S1)" onChange={updateData} name="pendidikan_terakhir" type='radio' label="Sarjana (S1)" id="q148" checked={kuesioner.pendidikan_terakhir === "Sarjana (S1)"} />
                                        <Form.Check disabled inline value="Magister (S2)" onChange={updateData} name="pendidikan_terakhir" type='radio' label="Magister (S2)" id="q149" checked={kuesioner.pendidikan_terakhir === "Magister (S2)"} />
                                        <Form.Check disabled inline value="Doktor (S3)" onChange={updateData} name="pendidikan_terakhir" type='radio' label="Doktor (S3)" id="q1410" checked={kuesioner.pendidikan_terakhir === "Doktor (S3)"} />
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>}
                    {kuesioner.type === "anak-anak" && <>
                        <Row className='mt-3'>
                            <h5 className={`text-center mb-3 ${kuesioner.type === "dewasa" ? "kuesioner-title-blue" : "kuesioner-title-yellow"}`}>PENDIDIKAN TERAKHIR ORANG TUA(AYAH)</h5>
                            <p>Apa jenjang pendidikan terakhir Ayah Anda?</p>
                            <hr />
                            <Col xl="12">
                                <Row className="ps-4">
                                    <Col xl="6">
                                        <Row>
                                            <Form.Check disabled inline value="Tidak menempuh pendidikan formal" onChange={updateData} name="pendidikan_terakhir_ayah" type='radio' label="Tidak menempuh pendidikan formal" id="q141" checked={kuesioner.pendidikan_terakhir_ayah === "Tidak menempuh pendidikan formal"} />
                                            <Form.Check disabled inline value="Sekolah Dasar (SD) atau sederajat" onChange={updateData} name="pendidikan_terakhir_ayah" type='radio' label="Sekolah Dasar (SD) atau sederajat" id="q142" checked={kuesioner.pendidikan_terakhir_ayah === "Sekolah Dasar (SD) atau sederajat"} />
                                            <Form.Check disabled inline value="Sekolah Menegah Pertama (SMP) atau sederajat" onChange={updateData} name="pendidikan_terakhir_ayah" type='radio' label="Sekolah Menegah Pertama (SMP) atau sederajat." id="q143" checked={kuesioner.pendidikan_terakhir_ayah === "Sekolah Menegah Pertama (SMP) atau sederajat"} />
                                            <Form.Check disabled inline value="Sekolah Menengah Akhir (SMA) atau sederajat" onChange={updateData} name="pendidikan_terakhir_ayah" type='radio' label="Sekolah Menengah Akhir (SMA) atau sederajat" id="q144" checked={kuesioner.pendidikan_terakhir_ayah === "Sekolah Menengah Akhir (SMA) atau sederajat"} />
                                            <Form.Check disabled inline value="Diploma I" onChange={updateData} name="pendidikan_terakhir_ayah" type='radio' label="Diploma I." id="q145" checked={kuesioner.pendidikan_terakhir_ayah === "Diploma I"} />
                                        </Row>
                                    </Col>
                                    <Col xl="6">
                                        <Row>
                                            <Form.Check disabled inline value="Diploma III" onChange={updateData} name="pendidikan_terakhir_ayah" type='radio' label="Diploma III" id="q146" checked={kuesioner.pendidikan_terakhir_ayah === "Diploma III"} />
                                            <Form.Check disabled inline value="Diploma IV" onChange={updateData} name="pendidikan_terakhir_ayah" type='radio' label="Diploma IV" id="q147" checked={kuesioner.pendidikan_terakhir_ayah === "Diploma IV"} />
                                            <Form.Check disabled inline value="Sarjana (S1)" onChange={updateData} name="pendidikan_terakhir_ayah" type='radio' label="Sarjana (S1)" id="q148" checked={kuesioner.pendidikan_terakhir_ayah === "Sarjana (S1)"} />
                                            <Form.Check disabled inline value="Magister (S2)" onChange={updateData} name="pendidikan_terakhir_ayah" type='radio' label="Magister (S2)" id="q149" checked={kuesioner.pendidikan_terakhir_ayah === "Magister (S2)"} />
                                            <Form.Check disabled inline value="Doktor (S3)" onChange={updateData} name="pendidikan_terakhir_ayah" type='radio' label="Doktor (S3)" id="q1410" checked={kuesioner.pendidikan_terakhir_ayah === "Doktor (S3)"} />
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className='mt-3'>
                            <h5 className={`text-center mb-3 ${kuesioner.type === "dewasa" ? "kuesioner-title-blue" : "kuesioner-title-yellow"}`}>PENDIDIKAN TERAKHIR ORANG TUA(IBU)</h5>
                            <p>Apa jenjang pendidikan terakhir Ibu Anda?</p>
                            <hr />
                            <Col xl="12">
                                <Row className="ps-4">
                                    <Col xl="6">
                                        <Row>
                                            <Form.Check disabled inline value="Tidak menempuh pendidikan formal" onChange={updateData} name="pendidikan_terakhir_ibu" type='radio' label="Tidak menempuh pendidikan formal" id="q141" checked={kuesioner.pendidikan_terakhir_ibu === "Tidak menempuh pendidikan formal"} />
                                            <Form.Check disabled inline value="Sekolah Dasar (SD) atau sederajat" onChange={updateData} name="pendidikan_terakhir_ibu" type='radio' label="Sekolah Dasar (SD) atau sederajat" id="q142" checked={kuesioner.pendidikan_terakhir_ibu === "Sekolah Dasar (SD) atau sederajat"} />
                                            <Form.Check disabled inline value="Sekolah Menegah Pertama (SMP) atau sederajat" onChange={updateData} name="pendidikan_terakhir_ibu" type='radio' label="Sekolah Menegah Pertama (SMP) atau sederajat." id="q143" checked={kuesioner.pendidikan_terakhir_ibu === "Sekolah Menegah Pertama (SMP) atau sederajat"} />
                                            <Form.Check disabled inline value="Sekolah Menengah Akhir (SMA) atau sederajat" onChange={updateData} name="pendidikan_terakhir_ibu" type='radio' label="Sekolah Menengah Akhir (SMA) atau sederajat" id="q144" checked={kuesioner.pendidikan_terakhir_ibu === "Sekolah Menengah Akhir (SMA) atau sederajat"} />
                                            <Form.Check disabled inline value="Diploma I" onChange={updateData} name="pendidikan_terakhir_ibu" type='radio' label="Diploma I." id="q145" checked={kuesioner.pendidikan_terakhir_ibu === "Diploma I"} />
                                        </Row>
                                    </Col>
                                    <Col xl="6">
                                        <Row>
                                            <Form.Check disabled inline value="Diploma III" onChange={updateData} name="pendidikan_terakhir_ibu" type='radio' label="Diploma III" id="q146" checked={kuesioner.pendidikan_terakhir_ibu === "Diploma III"} />
                                            <Form.Check disabled inline value="Diploma IV" onChange={updateData} name="pendidikan_terakhir_ibu" type='radio' label="Diploma IV" id="q147" checked={kuesioner.pendidikan_terakhir_ibu === "Diploma IV"} />
                                            <Form.Check disabled inline value="Sarjana (S1)" onChange={updateData} name="pendidikan_terakhir_ibu" type='radio' label="Sarjana (S1)" id="q148" checked={kuesioner.pendidikan_terakhir_ibu === "Sarjana (S1)"} />
                                            <Form.Check disabled inline value="Magister (S2)" onChange={updateData} name="pendidikan_terakhir_ibu" type='radio' label="Magister (S2)" id="q149" checked={kuesioner.pendidikan_terakhir_ibu === "Magister (S2)"} />
                                            <Form.Check disabled inline value="Doktor (S3)" onChange={updateData} name="pendidikan_terakhir_ibu" type='radio' label="Doktor (S3)" id="q1410" checked={kuesioner.pendidikan_terakhir_ibu === "Doktor (S3)"} />
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </>}
                    <hr />
                    <Row>
                        <Col xl={12}>
                            <Button className='primary float-end' type='submit'>Submit</Button>
                        </Col>
                    </Row>
                </Form>
            </ContainerWithCard>

            <ModalConfirmation show={showConfirmation} handleClose={() => { setShowConfirmation(false) }} handleSubmit={onSubmit} message="Apakah anda yakin?" />

            {response?.status && <ToastPopup message={response.data.msg} showToast={showToast} setShowToast={setShowToast} response={response?.status} />
            }
        </>
    )
}

export default QuestionerView