import React, { useEffect, useState } from "react";
import { PanelCard } from "../component/Cards";
import  { PanelForm } from "../component/ContainerCards";
import Form from "react-bootstrap/Form";
import axiosApi from "../api/axios";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import Button from "react-bootstrap/esm/Button";
import ReactPaginate from "react-paginate";
import Table from "react-bootstrap/esm/Table";
import { ModalConfirmation } from "../component/ModalPopup";
import { ToastPopup } from "../component/Toastbar";

const DiagnosisKlinis = () => {
  const [blok, setBlok] = useState([]);
  const [page, setPage] = useState(0);
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);
  const [showEdit, setShowEdit] = useState(false);
  const [showAdd, setShowAdd] = useState(false);

  const [id, setId] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [response, setResponse] = useState({});
  const [name, setName] = useState("");

  const [keyword, setKeyword] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const getBlok = async () => {
      const response = await axiosApi.get(
        `/setting/diagnosis/view?search_query=${keyword}&page=${page}&limit=${10}`
      );
      setBlok(response.data.result);
      setPage(response.data.page);
      setPages(response.data.totalPage);
      setRows(response.data.totalRows);
    };
    getBlok();
  }, [page, keyword, response]);

  const changePage = ({ selected }) => {
    setPage(selected);
  };

  const handleButton = (e) => {
    setId(e);
    setShowEdit(true);
  };

  const onEdit = async () => {
    try {
      const result = await axiosApi.put("setting/diagnosis/edit", {
        data: { id },
      });
      setResponse(result);
      setShowEdit(false);
      setShowToast(true);
    } catch (error) {
      setResponse(error.response);
      setShowEdit(false);
      setShowToast(true);
    }
  };

  const handleAdd = (e) => {
    e.preventDefault();
    setShowAdd(true);
  };

  const onAdd = async () => {
    try {
      const result = await axiosApi.post("setting/diagnosis/create", { name });
      setResponse(result);
      setShowAdd(false);
      setShowToast(true);
      setName("");
    } catch (error) {
      setResponse(error.response);
      setShowAdd(false);
      setShowToast(true);
    }
  };

  const searchData = (e) => {
    e.preventDefault();
    setPage(0);
    setKeyword(searchQuery);
  };

  const clearSearch = (e) => {
    e.preventDefault();
    setPage(0);
    setSearchQuery("");
    setKeyword("");
  };
  return (
    <>
      <PanelForm>
        <PanelCard>
          <Form onSubmit={handleAdd}>
            <Row className="d-flex flex-row justify-content-evenly">
              <Col xl="9">
                <Row>
                  <Col xl="12">
                    <input
                      type="text"
                      className="search-input"
                      placeholder="Tambah Diagnosis Klinis"
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                      autoComplete="off"
                    />
                  </Col>
                </Row>
              </Col>
              <Col xl="3">
                <Row>
                  <Col
                    xl="12"
                    className="d-flex flex-row justify-content-evenly"
                  >
                    <button className="button-search" type="submit">
                      Tambah
                    </button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </PanelCard>
        <PanelCard>
          <Form onSubmit={searchData}>
            <Row className="d-flex flex-row justify-content-evenly">
              <Col xl="9">
                <Row>
                  <Col xl="12">
                    <input
                      type="text"
                      value={searchQuery}
                      className="search-input"
                      placeholder="Search"
                      onChange={(e) => setSearchQuery(e.target.value)}
                      autoComplete="off"
                    />
                  </Col>
                </Row>
              </Col>
              <Col xl="3">
                <Row>
                  <Col
                    xl="12"
                    className="d-flex flex-row justify-content-evenly"
                  >
                    <button className="button-search" type="submit">
                      Search
                    </button>
                    <button
                      className="button-clear-search ms-1"
                      onClick={clearSearch}
                    >
                      Clear
                    </button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
          <Row className=" d-flex justify-content-center align-content-center align-items-center">
            <div className="table-responsive">
              <Table className="table-sm">
                <thead>
                  <tr>
                    <th className="text-left fs-7 fw-bolder font-purple pt-3 pb-3 font-15">
                      Nama
                    </th>
                    <th className="text-left fs-7 fw-bolder font-purple pt-3 pb-3 font-15">
                      Status
                    </th>
                    <th className="text-left fs-7 fw-bolder font-purple pt-3 pb-3 font-15">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {blok.map((item, index) => (
                    <tr key={index}>
                      <td className="text-left">{item.name}</td>
                      <td className="text-left">
                        {item.active ? "Aktif" : "Tidak Aktif"}
                      </td>

                      <td className="text-left">
                        <Button
                          onClick={(e) => {
                            handleButton(item.id);
                          }}
                          className="btn btn-primary red"
                        >
                          {item.active ? "Nonaktifkan" : "Aktifkan"}
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>

            <p>
              Total Rows : {rows} Page: {rows ? page + 1 : 0} of {pages}
            </p>
            <nav className="pagination justify-content-center" key={rows}>
              <ReactPaginate
                previousLabel={"Prev"}
                nextLabel={"Next"}
                pageCount={pages}
                onPageChange={changePage}
                containerClassName="pagination"
                pageLinkClassName="page-link font-black"
                previousLinkClassName="page-link font-orange"
                nextLinkClassName="page-link font-orange"
                activeLinkClassName="page-link active third font-white"
                disabledLinkClassName="page-link disabled font-disable"
              />
            </nav>
          </Row>
        </PanelCard>
      </PanelForm>

      <ModalConfirmation
        show={showEdit}
        handleClose={() => {
          setShowEdit(false);
        }}
        handleSubmit={onEdit}
        message="Apakah anda yakin?"
      />
      <ModalConfirmation
        show={showAdd}
        handleClose={() => {
          setShowAdd(false);
        }}
        handleSubmit={onAdd}
        message="Apakah anda yakin?"
      />

      {response?.status && (
        <ToastPopup
          message={response.data.msg}
          showToast={showToast}
          setShowToast={setShowToast}
          response={response?.status}
        />
      )}
    </>
  );
};

export default DiagnosisKlinis;
