import React from 'react'
import Row from 'react-bootstrap/esm/Row'

const Parter = ({ title, children }) => {
    return (
        <>
            <Row className='pb-3'>
                <div className='parter-title'>
                    <h5 className='no-margin'>{title}</h5>
                </div>
            </Row>
            <Row className='pb-5'>
                {children}
            </Row>
        </>
    )
}

export default Parter