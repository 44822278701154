import { useEffect, useState } from "react";
import axiosApi from "../api/axios";
import Cards from "../component/Cards";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/esm/Table";
import { MdDownload } from "react-icons/md";

const ReportsPage = () => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);

  useEffect(() => {
    const getPasiens = async () => {
      const response = await axiosApi.get(
        `/download/findAll?page=${page}&limit=${10}`
      );
      setData(response.data.result);
      setPage(response.data.page);
      setPages(response.data.totalPage);
      setRows(response.data.totalRows);
    };
    getPasiens();
  }, [page]);

  const parseDate = (param) => {
    let date = new Date(param).toLocaleString();
    return date;
  };

  const buttonDownload = async (filename, id) => {
    const link = document.createElement("a");
    // link.setAttribute('href', `http://localhost:3010/api/download/pasien/report?filename=${filename}&id=${id}`)
    link.href = `/api/download/pasien/report?filename=${filename}&id=${id}`;
    link.setAttribute("download", filename);

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
  };

  return (
    <>
      <Container fluid>
        <Row className="justify-content-md-center">
          <Col md={11}>
            <Cards>
              <div className="table-responsive">
                <Table className="table-sm">
                  <thead>
                    <tr>
                      <th className="text-right fw-bolder font-purple pt-3 pb-3 font-15">
                        Nama File
                      </th>
                      <th className="text-right fw-bolder font-purple pt-3 pb-3 font-15">
                        Tanggal dibuat
                      </th>
                      <th className="text-right fw-bolder font-purple pt-3 pb-3 font-15">
                        Status
                      </th>
                      <th className="text-right fw-bolder font-purple pt-3 pb-3 font-15">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((item, index) => (
                      <tr key={index}>
                        <td className="text-right">{item.file_name}</td>
                        <td className="text-right">
                          {parseDate(item.created_time)}
                        </td>
                        <td className="text-right">{item.status}</td>
                        <td>
                          {item.status === "SUCCESS" ? (
                              <button
                                type="button"
                                className="btn btn-primary red"
                                onClick={() =>
                                  buttonDownload(item.file_name, item.id)
                                }
                              >
                                <MdDownload />
                              </button>
                          ) : (
                            ""
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Cards>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ReportsPage;
