// import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Patient from './page/Patient';
import Login from './page/Login';
import Unauthorized from './page/Unauthorized';
import RequireAuth, { RequireAuthLogin } from './RequireAuth';
import Layout from './component/layout/Layout';
import 'bootstrap/dist/css/bootstrap.min.css';
import Dashboard from './page/Dashboard';
import NotFound from './page/NotFound';
import Register from './page/Register';
import PersistLogin from './PersistLogin';
import Roles from './page/Roles';
import Users from './page/Users';
import UsersEdit from './page/UsersEdit';
import RolesAdd from './page/RolesAdd';
import RolesEdit from './page/RolesEdit';
import Questioner from "./page/Questioner";
import Assesment from "./page/Assesment";
import PendingTask from "./page/PendingTask";
import AccountSetting from "./page/AccountSetting";
import UsersAdd from "./page/UsersAdd";
import PatientAdd from "./page/PatientAdd";
import PatientEdit from "./page/PatientEdit";
import PatientView from "./page/PatientView";
import RiwayatSistematik from "./page/RiwayatSistematik";
import TahunAjaran from "./page/TahunAjaran";
import Blok from "./page/Blok";
import QuestionerAdd from "./page/QuestionerAdd";
import AssesmentAdd from "./page/AssesmentAdd";
import AssesmentView from "./page/AssesmentView";
import QuestionerEdit from "./page/QuestionerEdit";
import TindakanPerawatan from "./page/TindakanPerawatan";
import AssesmentEdit from "./page/AssesmentEdit";
import QuestionerView from "./page/QuestionerView";
import DiagnosisKlinis from "./page/DiagnosisKlinis";
import ReportsPage from "./page/ReportsPage";
import UsersResetPassword from "./page/UserResetPassword";


function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<PersistLogin />}>
          <Route element={<RequireAuthLogin />}>
            <Route path="/" element={<Layout />} >
              <Route path="dashboard" element={<Dashboard />} />
              <Route element={<RequireAuth allowedRoles={['002']} />}>
                <Route path="patient" element={<Patient />} />
                <Route path="patient/add" element={<PatientAdd />} />
                <Route path='patient/edit/:id' element={<PatientEdit />} />
                <Route path='patient/view/:id' element={<PatientView />} />
                <Route path="patient/view/:id/editKuesioner/:id" element={<QuestionerEdit />} />
                <Route path="patient/view/:id/viewKuesioner/:id" element={<QuestionerView />} />
                <Route path="patient/view/:id/editAssesment/:id" element={<AssesmentEdit />} />
                <Route path="patient/view/:id/viewAssesment/:id" element={<AssesmentView />} />

              </Route>
              <Route element={<RequireAuth allowedRoles={['003']} />}>
                <Route path="user" element={<Users />} />
                <Route path="user/add" element={<UsersAdd />} />
                <Route path='user/edit/:id' element={<UsersEdit />} />
                <Route path='user/reset/:id' element={<UsersResetPassword />} />

              </Route>
              <Route element={<RequireAuth allowedRoles={['004']} />}>
                <Route path="role" element={<Roles />} />
                <Route path="role/add" element={<RolesAdd />} />
                <Route path="role/edit/:id" element={<RolesEdit />} />
              </Route>
              <Route element={<RequireAuth allowedRoles={['005']} />}>
                <Route path="kuesioner" element={<Questioner />} />
                <Route path="kuesioner/add" element={<QuestionerAdd />} />
                <Route path="kuesioner/edit/:id" element={<QuestionerEdit />} />
                <Route path="kuesioner/view/:id" element={<QuestionerView />} />

              </Route>
              <Route element={<RequireAuth allowedRoles={['006']} />}>
                <Route path="assesment" element={<Assesment />} />
                <Route path="assesment/add" element={<AssesmentAdd />} />
                <Route path="assesment/edit/:id" element={<AssesmentEdit />} />
                <Route path="assesment/view/:id" element={<AssesmentView />} />
              </Route>
              <Route element={<RequireAuth allowedRoles={['007']} />}>
                <Route path="pendingTask" element={<PendingTask />} />
              </Route>
              <Route element={<RequireAuth allowedRoles={['008']} />}>
                <Route path="blok" element={<Blok />} />
              </Route>
              <Route element={<RequireAuth allowedRoles={['009']} />}>
                <Route path="tahunAjaran" element={<TahunAjaran />} />
              </Route>
              <Route element={<RequireAuth allowedRoles={['010']} />}>
                <Route path="riwayatSistematik" element={<RiwayatSistematik />} />
              </Route>
              <Route element={<RequireAuth allowedRoles={['011']} />}>
                <Route path="tindakanPerawatan" element={<TindakanPerawatan />} />
              </Route>
              <Route element={<RequireAuth allowedRoles={['012']} />}>
                <Route path="diagnosisKlinis" element={<DiagnosisKlinis />} />
              </Route>
              <Route element={<RequireAuth allowedRoles={['013']} />}>
                <Route path="report" element={<ReportsPage />} />
              </Route>
              <Route path='/accountSetting' element={<AccountSetting />} />

            </Route>

          </Route>

          <Route path='/login' element={<Login />} />
        </Route>
        <Route path='/register' element={<Register />} />
        <Route path='/unauthorized' element={<Unauthorized />} />
        <Route path='*' element={<NotFound />} />




      </Routes>
    </BrowserRouter>
  );
}

export default App;
