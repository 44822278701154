import React, { useEffect, useState } from 'react'
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import axiosApi from '../api/axios';
import ContainerCards, { PanelForm } from '../component/ContainerCards';
import Form from 'react-bootstrap/Form';
import Cards, { PanelCard } from '../component/Cards';
import { FormContainer, FormControlCheck } from '../component/Forms';
import AsyncSelect from 'react-select/async';
import { Link, useNavigate } from 'react-router-dom';

import { useParams } from 'react-router-dom';
import Toastbar, { ToastPopup } from '../component/Toastbar';
import { ModalConfirmation } from '../component/ModalPopup';

const PatientEdit = () => {

    const navigate = useNavigate();
    const { id } = useParams();
    const [pasien, setPasien] = useState({});
    const [riwayat, setRiwayat] = useState('');
    const [provinceId, setProvinceId] = useState("");
    const [regenciesId, setRegenciesId] = useState("");
    const [districtId, setDistrictId] = useState("");
    const [enableRegencies, setEnableRegencies] = useState(true);
    const [enableDistrict, setEnableDistrict] = useState(true);
    const [enableVillage, setEnableVillage] = useState(true);
    const [showToast, setShowToast] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [valueProvinsi, setValueProvinsi] = useState({});
    const [valueKab, setValueKab] = useState({});
    const [valueKecamatan, setValueKecamatan] = useState({});
    const [valueKelurahan, setValueKelurahan] = useState({});
    const [showWarning, setShowWarning] = useState(false);
    const [response, setResponse] = useState({});

    const [data, setData] = useState({
        id: "",
        name: "",
        tanggalLahir: "",
        jenisKelamin: "",
        provinsi: "",
        kabupatenOrKota: "",
        kecamatan: "",
        desa: "",
        rt: "",
        rw: "",
        riwayatSistematik: []
    });

    useEffect(() => {
        const getPasien = async () => {
            const result = await axiosApi.get(`/pasien/findById?id=${id}`)
            const temp = result.data.result.pasien;
            setPasien(result.data.result.pasien);
            setRiwayat(result.data.result.riwayat);
            setData({
                id: id,
                name: temp.name,
                tanggalLahir: temp.tanggal_lahir,
                jenisKelamin: temp.jenis_kelamin,
                provinsi: temp.provinsi,
                kabupatenOrKota: temp.kabupaten_or_kota,
                kecamatan: temp.kecamatan,
                desa: temp.desa,
                rt: temp.rt,
                rw: temp.rw,
                riwayatSistematik: result.data.result.valueRiwayat
            });
            setValueProvinsi({ label: temp.provinsi, value: temp.provinsi })
            setValueKab({ label: temp.kabupaten_or_kota, value: temp.kabupaten_or_kota })
            setValueKecamatan({ label: temp.kecamatan, value: temp.kecamatan })
            setValueKelurahan({ label: temp.desa, value: temp.desa })

        }
        getPasien();
    }, [id])


    const updateData = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    const loadProvince = async (inputValue, callBack) => {

        const result = await axiosApi.get(`address/province?search_query=${inputValue}`);
        callBack(result.data.result);
    }
    const loadRegencies = async (inputValue, callBack) => {

        const result = await axiosApi.get(`address/regencies?search_query=${inputValue}&id=${provinceId}`);
        callBack(result.data.result);
    }

    const loadDistrict = async (inputValue, callBack) => {

        const result = await axiosApi.get(`address/district?search_query=${inputValue}&id=${regenciesId}`);
        callBack(result.data.result);
    }

    const loadVillage = async (inputValue, callBack) => {

        const result = await axiosApi.get(`address/village?search_query=${inputValue}&id=${districtId}`);
        callBack(result.data.result);
    }

    const loadRiwayat = async (inputValue, callBack) => {

        const result = await axiosApi.get(`setting/riwayatSistematik/list?search_query=${inputValue}`);
        callBack(result.data.result);
    }

    const handleProvince = (e) => {
        setProvinceId(e.value);
        setEnableDistrict(true);
        setEnableVillage(true);
        setEnableRegencies(false);
        setValueProvinsi({ label: e.label, value: e.label })
        setValueKab({})
        setValueKecamatan({})
        setValueKelurahan({})
        setData({
            ...data,
            "provinsi": e.label,
            "kabupatenOrKota": "",
            "kecamatan": "",
            "desa": ""
        })
    }
    const handleRegencies = (e) => {
        setRegenciesId(e.value);
        setEnableDistrict(false);
        setEnableVillage(true);
        setValueKab({ label: e.label, value: e.label })

        setData({
            ...data,
            "kabupatenOrKota": e.label
        })
    }

    const handleDistrict = (e) => {
        setDistrictId(e.value);
        setEnableVillage(false);
        setValueKecamatan({ label: e.label, value: e.label })

        setData({
            ...data,
            "kecamatan": e.label
        })
    }
    const handleVillage = (e) => {
        setValueKelurahan({ label: e.label, value: e.label })

        setData({
            ...data,
            "desa": e.label
        })
    }

    const handleRiwayat = (e) => {
        let temp = [];
        e.forEach((e) => {
            temp.push(e.value);
        })
        setRiwayat(e);
        setData({
            ...data,
            "riwayatSistematik": temp
        })
    }

    const onConfirm = (e) => {
        e.preventDefault();
        if (data.kabupatenOrKota === "" || data.kecamatan === "" || data.desa === "") {
            setShowWarning(true);
        } else {
            setShowConfirmation(true);
        }
    }

    const onSubmit = async (e) => {
        try {
            const result = await axiosApi.put("/pasien/edit",  {data : data});
            setResponse(result);
            setShowConfirmation(false);
            setShowToast(true);

            const sleep = ms => new Promise(r => setTimeout(r, ms));
            await sleep(1000);
            navigate("/patient", { replace: true });

        } catch (error) {
            setResponse(error.response);
            setShowConfirmation(false);
            setShowToast(true);
        }
    }

    return (
        <>
            <PanelForm>
                <PanelCard>
                    <Form onSubmit={onConfirm}>
                        <Row>
                            <FormContainer controlId="name" label="Nama Lengkap">
                                <Form.Control type='text' required onChange={updateData} name="name" defaultValue={pasien.name} />
                            </FormContainer>

                            <FormContainer controlId="tanggalLahir" label="Tanggal Lahir">
                                <Form.Control type='date' required onChange={updateData} name="tanggalLahir" defaultValue={pasien.tanggal_lahir} />
                            </FormContainer>
                            <FormControlCheck controlId="jenisKelamin" label="Jenis Kelamin">
                                <Form.Select name='jenisKelamin' onChange={updateData} required aria-label="jenis-kelamin" className="mb-3" value={pasien.jenis_kelamin}>
                                    <option value="">-Pilih salah satu-</option>
                                    <option value="Laki-laki">Laki-laki</option>
                                    <option value="Perempuan">Perempuan</option>
                                </Form.Select>
                            </FormControlCheck>
                            <FormContainer controlId="province" label="Provinsi">
                                <AsyncSelect loadOptions={loadProvince} onChange={handleProvince}
                                    defaultOptions
                                    value={valueProvinsi} required />
                            </FormContainer>
                            <FormContainer controlId="regencies" label="Kabupaten / Kota">
                                <AsyncSelect loadOptions={loadRegencies} onChange={handleRegencies} isDisabled={enableRegencies} defaultOptions required
                                    value={valueKab} />
                            </FormContainer>
                            <FormContainer controlId="district" label="Kecamatan">
                                <AsyncSelect loadOptions={loadDistrict} onChange={handleDistrict} isDisabled={enableDistrict} defaultOptions
                                    value={valueKecamatan} />
                            </FormContainer>
                            <FormContainer controlId="village" label="Kelurahan">
                                <AsyncSelect loadOptions={loadVillage} onChange={handleVillage} isDisabled={enableVillage} defaultOptions
                                    value={valueKelurahan} />
                            </FormContainer>
                            <FormContainer controlId="rtrw" label="RT/RW">
                                <InputGroup className="mb-3">
                                    <Form.Control onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }} type='text' placeholder='RT' name='rt' onChange={updateData} defaultValue={pasien.rt} />
                                    <Form.Control onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }} type='text' placeholder='RW' name='rw' onChange={updateData} defaultValue={pasien.rw} />
                                </InputGroup>
                            </FormContainer>
                            <FormContainer controlId="riwayat" label="Riwayat Sistematik">
                                <AsyncSelect isMulti loadOptions={loadRiwayat} onChange={handleRiwayat} defaultOptions
                                    value={riwayat} />
                            </FormContainer>
                        </Row>
                        <Row className='mt-3 mb-3'>
                            <Row>
                                <Col xl={12}>
                                    <Button className='primary float-end ms-2' type='submit'>Submit</Button>
                                    <Link to="/patient" className="btn btn-secondary secondary-color float-end" variant="secondary">
                                        Back
                                    </Link>
                                </Col>
                            </Row>
                        </Row>
                    </Form>


                </PanelCard>
            </PanelForm>

            <ModalConfirmation show={showConfirmation} handleClose={() => setShowConfirmation(false)} handleSubmit={onSubmit} message="Apakah anda yakin?" />
            <Toastbar message="Harap isi semua data" showToast={showWarning} setShowToast={setShowWarning} type="warning" />
            {response?.status && <ToastPopup message={response.data.msg} showToast={showToast} setShowToast={setShowToast} response={response?.status} />
            }
        </>
    )
}

export default PatientEdit