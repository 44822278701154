import Form from 'react-bootstrap/Form';
import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
// import axios from '../api/axios';
import useAuth from '../hooks/useAuth';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import jwt_decode from "jwt-decode";
import { axiosBasic } from '../api/axios';
import { ToastPopup } from '../component/Toastbar';
import '../css/login.css';

const Login = () => {

    const { auth, setAuth } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [response, setResponse] = useState({});
    const [showToast, setShowToast] = useState(false);
    useEffect(() => {
        if (auth?.name) {
            return navigate("/");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth]);

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const body = { username, password };

            // const response = await axios.post(LOGIN_URL, body);

            const response = await axiosBasic.post("/auth", body);

            const decode = jwt_decode(response.data.accessToken);

            const accessToken = response.data.accessToken;
            const id = decode?.id;
            const name = decode?.name;
            const email = decode?.email;
            const userNama = decode?.username;
            const permission = decode?.permission;
            setAuth({id, name, email, userNama, permission, accessToken })
            localStorage.setItem("persist", true);
            setUsername('');
            setPassword('');
            navigate(from, { replace: true });
        } catch (err) {
            setResponse(err.response);
            console.log(err.response);
            setShowToast(true);
        }



    }
    return (
        <>

            <div className='container-fluid vh-100'>
                <div className='row p-0 vh-100'>
                    <div className="col-12 col-xl-6 p-0">
                        <div className='login-form d-flex flex-column justify-content-center '>
                            <div className='col-12 d-flex flex-column align-items-center'>
                                <div className='login-logo mb-5'>
                                </div>
                                <h3 className='text-center col-10 col-md-6 col-xl-6'>Login</h3>
                                <Form onSubmit={handleLogin} className="col-12 d-flex flex-column align-items-center">
                                    <Form.Group className="mb-3 col-10 col-md-6 col-xl-6" controlId="username">
                                        <Form.Label>Username</Form.Label>
                                        <Form.Control type="text" onChange={(e) => setUsername(e.target.value)} required autoComplete='off' />
                                    </Form.Group>
                                    <Form.Group className="mb-3 col-10 col-md-6 col-xl-6" controlId="password">
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control type="password" onChange={(e) => setPassword(e.target.value)} required />
                                    </Form.Group>
                                    <div className='d-grid mb-4 col-10 col-md-6 col-xl-6'>
                                        <Button className='primary' type="submit">
                                            Login
                                        </Button>
                                    </div>
                                    <div className='d-grid'>
                                        <p>Belum mempunyai akun?</p>
                                        <Link className='text-center text-decoration-none' to="/register">Daftar disini</Link>
                                    </div>
                                </Form>

                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-xl-6 d-none d-xl-block p-0">
                        <div className='login-title d-flex flex-column justify-content-center'>
                            <div className='login-layer'>
                                {/* <h2>Selamat datang di website</h2>
                                <h1>Siagamu</h1> */}
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {response?.status && <ToastPopup message={response.data.msg} showToast={showToast} setShowToast={setShowToast} response={response?.status} />
            }
        </>


    )
}

export default Login