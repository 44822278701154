import { useState } from 'react';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import Cards from '../component/Cards';
import ContainerCards from '../component/ContainerCards';
import Form from 'react-bootstrap/Form';
import axiosApi from '../api/axios';
import AsyncSelect from 'react-select/async';
import { FormContainer } from '../component/Forms';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/esm/Button';
import { ModalConfirmation } from '../component/ModalPopup';
import { ToastPopup } from '../component/Toastbar';
import useAuth from '../hooks/useAuth';
import { useNavigate } from 'react-router-dom';

const QuestionerAdd = () => {

    const { auth } = useAuth();
    const [type, setType] = useState('');
    const navigate = useNavigate();

    const initData = {
        umur: "",
        type: "",
        tanggalKuesioner: "",
        jumlahGigi: "",
        pengalamanRasaSakit: "",
        penggunaanGigiPalsuLepasanQ1: "",
        penggunaanGigiPalsuLepasanQ2: "",
        penggunaanGigiPalsuLepasanQ3: "",
        identifikasiMandiriQ1: "",
        identifikasiMandiriQ2: "",
        frekuensiMembersihkanGigi: "",
        penggunaanBahanQ1: "",
        penggunaanBahanQ2: "",
        penggunaanBahanQ3: "",
        penggunaanBahanQ4: "",
        penggunaanBahanQ5: "",
        penggunaanBahanQ6: "",
        penggunaanBahanQ7: "",
        penggunaanBahanLainnya: "",
        penggunaanPastaQ1: "",
        penggunaanPastaQ2: "",
        pengalamanPeriksa: "",
        alasanPeriksa: "",
        pengalamanTidakMenyenangkanQ1: "",
        pengalamanTidakMenyenangkanQ2: "",
        pengalamanTidakMenyenangkanQ3: "",
        pengalamanTidakMenyenangkanQ4: "",
        pengalamanTidakMenyenangkanQ5: "",
        pengalamanTidakMenyenangkanQ6: "",
        pengalamanTidakMenyenangkanQ7: "",
        pengalamanTidakMenyenangkanQ8: "",
        pengalamanTidakMenyenangkanQ9: "",
        pengalamanTidakMenyenangkanQ10: "",
        pengalamanTidakMenyenangkanQ11: "",
        pengalamanTidakMenyenangkanQ12: "",
        pengalamanTidakMenyenangkanQ13: "",
        mengkonsumsiMakananMinumanQ1: "",
        mengkonsumsiMakananMinumanQ2: "",
        mengkonsumsiMakananMinumanQ3: "",
        mengkonsumsiMakananMinumanQ4: "",
        mengkonsumsiMakananMinumanQ5: "",
        mengkonsumsiMakananMinumanQ6: "",
        mengkonsumsiMakananMinumanQ7: "",
        mengkonsumsiMakananMinumanQ8: "",
        mengkonsumsiMakananMinumanQ9: "",
        mengkonsumsiMakananMinumanQ10: "",
        konsumsiTembakauQ1: "",
        konsumsiTembakauQ2: "",
        konsumsiTembakauQ3: "",
        konsumsiTembakauQ4: "",
        konsumsiTembakauQ5: "",
        konsumsiTembakauQ6: "",
        konsumsiTembakauLainnya: "",
        konsumsiAlkohol: "",
        pendidikanTerakhir: "",
        pendidikanTerakhirAyah: "",
        pendidikanTerakhirIbu: "",
        pasienId: ""
    }
    const [data, setData] = useState(initData);

    const [pasien, setPasien] = useState({});
    const [umur, setUmur] = useState("");
    const [showAssesment, setShowAssesment] = useState(false);
    const [disable, setDisable] = useState(true);
    const [disableLainnya1, setDisableLainnya1] = useState(true);
    const [disableLainnya2, setDisableLainnya2] = useState(true);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [response, setResponse] = useState({})

    const loadOption = async (inputValue, callBack) => {

        const result = await axiosApi.get(`pasien/list?search_query=${inputValue}`);
        callBack(result.data.result);
    }

    const changeType = (e) => {
        setType(e.target.value);
        let temp = e.target.value;
        setData(initData);
        setShowAssesment(false);
        if (temp === "") {
            setDisable(true);
        } else {
            setDisable(false);
        }
    }


    const updateData = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        });
    }
    const updateDataLainnya1 = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        });
        if (e.target.value === "Ya") {
            setDisableLainnya1(false);
        } else {
            setDisableLainnya1(true);

        }
    }
    const updateDataLainnya2 = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        });
        if (e.target.value === "Tidak pernah") {
            setDisableLainnya2(true);
        } else {
            setDisableLainnya2(false);

        }
    }

    const calculateAge = (param) => {
        let dob = new Date(param);
        let month_diff = Date.now() - dob.getTime();
        let age_dt = new Date(month_diff);

        let year = age_dt.getUTCFullYear();

        let age = Math.abs(year - 1970);
        return age;
    }
    const handleName = async (e) => {
        try {
            const result = await axiosApi.get(`pasien/findById?id=${e.value}`);
            setPasien(result.data.result.pasien);
            let umurTemp = calculateAge(result.data.result.pasien.tanggal_lahir);
            setUmur(umurTemp)
            setData({
                ...data,
                "umur": umurTemp,
                "pasienId": e.value,
                "type": type,
                "pengisiKuesionerId": auth.id
            });
            setShowAssesment(true);

        } catch (error) {

        }
    }

    const handleConfirm = (e) => {
        e.preventDefault();
        setShowConfirmation(true);
    }

    const onSubmit = async () => {
        try {
            const response = await axiosApi.post('kuesioner/create', { data: data })
            setResponse(response);

            setShowConfirmation(false);
            setShowToast(true);
            const sleep = ms => new Promise(r => setTimeout(r, ms));
            await sleep(1000);

            navigate("/kuesioner", { replace: true });
        } catch (error) {
            setResponse(error.response);
            setShowConfirmation(false);
            setShowToast(true);
        }
    }

    return (
        <>
            <ContainerCards>
                <Card className='shadow mb-2' >
                    <Card.Header>
                        <h3 className='float-start'>Kuesioner</h3>
                        <div className='col-xl-3 float-end'>
                            <Form.Select value={type} onChange={changeType}>
                                <option value="">-Pilih salah satu-</option>
                                <option value="dewasa">Dewasa</option>
                                <option value="anak-anak">Anak-anak</option>
                            </Form.Select>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <FormContainer controlId="nama" label="Nama">
                                <AsyncSelect loadOptions={loadOption} onChange={handleName} isDisabled={disable} />
                            </FormContainer>
                        </Row>
                    </Card.Body>
                </Card>
                {showAssesment && <Cards>
                    <Row>
                        <FormContainer controlId="name" label="Nama">
                            <Form.Control type='text' disabled defaultValue={pasien.name} />
                        </FormContainer>
                        <FormContainer controlId="name" label="Jenis Kelamin">
                            <Form.Control type='text' disabled defaultValue={pasien.jenis_kelamin} />
                        </FormContainer>
                        <FormContainer controlId="name" label="Umur">
                            <Form.Control type='text' disabled defaultValue={umur} />
                        </FormContainer>
                        <FormContainer controlId="name" label="Tanggal Kuesioner">
                            <Form.Control type='date' onChange={updateData} name="tanggalKuesioner" required />
                        </FormContainer>
                    </Row>
                    <Form onSubmit={handleConfirm}>
                        {type === "dewasa" && <>
                            <Row className='mt-3'>
                                <h5 className={`text-center mb-3 ${type === "dewasa" ? "kuesioner-title-blue" : "kuesioner-title-yellow"}`}>JUMLAH GIGI YANG DAPAT DIIDENTIFIKASI SECARA MANDIRI</h5>
                                <p>Berapa jumlah gigi asli yang Anda milki saat ini?</p>
                                <hr />

                                <Col xl="12">
                                    <Form.Check inline value="Tidak punya" onChange={updateData} name="jumlahGigi" type='radio' label="Tidak punya" id="q1" />
                                    <Form.Check inline value="1 - 9 gigi" onChange={updateData} name="jumlahGigi" type='radio' label="1 - 9 gigi" id="q12" />
                                    <Form.Check inline value="10 - 19 gigi" onChange={updateData} name="jumlahGigi" type='radio' label="10 - 19 gigi" id="q13" />
                                    <Form.Check inline value="20 gigi atau lebih" onChange={updateData} name="jumlahGigi" type='radio' label="20 gigi atau lebih" id="q14" />
                                </Col>
                            </Row>
                        </>}

                        <Row className='mt-3'>
                            <h5 className={`text-center mb-3 ${type === "dewasa" ? "kuesioner-title-blue" : "kuesioner-title-yellow"}`}>PENGALAMAN RASA SAKIT/TIDAK NYAMAN PADA GIGI DAN MULUT</h5>
                            <p>Dalam 12 bulan terakhir, apakah Anda pernah mengeluhkan rasa sakit atau
                                tidak nyaman pada gigi atau mulut Anda?</p>
                            <hr />

                            <Col xl="12">

                                <Form.Check inline value="Tidak" onChange={updateData} name="pengalamanRasaSakit" type='radio' label="Tidak" id="q21" />
                                <Form.Check inline value="Ya" onChange={updateData} name="pengalamanRasaSakit" type='radio' label="Ya" id="q22" />
                                <Form.Check inline value="Tidak tahu" onChange={updateData} name="pengalamanRasaSakit" type='radio' label="Tidak tahu" id="q23" />
                                <Form.Check inline value="Tidak menjawab" onChange={updateData} name="pengalamanRasaSakit" type='radio' label="Tidak menjawab" id="q24" />
                            </Col>
                        </Row>
                        {type === "dewasa" && <Row className='mt-3'>
                            <h5 className={`text-center mb-3 ${type === "dewasa" ? "kuesioner-title-blue" : "kuesioner-title-yellow"}`}>PENGGUNAAN GIGI PALSU LEPASAN</h5>
                            <p>Apakah Anda menggunakan gigi palsu yang dapat dilepas?</p>
                            <hr />
                            <Col xl="12">
                                <Row>
                                    <Form.Label className='col-xl-6 col-lg-6 col-sm-12 col-md-6'>
                                        1. Gigi palsu lepasan sebagian
                                    </Form.Label>
                                    <Col xl="6" lg="6" sm="12" md="6">
                                        <Form.Check inline value="Ya" onChange={updateData} name="penggunaanGigiPalsuLepasanQ1" type='radio' label="Ya" id="q311" />
                                        <Form.Check inline value="Tidak" onChange={updateData} name="penggunaanGigiPalsuLepasanQ1" type='radio' label="Tidak" id="q312" />
                                        <Form.Check inline value="Tidak Tahu" onChange={updateData} name="penggunaanGigiPalsuLepasanQ1" type='radio' label="Tidak Tahu" id="q313" />
                                    </Col>
                                </Row>
                            </Col>
                            <Col xl="12">
                                <Row>
                                    <Form.Label className='col-xl-6 col-lg-6 col-sm-12 col-md-6'>
                                        2. Gigi palsu lepasan atas penuh
                                    </Form.Label>
                                    <Col xl="6" lg="6" sm="12" md="6">
                                        <Form.Check inline value="Ya" onChange={updateData} name="penggunaanGigiPalsuLepasanQ2" type='radio' label="Ya" id="q321" />
                                        <Form.Check inline value="Tidak" onChange={updateData} name="penggunaanGigiPalsuLepasanQ2" type='radio' label="Tidak" id="q322" />
                                        <Form.Check inline value="Tidak Tahu" onChange={updateData} name="penggunaanGigiPalsuLepasanQ2" type='radio' label="Tidak Tahu" id="q323" />
                                    </Col>
                                </Row>
                            </Col>
                            <Col xl="12">
                                <Row>
                                    <Form.Label className='col-xl-6 col-lg-6 col-sm-12 col-md-6'>
                                        3. Gigi palsu lepasan bawah penuh
                                    </Form.Label>
                                    <Col xl="6" lg="6" sm="12" md="6">
                                        <Form.Check inline value="Ya" onChange={updateData} name="penggunaanGigiPalsuLepasanQ3" type='radio' label="Ya" id="q331" />
                                        <Form.Check inline value="Tidak" onChange={updateData} name="penggunaanGigiPalsuLepasanQ3" type='radio' label="Tidak" id="q332" />
                                        <Form.Check inline value="Tidak Tahu" onChange={updateData} name="penggunaanGigiPalsuLepasanQ3" type='radio' label="Tidak Tahu" id="q333" />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>}


                        <Row className='mt-3'>
                            <h5 className={`text-center mb-3 ${type === "dewasa" ? "kuesioner-title-blue" : "kuesioner-title-yellow"}`}>IDENTIFIKASI MANDIRI KONDISI GIGI DAN GUSI</h5>
                            <p>Bagaimana Anda menggambarkan keadaan gigi dan gusi Anda?</p>
                            <hr />

                            <Col xl="12">
                                <Row>
                                    <Form.Label className='col-xl-3 col-lg-3 col-md-12 col-sm-12'>
                                        1. Gigi ?
                                    </Form.Label>
                                    <Col xl="9" lg="9" sm="12" md="12">
                                        <Form.Check inline value="Sempurna" onChange={updateData} name="identifikasiMandiriQ1" type='radio' label="Sempurna" id="q411" />
                                        <Form.Check inline value="Sangat baik" onChange={updateData} name="identifikasiMandiriQ1" type='radio' label="Sangat baik" id="q412" />
                                        <Form.Check inline value="Baik" onChange={updateData} name="identifikasiMandiriQ1" type='radio' label="Baik" id="q413" />
                                        <Form.Check inline value="Rata-rata" onChange={updateData} name="identifikasiMandiriQ1" type='radio' label="Rata-rata" id="q414" />
                                        <Form.Check inline value="Buruk" onChange={updateData} name="identifikasiMandiriQ1" type='radio' label="Buruk" id="q415" />
                                        <Form.Check inline value="Sangat Buruk" onChange={updateData} name="identifikasiMandiriQ1" type='radio' label="Sangat Buruk" id="q416" />
                                    </Col>
                                </Row>

                            </Col>
                            <Col xl="12">
                                <Row>
                                    <Form.Label className='col-xl-3 col-lg-3 col-md-12 col-sm-12'>
                                        2. Gusi ?
                                    </Form.Label>
                                    <Col xl="9" lg="9" sm="12" md="12">
                                        <Form.Check inline value="Sempurna" onChange={updateData} name="identifikasiMandiriQ2" type='radio' label="Sempurna" id="q411" />
                                        <Form.Check inline value="Sangat baik" onChange={updateData} name="identifikasiMandiriQ2" type='radio' label="Sangat baik" id="q422" />
                                        <Form.Check inline value="Baik" onChange={updateData} name="identifikasiMandiriQ2" type='radio' label="Baik" id="q423" />
                                        <Form.Check inline value="Rata-rata" onChange={updateData} name="identifikasiMandiriQ2" type='radio' label="Rata-rata" id="q424" />
                                        <Form.Check inline value="Buruk" onChange={updateData} name="identifikasiMandiriQ2" type='radio' label="Buruk" id="q425" />
                                        <Form.Check inline value="Sangat Buruk" onChange={updateData} name="identifikasiMandiriQ2" type='radio' label="Sangat Buruk" id="q426" />
                                    </Col>
                                </Row>

                            </Col>
                        </Row>

                        <Row className='mt-3'>
                            <h5 className={`text-center mb-3 ${type === "dewasa" ? "kuesioner-title-blue" : "kuesioner-title-yellow"}`}>FREKUENSI MEMBERSIHKAN GIGI</h5>
                            <p>Seberapa sering Anda membersihkan gigi Anda?</p>
                            <hr />
                            <Col xl="12">
                                <Row className="ps-4">
                                    <Col xl="6">
                                        <Row>
                                            <Form.Check inline value="Tidak pernah" onChange={updateData} name="frekuensiMembersihkanGigi" type='radio' label="Tidak pernah" id="q511" />
                                            <Form.Check inline value="1 kali sebulan" onChange={updateData} name="frekuensiMembersihkanGigi" type='radio' label="1 kali sebulan" id="q512" />
                                            <Form.Check inline value="2 - 3 kali sebulan" onChange={updateData} name="frekuensiMembersihkanGigi" type='radio' label="2 - 3 kali sebulan" id="q513" />
                                            <Form.Check inline value="1 kali seminggu" onChange={updateData} name="frekuensiMembersihkanGigi" type='radio' label="1 kali seminggu" id="q514" />
                                        </Row>
                                    </Col>
                                    <Col xl="6">
                                        <Row>
                                            <Form.Check inline value="2 - 6 kali seminggu" onChange={updateData} name="frekuensiMembersihkanGigi" type='radio' label="2 - 6 kali seminggu" id="q515" />
                                            <Form.Check inline value="1 kali sehari" onChange={updateData} name="frekuensiMembersihkanGigi" type='radio' label="1 kali sehari" id="q516" />
                                            <Form.Check inline value="2 kali atau lebih setiap hari" onChange={updateData} name="frekuensiMembersihkanGigi" type='radio' label="2 kali atau lebih setiap hari" id="q517" />
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <Row className='mt-3'>
                            <h5 className={`text-center mb-3 ${type === "dewasa" ? "kuesioner-title-blue" : "kuesioner-title-yellow"}`}>PENGGUNAAN BAHAN/PERALATAN PENDUKUNG KEBERSIHAN MULUT</h5>
                            <p>Apakah Anda menggunakan bahan atau peralatan pendukung berikut untuk
                                membersihkan gigi atau mulut Anda?</p>
                            <hr />
                            <Col xl="12">
                                <Row>
                                    <Col xl="6">
                                        <Col xl="12">
                                            <Form.Label className='col-xl-4 col-sm-6 col-md-6'>
                                                1. Sikat Gigi
                                            </Form.Label>
                                            <Form.Check inline value="Ya" onChange={updateData} name="penggunaanBahanQ1" type='radio' label="Ya" id="q611" />
                                            <Form.Check inline value="Tidak" onChange={updateData} name="penggunaanBahanQ1" type='radio' label="Tidak" id="q612" />
                                        </Col>
                                        <Col xl="12">
                                            <Form.Label className='col-xl-4 col-sm-6 col-md-6'>
                                                2. Sikat gigi kayu
                                            </Form.Label>
                                            <Form.Check inline value="Ya" onChange={updateData} name="penggunaanBahanQ2" type='radio' label="Ya" id="q621" />
                                            <Form.Check inline value="Tidak" onChange={updateData} name="penggunaanBahanQ2" type='radio' label="Tidak" id="q622" />
                                        </Col>
                                        <Col xl="12">
                                            <Form.Label className='col-xl-4 col-sm-6 col-md-6'>
                                                3. Sikat gigi plastik
                                            </Form.Label>
                                            <Form.Check inline value="Ya" onChange={updateData} name="penggunaanBahanQ3" type='radio' label="Ya" id="q631" />
                                            <Form.Check inline value="Tidak" onChange={updateData} name="penggunaanBahanQ3" type='radio' label="Tidak" id="q632" />
                                        </Col>
                                        <Col xl="12">
                                            <Form.Label className='col-xl-4 col-sm-6 col-md-6'>
                                                4. Benang gigi
                                            </Form.Label>
                                            <Form.Check inline value="Ya" onChange={updateData} name="penggunaanBahanQ4" type='radio' label="Ya" id="q641" />
                                            <Form.Check inline value="Tidak" onChange={updateData} name="penggunaanBahanQ4" type='radio' label="Tidak" id="q642" />
                                        </Col>
                                    </Col>
                                    <Col xl="6">
                                        <Col xl="12">
                                            <Form.Label className='col-xl-4 col-sm-6 col-md-6'>
                                                5. Arang
                                            </Form.Label>
                                            <Form.Check inline value="Ya" onChange={updateData} name="penggunaanBahanQ5" type='radio' label="Ya" id="q651" />
                                            <Form.Check inline value="Tidak" onChange={updateData} name="penggunaanBahanQ5" type='radio' label="Tidak" id="q652" />
                                        </Col>
                                        <Col xl="12">
                                            <Form.Label className='col-xl-4 col-sm-6 col-md-6'>
                                                6. Siwak
                                            </Form.Label>
                                            <Form.Check inline value="Ya" onChange={updateData} name="penggunaanBahanQ6" type='radio' label="Ya" id="q661" />
                                            <Form.Check inline value="Tidak" onChange={updateData} name="penggunaanBahanQ6" type='radio' label="Tidak" id="q662" />
                                        </Col>
                                        <Col xl="12">
                                            <Form.Label className='col-xl-4 col-sm-6 col-md-6'>
                                                7. Lainnya
                                            </Form.Label>
                                            <Form.Check inline value="Ya" onChange={updateDataLainnya1} name="penggunaanBahanQ7" type='radio' label="Ya" id="q671" />
                                            <Form.Check inline value="Tidak" onChange={updateDataLainnya1} name="penggunaanBahanQ7" type='radio' label="Tidak" id="q672" />
                                        </Col>
                                        <Col xl="12">
                                            <Form.Group as={Row} className="mb-3" controlId="lainnya">
                                                <Form.Label column xl="4" md="6" sm="6">
                                                    Lainnya
                                                </Form.Label>
                                                <Col xl="8" md="6" sm="6">
                                                    <Form.Control type='text' onChange={updateData} name="penggunaanBahanLainnya" disabled={disableLainnya1} />
                                                </Col>
                                            </Form.Group>
                                        </Col>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <Row className='mt-3'>
                            <h5 className={`text-center mb-3 ${type === "dewasa" ? "kuesioner-title-blue" : "kuesioner-title-yellow"}`}>PENGGUNAAN PASTA GIGI MENGANDUNG FLORIDA</h5>
                            <hr />
                            <Col xl="12">
                                <Row>
                                    <Form.Label className='col-xl-6 col-lg-6 col-sm-12 col-md-6'>
                                        1. Apakah Anda menggunakan pasta gigi saat membersihkan gigi Anda?
                                    </Form.Label>
                                    <Col xl="6" lg="6" sm="12" md="6">
                                        <Form.Check inline value="Ya" onChange={updateData} name="penggunaanPastaQ1" type='radio' label="Ya" id="q711" />
                                        <Form.Check inline value="Tidak" onChange={updateData} name="penggunaanPastaQ1" type='radio' label="Tidak" id="q712" />
                                        <Form.Check inline value="Tidak Tahu" onChange={updateData} name="penggunaanPastaQ1" type='radio' label="Tidak Tahu" id="713" />
                                    </Col>
                                </Row>
                                <Row>
                                    <Form.Label className='col-xl-6 col-lg-6 col-sm-12 col-md-6'>
                                        2.Apakah Anda menggunakan pasta gigi yang
                                        mengandung florida saat membersihkan gigi Anda?
                                    </Form.Label>
                                    <Col xl="6" lg="6" sm="12" md="6">
                                        <Form.Check inline value="Ya" onChange={updateData} name="penggunaanPastaQ2" type='radio' label="Ya" id="q721" />
                                        <Form.Check inline value="Tidak" onChange={updateData} name="penggunaanPastaQ2" type='radio' label="Tidak" id="q722" />
                                        <Form.Check inline value="Tidak Tahu" onChange={updateData} name="penggunaanPastaQ2" type='radio' label="Tidak Tahu" id="723" />
                                    </Col>
                                </Row>

                            </Col>
                        </Row>

                        <Row className='mt-3'>
                            <h5 className={`text-center mb-3 ${type === "dewasa" ? "kuesioner-title-blue" : "kuesioner-title-yellow"}`}>PENGALAMAN PERIKSA KE DOKTER GIGI</h5>
                            <p>Sudah berapa lama Anda tidak ke dokter gigi untuk terakhir kalinya?</p>
                            <hr />
                            <Col xl="12">
                                <Row className="ps-4">
                                    <Col xl="6">
                                        <Row>
                                            <Form.Check inline value="< 6 bulan" onChange={updateData} name="pengalamanPeriksa" type='radio' label="< 6 bulan" id="q81" />
                                            <Form.Check inline value="6 - 12 bulan" onChange={updateData} name="pengalamanPeriksa" type='radio' label="6 - 12 bulan" id="q82" />
                                            <Form.Check inline value="> 1 tahun, < 2 tahun" onChange={updateData} name="pengalamanPeriksa" type='radio' label="> 1 tahun, < 2 tahun" id="q83" />
                                        </Row>
                                    </Col>
                                    <Col xl="6">
                                        <Row>
                                            <Form.Check inline value="> 1 tahun, < 2 tahun" onChange={updateData} name="pengalamanPeriksa" type='radio' label="> 1 tahun, < 2 tahun" id="q84" />
                                            <Form.Check inline value="5 tahun atau lebih" onChange={updateData} name="pengalamanPeriksa" type='radio' label="5 tahun atau lebih" id="q85" />
                                            <Form.Check inline value="Tidak pernah periksa" onChange={updateData} name="pengalamanPeriksa" type='radio' label="Tidak pernah periksa" id="q86" />
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className='mt-3'>
                            <h5 className={`text-center mb-3 ${type === "dewasa" ? "kuesioner-title-blue" : "kuesioner-title-yellow"}`}>ALASAN PERIKSA KE DOKTER GIGI</h5>
                            <p>Apa alasan Anda periksa ke dokter gigi?</p>
                            <hr />
                            <Col xl="12">
                                <Row className="ps-4">
                                    <Form.Check inline value="Konsultasi" onChange={updateData} name="alasanPeriksa" type='radio' label="Konsultasi" id="q91" />
                                    <Form.Check inline value="Sakit atau mengalami masalah pada gigi, gusi, atau mulut" onChange={updateData} name="alasanPeriksa" type='radio' label="Sakit atau mengalami masalah pada gigi, gusi, atau mulut" id="q92" />
                                    <Form.Check inline value="Pengobatan atau konsultasi paska pengobatan" onChange={updateData} name="alasanPeriksa" type='radio' label="Pengobatan atau konsultasi paska pengobatan" id="q93" />
                                    <Form.Check inline value="Check Up (pengecekkan) atau pemeriksaan rutin" onChange={updateData} name="alasanPeriksa" type='radio' label="Check Up (pengecekkan) atau pemeriksaan rutin" id="q94" />
                                    <Form.Check inline value="Tidak tahu atau tidak ingat" onChange={updateData} name="alasanPeriksa" type='radio' label="Tidak tahu atau tidak ingat" id="q95" />

                                </Row>
                            </Col>
                        </Row>
                        <Row className='mt-3'>
                            <h5 className={`text-center mb-3 ${type === "dewasa" ? "kuesioner-title-blue" : "kuesioner-title-yellow"}`}>PENGALAMAN TIDAK MENYENANGKAN MENGURANGI
                                KUALITAS HIDUP TERKAIT MASALAH PADA AREA MULUT</h5>
                            <p>Karena kondisi gigi dan mulut Anda, seberapa sering Anda mengalami
                                masalah berikut selama 12 bulan terakhir?</p>
                            <hr />
                            <Col xl="12">
                                <Row>
                                    <Form.Label className='col-xl-12 col-lg-12 col-sm-12 col-md-12'>
                                        1. Kesulitan menggigit makanan
                                    </Form.Label>
                                    <Col xl="12" lg="12" sm="12" md="12" className="pb-2">
                                        <Row className="ps-4">
                                            <Col xl="6" lg="6" md="12" sm="12">
                                                <Row>
                                                    <Form.Check inline value="Tidak tahu" onChange={updateData} name="pengalamanTidakMenyenangkanQ1" type='radio' label="Tidak tahu" id="q1011" />
                                                    <Form.Check inline value="Tidak" onChange={updateData} name="pengalamanTidakMenyenangkanQ1" type='radio' label="Tidak" id="q1012" />
                                                    <Form.Check inline value="Kadang" onChange={updateData} name="pengalamanTidakMenyenangkanQ1" type='radio' label="Kadang" id="q1013" />
                                                </Row>

                                            </Col>
                                            <Col xl="6" lg="6" md="12" sm="12">
                                                <Row>
                                                    <Form.Check inline value="Cukup sering" onChange={updateData} name="pengalamanTidakMenyenangkanQ1" type='radio' label="Cukup sering" id="q1014" />
                                                    <Form.Check inline value="Sangat sering" onChange={updateData} name="pengalamanTidakMenyenangkanQ1" type='radio' label="Sangat sering" id="q1015" />
                                                </Row>

                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xl="12">
                                <Row>
                                    <Form.Label className='col-xl-12 col-lg-12 col-sm-12 col-md-12'>
                                        2. Kesulitan mengunyah makanan
                                    </Form.Label>
                                    <Col xl="12" lg="12" sm="12" md="12" className="pb-2">
                                        <Row className="ps-4">
                                            <Col xl="6" lg="6" md="12" sm="12">
                                                <Row>
                                                    <Form.Check inline value="Tidak tahu" onChange={updateData} name="pengalamanTidakMenyenangkanQ2" type='radio' label="Tidak tahu" id="q1021" />
                                                    <Form.Check inline value="Tidak" onChange={updateData} name="pengalamanTidakMenyenangkanQ2" type='radio' label="Tidak" id="q1022" />
                                                    <Form.Check inline value="Kadang" onChange={updateData} name="pengalamanTidakMenyenangkanQ2" type='radio' label="Kadang" id="q1023" />
                                                </Row>

                                            </Col>
                                            <Col xl="6" lg="6" md="12" sm="12">
                                                <Row>
                                                    <Form.Check inline value="Cukup sering" onChange={updateData} name="pengalamanTidakMenyenangkanQ2" type='radio' label="Cukup sering" id="q1024" />
                                                    <Form.Check inline value="Sangat sering" onChange={updateData} name="pengalamanTidakMenyenangkanQ2" type='radio' label="Sangat sering" id="q1025" />
                                                </Row>

                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xl="12">
                                <Row>
                                    <Form.Label className='col-xl-12 col-lg-12 col-sm-12 col-md-12'>
                                        3. Kesulitan berbicara
                                    </Form.Label>
                                    <Col xl="12" lg="12" sm="12" md="12" className="pb-2">
                                        <Row className="ps-4">
                                            <Col xl="6" lg="6" md="12" sm="12">
                                                <Row>
                                                    <Form.Check inline value="Tidak tahu" onChange={updateData} name="pengalamanTidakMenyenangkanQ3" type='radio' label="Tidak tahu" id="q1031" />
                                                    <Form.Check inline value="Tidak" onChange={updateData} name="pengalamanTidakMenyenangkanQ3" type='radio' label="Tidak" id="q1032" />
                                                    <Form.Check inline value="Kadang" onChange={updateData} name="pengalamanTidakMenyenangkanQ3" type='radio' label="Kadang" id="q1033" />
                                                </Row>

                                            </Col>
                                            <Col xl="6" lg="6" md="12" sm="12">
                                                <Row>
                                                    <Form.Check inline value="Cukup sering" onChange={updateData} name="pengalamanTidakMenyenangkanQ3" type='radio' label="Cukup sering" id="q1034" />
                                                    <Form.Check inline value="Sangat sering" onChange={updateData} name="pengalamanTidakMenyenangkanQ3" type='radio' label="Sangat sering" id="q1035" />
                                                </Row>

                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xl="12">
                                <Row>
                                    <Form.Label className='col-xl-12 col-lg-12 col-sm-12 col-md-12'>
                                        4. Mulut kering
                                    </Form.Label>
                                    <Col xl="12" lg="12" sm="12" md="12" className="pb-2">
                                        <Row className="ps-4">
                                            <Col xl="6" lg="6" md="12" sm="12">
                                                <Row>
                                                    <Form.Check inline value="Tidak tahu" onChange={updateData} name="pengalamanTidakMenyenangkanQ4" type='radio' label="Tidak tahu" id="q1041" />
                                                    <Form.Check inline value="Tidak" onChange={updateData} name="pengalamanTidakMenyenangkanQ4" type='radio' label="Tidak" id="q1042" />
                                                    <Form.Check inline value="Kadang" onChange={updateData} name="pengalamanTidakMenyenangkanQ4" type='radio' label="Kadang" id="q1043" />
                                                </Row>

                                            </Col>
                                            <Col xl="6" lg="6" md="12" sm="12">
                                                <Row>
                                                    <Form.Check inline value="Cukup sering" onChange={updateData} name="pengalamanTidakMenyenangkanQ4" type='radio' label="Cukup sering" id="q1044" />
                                                    <Form.Check inline value="Sangat sering" onChange={updateData} name="pengalamanTidakMenyenangkanQ4" type='radio' label="Sangat sering" id="q1045" />
                                                </Row>

                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xl="12">
                                <Row>
                                    <Form.Label className='col-xl-12 col-lg-12 col-sm-12 col-md-12'>
                                        5. Merasa malu terhadap kondisi gigi Anda
                                    </Form.Label>
                                    <Col xl="12" lg="12" sm="12" md="12" className="pb-2">
                                        <Row className="ps-4">
                                            <Col xl="6" lg="6" md="12" sm="12">
                                                <Row>
                                                    <Form.Check inline value="Tidak tahu" onChange={updateData} name="pengalamanTidakMenyenangkanQ5" type='radio' label="Tidak tahu" id="q1051" />
                                                    <Form.Check inline value="Tidak" onChange={updateData} name="pengalamanTidakMenyenangkanQ5" type='radio' label="Tidak" id="q1052" />
                                                    <Form.Check inline value="Kadang" onChange={updateData} name="pengalamanTidakMenyenangkanQ5" type='radio' label="Kadang" id="q1053" />
                                                </Row>

                                            </Col>
                                            <Col xl="6" lg="6" md="12" sm="12">
                                                <Row>
                                                    <Form.Check inline value="Cukup sering" onChange={updateData} name="pengalamanTidakMenyenangkanQ5" type='radio' label="Cukup sering" id="q1054" />
                                                    <Form.Check inline value="Sangat sering" onChange={updateData} name="pengalamanTidakMenyenangkanQ5" type='radio' label="Sangat sering" id="q1055" />
                                                </Row>

                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xl="12">
                                <Row>
                                    <Form.Label className='col-xl-12 col-lg-12 col-sm-12 col-md-12'>
                                        6. Teman atau orang sekitar sering mengejek
                                        Anda karena gigi Anda
                                    </Form.Label>
                                    <Col xl="12" lg="12" sm="12" md="12" className="pb-2">
                                        <Row className="ps-4">
                                            <Col xl="6" lg="6" md="12" sm="12">
                                                <Row>
                                                    <Form.Check inline value="Tidak tahu" onChange={updateData} name="pengalamanTidakMenyenangkanQ6" type='radio' label="Tidak tahu" id="q1061" />
                                                    <Form.Check inline value="Tidak" onChange={updateData} name="pengalamanTidakMenyenangkanQ6" type='radio' label="Tidak" id="q1062" />
                                                    <Form.Check inline value="Kadang" onChange={updateData} name="pengalamanTidakMenyenangkanQ6" type='radio' label="Kadang" id="q1063" />
                                                </Row>

                                            </Col>
                                            <Col xl="6" lg="6" md="12" sm="12">
                                                <Row>
                                                    <Form.Check inline value="Cukup sering" onChange={updateData} name="pengalamanTidakMenyenangkanQ6" type='radio' label="Cukup sering" id="q1064" />
                                                    <Form.Check inline value="Sangat sering" onChange={updateData} name="pengalamanTidakMenyenangkanQ6" type='radio' label="Sangat sering" id="q1065" />
                                                </Row>

                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xl="12">
                                <Row>
                                    <Form.Label className='col-xl-12 col-lg-12 col-sm-12 col-md-12'>
                                        7. Merasa kaku atau tegang karena masalah
                                        gigi dan mulut Anda
                                    </Form.Label>
                                    <Col xl="12" lg="12" sm="12" md="12" className="pb-2">
                                        <Row className="ps-4">
                                            <Col xl="6" lg="6" md="12" sm="12">
                                                <Row>
                                                    <Form.Check inline value="Tidak tahu" onChange={updateData} name="pengalamanTidakMenyenangkanQ7" type='radio' label="Tidak tahu" id="q1071" />
                                                    <Form.Check inline value="Tidak" onChange={updateData} name="pengalamanTidakMenyenangkanQ7" type='radio' label="Tidak" id="q1072" />
                                                    <Form.Check inline value="Kadang" onChange={updateData} name="pengalamanTidakMenyenangkanQ7" type='radio' label="Kadang" id="q1073" />
                                                </Row>

                                            </Col>
                                            <Col xl="6" lg="6" md="12" sm="12">
                                                <Row>
                                                    <Form.Check inline value="Cukup sering" onChange={updateData} name="pengalamanTidakMenyenangkanQ7" type='radio' label="Cukup sering" id="q1074" />
                                                    <Form.Check inline value="Sangat sering" onChange={updateData} name="pengalamanTidakMenyenangkanQ7" type='radio' label="Sangat sering" id="q1075" />
                                                </Row>

                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xl="12">
                                <Row>
                                    <Form.Label className='col-xl-12 col-lg-12 col-sm-12 col-md-12'>
                                        8. Menghindari senyum karena kondisi gigi Anda
                                    </Form.Label>
                                    <Col xl="12" lg="12" sm="12" md="12" className="pb-2">
                                        <Row className="ps-4">
                                            <Col xl="6" lg="6" md="12" sm="12">
                                                <Row>
                                                    <Form.Check inline value="Tidak tahu" onChange={updateData} name="pengalamanTidakMenyenangkanQ8" type='radio' label="Tidak tahu" id="q1081" />
                                                    <Form.Check inline value="Tidak" onChange={updateData} name="pengalamanTidakMenyenangkanQ8" type='radio' label="Tidak" id="q1082" />
                                                    <Form.Check inline value="Kadang" onChange={updateData} name="pengalamanTidakMenyenangkanQ8" type='radio' label="Kadang" id="q1083" />
                                                </Row>

                                            </Col>
                                            <Col xl="6" lg="6" md="12" sm="12">
                                                <Row>
                                                    <Form.Check inline value="Cukup sering" onChange={updateData} name="pengalamanTidakMenyenangkanQ8" type='radio' label="Cukup sering" id="q1084" />
                                                    <Form.Check inline value="Sangat sering" onChange={updateData} name="pengalamanTidakMenyenangkanQ8" type='radio' label="Sangat sering" id="q1085" />
                                                </Row>

                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xl="12">
                                <Row>
                                    <Form.Label className='col-xl-12 col-lg-12 col-sm-12 col-md-12'>
                                        9. Kualitas tidur terganggu
                                    </Form.Label>
                                    <Col xl="12" lg="12" sm="12" md="12" className="pb-2">
                                        <Row className="ps-4">
                                            <Col xl="6" lg="6" md="12" sm="12">
                                                <Row>
                                                    <Form.Check inline value="Tidak tahu" onChange={updateData} name="pengalamanTidakMenyenangkanQ9" type='radio' label="Tidak tahu" id="q1091" />
                                                    <Form.Check inline value="Tidak" onChange={updateData} name="pengalamanTidakMenyenangkanQ9" type='radio' label="Tidak" id="q1092" />
                                                    <Form.Check inline value="Kadang" onChange={updateData} name="pengalamanTidakMenyenangkanQ9" type='radio' label="Kadang" id="q1093" />
                                                </Row>

                                            </Col>
                                            <Col xl="6" lg="6" md="12" sm="12">
                                                <Row>
                                                    <Form.Check inline value="Cukup sering" onChange={updateData} name="pengalamanTidakMenyenangkanQ9" type='radio' label="Cukup sering" id="q1094" />
                                                    <Form.Check inline value="Sangat sering" onChange={updateData} name="pengalamanTidakMenyenangkanQ9" type='radio' label="Sangat sering" id="q1095" />
                                                </Row>

                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xl="12">
                                <Row>
                                    <Form.Label className='col-xl-12 col-lg-12 col-sm-12 col-md-12'>
                                        10. Mengambil cuti kerja
                                    </Form.Label>
                                    <Col xl="12" lg="12" sm="12" md="12" className="pb-2">
                                        <Row className="ps-4">
                                            <Col xl="6" lg="6" md="12" sm="12">
                                                <Row>
                                                    <Form.Check inline value="Tidak tahu" onChange={updateData} name="pengalamanTidakMenyenangkanQ10" type='radio' label="Tidak tahu" id="q10101" />
                                                    <Form.Check inline value="Tidak" onChange={updateData} name="pengalamanTidakMenyenangkanQ10" type='radio' label="Tidak" id="q10102" />
                                                    <Form.Check inline value="Kadang" onChange={updateData} name="pengalamanTidakMenyenangkanQ10" type='radio' label="Kadang" id="q10103" />
                                                </Row>

                                            </Col>
                                            <Col xl="6" lg="6" md="12" sm="12">
                                                <Row>
                                                    <Form.Check inline value="Cukup sering" onChange={updateData} name="pengalamanTidakMenyenangkanQ10" type='radio' label="Cukup sering" id="q10104" />
                                                    <Form.Check inline value="Sangat sering" onChange={updateData} name="pengalamanTidakMenyenangkanQ10" type='radio' label="Sangat sering" id="q10105" />
                                                </Row>

                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xl="12">
                                <Row>
                                    <Form.Label className='col-xl-12 col-lg-12 col-sm-12 col-md-12'>
                                        11. Kesulitan melakukan aktifitas rutin
                                    </Form.Label>
                                    <Col xl="12" lg="12" sm="12" md="12" className="pb-2">
                                        <Row className="ps-4">
                                            <Col xl="6" lg="6" md="12" sm="12">
                                                <Row>
                                                    <Form.Check inline value="Tidak tahu" onChange={updateData} name="pengalamanTidakMenyenangkanQ11" type='radio' label="Tidak tahu" id="q10111" />
                                                    <Form.Check inline value="Tidak" onChange={updateData} name="pengalamanTidakMenyenangkanQ11" type='radio' label="Tidak" id="q10112" />
                                                    <Form.Check inline value="Kadang" onChange={updateData} name="pengalamanTidakMenyenangkanQ11" type='radio' label="Kadang" id="q10113" />
                                                </Row>

                                            </Col>
                                            <Col xl="6" lg="6" md="12" sm="12">
                                                <Row>
                                                    <Form.Check inline value="Cukup sering" onChange={updateData} name="pengalamanTidakMenyenangkanQ11" type='radio' label="Cukup sering" id="q10114" />
                                                    <Form.Check inline value="Sangat sering" onChange={updateData} name="pengalamanTidakMenyenangkanQ11" type='radio' label="Sangat sering" id="q10115" />
                                                </Row>

                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xl="12">
                                <Row>
                                    <Form.Label className='col-xl-12 col-lg-12 col-sm-12 col-md-12'>
                                        12. Merasa tidak toleransi terhada orang lain yang
                                        mendekat
                                    </Form.Label>
                                    <Col xl="12" lg="12" sm="12" md="12" className="pb-2">
                                        <Row className="ps-4">
                                            <Col xl="6" lg="6" md="12" sm="12">
                                                <Row>
                                                    <Form.Check inline value="Tidak tahu" onChange={updateData} name="pengalamanTidakMenyenangkanQ12" type='radio' label="Tidak tahu" id="q10121" />
                                                    <Form.Check inline value="Tidak" onChange={updateData} name="pengalamanTidakMenyenangkanQ12" type='radio' label="Tidak" id="q1012" />
                                                    <Form.Check inline value="Kadang" onChange={updateData} name="pengalamanTidakMenyenangkanQ12" type='radio' label="Kadang" id="q1013" />
                                                </Row>

                                            </Col>
                                            <Col xl="6" lg="6" md="12" sm="12">
                                                <Row>
                                                    <Form.Check inline value="Cukup sering" onChange={updateData} name="pengalamanTidakMenyenangkanQ12" type='radio' label="Cukup sering" id="q10124" />
                                                    <Form.Check inline value="Sangat sering" onChange={updateData} name="pengalamanTidakMenyenangkanQ12" type='radio' label="Sangat sering" id="q10125" />
                                                </Row>

                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xl="12">
                                <Row>
                                    <Form.Label className='col-xl-12 col-lg-12 col-sm-12 col-md-12'>
                                        13. Mengurangi kegiatan sosial
                                    </Form.Label>
                                    <Col xl="12" lg="12" sm="12" md="12" className="pb-2">
                                        <Row className="ps-4">
                                            <Col xl="6" lg="6" md="12" sm="12">
                                                <Row>
                                                    <Form.Check inline value="Tidak tahu" onChange={updateData} name="pengalamanTidakMenyenangkanQ13" type='radio' label="Tidak tahu" id="q10131" />
                                                    <Form.Check inline value="Tidak" onChange={updateData} name="pengalamanTidakMenyenangkanQ13" type='radio' label="Tidak" id="q10132" />
                                                    <Form.Check inline value="Kadang" onChange={updateData} name="pengalamanTidakMenyenangkanQ13" type='radio' label="Kadang" id="q10133" />
                                                </Row>

                                            </Col>
                                            <Col xl="6" lg="6" md="12" sm="12">
                                                <Row>
                                                    <Form.Check inline value="Cukup sering" onChange={updateData} name="pengalamanTidakMenyenangkanQ13" type='radio' label="Cukup sering" id="q10134" />
                                                    <Form.Check inline value="Sangat sering" onChange={updateData} name="pengalamanTidakMenyenangkanQ13" type='radio' label="Sangat sering" id="q10135" />
                                                </Row>

                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className='mt-3'>
                            <h5 className={`text-center mb-3 ${type === "dewasa" ? "kuesioner-title-blue" : "kuesioner-title-yellow"}`}>MENGKONSUMSI MAKANAN DAN MINUMAN MENGANDUNG GULA</h5>
                            <p>Seberapa sering Anda mengkonsumsi makanan atau minuman berikut,
                                walaupun dalam jumlah yang sedikit?</p>
                            <hr />
                            <Col xl="12">
                                <Row>
                                    <Form.Label className='col-xl-12 col-lg-12 col-sm-12 col-md-12'>
                                        1. Buahan segar
                                    </Form.Label>
                                    <Col xl="12" lg="12" sm="12" md="12" className="pb-2">
                                        <Row className="ps-4">
                                            <Col xl="6" lg="6" md="12" sm="12">
                                                <Row>
                                                    <Form.Check inline value="Tidak pernah" onChange={updateData} name="mengkonsumsiMakananMinumanQ1" type='radio' label="Tidak pernah" id="q1111" />
                                                    <Form.Check inline value="Beberapa kali sebulan" onChange={updateData} name="mengkonsumsiMakananMinumanQ1" type='radio' label="Beberapa kali sebulan" id="q1112" />
                                                    <Form.Check inline value="1 kali seminggu" onChange={updateData} name="mengkonsumsiMakananMinumanQ1" type='radio' label="1 kali seminggu" id="q1113" />
                                                </Row>
                                            </Col>
                                            <Col xl="6" lg="6" md="12" sm="12">
                                                <Row>
                                                    <Form.Check inline value="Beberapa kali seminggu" onChange={updateData} name="mengkonsumsiMakananMinumanQ1" type='radio' label="Beberapa kali seminggu" id="q1114" />
                                                    <Form.Check inline value="Setiap hari" onChange={updateData} name="mengkonsumsiMakananMinumanQ1" type='radio' label="Setiap hari" id="q1115" />
                                                    <Form.Check inline value="Beberapa kali sehari" onChange={updateData} name="mengkonsumsiMakananMinumanQ1" type='radio' label="Beberapa kali sehari" id="q1116" />
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xl="12">
                                <Row>
                                    <Form.Label className='col-xl-12 col-lg-12 col-sm-12 col-md-12'>
                                        2. Biskuit, kue, kue dengan krim
                                    </Form.Label>
                                    <Col xl="12" lg="12" sm="12" md="12" className="pb-2">
                                        <Row className="ps-4">
                                            <Col xl="6" lg="6" md="12" sm="12">
                                                <Row>
                                                    <Form.Check inline value="Tidak pernah" onChange={updateData} name="mengkonsumsiMakananMinumanQ2" type='radio' label="Tidak pernah" id="q1121" />
                                                    <Form.Check inline value="Beberapa kali sebulan" onChange={updateData} name="mengkonsumsiMakananMinumanQ2" type='radio' label="Beberapa kali sebulan" id="q1122" />
                                                    <Form.Check inline value="1 kali seminggu" onChange={updateData} name="mengkonsumsiMakananMinumanQ2" type='radio' label="1 kali seminggu" id="q1113" />
                                                </Row>
                                            </Col>
                                            <Col xl="6" lg="6" md="12" sm="12">
                                                <Row>
                                                    <Form.Check inline value="Beberapa kali seminggu" onChange={updateData} name="mengkonsumsiMakananMinumanQ2" type='radio' label="Beberapa kali seminggu" id="q1124" />
                                                    <Form.Check inline value="Setiap hari" onChange={updateData} name="mengkonsumsiMakananMinumanQ2" type='radio' label="Setiap hari" id="q1125" />
                                                    <Form.Check inline value="Beberapa kali sehari" onChange={updateData} name="mengkonsumsiMakananMinumanQ2" type='radio' label="Beberapa kali sehari" id="q1126" />
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xl="12">
                                <Row>
                                    <Form.Label className='col-xl-12 col-lg-12 col-sm-12 col-md-12'>
                                        3. Pie atau roti manis
                                    </Form.Label>
                                    <Col xl="12" lg="12" sm="12" md="12" className="pb-2">
                                        <Row className="ps-4">
                                            <Col xl="6" lg="6" md="12" sm="12">
                                                <Row>
                                                    <Form.Check inline value="Tidak pernah" onChange={updateData} name="mengkonsumsiMakananMinumanQ3" type='radio' label="Tidak pernah" id="q1131" />
                                                    <Form.Check inline value="Beberapa kali sebulan" onChange={updateData} name="mengkonsumsiMakananMinumanQ3" type='radio' label="Beberapa kali sebulan" id="q1132" />
                                                    <Form.Check inline value="1 kali seminggu" onChange={updateData} name="mengkonsumsiMakananMinumanQ3" type='radio' label="1 kali seminggu" id="q1133" />
                                                </Row>
                                            </Col>
                                            <Col xl="6" lg="6" md="12" sm="12">
                                                <Row>
                                                    <Form.Check inline value="Beberapa kali seminggu" onChange={updateData} name="mengkonsumsiMakananMinumanQ3" type='radio' label="Beberapa kali seminggu" id="q1134" />
                                                    <Form.Check inline value="Setiap hari" onChange={updateData} name="mengkonsumsiMakananMinumanQ3" type='radio' label="Setiap hari" id="q1135" />
                                                    <Form.Check inline value="Beberapa kali sehari" onChange={updateData} name="mengkonsumsiMakananMinumanQ3" type='radio' label="Beberapa kali sehari" id="q1136" />
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xl="12">
                                <Row>
                                    <Form.Label className='col-xl-12 col-lg-12 col-sm-12 col-md-12'>
                                        4. Selai atau madu
                                    </Form.Label>
                                    <Col xl="12" lg="12" sm="12" md="12" className="pb-2">
                                        <Row className="ps-4">
                                            <Col xl="6" lg="6" md="12" sm="12">
                                                <Row>
                                                    <Form.Check inline value="Tidak pernah" onChange={updateData} name="mengkonsumsiMakananMinumanQ4" type='radio' label="Tidak pernah" id="q1141" />
                                                    <Form.Check inline value="Beberapa kali sebulan" onChange={updateData} name="mengkonsumsiMakananMinumanQ4" type='radio' label="Beberapa kali sebulan" id="q1142" />
                                                    <Form.Check inline value="1 kali seminggu" onChange={updateData} name="mengkonsumsiMakananMinumanQ4" type='radio' label="1 kali seminggu" id="q1143" />
                                                </Row>
                                            </Col>
                                            <Col xl="6" lg="6" md="12" sm="12">
                                                <Row>
                                                    <Form.Check inline value="Beberapa kali seminggu" onChange={updateData} name="mengkonsumsiMakananMinumanQ4" type='radio' label="Beberapa kali seminggu" id="q1144" />
                                                    <Form.Check inline value="Setiap hari" onChange={updateData} name="mengkonsumsiMakananMinumanQ4" type='radio' label="Setiap hari" id="q1145" />
                                                    <Form.Check inline value="Beberapa kali sehari" onChange={updateData} name="mengkonsumsiMakananMinumanQ4" type='radio' label="Beberapa kali sehari" id="q1146" />
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xl="12">
                                <Row>
                                    <Form.Label className='col-xl-12 col-lg-12 col-sm-12 col-md-12'>
                                        5. Permen karet mengandung gula
                                    </Form.Label>
                                    <Col xl="12" lg="12" sm="12" md="12" className="pb-2">
                                        <Row className="ps-4">
                                            <Col xl="6" lg="6" md="12" sm="12">
                                                <Row>
                                                    <Form.Check inline value="Tidak pernah" onChange={updateData} name="mengkonsumsiMakananMinumanQ5" type='radio' label="Tidak pernah" id="q1151" />
                                                    <Form.Check inline value="Beberapa kali sebulan" onChange={updateData} name="mengkonsumsiMakananMinumanQ5" type='radio' label="Beberapa kali sebulan" id="q1152" />
                                                    <Form.Check inline value="1 kali seminggu" onChange={updateData} name="mengkonsumsiMakananMinumanQ5" type='radio' label="1 kali seminggu" id="q1153" />
                                                </Row>
                                            </Col>
                                            <Col xl="6" lg="6" md="12" sm="12">
                                                <Row>
                                                    <Form.Check inline value="Beberapa kali seminggu" onChange={updateData} name="mengkonsumsiMakananMinumanQ5" type='radio' label="Beberapa kali seminggu" id="q1154" />
                                                    <Form.Check inline value="Setiap hari" onChange={updateData} name="mengkonsumsiMakananMinumanQ5" type='radio' label="Setiap hari" id="q1155" />
                                                    <Form.Check inline value="Beberapa kali sehari" onChange={updateData} name="mengkonsumsiMakananMinumanQ5" type='radio' label="Beberapa kali sehari" id="q1156" />
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xl="12">
                                <Row>
                                    <Form.Label className='col-xl-12 col-lg-12 col-sm-12 col-md-12'>
                                        6. Permen
                                    </Form.Label>
                                    <Col xl="12" lg="12" sm="12" md="12" className="pb-2">
                                        <Row className="ps-4">
                                            <Col xl="6" lg="6" md="12" sm="12">
                                                <Row>
                                                    <Form.Check inline value="Tidak pernah" onChange={updateData} name="mengkonsumsiMakananMinumanQ6" type='radio' label="Tidak pernah" id="q1161" />
                                                    <Form.Check inline value="Beberapa kali sebulan" onChange={updateData} name="mengkonsumsiMakananMinumanQ6" type='radio' label="Beberapa kali sebulan" id="q1162" />
                                                    <Form.Check inline value="1 kali seminggu" onChange={updateData} name="mengkonsumsiMakananMinumanQ6" type='radio' label="1 kali seminggu" id="q1163" />
                                                </Row>
                                            </Col>
                                            <Col xl="6" lg="6" md="12" sm="12">
                                                <Row>
                                                    <Form.Check inline value="Beberapa kali seminggu" onChange={updateData} name="mengkonsumsiMakananMinumanQ6" type='radio' label="Beberapa kali seminggu" id="q1164" />
                                                    <Form.Check inline value="Setiap hari" onChange={updateData} name="mengkonsumsiMakananMinumanQ6" type='radio' label="Setiap hari" id="q1165" />
                                                    <Form.Check inline value="Beberapa kali sehari" onChange={updateData} name="mengkonsumsiMakananMinumanQ6" type='radio' label="Beberapa kali sehari" id="q1166" />
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xl="12">
                                <Row>
                                    <Form.Label className='col-xl-12 col-lg-12 col-sm-12 col-md-12'>
                                        7. Minuman bersoda
                                    </Form.Label>
                                    <Col xl="12" lg="12" sm="12" md="12" className="pb-2">
                                        <Row className="ps-4">
                                            <Col xl="6" lg="6" md="12" sm="12">
                                                <Row>
                                                    <Form.Check inline value="Tidak pernah" onChange={updateData} name="mengkonsumsiMakananMinumanQ7" type='radio' label="Tidak pernah" id="q1171" />
                                                    <Form.Check inline value="Beberapa kali sebulan" onChange={updateData} name="mengkonsumsiMakananMinumanQ7" type='radio' label="Beberapa kali sebulan" id="q1172" />
                                                    <Form.Check inline value="1 kali seminggu" onChange={updateData} name="mengkonsumsiMakananMinumanQ7" type='radio' label="1 kali seminggu" id="q1173" />
                                                </Row>
                                            </Col>
                                            <Col xl="6" lg="6" md="12" sm="12">
                                                <Row>
                                                    <Form.Check inline value="Beberapa kali seminggu" onChange={updateData} name="mengkonsumsiMakananMinumanQ7" type='radio' label="Beberapa kali seminggu" id="q1174" />
                                                    <Form.Check inline value="Setiap hari" onChange={updateData} name="mengkonsumsiMakananMinumanQ7" type='radio' label="Setiap hari" id="q1175" />
                                                    <Form.Check inline value="Beberapa kali sehari" onChange={updateData} name="mengkonsumsiMakananMinumanQ7" type='radio' label="Beberapa kali sehari" id="q1176" />
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xl="12">
                                <Row>
                                    <Form.Label className='col-xl-12 col-lg-12 col-sm-12 col-md-12'>
                                        8. Susu dengan gula
                                    </Form.Label>
                                    <Col xl="12" lg="12" sm="12" md="12" className="pb-2">
                                        <Row className="ps-4">
                                            <Col xl="6" lg="6" md="12" sm="12">
                                                <Row>
                                                    <Form.Check inline value="Tidak pernah" onChange={updateData} name="mengkonsumsiMakananMinumanQ8" type='radio' label="Tidak pernah" id="q1181" />
                                                    <Form.Check inline value="Beberapa kali sebulan" onChange={updateData} name="mengkonsumsiMakananMinumanQ8" type='radio' label="Beberapa kali sebulan" id="q1182" />
                                                    <Form.Check inline value="1 kali seminggu" onChange={updateData} name="mengkonsumsiMakananMinumanQ8" type='radio' label="1 kali seminggu" id="q1183" />
                                                </Row>
                                            </Col>
                                            <Col xl="6" lg="6" md="12" sm="12">
                                                <Row>
                                                    <Form.Check inline value="Beberapa kali seminggu" onChange={updateData} name="mengkonsumsiMakananMinumanQ8" type='radio' label="Beberapa kali seminggu" id="q1184" />
                                                    <Form.Check inline value="Setiap hari" onChange={updateData} name="mengkonsumsiMakananMinumanQ8" type='radio' label="Setiap hari" id="q1185" />
                                                    <Form.Check inline value="Beberapa kali sehari" onChange={updateData} name="mengkonsumsiMakananMinumanQ8" type='radio' label="Beberapa kali sehari" id="q1186" />
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xl="12">
                                <Row>
                                    <Form.Label className='col-xl-12 col-lg-12 col-sm-12 col-md-12'>
                                        9. Teh manis
                                    </Form.Label>
                                    <Col xl="12" lg="12" sm="12" md="12" className="pb-2">
                                        <Row className="ps-4">
                                            <Col xl="6" lg="6" md="12" sm="12">
                                                <Row>
                                                    <Form.Check inline value="Tidak pernah" onChange={updateData} name="mengkonsumsiMakananMinumanQ9" type='radio' label="Tidak pernah" id="q1191" />
                                                    <Form.Check inline value="Beberapa kali sebulan" onChange={updateData} name="mengkonsumsiMakananMinumanQ9" type='radio' label="Beberapa kali sebulan" id="q1192" />
                                                    <Form.Check inline value="1 kali seminggu" onChange={updateData} name="mengkonsumsiMakananMinumanQ9" type='radio' label="1 kali seminggu" id="q1193" />
                                                </Row>
                                            </Col>
                                            <Col xl="6" lg="6" md="12" sm="12">
                                                <Row>
                                                    <Form.Check inline value="Beberapa kali seminggu" onChange={updateData} name="mengkonsumsiMakananMinumanQ9" type='radio' label="Beberapa kali seminggu" id="q1194" />
                                                    <Form.Check inline value="Setiap hari" onChange={updateData} name="mengkonsumsiMakananMinumanQ9" type='radio' label="Setiap hari" id="q1195" />
                                                    <Form.Check inline value="Beberapa kali sehari" onChange={updateData} name="mengkonsumsiMakananMinumanQ9" type='radio' label="Beberapa kali sehari" id="q1196" />
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xl="12">
                                <Row>
                                    <Form.Label className='col-xl-12 col-lg-12 col-sm-12 col-md-12'>
                                        10. Kopi dengan gula
                                    </Form.Label>
                                    <Col xl="12" lg="12" sm="12" md="12" className="pb-2">
                                        <Row className="ps-4">
                                            <Col xl="6" lg="6" md="12" sm="12">
                                                <Row>
                                                    <Form.Check inline value="Tidak pernah" onChange={updateData} name="mengkonsumsiMakananMinumanQ10" type='radio' label="Tidak pernah" id="q11101" />
                                                    <Form.Check inline value="Beberapa kali sebulan" onChange={updateData} name="mengkonsumsiMakananMinumanQ10" type='radio' label="Beberapa kali sebulan" id="q11102" />
                                                    <Form.Check inline value="1 kali seminggu" onChange={updateData} name="mengkonsumsiMakananMinumanQ10" type='radio' label="1 kali seminggu" id="q11103" />
                                                </Row>
                                            </Col>
                                            <Col xl="6" lg="6" md="12" sm="12">
                                                <Row>
                                                    <Form.Check inline value="Beberapa kali seminggu" onChange={updateData} name="mengkonsumsiMakananMinumanQ10" type='radio' label="Beberapa kali seminggu" id="q11104" />
                                                    <Form.Check inline value="Setiap hari" onChange={updateData} name="mengkonsumsiMakananMinumanQ10" type='radio' label="Setiap hari" id="q11105" />
                                                    <Form.Check inline value="Beberapa kali sehari" onChange={updateData} name="mengkonsumsiMakananMinumanQ10" type='radio' label="Beberapa kali sehari" id="q11106" />
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className='mt-3'>
                            <h5 className={`text-center mb-3 ${type === "dewasa" ? "kuesioner-title-blue" : "kuesioner-title-yellow"}`}>KONSUMSI TEMBAKAU</h5>
                            <p>Seberapa sering Anda mengkonsumsi jenis tembakau berikut?</p>
                            <hr />
                            <Col xl="12">
                                <Row>
                                    <Form.Label className='col-xl-12 col-lg-12 col-sm-12 col-md-12'>
                                        1. Sigaret
                                    </Form.Label>
                                    <Col xl="12" lg="12" sm="12" md="12" className="pb-2">
                                        <Row className="ps-4">
                                            <Col xl="6" lg="6" md="12" sm="12">
                                                <Row>
                                                    <Form.Check inline value="Tidak pernah" onChange={updateData} name="konsumsiTembakauQ1" type='radio' label="Tidak pernah" id="q1211" />
                                                    <Form.Check inline value="Jarang" onChange={updateData} name="konsumsiTembakauQ1" type='radio' label="Jarang" id="q1212" />
                                                    <Form.Check inline value="Beberapa kali sebulan" onChange={updateData} name="konsumsiTembakauQ1" type='radio' label="Beberapa kali sebulan" id="q1213" />
                                                </Row>
                                            </Col>
                                            <Col xl="6" lg="6" md="12" sm="12">
                                                <Row>
                                                    <Form.Check inline value="1 kali seminggu" onChange={updateData} name="konsumsiTembakauQ1" type='radio' label="1 kali seminggu" id="q1214" />
                                                    <Form.Check inline value="Beberapa kali seminggu" onChange={updateData} name="konsumsiTembakauQ1" type='radio' label="Beberapa kali seminggu" id="q1215" />
                                                    <Form.Check inline value="Setiap hari" onChange={updateData} name="konsumsiTembakauQ1" type='radio' label="Setiap hari" id="q1216" />
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xl="12">
                                <Row>
                                    <Form.Label className='col-xl-12 col-lg-12 col-sm-12 col-md-12'>
                                        2. Cerutu
                                    </Form.Label>
                                    <Col xl="12" lg="12" sm="12" md="12" className="pb-2">
                                        <Row className="ps-4">
                                            <Col xl="6" lg="6" md="12" sm="12">
                                                <Row>
                                                    <Form.Check inline value="Tidak pernah" onChange={updateData} name="konsumsiTembakauQ2" type='radio' label="Tidak pernah" id="q1221" />
                                                    <Form.Check inline value="Jarang" onChange={updateData} name="konsumsiTembakauQ2" type='radio' label="Jarang" id="q1222" />
                                                    <Form.Check inline value="Beberapa kali sebulan" onChange={updateData} name="konsumsiTembakauQ2" type='radio' label="Beberapa kali sebulan" id="q1223" />
                                                </Row>
                                            </Col>
                                            <Col xl="6" lg="6" md="12" sm="12">
                                                <Row>
                                                    <Form.Check inline value="1 kali seminggu" onChange={updateData} name="konsumsiTembakauQ2" type='radio' label="1 kali seminggu" id="q1224" />
                                                    <Form.Check inline value="Beberapa kali seminggu" onChange={updateData} name="konsumsiTembakauQ2" type='radio' label="Beberapa kali seminggu" id="q1225" />
                                                    <Form.Check inline value="Setiap hari" onChange={updateData} name="konsumsiTembakauQ2" type='radio' label="Setiap hari" id="q1226" />
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xl="12">
                                <Row>
                                    <Form.Label className='col-xl-12 col-lg-12 col-sm-12 col-md-12'>
                                        3. Pipa
                                    </Form.Label>
                                    <Col xl="12" lg="12" sm="12" md="12" className="pb-2">
                                        <Row className="ps-4">
                                            <Col xl="6" lg="6" md="12" sm="12">
                                                <Row>
                                                    <Form.Check inline value="Tidak pernah" onChange={updateData} name="konsumsiTembakauQ3" type='radio' label="Tidak pernah" id="q1231" />
                                                    <Form.Check inline value="Jarang" onChange={updateData} name="konsumsiTembakauQ3" type='radio' label="Jarang" id="q1232" />
                                                    <Form.Check inline value="Beberapa kali sebulan" onChange={updateData} name="konsumsiTembakauQ3" type='radio' label="Beberapa kali sebulan" id="q1233" />
                                                </Row>
                                            </Col>
                                            <Col xl="6" lg="6" md="12" sm="12">
                                                <Row>
                                                    <Form.Check inline value="1 kali seminggu" onChange={updateData} name="konsumsiTembakauQ3" type='radio' label="1 kali seminggu" id="q1234" />
                                                    <Form.Check inline value="Beberapa kali seminggu" onChange={updateData} name="konsumsiTembakauQ3" type='radio' label="Beberapa kali seminggu" id="q1235" />
                                                    <Form.Check inline value="Setiap hari" onChange={updateData} name="konsumsiTembakauQ3" type='radio' label="Setiap hari" id="q1236" />
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xl="12">
                                <Row>
                                    <Form.Label className='col-xl-12 col-lg-12 col-sm-12 col-md-12'>
                                        4. Tembakau kunyah
                                    </Form.Label>
                                    <Col xl="12" lg="12" sm="12" md="12" className="pb-2">
                                        <Row className="ps-4">
                                            <Col xl="6" lg="6" md="12" sm="12">
                                                <Row>
                                                    <Form.Check inline value="Tidak pernah" onChange={updateData} name="konsumsiTembakauQ4" type='radio' label="Tidak pernah" id="q1241" />
                                                    <Form.Check inline value="Jarang" onChange={updateData} name="konsumsiTembakauQ4" type='radio' label="Jarang" id="q1242" />
                                                    <Form.Check inline value="Beberapa kali sebulan" onChange={updateData} name="konsumsiTembakauQ4" type='radio' label="Beberapa kali sebulan" id="q1243" />
                                                </Row>
                                            </Col>
                                            <Col xl="6" lg="6" md="12" sm="12">
                                                <Row>
                                                    <Form.Check inline value="1 kali seminggu" onChange={updateData} name="konsumsiTembakauQ4" type='radio' label="1 kali seminggu" id="q1244" />
                                                    <Form.Check inline value="Beberapa kali seminggu" onChange={updateData} name="konsumsiTembakauQ4" type='radio' label="Beberapa kali seminggu" id="q1245" />
                                                    <Form.Check inline value="Setiap hari" onChange={updateData} name="konsumsiTembakauQ4" type='radio' label="Setiap hari" id="q1246" />
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xl="12">
                                <Row>
                                    <Form.Label className='col-xl-12 col-lg-12 col-sm-12 col-md-12'>
                                        5. Tembakau sirih
                                    </Form.Label>
                                    <Col xl="12" lg="12" sm="12" md="12" className="pb-2">
                                        <Row className="ps-4">
                                            <Col xl="6" lg="6" md="12" sm="12">
                                                <Row>
                                                    <Form.Check inline value="Tidak pernah" onChange={updateData} name="konsumsiTembakauQ5" type='radio' label="Tidak pernah" id="q1251" />
                                                    <Form.Check inline value="Jarang" onChange={updateData} name="konsumsiTembakauQ5" type='radio' label="Jarang" id="q1252" />
                                                    <Form.Check inline value="Beberapa kali sebulan" onChange={updateData} name="konsumsiTembakauQ5" type='radio' label="Beberapa kali sebulan" id="q1253" />
                                                </Row>
                                            </Col>
                                            <Col xl="6" lg="6" md="12" sm="12">
                                                <Row>
                                                    <Form.Check inline value="1 kali seminggu" onChange={updateData} name="konsumsiTembakauQ5" type='radio' label="1 kali seminggu" id="q1254" />
                                                    <Form.Check inline value="Beberapa kali seminggu" onChange={updateData} name="konsumsiTembakauQ5" type='radio' label="Beberapa kali seminggu" id="q1255" />
                                                    <Form.Check inline value="Setiap hari" onChange={updateData} name="konsumsiTembakauQ5" type='radio' label="Setiap hari" id="q1256" />
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xl="12">
                                <Row>
                                    <Form.Label className='col-xl-12 col-lg-12 col-sm-12 col-md-12'>
                                        6. Lainnya
                                    </Form.Label>
                                    <Col xl="12" lg="12" sm="12" md="12" className="pb-2">
                                        <Row className="ps-4">
                                            <Col xl="6" lg="6" md="12" sm="12">
                                                <Row>
                                                    <Form.Check inline value="Tidak pernah" onChange={updateDataLainnya2} name="konsumsiTembakauQ6" type='radio' label="Tidak pernah" id="q1261" />
                                                    <Form.Check inline value="Jarang" onChange={updateDataLainnya2} name="konsumsiTembakauQ6" type='radio' label="Jarang" id="q1262" />
                                                    <Form.Check inline value="Beberapa kali sebulan" onChange={updateDataLainnya2} name="konsumsiTembakauQ6" type='radio' label="Beberapa kali sebulan" id="q1263" />
                                                </Row>
                                            </Col>
                                            <Col xl="6" lg="6" md="12" sm="12">
                                                <Row>
                                                    <Form.Check inline value="1 kali seminggu" onChange={updateDataLainnya2} name="konsumsiTembakauQ6" type='radio' label="1 kali seminggu" id="q1264" />
                                                    <Form.Check inline value="Beberapa kali seminggu" onChange={updateDataLainnya2} name="konsumsiTembakauQ6" type='radio' label="Beberapa kali seminggu" id="q1265" />
                                                    <Form.Check inline value="Setiap hari" onChange={updateDataLainnya2} name="konsumsiTembakauQ6" type='radio' label="Setiap hari" id="q1266" />
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xl="12">
                                <Form.Group as={Row} className="mb-3" controlId="lainnya2">
                                    <Form.Label column xl="2" md="2" sm="2">
                                        Lainnya
                                    </Form.Label>
                                    <Col xl="10" md="10" sm="10">
                                        <Form.Control type='text' name='konsumsiTembakauLainnya' onChange={updateDataLainnya2} disabled={disableLainnya2} />
                                    </Col>
                                </Form.Group>
                            </Col>

                        </Row>
                        {type === "dewasa" && <Row className='mt-3'>
                            <h5 className={`text-center mb-3 ${type === "dewasa" ? "kuesioner-title-blue" : "kuesioner-title-yellow"}`}>KONSUMSI ALKOHOL</h5>
                            <p>Dalam kurun waktu 30 hari terakhir, dalam seharinya, berapa gelas sehari
                                biasanya Anda mengkonsumsi minuman yang mengandung alkohol?</p>
                            <hr />
                            <Col xl="12">
                                <Row className="ps-4">
                                    <Col xl="6">
                                        <Row>
                                            <Form.Check inline value="< 1 gelas" onChange={updateData} name="konsumsiAlkohol" type='radio' label="< 1 gelas" id="q131" />
                                            <Form.Check inline value="1 gelas" onChange={updateData} name="konsumsiAlkohol" type='radio' label="1 gelas" id="q132" />
                                            <Form.Check inline value="2 gelas" onChange={updateData} name="konsumsiAlkohol" type='radio' label="2 gelas" id="q133" />
                                            <Form.Check inline value="3 gelas" onChange={updateData} name="konsumsiAlkohol" type='radio' label="3 gelas" id="q134" />

                                        </Row>
                                    </Col>
                                    <Col xl="6">
                                        <Row>
                                            <Form.Check inline value="4 gelas" onChange={updateData} name="konsumsiAlkohol" type='radio' label="4 gelas" id="q135" />
                                            <Form.Check inline value="5 gelas" onChange={updateData} name="konsumsiAlkohol" type='radio' label="5 gelas" id="q136" />
                                            <Form.Check inline value="6 gelas" onChange={updateData} name="konsumsiAlkohol" type='radio' label="6 gelas" id="q137" />
                                            <Form.Check inline value="Tidak mengkonsumsi alkohol" onChange={updateData} name="q1" type='radio' label="Tidak mengkonsumsi alkohol" id="q138" />

                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>}
                        {type === "dewasa" && <Row className='mt-3'>
                            <h5 className={`text-center mb-3 ${type === "dewasa" ? "kuesioner-title-blue" : "kuesioner-title-yellow"}`}>PENDIDIKAN TERAKHIR</h5>
                            <p>Apa jenjang pendidikan terakhir Anda?</p>
                            <hr />
                            <Col xl="12">
                                <Row className="ps-4">
                                    <Col xl="6">
                                        <Row>
                                            <Form.Check inline value="Tidak menempuh pendidikan formal" onChange={updateData} name="pendidikanTerakhir" type='radio' label="Tidak menempuh pendidikan formal" id="q141" />
                                            <Form.Check inline value="Sekolah Dasar (SD) atau sederajat" onChange={updateData} name="pendidikanTerakhir" type='radio' label="Sekolah Dasar (SD) atau sederajat" id="q142" />
                                            <Form.Check inline value="Sekolah Menegah Pertama (SMP) atau sederajat." onChange={updateData} name="pendidikanTerakhir" type='radio' label="Sekolah Menegah Pertama (SMP) atau sederajat." id="q143" />
                                            <Form.Check inline value="Sekolah Menengah Akhir (SMA) atau sederajat" onChange={updateData} name="pendidikanTerakhir" type='radio' label="Sekolah Menengah Akhir (SMA) atau sederajat" id="q144" />
                                            <Form.Check inline value="Diploma I." onChange={updateData} name="pendidikanTerakhir" type='radio' label="Diploma I." id="q145" />
                                        </Row>
                                    </Col>
                                    <Col xl="6">
                                        <Row>
                                            <Form.Check inline value="Diploma III" onChange={updateData} name="pendidikanTerakhir" type='radio' label="Diploma III" id="q146" />
                                            <Form.Check inline value="Diploma IV" onChange={updateData} name="pendidikanTerakhir" type='radio' label="Diploma IV" id="q147" />
                                            <Form.Check inline value="Sarjana (S1)" onChange={updateData} name="pendidikanTerakhir" type='radio' label="Sarjana (S1)" id="q148" />
                                            <Form.Check inline value="Magister (S2)" onChange={updateData} name="pendidikanTerakhir" type='radio' label="Magister (S2)" id="q149" />
                                            <Form.Check inline value="Doktor (S3)" onChange={updateData} name="pendidikanTerakhir" type='radio' label="Doktor (S3)" id="q1410" />                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>}
                        {type === "anak-anak" && <>
                            <Row className='mt-3'>
                                <h5 className={`text-center mb-3 ${type === "dewasa" ? "kuesioner-title-blue" : "kuesioner-title-yellow"}`}>PENDIDIKAN TERAKHIR ORANG TUA(AYAH)</h5>
                                <p>Apa jenjang pendidikan terakhir Ayah Anda?</p>
                                <hr />
                                <Col xl="12">
                                    <Row className="ps-4">
                                        <Col xl="6">
                                            <Row>
                                                <Form.Check inline value="Tidak menempuh pendidikan formal" onChange={updateData} name="pendidikanTerakhirAyah" type='radio' label="Tidak menempuh pendidikan formal" id="q141" />
                                                <Form.Check inline value="Sekolah Dasar (SD) atau sederajat" onChange={updateData} name="pendidikanTerakhirAyah" type='radio' label="Sekolah Dasar (SD) atau sederajat" id="q142" />
                                                <Form.Check inline value="Sekolah Menegah Pertama (SMP) atau sederajat." onChange={updateData} name="pendidikanTerakhirAyah" type='radio' label="Sekolah Menegah Pertama (SMP) atau sederajat." id="q143" />
                                                <Form.Check inline value="Sekolah Menengah Akhir (SMA) atau sederajat" onChange={updateData} name="pendidikanTerakhirAyah" type='radio' label="Sekolah Menengah Akhir (SMA) atau sederajat" id="q144" />
                                                <Form.Check inline value="Diploma I." onChange={updateData} name="pendidikanTerakhirAyah" type='radio' label="Diploma I." id="q145" />
                                            </Row>
                                        </Col>
                                        <Col xl="6">
                                            <Row>
                                                <Form.Check inline value="Diploma III" onChange={updateData} name="pendidikanTerakhirAyah" type='radio' label="Diploma III" id="q146" />
                                                <Form.Check inline value="Diploma IV" onChange={updateData} name="pendidikanTerakhirAyah" type='radio' label="Diploma IV" id="q147" />
                                                <Form.Check inline value="Sarjana (S1)" onChange={updateData} name="pendidikanTerakhirAyah" type='radio' label="Sarjana (S1)" id="q148" />
                                                <Form.Check inline value="Magister (S2)" onChange={updateData} name="pendidikanTerakhirAyah" type='radio' label="Magister (S2)" id="q149" />
                                                <Form.Check inline value="Doktor (S3)" onChange={updateData} name="pendidikanTerakhirAyah" type='radio' label="Doktor (S3)" id="q1410" />                                        </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className='mt-3'>
                                <h5 className={`text-center mb-3 ${type === "dewasa" ? "kuesioner-title-blue" : "kuesioner-title-yellow"}`}>PENDIDIKAN TERAKHIR ORANG TUA(IBU)</h5>
                                <p>Apa jenjang pendidikan terakhir Ibu Anda?</p>
                                <hr />
                                <Col xl="12">
                                    <Row className="ps-4">
                                        <Col xl="6">
                                            <Row>
                                                <Form.Check inline value="Tidak menempuh pendidikan formal" onChange={updateData} name="pendidikanTerakhirIbu" type='radio' label="Tidak menempuh pendidikan formal" id="q141" />
                                                <Form.Check inline value="Sekolah Dasar (SD) atau sederajat" onChange={updateData} name="pendidikanTerakhirIbu" type='radio' label="Sekolah Dasar (SD) atau sederajat" id="q142" />
                                                <Form.Check inline value="Sekolah Menegah Pertama (SMP) atau sederajat." onChange={updateData} name="pendidikanTerakhirIbu" type='radio' label="Sekolah Menegah Pertama (SMP) atau sederajat." id="q143" />
                                                <Form.Check inline value="Sekolah Menengah Akhir (SMA) atau sederajat" onChange={updateData} name="pendidikanTerakhirIbu" type='radio' label="Sekolah Menengah Akhir (SMA) atau sederajat" id="q144" />
                                                <Form.Check inline value="Diploma I." onChange={updateData} name="pendidikanTerakhirIbu" type='radio' label="Diploma I." id="q145" />
                                            </Row>
                                        </Col>
                                        <Col xl="6">
                                            <Row>
                                                <Form.Check inline value="Diploma III" onChange={updateData} name="pendidikanTerakhirIbu" type='radio' label="Diploma III" id="q146" />
                                                <Form.Check inline value="Diploma IV" onChange={updateData} name="pendidikanTerakhirIbu" type='radio' label="Diploma IV" id="q147" />
                                                <Form.Check inline value="Sarjana (S1)" onChange={updateData} name="pendidikanTerakhirIbu" type='radio' label="Sarjana (S1)" id="q148" />
                                                <Form.Check inline value="Magister (S2)" onChange={updateData} name="pendidikanTerakhirIbu" type='radio' label="Magister (S2)" id="q149" />
                                                <Form.Check inline value="Doktor (S3)" onChange={updateData} name="pendidikanTerakhirIbu" type='radio' label="Doktor (S3)" id="q1410" />                                        </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </>}
                        <hr />
                        <Row>
                            <Col xl={12}>
                                <Button className='primary float-end' type='submit'>Submit</Button>
                            </Col>
                        </Row>
                    </Form>
                </Cards>}

            </ContainerCards>

            <ModalConfirmation show={showConfirmation} handleClose={() => { setShowConfirmation(false) }} handleSubmit={onSubmit} message="Apakah anda yakin?" />

            {response?.status && <ToastPopup message={response.data.msg} showToast={showToast} setShowToast={setShowToast} response={response?.status} />
            }
        </>
    );
}

export default QuestionerAdd